import React from "react";
import { css } from "@emotion/react";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import DropdownField from "../../../components/forms/DropdownField";
import { isEnteredStatusSearchFilterState } from "./OrderItemInfo";

const OrderItemInfoEnterTabPanelContent = ({
    handleEnterEvent,
    formik,
    dropdownItemTemplate = null,
    isEnteredStatusDropdownItems = [],
}) => {
    return (
        <div className="p-fluid formgrid grid col-12">
            <div className="field grid col-12" css={inputGroupStyle}>
                <label
                    htmlFor="ItemWeight"
                    className="col-12 mb-2 md:col-2 md:mb-0"
                    css={inputLabelStyle}
                >
                    무게
                </label>
                <InputTextWithError
                    className={"col-12 md:col-10"}
                    name="ItemWeight"
                    type="number"
                    onKeyDown={handleEnterEvent}
                    onChange={formik.handleChange}
                    value={formik.values.ItemWeight ?? ''}
                    error={formik.errors.ItemWeight}
                    min={0}
                    step={0.01}
                />
                <div css={unitStyle}>kg</div>
            </div>

            <div className="field grid col-12" css={inputGroupStyle}>
                <label
                    htmlFor="ItemWidth"
                    className="col-12 mb-2 md:col-2 md:mb-0"
                    css={inputLabelStyle}
                >
                    Width
                </label>
                <InputTextWithError
                    className={"col-12 md:col-10"}
                    name="ItemWidth"
                    type="number"
                    onKeyDown={handleEnterEvent}
                    onChange={formik.handleChange}
                    value={formik.values.ItemWidth ?? ''}
                    error={formik.errors.ItemWidth}
                    min={0}
                    step={0.01}
                />
                <div css={unitStyle}>cm</div>
            </div>

            <div className="field grid col-12" css={inputGroupStyle}>
                <label
                    htmlFor="ItemHeight"
                    className="col-12 mb-2 md:col-2 md:mb-0"
                    css={inputLabelStyle}
                >
                    Height
                </label>
                <InputTextWithError
                    className={"col-12 md:col-10"}
                    name="ItemHeight"
                    type="number"
                    onKeyDown={handleEnterEvent}
                    onChange={formik.handleChange}
                    value={formik.values.ItemHeight ?? ''}
                    error={formik.errors.ItemHeight}
                    min={0}
                    step={0.01}
                />
                <div css={unitStyle}>cm</div>
            </div>

            <div className="field grid col-12" css={inputGroupStyle}>
                <label
                    htmlFor="ItemDepth"
                    className="col-12 mb-2 md:col-2 md:mb-0"
                    css={inputLabelStyle}
                >
                    Depth
                </label>
                <InputTextWithError
                    className={"col-12 md:col-10"}
                    name="ItemDepth"
                    type="number"
                    onKeyDown={handleEnterEvent}
                    onChange={formik.handleChange}
                    value={formik.values.ItemDepth ?? ''}
                    error={formik.errors.ItemDepth}
                    min={0}
                    step={0.01}
                />
                <div css={unitStyle}>cm</div>
            </div>

            <div className="field grid col-12" css={inputGroupStyle}>
                <label
                    htmlFor="IsEntered"
                    className="col-12 mb-2 md:col-2 md:mb-0"
                    css={inputLabelStyle}
                >
                    입고상태
                </label>
                <span css={dropdownSearchFilterContainerStyle} className={"col-12 md:col-10"}>
                    <DropdownField
                        css={dropdownSearchFilterStyle}
                        atom={isEnteredStatusSearchFilterState}
                        dropdownItems={isEnteredStatusDropdownItems}
                        stateKey={"isEnteredItem"}
                        name={"IsEntered"}
                        itemTemplate={dropdownItemTemplate}
                        placeholder={"Select One"}
                        error={formik.errors.isEnteredStatusDropdownItem}
                    />
                </span>
            </div>
        </div>
    )
}

export default OrderItemInfoEnterTabPanelContent;

const inputGroupStyle = css`
    min-height: 54px;

    &.field.grid label {
        height: 3rem;
        min-width: 8rem;
    }

    > span {
        flex: 1;
    }

    input {
        height: 3rem;
    }

    .p-dropdown.p-component {
        height: 3rem;
        max-height: 3rem;

        .p-inputtext {
            height: 100%;
        }
    }

    .p-dropdown-label.p-inputtext {
        font-size: 24px;
    }

    .p-inputtext.p-component.p-filled {
        font-size: 28px;
    }
`;

const inputLabelStyle = css`
    width: 100px;
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 12px !important;
`;

const dropdownSearchFilterContainerStyle = css`
    margin-bottom: 12px;
    padding-left: 0;
    height: 41px; // TODO 나중에 고쳐야 할지도? 
`;

const dropdownSearchFilterStyle = css`
    padding-left: 0;
`;

const unitStyle = css`
    display: flex;
    align-items: flex-end;
    padding-bottom: 12px;
    padding-left: 5px;
    font-size: 30px;
    min-width: 50px;
`;



