import React from "react";
import { css } from "@emotion/react";
import BarcodeBox from "./BarcodeBox";
import DeliverToBox from "./DeliverToBox";
import FromBox from "./FromBox";

const Body = ({
    consignment,
}) => (
    <div css={bodyContainerStyle}>
        <BarcodeBox consignment={consignment} css={borderBottomBoxStyle} />
        <DeliverToBox consignment={consignment} css={borderBottomBoxStyle} />
        <FromBox consignment={consignment} css={fromBoxStyle}/>
    </div>
);

export default Body;

const bodyContainerStyle = css`
    display: flex;
    flex-direction: column;
    border: 1px solid;
`;

const boxStyle = css`
    padding: 10px;
`;

const borderBottomBoxStyle = css`
    ${boxStyle};
    border-bottom: 1px solid;
`;

const fromBoxStyle = css`
    ${boxStyle};
`;