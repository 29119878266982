import React from "react";
import PropTypes from "prop-types";

const Empty = ({ path }) => (
    <div className="grid">
        <div className="col-12">
            <div className="card">
                <h5>Empty Page</h5>
                {path
                    ? (<p>요청하신 페이지({path})를 찾을 수가 없습니다.</p>)
                    : (<p>요청하신 페이지를 찾을 수가 없습니다.</p>)
                }
            </div>
        </div>
    </div>
);

Empty.propTypes = {
    path: PropTypes.string,
}

export default Empty;