import React, { useMemo } from "react";
import { useRecoilState } from "recoil";
import { throttle } from "lodash";
import { DataTable } from "primereact/datatable";

import { selectedCustomersState } from "./store";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../includes/constants";
import useCustomersData from "./hooks/useCustomersData";
import useDataTableColumns from "../../core/hooks/useDataTableColumns";
import useDataTableExportCSV from "../../core/hooks/useDataTableExportCSV";

import CustomerPageFilter from "./components/CustomerPageFilter";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import ScrollDataTableContainer from "../../components/layouts/ScrollDataTableContainer";
import { formatIntNumber } from "../../includes/numbers";
import { css } from "@emotion/react";

const fieldColumnTitleMap = {
    UserId: "회원 ID",
    UserName: "회원명",
    UserLoginId: "아이디",
    EmailAddress: "이메일",
    PhoneNumber: "전화번호",
    MobileNumber: "휴대폰",
    IsActive: "사용여부",
    CreatDate: "회원가입일",
    LastLoginDate: "최종로그인",
    LastUpdateDate: "최종수정일",
    ZipCodeKR: "우편번호",
    UserAddressKR1: "주소",
    UserAddressKR2: "상세주소",
    ZipCodeNZ: "우편번호",
    UserAddressNZ1: "주소",
    UserAddressNZ2: "상세주소",
    Mileage: "마일리지",
    PersonalCustomCode: "개인통관부호",
}

const sortableFields = [
    "UserId",
    "UserLoginId",
    "UserName",
    "EmailAddress",
    "IsActive",
    "PhoneNumber",
    "MobileNumber",
    "LastLoginDate",
    "LastUpdateDate",
    "Mileage",
];

const CustomerPage = () => {
    const [selectedItems, setSelectedItems] = useRecoilState(selectedCustomersState);
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: customersData, loading: customersDataLoading }, getCustomers] = useCustomersData();

    const onSearch = useMemo(() => {
        return throttle((e) => {
            if (customersDataLoading) return getPromise();
            return getCustomers();
        }, 1000);
    }, [getCustomers, customersDataLoading]);

    const {
        sortPaginationProps,
        renderColumn,
        renderLinkColumn,
        renderIndex,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getCustomers,
        dataSize: customersData?.Data?.length ?? 0,
    });

    return (
        <div className="grid" css={customerPageStyle}>
            <div className="col-12">

                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>회원 리스트</h5>
                    <CustomerPageFilter onSearch={onSearch} loading={customersDataLoading} />
                </div>

                <div className="card">

                    <ScrollDataTableContainer loading={customersDataLoading} minWidth={1024}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={customersDataLoading}
                            ref={dataTableRef}
                            value={customersData?.Data || []}
                            selection={selectedItems}
                            onSelectionChange={(e) => setSelectedItems(e.value)}
                            dataKey="CustomerId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={customersData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={customersData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                        >
                            {renderIndex("번호")}
                            {renderLinkColumn("UserLoginId", "UserId")}
                            {renderColumn("UserName")}
                            {renderColumn("EmailAddress")}
                            {renderColumn("CreatDate")}
                            {renderColumn("LastLoginDate")}
                            {renderColumn("Mileage", { formatValue: formatIntNumber })}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}

export default CustomerPage;

const customerPageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-Mileage {
            text-align: right;
            width: 140px;
        }
    }

    .column-Mileage {
        text-align: right;
        padding-right: 20px;
    }
`;