import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";

import { NOOP, PERMISSION_ADMIN } from "../../../includes/constants";
import useCreateConsignment from "../../../service/hooks/useCreateConsignment";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";

import { orderDetailPageDeliveryStatusState, orderDetailPageFlightSelectState } from "../store";
import Loading from "../../../components/loading/Loading";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import DataTableActions from "../../../components/layouts/DataTableActions";
import DeliveryStatusSearchFilter from "../../../components/filters/DeliveryStatusSearchFilter";
import FlightSelectSearchFilter from "../../../components/filters/FlightSelectSearchFilter";
import ConfirmActionButton from "../../../components/action-buttons/ConfirmActionButton";
import CreateConsignmentActionButton from "../../../components/action-buttons/CreateConsignmentActionButton";
import ConsignmentPrintActionButton from "../../../components/action-buttons/ConsignmentPrintActionButton";
import PrintConsignmentDialog from "../../Consignment/components/PrintConsignmentDialog";
import { getColumnValue } from "../../../includes/datatable";
import { toNumber, getNewZealandCurrencyMark } from "../../../includes/numbers";
import { currentUserState } from "../../../includes/auth";

const OrderInfo = ({
  refresh = NOOP,
  onSaveOrderInfo = NOOP,
  orderData = null,
  className = "",
}) => {

  const currentUser = useRecoilValue(currentUserState);

  const [onConfirmCreateConsignment] = useCreateConsignment({
    callbackAfterSuccess: refresh,
    orderIds: [Number(orderData?.OrderId)],
    successMessage: "송장을 성공적으로 발행하였습니다.",
  });

  const renderDialog = useCallback(({
    onCancel,
    onConfirm,
  }) => {
    const consignmentIds = [orderData?.ConsignmentId];
    return (
      <PrintConsignmentDialog
        onCancel={onCancel}
        onConfirm={onConfirm}
        title="송장출력"
        consignmentIds={consignmentIds}
      />
    )
  }, [orderData?.ConsignmentId]);

  const printConsignmentClicked = useCreateActionButton({
    onSelect: refresh,
    confirmMessage: "송장을 출력하시겠습니까?",
    renderDialog: renderDialog
  });

  const totalInvoiceAmount = useMemo(() => {
    if (!orderData?.OrderInvoiceList) return '';

    const sum = orderData.OrderInvoiceList.reduce((sum, invoice) => {
      sum += Number(invoice.TotalAmount);
      return sum;
    }, 0);

    return sum.toFixed(2);
  }, [orderData]);



  return (
    <div className={classNames("col-12", className)}>
      <form noValidate>
        {!orderData && <Loading />}

        <DataTableActions className={"mb-2"}>

          {orderData?.ConsignmentNumber
            ? (
              <ConsignmentPrintActionButton
                className={"mb-2 ml-2"}
                onOkayClicked={printConsignmentClicked}
              />
            )
            : (
              <CreateConsignmentActionButton
                className={"mb-2 ml-2"}
                onOkayClicked={onConfirmCreateConsignment}
              />
            )
          }

          <ConfirmActionButton
            icon="pi pi-pencil"
            className={"mb-2 ml-2"}
            label={"주문정보 수정"}
            onOkayClicked={onSaveOrderInfo}
          >
            주문정보를 수정하시겠습니까?
          </ConfirmActionButton>
        </DataTableActions>

        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-3">
            <label htmlFor="OrderId">주문번호</label>

            <InputTextWithError
              name="OrderId"
              type="text"
              value={orderData?.OrderNumber || ''}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="UserName">주문자</label>
            <InputTextWithError
              name="UserName"
              type="text"
              value={orderData?.UserName || ''}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="DeliveryLocationName">배송지역</label>
            <InputTextWithError
              name="DeliveryLocationName"
              type="text"
              value={orderData?.DeliveryLocationName || ''}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>


          <div className="field col-12 md:col-3">
            {currentUser.permission === PERMISSION_ADMIN &&
              <>
                <label htmlFor="TotalChargeAmount">결제금액</label>
                <InputTextWithError
                  name="TotalChargeAmount"
                  type="text"
                  value={`${getNewZealandCurrencyMark()}${toNumber(orderData?.TotalChargeAmount)}`}
                  onChange={NOOP}
                  readOnly
                  noBorder
                />
              </>
            }
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="OrderDate">주문일</label>
            <InputTextWithError
              name="OrderDate"
              type="text"
              value={orderData?.OrderDate || ''}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="ConsignmentNumber">송장번호</label>
            <InputTextWithError
              name="ConsignmentNumber"
              type="text"
              value={orderData?.ConsignmentNumber || ''}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="ConsignmentNumber">수령방식</label>
            <InputTextWithError
              name="ConsignmentNumber"
              type="text"
              value={getColumnValue("PickupTypeCode", orderData?.PickupTypeCode)}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>

          <div className="field col-12 md:col-3">
            {currentUser.permission === PERMISSION_ADMIN &&
              <>
                <label htmlFor="totalInvoiceAmount">인보이스 발행 금액</label>
                <InputTextWithError
                  name="totalInvoiceAmount"
                  type="text"
                  value={`${getNewZealandCurrencyMark()}${toNumber(totalInvoiceAmount)}`}
                  onChange={NOOP}
                  readOnly
                  noBorder
                />
              </>
            }
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="IsCombineItem">합배송신청</label>
            <InputTextWithError
              name="IsCombineItem"
              type="text"
              value={getColumnValue("IsCombineItem", orderData?.IsCombineItem)}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="IsRepackage">재포장신청</label>
            <InputTextWithError
              name="IsRepackage"
              type="text"
              value={getColumnValue("IsRepackage", orderData?.IsRepackage)}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="IsUseMileage">마일리지사용</label>
            <InputTextWithError
              name="IsUseMileage"
              type="text"
              value={getColumnValue("IsUseMileage", orderData?.IsUseMileage)}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>

          <div className="field col-12 md:col-3">
            {currentUser.permission === PERMISSION_ADMIN &&
              <>
                <label htmlFor="TotalPaidAmount">입금액</label>
                <InputTextWithError
                  name="TotalPaidAmount"
                  type="text"
                  value={`${getNewZealandCurrencyMark()}${toNumber(orderData?.TotalPaidAmount)}`}
                  onChange={NOOP}
                  readOnly
                  noBorder
                />
              </>
            }
          </div>


          <div className="field col-12 md:col-3">
            <FlightSelectSearchFilter
              className={"field"}
              css={deliveryStatusSearchFilterStyle}
              label={"운항명"}
              state={orderDetailPageFlightSelectState}
            />
          </div>
          <div className="field col-12 md:col-3">
              <DeliveryStatusSearchFilter
                className={"field"}
                css={deliveryStatusSearchFilterStyle}
                label={"배송상태"}
                atom={orderDetailPageDeliveryStatusState}
              />
          </div>
         
        </div>
      </form>
    </div>
  )
}

export default OrderInfo;

const deliveryStatusSearchFilterStyle = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const checkboxLabelStyle = css`
    cursor: pointer;
`;