import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { useFormik } from "formik";
import { css } from "@emotion/react";
import { trim } from "lodash";
import { TabPanel } from "primereact/tabview";

import { isFalse, isTrue } from "../../../includes/ajax";
import useToast from "../../../core/hooks/useToast";

import AwaitButton from "../../../components/AwaitButton";
import Loading from "../../../components/loading/Loading";
import EnhancedTabView from "../../../components/tabview/EnhancedTabView";
import SearchTrackingNumberInput from "./SearchTrackingNumberInput";
import { validateNumber } from "../../../includes/numbers";
import useEnterOrderItem from "../hooks/useEnterOrderItem";
import BackgroundAnimationContainer from "../../../components/layouts/BackgroundAnimationContainer";
import OrderItemInfoItemTabPanelContent from "./OrderItemInfoItemTabPanelContent";
import OrderItemInfoEnterTabPanelContent from "./OrderItemInfoEnterTabPanelContent";
import useHandleEnterEvent from "../../../core/hooks/useHandleEnterEvent";

const isEnteredStatusDropdownItems = [
  { Name: '입고전', Code: false },
  { Name: '입고완료', Code: true },
];

const IsEnteredStatusFalseItem = isEnteredStatusDropdownItems[0];
const IsEnteredStatusTrueItem = isEnteredStatusDropdownItems[1];

export const isEnteredStatusSearchFilterState = atom({
  key: 'isEnteredStatusSearchFilterState',
  default: {
    isEnteredItem: null,
  },
});

const OrderItemInfo = ({
  orderItemData,
  orderItemLoading,
  orderItemError,
  getOrderItemByTrackingNumber,
}) => {

  const { showSuccess, showInfo, showError } = useToast();
  const [isEnteredStatusSearchFilter, setIsEnteredStatusSearchFilter] = useRecoilState(isEnteredStatusSearchFilterState);
  const inputRef = useRef();
  const [inputKey, setInputKey] = useState(Math.random());

  const [{
    data: enterOrderItemData,
    error: enterOrderItemError,
    loading: enterOrderItemLoading,
  }, enterOrderItem] = useEnterOrderItem();

  useEffect(() => {
    if (!orderItemData) return;

    formik.setValues({});

    const isEnteredStatusDropdownItem = isEnteredStatusDropdownItems.find(item => {
      return (
        (item.Code === true && isTrue(orderItemData?.IsEntered))
        ||
        (item.Code === false && isFalse(orderItemData?.IsEntered))
      );
    }) || IsEnteredStatusFalseItem;

    if (orderItemData?.EnteredDate) {
      setIsEnteredStatusSearchFilter({ isEnteredItem: isEnteredStatusDropdownItem });
    } else {
      setIsEnteredStatusSearchFilter({ isEnteredItem: IsEnteredStatusTrueItem });
    }

    console.log('orderItemData', orderItemData);
    formik.setValues({
      OrderItemId: orderItemData?.OrderItemId ?? '',
      UserName: orderItemData?.UserName ?? '',
      ItemName: orderItemData?.ItemName ?? '',
      DeliveryCompany: orderItemData?.DeliveryCompany ?? '',
      TrackingNumber: orderItemData?.TrackingNumber ?? '',
      ItemAmount: Number(orderItemData?.ItemAmount) || '',
      ItemQuantity: Number(orderItemData?.ItemQuantity) || '',
      EnteredDate: orderItemData?.EnteredDate || '',

      ItemWeight: orderItemData?.EnteredDate ? (orderItemData?.ItemWeight ?? '') : (Number(orderItemData?.ItemWeight) || ''),
      ItemWidth: orderItemData?.EnteredDate ? (orderItemData?.ItemWidth ?? '') : (Number(orderItemData?.ItemWidth) || ''),
      ItemHeight: orderItemData?.EnteredDate ? (orderItemData?.ItemHeight ?? '') : (Number(orderItemData?.ItemHeight) || ''),
      ItemDepth: orderItemData?.EnteredDate ? (orderItemData?.ItemDepth ?? '') : (Number(orderItemData?.ItemDepth) || ''),

      isEnteredStatusDropdownItem: isEnteredStatusDropdownItem,
      IsCombineItem: orderItemData?.IsCombineItem === "True" ? true : false,
      IsRepackage: orderItemData?.IsRepackage === "True" ? true : false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemData]);

  useEffect(() => {
    if (orderItemError) {
      showError({ message: orderItemError });
      setIsEnteredStatusSearchFilter({ isEnteredItem: null });
      formik.resetForm();
      inputRef?.current?.focus?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemError]);

  useEffect(() => {
    if (enterOrderItemError) {
      showError({ message: enterOrderItemError });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterOrderItemError]);

  useEffect(() => {
    if (enterOrderItemData) {
      if (enterOrderItemData.EnteredDate) {
        showSuccess({ title: '상품입고', message: `${enterOrderItemData.ItemName} 상품을 입고하였습니다.` });
      } else {
        showInfo({ title: '상품수정', message: `${enterOrderItemData.ItemName} 상품을 수정하였습니다.` });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterOrderItemData]);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {
      onSaveClicked(values);
    },
  });

  const resetForm = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = "";
      setInputKey(Math.random());
    }

    setIsEnteredStatusSearchFilter({ isEnteredItem: IsEnteredStatusFalseItem });

    formik.setValues({
      OrderItemId: '',
      UserName: '',
      ItemName: '',
      DeliveryCompany: '',
      TrackingNumber: '',
      ItemAmount: '',
      ItemQuantity: '',
      EnteredDate: '',
      ItemWeight: '',
      ItemWidth: '',
      ItemHeight: '',
      ItemDepth: '',
      isEnteredStatusDropdownItem: null,
      IsCombineItem: false,
      IsRepackage: false,
    });

    inputRef.current?.focus?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsEnteredStatusSearchFilter, isEnteredStatusDropdownItems]);

  const onSaveClicked = useCallback((values) => {
    const updateRequest = {
      ItemWeight: values.ItemWeight,
      ItemWidth: values.ItemWidth,
      ItemHeight: values.ItemHeight,
      ItemDepth: values.ItemDepth,
      IsEntered: isEnteredStatusSearchFilter.isEnteredItem.Code,
    }

    return enterOrderItem({
      orderItemId: values.OrderItemId,
      data: updateRequest,
    }).then(() => {
      resetForm();
    });
  }, [resetForm, enterOrderItem, isEnteredStatusSearchFilter]);

  const dropdownItemTemplate = useMemo(() => {
    return option => (
      <div className="country-item">
        <div css={optionItemStyle}>{option.Name}</div>
      </div>
    );
  }, []);

  const onSubmit = useCallback(e => {
    return formik.submitForm();
  }, [formik]);

  const handleEnterEvent = useHandleEnterEvent(onSubmit);

  return (
    <BackgroundAnimationContainer
      className="col-12"
      css={orderItemInfoStyle}
      triggerKey={orderItemData}
    >
      {(orderItemLoading || enterOrderItemLoading) && <Loading onTop overlayColour={'grey'} />}

      <SearchTrackingNumberInput
        onSearch={getOrderItemByTrackingNumber}
        inputRef={inputRef}
        inputKey={inputKey}
      />

      <form onSubmit={formik.handleSubmit} className={"col-12 grid"}>
        <EnhancedTabView className={"col-12 lg:col-6"}>
          <TabPanel header="상품 정보" key={"상품 정보"}>
            <OrderItemInfoItemTabPanelContent formik={formik} />
          </TabPanel>
        </EnhancedTabView>

        <EnhancedTabView className={"col-12 lg:col-6"}>
          <TabPanel header="입고 정보" key={"입고 정보"}>
            <OrderItemInfoEnterTabPanelContent
              formik={formik}
              handleEnterEvent={handleEnterEvent}
              dropdownItemTemplate={dropdownItemTemplate}
              isEnteredStatusDropdownItems={isEnteredStatusDropdownItems}
            />
          </TabPanel>
        </EnhancedTabView>

        <div className={"col-12 flex justify-content-center"}>
          <AwaitButton
            buttonClassName={"p-button-secondary"}
            icon="pi pi-replay"
            css={buttonStyle}
            label={"리셋"}
            type="button"
            onClick={resetForm}
          />
          <AwaitButton
            icon="pi pi-check"
            css={buttonStyle}
            label={"저장"}
            type="submit"
          />
        </div>
      </form>
    </BackgroundAnimationContainer>
  )
}

export default OrderItemInfo;

const validate = (values) => {
  const errors = {};

  if (!trim(values.OrderItemId)) {
    errors.OrderItemId = "Required";
  }

  if (!trim(values.ItemWeight)) {
    errors.ItemWeight = "Required";
  } else if (!validateNumber(values.ItemWeight)) {
    errors.ItemWeight = "소수점 두자리까지 입력이 가능합니다.";
  }

  if (!trim(values.ItemWidth)) {
    errors.ItemWidth = "Required";
  } else if (!validateNumber(values.ItemWidth)) {
    errors.ItemWidth = "소수점 두자리까지 입력이 가능합니다.";
  }

  if (!trim(values.ItemHeight)) {
    errors.ItemHeight = "Required";
  } else if (!validateNumber(values.ItemHeight)) {
    errors.ItemHeight = "소수점 두자리까지 입력이 가능합니다.";
  }

  if (!trim(values.ItemDepth)) {
    errors.ItemDepth = "Required";
  } else if (!validateNumber(values.ItemDepth)) {
    errors.ItemDepth = "소수점 두자리까지 입력이 가능합니다.";
  }

  return errors;
}

const orderItemInfoStyle = css`
    position: relative;
`;

const buttonStyle = css`
    margin: 0 20px;
    height: 60px;

    .p-button-label {
        font-size: 30px;
    }
`;

const optionItemStyle = css`
    font-size: 20px;
`;