import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import useAPI from "../../../../core/hooks/useAPI";
import useAddWeightCharge from "../../../../service/hooks/useAddWeightCharge";
import { API_ORDER_URI, NOOP } from "../../../../includes/constants";
import { useAwaitingDialogContext } from "../../../../core/contexts/AwaitingDialogContext";
import { selectedFlightOrderItemsState } from "./store";

import DataTableActions from "../../../../components/layouts/DataTableActions";
import DeliveryStatusActionButton from "../../../../components/action-buttons/DeliveryStatusActionButton";
import CreateConsignmentActionButton from "../../../../components/action-buttons/CreateConsignmentActionButton";
import useCreateConsignment from "../../../../service/hooks/useCreateConsignment";
import useCreateInvoice from "../../../../service/hooks/useCreateInvoice";
import ConfirmActionButton from "../../../../components/action-buttons/ConfirmActionButton";

const FlightOrdersPageDataTableActions = ({
    className = '',
    refresh = NOOP,
}) => {
    const selectedOrderItems = useRecoilValue(selectedFlightOrderItemsState);
    const { closeConfirmation } = useAwaitingDialogContext();

    /***********************************************************
     * 배송상태 변경
     ***********************************************************/
    const updateOrderDeliveryStatus = useAPI({
        url: `${API_ORDER_URI}/deliverystatus`,
        method: 'PUT',
        successMessage: "선택하신 주문의 배송상태를 성공적으로 변경하였습니다.",
        callbackAfterSuccess: refresh,
    });

    const onSelectDeliveryStatus = useCallback((deliveryStatusUpdateData) => {
        if (deliveryStatusUpdateData) {
            updateOrderDeliveryStatus({
                data: {
                    DeliveryStatusCode: deliveryStatusUpdateData.deliveryStatusCode,
                    IsSendEmail: deliveryStatusUpdateData.isSendEmail,
                    OrderIds: selectedOrderItems.map(order => order.OrderId),
                }
            });
        } else {
            closeConfirmation();
        }
    }, [updateOrderDeliveryStatus, closeConfirmation, selectedOrderItems]);

    const [onConfirmCreateConsignment] = useCreateConsignment({
        callbackAfterSuccess: refresh,
        orderIds: selectedOrderItems.map(order => Number(order.OrderId)),
        successMessage: "선택하신 주문의 송장을 성공적으로 발행하였습니다.",
    });

    const [onConfirmCreateInvoice] = useCreateInvoice({
        callbackAfterSuccess: refresh,
        orderIds: selectedOrderItems.map(order => Number(order.OrderId)),
        successMessage: "선택하신 주문의 인보이스를 성공적으로 발행하였습니다.",
    });

    const [onConfirmAddWeightCharge] = useAddWeightCharge({
        callbackAfterSuccess: refresh,
        orderIds: selectedOrderItems.map(order => Number(order.OrderId)),
        successMessage: "선택하신 주문의 배송비를 성공적으로 계산하였습니다.",
    });

    return (
        <DataTableActions className={className}>
            <DeliveryStatusActionButton onSelect={onSelectDeliveryStatus} disabled={isEmpty(selectedOrderItems)} />
            <ConfirmActionButton
                icon={"pi pi-calendar-times"}
                className={"mb-2 ml-2"}
                label={"배송비 계산"}
                onOkayClicked={onConfirmAddWeightCharge}
                disabled={isEmpty(selectedOrderItems)}
            >
                선택하신 주문의 배송비를 계산하시겠습니까?
            </ConfirmActionButton>
            <CreateConsignmentActionButton
                className={"mb-2 ml-2"}
                onOkayClicked={onConfirmCreateConsignment}
                disabled={isEmpty(selectedOrderItems)}
            />
            <ConfirmActionButton
                icon={"pi pi-plus"}
                className={"mb-2 ml-2"}
                label={"인보이스 발행"}
                onOkayClicked={onConfirmCreateInvoice}
                disabled={isEmpty(selectedOrderItems)}
            >
                선택하신 주문의 인보이스를 발행하시겠습니까?
            </ConfirmActionButton>
        </DataTableActions>
    );
}

FlightOrdersPageDataTableActions.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    refresh: PropTypes.func,
}

export default FlightOrdersPageDataTableActions;