import { useMemo } from 'react';
import { useSetRecoilState } from "recoil";
import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";

const PaginationFilter = ({
    atom,
    supportQueryString = false,
}) => {
    const setPaginationFilterState = useSetRecoilState(atom);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setPaginationFilterState({
                pageNo: searchObject.pageNo,
                pageSize: searchObject.pageSize,
                orderField: searchObject.orderField,
                orderBy: searchObject.orderBy,
            });
        }
    }, [setPaginationFilterState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    return null;
}

export default PaginationFilter;