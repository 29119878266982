import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { TabPanel } from "primereact/tabview";

import { mainPathNameState } from "../../../store";
import useAPI from "../../../core/hooks/useAPI";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";

import usePopupData from "./hooks/usePopupData";
import { API_BOARD_POPUP_URI } from "../../../includes/constants";

import { menuState } from "../../../service/hooks/useMenus";
import Loading from "../../../components/loading/Loading";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EnhancedTabView from "../../../components/tabview/EnhancedTabView";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import CardContainer from "../../../components/layouts/CardContainer";
import PopupWriter from "./components/PopupWriter";

const PopupDetailPage = () => {
    const menuStateValue = useRecoilValue(menuState);
    const [popupDataDto, setPopupDataDto] = useState(null);
    const history = useHistory();
    const { boardPopupId } = useParams();
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const isCreateMode = useMemo(() => boardPopupId === "create", [boardPopupId]);
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const [{
        data: popupData,
        loading: popupLoading,
    }, getPopup] = usePopupData();

    useEffect(() => {
        if (popupData) {
            setPopupDataDto(popupData);
        }
    }, [popupData]);

    useEffect(() => {
        if (!isCreateMode && boardPopupId) {
            getPopup(boardPopupId);
        }
    }, [boardPopupId, isCreateMode, getPopup]);

    /***********************************************************
     * 팝업관리 생성
     ***********************************************************/
    const createPopup = useAPI({
        url: `${API_BOARD_POPUP_URI}`,
        method: 'POST',
        successMessage: "팝업을 성공적으로 생성하였습니다.",
    });

    const _onCreatePopup = useCallback((data) => {
        return createPopup({
            data: data,
        }).then(({ data: returnData }) => {
            setPopupDataDto({ ...returnData });
            history.replace(`${mainPathNameStateValue}/${returnData.BoardPopupId}`);
        });
    }, [createPopup, history, mainPathNameStateValue]);

    const confirmCreatePopup = useCallback((data) => {
        return _onCreatePopup(data).then(closeConfirmation);
    }, [_onCreatePopup, closeConfirmation]);

    /***********************************************************
     * 팝업관리 수정
     ***********************************************************/
    const updatePopup = useAPI({
        url: `${API_BOARD_POPUP_URI}/${popupDataDto?.BoardPopupId}`,
        method: 'PUT',
        successMessage: "팝업을 성공적으로 수정하였습니다.",
    });

    const _onUpdatePopup = useCallback((data) => {
        return updatePopup({
            data: data,
        }).then(({ data: returnData }) => {
            setPopupDataDto(returnData);
        });
    }, [updatePopup, setPopupDataDto]);

    const updatePopupDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"팝업 수정"}>
            팝업을 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const confirmUpdatePopup = useCallback((data) => {
        return openConfirmation(updatePopupDialog)
            .then((confirmedData) => {
                _onUpdatePopup(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onUpdatePopup, updatePopupDialog, openConfirmation, closeConfirmation]);

    const breadcrumbItems = useMemo(() => {
        return [
            ...menuStateValue.currentBreadcrumbItems,
            { label: isCreateMode ? '글작성' : '글수정' }
        ]
    }, [menuStateValue, isCreateMode]);

    return (
        <div className="grid" css={popupDetailPageStyle}>
            {popupLoading && <Loading overlay />}
            <div className="col-12">
                <EnhancedBreadCrumb model={breadcrumbItems} showBack />

                <CardContainer>
                    <EnhancedTabView>
                        <TabPanel header="팝업관리" key={"팝업관리"}>
                            <PopupWriter
                                popupData={popupDataDto}
                                onSavePopup={
                                    isCreateMode
                                        ? confirmCreatePopup
                                        : confirmUpdatePopup
                                }
                            />
                        </TabPanel>
                    </EnhancedTabView>
                </CardContainer>
            </div>
        </div>
    );
}

export default PopupDetailPage;

const popupDetailPageStyle = css`
    position: relative;
`;