import axios from 'axios';
import { getToken } from "./auth";
import { API_BASE } from "./constants";
import qs from "qs";
import { isArray } from "lodash";

// const instance = axios.create({
//     baseURL: API_BASE,
//     timeout: 3000,
//     headers: {'X-Custom-Header': 'foobar'}
// });

// https://github.com/axios/axios#config-defaults
axios.defaults.baseURL = API_BASE;

export const initAxios = ({
    errorHandler
}) => {
    if (axios.interceptors.hasAuthErrorHandler) {
        return;
    }
    axios.interceptors.hasAuthErrorHandler = true;

    // Add a request interceptor
    axios.interceptors.request.use((config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    });

    axios.interceptors.response.use((response) => {
        /**
         * 서버에서 내려오는 data 가 그냥 "Ok"로 만 오는 경우가 많은데.
         * 이럴 경우, 이 response.data의 변화를 감지하는 useAxios 의 useEffect 에서
         * 변경을 감지하지 못한다.
         * 그래서 여기서 매번 새로운 객체로 감싸서 내려보내준다. ^^
         */
        if (response.data === "Ok") {
            response.data = {
                status: "Ok",
            }
        }
        return response;
    }, errorHandler);
}

export const stringifyFilter = (prefix, value) => {
    if (prefix === 'b') {
        // Return an `undefined` value to omit a property.
        return;
    }

    const val = value ?? undefined;

    if (val) {
        return val;
    }
    return;
}

export const getTotalQueryString = (...queryStrings) => {
    const queryString = queryStrings.join("&");
    const parsedQueryString = qs.parse(queryString);

    Object.keys(parsedQueryString).forEach(key => {
        if (isArray(parsedQueryString[key])) {
            parsedQueryString[key] = parsedQueryString[key].find(value => !!value);
        }
    })

    return qs.stringify(parsedQueryString, { filter: stringifyFilter, encodeValuesOnly: true });
}

export const generateUrl = (baseUrl, ...parameters) => {
    let queryString = parameters.join("&");

    if (queryString) {
        if (queryString.endsWith("&")) {
            queryString = queryString.substr(0, queryString.length - 1);
        }

        if (queryString.startsWith("&")) {
            queryString = queryString.substr(1, queryString.length);
        }

        return `${baseUrl}?${queryString}`;
    } else {
        return baseUrl;
    }
};

const trueValues = {
    "true": true,
    "True": true,
    "TRUE": true,
};

export const isTrue = (value) => {
    return trueValues[value] ?? false;
};

const falseValues = {
    "false": true,
    "False": true,
    "FALSE": true,
};

export const isFalse = (value) => {
    return falseValues[value] ?? false;
};

export const isBooleanString = (value) => {
    return isTrue(value) || isFalse(value);
};

export const toBooleanValue = (value, defaultValue = false) => {
    if (isTrue(value)) {
        return true;
    }
    if (isFalse(value)) {
        return false;
    }
    return defaultValue;
};

