import EnhancedDialog from "./EnhancedDialog";
import React, { useCallback, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { Checkbox } from 'primereact/checkbox';
import { atom, useRecoilState } from "recoil";
import PropTypes from "prop-types";
import DeliveryStatusSearchFilter from "../filters/DeliveryStatusSearchFilter";
import { Button } from "primereact/button";

export const deliveryStatusDialogState = atom({
    key: 'deliveryStatusDialogState',
    default: {
        deliveryStatusCode: null,
        deliveryStatusName: null,
        isSendEmail: false,
    },
});

const DeliveryStatusDialog = ({
    onCancel,
    onConfirm,
    title = "배송상태",
    shouldShowConfirm = true,
    renderConfirm = null, // should be null as default
}) => {
    const [state, setState] = useRecoilState(deliveryStatusDialogState);
    const [showConfirm, setShowConfirm] = useState(false);

    const handleReturnConfirm = useCallback(() => {
        onConfirm(state)
    }, [onConfirm, state]);

    const handleConfirm = useCallback(() => {
        if (shouldShowConfirm && renderConfirm && state) {
            setShowConfirm(true);
        } else {
            return onConfirm(state);
        }
    }, [shouldShowConfirm, renderConfirm, onConfirm, state, setShowConfirm]);

    const onIsSendEmailChange = useCallback((e) => {
        setState(prev => {
            return {
                ...prev,
                isSendEmail: e.checked,
            }
        });
    }, [setState]);

    const confirmationDialogFooter = useMemo(() => (
        <>
            {onCancel && (
                <Button
                    type="button"
                    label="Close"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-text"
                />
            )}
            <Button
                type="button"
                label="Yes"
                icon="pi pi-check"
                onClick={handleConfirm}
                autoFocus
                disabled={!state.deliveryStatusCode}
            />
        </>
    ), [onCancel, handleConfirm, state.deliveryStatusCode]);

    const onConfirmCancel = useCallback(() => {
        setShowConfirm(false)
    }, [setShowConfirm]);

    return (
        <EnhancedDialog
            onCancel={onCancel}
            onConfirm={handleConfirm}
            title={title}
            css={deliveryStatusDialogStyle}
            footer={confirmationDialogFooter}
            withLoading={false}
        >
            {showConfirm && renderConfirm && (
                renderConfirm({
                    onConfirm: handleReturnConfirm,
                    onCancel: onConfirmCancel,
                    data: state,
                })
            )}
            <div>
                <div className="col-12" css={filtersStyle}>
                    <DeliveryStatusSearchFilter atom={deliveryStatusDialogState} />
                </div>

                <div className="col-12 field-checkbox">
                    <Checkbox
                        inputId="isSendEmail"
                        value="1"
                        onChange={onIsSendEmailChange}
                        checked={state.isSendEmail}
                    />
                    <label
                        htmlFor="isSendEmail"
                        className="p-checkbox-label"
                        css={checkboxLabelStyle}
                    >
                        고객 이메일 전송
                    </label>
                </div>
            </div>
        </EnhancedDialog>
    )
}

DeliveryStatusDialog.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    shouldShowConfirm: PropTypes.bool,
    renderConfirm: PropTypes.func,
}

export default DeliveryStatusDialog;

const deliveryStatusDialogStyle = css`
    width: 350px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const filtersStyle = css`
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
    display: flex;
    align-items: center;
`;

const checkboxLabelStyle = css`
    cursor: pointer;
`;