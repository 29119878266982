import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { css } from "@emotion/react";

const FilterContainer = ({ children = '', className }) => (
    <div
        css={filterContainerStyle}
        className={classNames("col-12 lg:col-6 md:col-12 sm:col-12", className)}
    >
        {children}
    </div>
);

FilterContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default memo(FilterContainer);

const filterContainerStyle = css`
    display: flex;
    flex-wrap: wrap;
    align-items: start;
`;