import React, { useCallback } from "react";
import PropTypes from "prop-types";
import AwaitButton from "../AwaitButton";
import { NOOP } from "../../includes/constants";

const ConsignmentPrintActionButton = ({
    className = '',
    disabled = false,
    onOkayClicked = NOOP,
}) => {
    const onButtonClicked = useCallback(() => {
        onOkayClicked(true);
    }, [onOkayClicked]);

    return (
        <AwaitButton
            type="button"
            className={className}
            onClick={onButtonClicked}
            label={"송장인쇄"}
            disabled={disabled}
            icon="pi pi-print"
        />
    )
}

ConsignmentPrintActionButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onOkayClicked: PropTypes.func,
}

export default ConsignmentPrintActionButton;