import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AwaitButton from "../AwaitButton";
import { NOOP } from "../../includes/constants";
import useCreateActionButton from "../../service/hooks/useCreateActionButton";

const CreateActionButton = ({
    className = '',
    disabled = false,
    onSelect = NOOP,
    label = "상품추가",
    confirmMessage = "상품을 추가하시겠습니까?",
    atom,
    dialogComponent,
    icon="pi pi-plus",
    shouldShowConfirm = true,
    ...props
}) => {
    const onCreateClicked = useCreateActionButton({
        onSelect,
        confirmMessage,
        atom,
        dialogComponent,
        shouldShowConfirm,
    });

    return (
        <AwaitButton
            icon={icon}
            type="button"
            buttonClassName={classNames("ml-2", className)}
            onClick={onCreateClicked}
            label={label}
            disabled={disabled}
            {...props}
        />
    )
}

CreateActionButton.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
}

export default CreateActionButton;