import React, { useCallback, useEffect, useMemo } from "react";
import { atom, useRecoilState } from "recoil";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { trim } from "lodash";
import { Button } from "primereact/button";

import EnhancedDialog from "./EnhancedDialog";
import useShowConfirm from "../../service/hooks/useShowConfirm";
import InputTextWithError from "../forms/InputTextWithError";
import DropdownWithError from "../forms/DropdownWithError";
import { validateNumber } from "../../includes/numbers";

const isEnteredStatusDropdownItems = [
  { Name: '입고전', Code: false },
  { Name: '입고완료', Code: true },
];

export const orderItemEnterDialogState = atom({
  key: 'orderItemEnterDialogState',
  default: {
    ItemWeight: 0,
    ItemWidth: 0,
    ItemHeight: 0,
    ItemDepth: 0,
    IsEntered: true
  },
});

const OrderItemEnterDialog = ({
  onCancel = null,
  onConfirm,
  title = "입고처리",
  renderConfirm = null, // should be null as default
  readOnly = false,
  shouldShowConfirm = false,
}) => {


  const [state, setState] = useRecoilState(orderItemEnterDialogState);

  const { handleConfirm, renderConfirmDialog, }
    = useShowConfirm({ renderConfirm, state, onCancel, onConfirm, shouldShowConfirm, });

  const onSaveClicked = useCallback((values) => {

    setState(prev => {
      return {
        ...prev,
        ...values,
      }
    });
    handleConfirm();
  }, [setState, handleConfirm]);

  const validate = (values) => {
    const errors = {};

    if (!trim(values.ItemWeight)) {
      errors.ItemWeight = "Required";
    } else if (!validateNumber(values.ItemWeight)) {
      errors.ItemWeight = "소수점 두자리까지 입력이 가능합니다.";
    }

    if (!trim(values.ItemWidth)) {
      errors.ItemWidth = "Required";
    } else if (!validateNumber(values.ItemWidth)) {
      errors.ItemWidth = "소수점 두자리까지 입력이 가능합니다.";
    }

    if (!trim(values.ItemHeight)) {
      errors.ItemHeight = "Required";
    } else if (!validateNumber(values.ItemHeight)) {
      errors.ItemHeight = "소수점 두자리까지 입력이 가능합니다.";
    }

    if (!trim(values.ItemDepth)) {
      errors.ItemDepth = "Required";
    } else if (!validateNumber(values.ItemDepth)) {
      errors.ItemDepth = "소수점 두자리까지 입력이 가능합니다.";
    }

    if (!values.isEnteredStatusDropdownItem) {
      errors.isEnteredStatusDropdownItem = "Required";
    }
    return errors;
  }


  const formik = useFormik({
    initialValues: {
      ...state,
      isEnteredStatusDropdownItem: isEnteredStatusDropdownItems.find(item => item.Code === state.IsEntered),
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {

      const newValue = {
        ...values,
      };

      newValue.IsEntered = newValue.isEnteredStatusDropdownItem.Code;
      delete newValue.isEnteredStatusDropdownItem;

      onSaveClicked(newValue);
    },
  });

  // useEffect(() => {

  //   console.log("useEffect.state", state);
  //   formik.setValues({
  //     ...state,
  //   });

  //   //initialise the drop down list item 
  //   formik.setFieldValue("isEnteredStatusDropdownItem", isEnteredStatusDropdownItems.find(item => item.Code === state.IsEntered));

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state, isEnteredStatusDropdownItems]);


  const onSubmitForm = useCallback(() => {

    return formik.submitForm();
  }, [formik]);

  const confirmationDialogFooter = useMemo(() => (
    <>
      {onCancel && (
        <Button
          type="button"
          label="Close"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-text"
        />
      )}


      {onConfirm && (
        <Button
          type="submit"
          label="Yes"
          icon="pi pi-check"
          autoFocus
          onClick={onSubmitForm}
        />
      )}

    </>
  ), [onConfirm, onCancel, onSubmitForm]);


  return (
    <EnhancedDialog
      title={title}
      onCancel={onCancel}
      css={createOrderItemDialogStyle}
      footer={confirmationDialogFooter}
      withLoading={false}
    >
      {renderConfirmDialog()}
      <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
        <div className="p-fluid">
          <div className="field grid">
            <label htmlFor="ItemWeight" className="col-12 mb-2 md:col-3 md:mb-0">무게</label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="ItemWeight"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ItemWeight ?? ''}
                error={formik.errors.ItemWeight}
                min={0}
                step={0.01}

              />
            </div>
          </div>

          <div className="field grid">
            <label htmlFor="ItemWidth" className="col-12 mb-2 md:col-3 md:mb-0">Width</label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="ItemWidth"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ItemWidth ?? ''}
                error={formik.errors.ItemWidth}
                min={0}
                step={0.01}

              />
            </div>
          </div>

          <div className="field grid">
            <label htmlFor="ItemHeight" className="col-12 mb-2 md:col-3 md:mb-0">Height</label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="ItemHeight"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ItemHeight ?? ''}
                error={formik.errors.ItemHeight}
                min={0}
                step={0.01}

              />
            </div>
          </div>

          <div className="field grid">
            <label htmlFor="ItemDepth" className="col-12 mb-2 md:col-3 md:mb-0">Depth</label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="ItemDepth"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ItemDepth ?? ''}
                error={formik.errors.ItemDepth}
                min={0}
                step={0.01}
              />
            </div>
          </div>

          <div className="field grid">
            <label
              htmlFor={"IsEntered"}
              className="col-12 mb-2 md:col-3 md:mb-0"
            >
              입고상태
            </label>
            <DropdownWithError
              className="col-12 md:col-9"
              name="isEnteredStatusDropdownItem"
              value={formik.values.isEnteredStatusDropdownItem}
              onChange={formik.handleChange}
              options={isEnteredStatusDropdownItems}
              error={formik.errors.isEnteredStatusDropdownItem}
              optionLabel="Name"
              placeholder="Select One"
              readOnly={readOnly}
            />
          </div>
        </div>
      </form>
    </EnhancedDialog>
  );
};

export default OrderItemEnterDialog;

const createOrderItemDialogStyle = css`
    width: 450px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;