import { useCallback } from "react";
import { NOOP } from "../../includes/constants";

const useHandleEnterEvent = (onHandle = NOOP) => {
    const handleEnterEvent = useCallback(e => {
        if (e.key === "Enter") {
            onHandle(e);
        }
    }, [onHandle]);

    return handleEnterEvent;
}

export default useHandleEnterEvent;