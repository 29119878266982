import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { TabPanel } from "primereact/tabview";
import PurchaseOrderItemInfo from "./components/PurchaseOrderItemInfo";
import usePurchaseOrderItemData, { TYPE_ORDER_ITEM, TYPE_TRACKING_NUMBER } from "./hooks/usePurchaseOrderItemData";
import { useRecoilValue } from "recoil";
import EnhancedTabView from "../../components/tabview/EnhancedTabView";
import { menuState } from "../../service/hooks/useMenus";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import CardContainer from "../../components/layouts/CardContainer";
import useSetState from "../../core/hooks/useSetState";

const PurchaseOrderItemDetailPage = () => {
    const menuStateValue = useRecoilValue(menuState);
    const { purchaseOrderItemId } = useParams();
    const isTrackingMode = useMemo(() => purchaseOrderItemId === "tracking", [purchaseOrderItemId]);
    const [state, setState] = useSetState({
        data: null,
        loading: false,
        error: null,
    });

    const [{
        data: orderItemByTrackingNumberData,
        loading: orderItemByTrackingNumberLoading,
        error: orderItemByTrackingNumberError,
    }, getPurchaseOrderItemByTrackingNumber] = usePurchaseOrderItemData(TYPE_TRACKING_NUMBER);

    const [{
        data: orderItemData,
        loading: orderItemLoading,
        error: orderItemError,
    }, getPurchaseOrderItem] = usePurchaseOrderItemData(TYPE_ORDER_ITEM);

    useEffect(() => {
        if (orderItemByTrackingNumberData) {
            setState({
                data: orderItemByTrackingNumberData,
                loading: orderItemByTrackingNumberLoading,
                error: orderItemByTrackingNumberError,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItemByTrackingNumberData, orderItemByTrackingNumberLoading, orderItemByTrackingNumberError]);

    useEffect(() => {
        if (orderItemData) {
            setState({
                data: orderItemData,
                loading: orderItemLoading,
                error: orderItemError,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItemData, orderItemLoading, orderItemError]);

    useEffect(() => {
        if (!isTrackingMode && purchaseOrderItemId) {
            getPurchaseOrderItem(purchaseOrderItemId);
        }
    }, [purchaseOrderItemId, isTrackingMode, getPurchaseOrderItem]);

    const breadcrumbItems = useMemo(() => {
        return [
            ...menuStateValue.currentBreadcrumbItems,
            { label: '상품입고' }
        ]
    }, [menuStateValue]);

    return (
        <div className="grid" css={orderItemDetailPageStyle}>
            <div className="col-12">
                <EnhancedBreadCrumb model={breadcrumbItems} showBack />

                <CardContainer css={orderItemEnterContainerStyle}>
                    <EnhancedTabView>
                        <TabPanel header="상품 입고" key={"상품 입고"}>
                            <PurchaseOrderItemInfo
                                orderItemData={state.data}
                                orderItemLoading={state.loading}
                                orderItemError={state.error}
                                getOrderItemByTrackingNumber={getPurchaseOrderItemByTrackingNumber}
                            />
                        </TabPanel>
                    </EnhancedTabView>
                </CardContainer>
            </div>
        </div>
    );
}

export default PurchaseOrderItemDetailPage;

const orderItemDetailPageStyle = css`
    position: relative;
`;

const orderItemEnterContainerStyle = css`
    .p-tabview-title {
        font-size: 30px;
    }
`;