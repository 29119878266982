import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

import FilterLabel from "./FilterLabel";
import { NOOP } from "../../includes/constants";
import useHandleEnterEvent from "../../core/hooks/useHandleEnterEvent";

const TextFieldSearchFilter = ({
    atom,
    label = "검색",
    dropdownItems = [],
    supportQueryString = false,
    onSearch = NOOP,
}) => {
    const setState = useSetRecoilState(atom);
    const state = useRecoilValue(atom);
    const searchStrRef = useRef();

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setState({
                searchField: searchObject.searchField,
                searchStr: searchObject.searchStr,
            });
        }
    }, [setState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setState(prev => {
            if (prev.searchField) return prev;

            return {
                ...prev,
                searchField: defaultItem.searchField,
            }
        });
    }, [setState]);

    useSetFilterDefaultValue({
        items: dropdownItems,
        isAlreadySet: !!state.searchField,
        setFilterDefaultValue,
    });

    const onChangeSearchStr = useCallback((e) => {
        const searchStr = e.target.value;
        setState(prev => {
            return {
                ...prev,
                searchStr: searchStr,
            }
        });
    }, [setState]);

    const setDropdownItem = useCallback(e => {
        setState(prev => {
            return {
                ...prev,
                searchField: e.value?.searchField,
            }
        });
    }, [setState]);

    const handleEnterEvent = useHandleEnterEvent(onSearch);

    useEffect(() => {
        // 입력창에 포커스를 한다.
        searchStrRef?.current?.focus();
    }, []);

    return (
        <div className="grid col-12 p-fluid align-items-center">
            <FilterLabel htmlFor={"searchField"}>{label}</FilterLabel>
            <Dropdown
                name="searchField"
                value={dropdownItems.find(item => item.searchField === state.searchField)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="name"
                placeholder="Select One"
            />
            <div className="col-6 lg:col-6" css={lastInputStyle}>
                <InputText
                    name="searchStr"
                    type="text"
                    onChange={onChangeSearchStr}
                    onKeyDown={handleEnterEvent}
                    value={state.searchStr || ''}
                    forwardRef={searchStrRef}
                />
            </div>
        </div>
    )
}

const lastInputStyle = theme => css`
    // max-width: 420px;

    ${theme.breakpoints.down("lg")} {
        display: flex;
        flex: 1;
    }
`;

export default TextFieldSearchFilter;