import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { TabPanel } from "primereact/tabview";

import useAPI from "../../../core/hooks/useAPI";
import useToast from "../../../core/hooks/useToast";
import useQnaData from "./hooks/useQnaData";
import { mainPathNameState } from "../../../store";
import { menuState } from "../../../service/hooks/useMenus";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import { API_BOARD_QNA_COMMENT_URI, API_BOARD_QNA_URI } from "../../../includes/constants";

import Loading from "../../../components/loading/Loading";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EnhancedTabView from "../../../components/tabview/EnhancedTabView";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import CardContainer from "../../../components/layouts/CardContainer";
import QnaCommentsWriter from "./components/QnaCommentsWriter";
import QnaWriter from "./components/QnaWriter";
import QnaDetail from "./components/QnaDetail";
import EnhancedDivider from "../../../components/layouts/EnhancedDivider";

const QnaDetailPage = () => {
    const { showError } = useToast();
    const menuStateValue = useRecoilValue(menuState);
    const [qnaDataDto, setQnaDataDto] = useState(null);
    const { boardQnaId } = useParams();
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const [isEditMode, setIsEditMode] = useState(false);
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const commentData = useMemo(() => {
        return qnaDataDto?.Comments?.[0];
    }, [qnaDataDto]);

    const [{
        data: qnaData,
        loading: qnaLoading,
    }, getQna] = useQnaData();

    useEffect(() => {
        if (qnaData) {
            setQnaDataDto(qnaData);
        }
    }, [qnaData]);

    useEffect(() => {
        if (boardQnaId) {
            getQna(boardQnaId);
        }
    }, [boardQnaId, getQna]);

    const toggleEditModeClicked = useCallback(() => {
        setIsEditMode(prev => !prev);
    }, [setIsEditMode]);

    /***********************************************************
     * Q&A 수정
     ***********************************************************/
    const updateQna = useAPI({
        url: `${API_BOARD_QNA_URI}/${qnaDataDto?.BoardQnaId}`,
        method: 'PUT',
        successMessage: "Q&A를 성공적으로 수정하였습니다.",
    });

    const _onUpdateQna = useCallback((data) => {
        return updateQna({
            data: data,
        }).then(({ data: returnData }) => {
            setQnaDataDto(returnData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateQna, qnaDataDto]);

    const updateQnaDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"Q&A 수정"}>
            Q&A 질문을 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const confirmUpdateQna = useCallback((data) => {
        return openConfirmation(updateQnaDialog)
            .then((confirmedData) => {
                _onUpdateQna(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onUpdateQna, updateQnaDialog, openConfirmation, closeConfirmation]);

    /***********************************************************
     * 답변 생성
     ***********************************************************/
    const createComment = useAPI({
        url: `${API_BOARD_QNA_COMMENT_URI}`,
        method: 'POST',
        successMessage: "답변를 성공적으로 생성하였습니다.",
    });

    const _onCreateComment = useCallback((data) => {
        return createComment({
            data: data,
        }).then(({ data: returnData }) => {
            setQnaDataDto({ ...returnData });
        });
    }, [createComment]);

    const createCommentDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"답변 생성"}>
            Q&A의 답변를 생성하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const confirmCreateComment = useCallback((data) => {
        return openConfirmation(createCommentDialog)
            .then((confirmedData) => {
                _onCreateComment(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onCreateComment, createCommentDialog, openConfirmation, closeConfirmation]);

    /***********************************************************
     * 답변 수정
     ***********************************************************/
    const updateComment = useAPI({
        url: `${API_BOARD_QNA_COMMENT_URI}/${commentData?.CommentId}`,
        method: 'PUT',
        successMessage: "답변를 성공적으로 수정하였습니다.",
    });

    const _onUpdateComment = useCallback((data) => {
        return updateComment({
            data: data,
        }).then(({ data: returnData }) => {
            setQnaDataDto(returnData);
        });
    }, [updateComment]);

    const updateCommentDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"답변 수정"}>
            Q&A의 답변를 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const confirmUpdateComment = useCallback((data) => {
        return openConfirmation(updateCommentDialog)
            .then((confirmedData) => {
                _onUpdateComment(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onUpdateComment, updateCommentDialog, openConfirmation, closeConfirmation]);

    const breadcrumbItems = useMemo(() => {
        return [
            ...menuStateValue.currentBreadcrumbItems,
            { label: isEditMode ? '게시글 수정' : '게시글 답변하기' }
        ]
    }, [menuStateValue, isEditMode]);

    if (!boardQnaId) {
        showError({ message: "잘못된 접급입니다." });
        return <Redirect to={mainPathNameStateValue} />;
    }

    return (
        <div className="grid" css={qnaDetailPageStyle}>
            {qnaLoading && <Loading overlay />}
            <div className="col-12">
                <EnhancedBreadCrumb model={breadcrumbItems} showBack />

                <CardContainer>
                    <EnhancedTabView>
                        <TabPanel header="Q&A" key={"Q&A"}>
                            {isEditMode
                                ? (
                                    <QnaWriter
                                        qnaData={qnaDataDto}
                                        onSave={confirmUpdateQna}
                                        toggleEditModeClicked={toggleEditModeClicked}
                                    />
                                )
                                : (
                                    <QnaDetail
                                        qnaData={qnaDataDto}
                                        toggleEditModeClicked={toggleEditModeClicked}
                                    />
                                )
                            }
                            <EnhancedDivider />
                            <QnaCommentsWriter
                                commentData={commentData}
                                boardId={qnaDataDto?.BoardQnaId}
                                onSave={
                                    commentData
                                        ? confirmUpdateComment
                                        : confirmCreateComment
                                }
                            />
                        </TabPanel>
                    </EnhancedTabView>
                </CardContainer>
            </div>
        </div>
    );
}

export default QnaDetailPage;

const qnaDetailPageStyle = css`
    position: relative;
`;