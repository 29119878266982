export const smoothScroll = (selector) => {
    const link = document.querySelector(selector);
    if (link) {
        link.scrollIntoView({
            alignToTop: false,
            block: "start", /** start, center, end, nearest **/
            behavior: "smooth",
        });
        return true;
    }
    return false;
}