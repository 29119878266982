import React, { useCallback, useMemo } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";

import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

import FilterLabel from "./FilterLabel";

const _dropdownItems = [
    { Name: '전체', Code: 'All', isDefault: true },
    { Name: '결재완료', Code: 'Payment' },
    { Name: '결재 미완료', Code: 'Unpayment' },
];

export const paymentStatusSearchFilterState = atom({
    key: 'paymentStatusSearchFilterState',
    default: {
        paymentStatus: _dropdownItems[0].Code,
    },
});

const PaymentStatusSearchFilter = ({
    label = "결제 상태",
    className = null,
    dropdownItems = _dropdownItems,
    placeholder = "전체",
    supportQueryString = false,
}) => {
    const setPaymentStatusSearchFilterState = useSetRecoilState(paymentStatusSearchFilterState);
    const paymentStatusSearchFilterValue = useRecoilValue(paymentStatusSearchFilterState);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setPaymentStatusSearchFilterState({
                paymentStatus: searchObject.paymentStatus,
            });
        }
    }, [setPaymentStatusSearchFilterState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setPaymentStatusSearchFilterState(prev => {
            if (prev.paymentStatus) return prev;

            return {
                ...prev,
                paymentStatus: defaultItem.Code,
            }
        });
    }, [setPaymentStatusSearchFilterState]);

    useSetFilterDefaultValue({
        items: dropdownItems,
        isAlreadySet: !!paymentStatusSearchFilterValue.paymentStatus,
        setFilterDefaultValue,
    });
    //
    // useEffect(() => {
    //     // 처음 한번만 세팅 해 주세요.
    //     if (!paymentStatusSearchFilterValue.paymentStatus) {
    //         if (!isEmpty(dropdownItems)) {
    //             const dropdownItem = dropdownItems.find(i => i.isDefault);
    //             if (dropdownItem) {
    //                 setPaymentStatusSearchFilterState(prev => {
    //                     if (prev.paymentStatus) return prev;
    //
    //                     return {
    //                         ...prev,
    //                         paymentStatus: dropdownItem.Code,
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dropdownItems, setPaymentStatusSearchFilterState]);

    const setDropdownItem = useCallback(e => {
        setPaymentStatusSearchFilterState({
            paymentStatus: e.value?.Code
        })
    }, [setPaymentStatusSearchFilterState]);

    return (
        <div className={className} css={searchGroupStyle}>
            <FilterLabel htmlFor={"paymentStatus"}>{label}</FilterLabel>
            <Dropdown
                name="paymentStatus"
                value={dropdownItems.find(item => item.Code === paymentStatusSearchFilterValue.paymentStatus)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="Name"
                placeholder={placeholder}
            />
        </div>
    )
}

const searchGroupStyle = theme => css`
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: max-content;
`;

export default PaymentStatusSearchFilter;