import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_FLIGHT_URI } from "../../../includes/constants";

const useUpdateFlightInfo = () => {
    const [{
        data,
        loading,
        error
    }, updateData] = useAxios(
        { url: API_FLIGHT_URI /** Should set `method` e.g. POST, PUT **/ },
        { manual: true },
    );

    const updateFlightInfo = useCallback(({ flightId, data }) => {
        return updateData({
            url: `${API_FLIGHT_URI}/${flightId ?? ""}`,
            method: flightId ? "PUT" : "POST",
            data,
        });
    }, [updateData]);

    return [
        {
            data,
            loading,
            error
        },
        updateFlightInfo,
    ];
}

export default useUpdateFlightInfo;