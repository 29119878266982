import React, { lazy, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import { useSetRecoilState, } from 'recoil';
import { css, Global } from "@emotion/react";

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';

import { Toast } from "primereact/toast";
import { initQuill } from "./includes/editorConfig";
import { currentUserState, getUserFromStorage } from "./includes/auth";
import { withToastContext } from "./core/contexts/ContextProviderContainer";
import { useToastContext } from "./core/contexts/ToastContext";
import useAxiosAuth from "./core/hooks/useAxiosAuth";
import { AwaitingDialogContextProvider } from "./core/contexts/AwaitingDialogContext";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Loading from "./components/loading/Loading";

const UserSession = lazy(() => import('./components/auth/UserSession'));

const App = () => {
  initQuill();
  useAxiosAuth();

  const { toastRef } = useToastContext();
  const setCurrentUserInfo = useSetRecoilState(currentUserState);
  const user = getUserFromStorage();

  useEffect(() => {
    if (user) {
      setCurrentUserInfo(user);
    }
  }, [user, setCurrentUserInfo]);

  return (
    <AwaitingDialogContextProvider>
      <Global styles={style} />
      <Toast ref={toastRef} />
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="">
          <React.Suspense fallback={<Loading onTop />}>
            <UserSession>
              {({ user }) => {
                if (user) {
                  return (
                    <Home />
                  );
                } else {
                  return (
                    <Login />
                  );
                }
              }}
            </UserSession>
          </React.Suspense>
        </Route>
      </Switch>
    </AwaitingDialogContextProvider>
  );
}

export default withToastContext(App);

const style = css`
    body {
        padding: 0;
        margin: 0;
    }

    a {
        text-decoration: none;
    }

    * {
        font-family: 'Nanum Gothic', sans-serif;
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    .react-responsive-modal-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .p-inputtext {
        //height: 38px;
        height: 30px;
        height: 2.32rem;
    }

    .p-inputtextarea.p-inputtext {
        height: auto;
    }

    .p-fluid {
        lebel,
        .p-dropdown {
            //max-height: 38px;
            //max-height: 30px;
            max-height: 2.32rem;
        }

    }

    .p-dropdown {
        //min-width: 135px;
        min-width: 10.4rem;
    }

    .p-dialog:not(.confirm-dialog) .p-dialog-content {
        padding: 0 1.5rem 1rem 1.5rem;
    }

    .p-dialog:not(.confirm-dialog) .p-dialog-header {
        border-bottom: 1px solid var(--surface-border);
    }

    .p-dialog:not(.confirm-dialog) .p-dialog-footer {
        padding: 1.5rem;
        border-top: 1px solid var(--surface-border);
    }

    .p-dialog .p-dialog-content {
        min-height: 60px;
        padding-top: 1rem;
    }

    .col-12 {
        padding-right: 0;
    }

    .p-fluid .field.grid > label {
        height: 30px;
        height: 2.32rem;
    }

    .field > label {
        font-weight: 800;
        font-size: 1.1rem;
    }

    .layout-topbar .layout-menu-button {
        margin-left: 0;
        margin-right: 2rem;
    }

    .datatable-row-summary {
        font-weight: 600;

    }

    .datatable-row-summary .p-selection-column .p-checkbox {
      visibility: hidden;
    }
    
    .p-radiobutton {
        min-width: 21px;
        min-height: 21px; 
    }
    
    .p-radiobutton .p-radiobutton-box { 
        min-width: 21px;
        min-height: 21px; 
    }
`;
