import React, { useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";

import {
    flightOrdersDateSearchFilterState,
    flightOrdersListFilterState,
    flightOrdersPaginationFilterState
} from "./store";
import { NOOP } from "../../../../includes/constants";

import AwaitButton from "../../../../components/AwaitButton";
import FilterContainer from "../../../../components/filters/FilterContainer";
import PaginationFilter from "../../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../../components/filters/PageFilterContainer";
import FilterActionContainer from "../../../../components/filters/FilterActionContainer";
import TextFieldSearchFilter from "../../../../components/filters/TextFieldSearchFilter";
import FilterContainerGroups from "../../../../components/filters/FilterContainerGroups";
import DeliveryStatusSearchFilter from "../../../../components/filters/DeliveryStatusSearchFilter";
import { deliveryStatusSearchFilterState } from "../../../../components/filters/store";
import DateSearchFilter from "../../../../components/filters/DateSearchFilter";
import LocationSearchFilter, { locationSearchFilterState } from "../../../../components/filters/LocationSearchFilter";

const searchFieldItems = [
    { name: '주문일', searchDateField: 'FlightOrdersDate', isDefault: true },
];

const textFieldSearchDropdownItems = [
    { name: '주문자명', searchField: 'UserName' },
    { name: '주문번호', searchField: 'FlightOrdersNumber' },
    { name: '수령자명', searchField: 'ReceiverName' },
    { name: '수령자 전화번호', searchField: 'ReceiverPhone' },
    { name: '수령자 휴대폰', searchField: 'ReceiverMobile' },
];

export const flightOrdersSearchFilterState = atom({
    key: 'flightOrdersSearchFilterState',
    default: {
        searchField: textFieldSearchDropdownItems[0].searchField,
        searchStr: "",
    },
});

const FlightOrdersPageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(flightOrdersListFilterState);
    const flightOrdersSearchFilterValue = useRecoilValue(flightOrdersSearchFilterState);
    const paginationFilterValue = useRecoilValue(flightOrdersPaginationFilterState);
    const flightOrdersDateSearchFilterValue = useRecoilValue(flightOrdersDateSearchFilterState);
    const locationSearchFilterValue = useRecoilValue(locationSearchFilterState);
    const deliveryStatusSearchFilterValue = useRecoilValue(deliveryStatusSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...flightOrdersSearchFilterValue,
            }
        });
    }, [flightOrdersSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...flightOrdersDateSearchFilterValue,
            }
        })
    }, [flightOrdersDateSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...locationSearchFilterValue,
            }
        })
    }, [locationSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...deliveryStatusSearchFilterValue,
            }
        })
    }, [deliveryStatusSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString={false} atom={flightOrdersPaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer className={"align-items-start"}>
                    <TextFieldSearchFilter
                        atom={flightOrdersSearchFilterState}
                        label={"주문 검색"}
                        dropdownItems={textFieldSearchDropdownItems}
                        supportQueryString
                        onSearch={onSearch}
                    />
                </FilterContainer>
                <FilterContainer>
                    <DateSearchFilter
                        atom={flightOrdersDateSearchFilterState}
                        searchFieldItems={searchFieldItems}
                        supportQueryString
                    />
                </FilterContainer>

                <FilterContainer>
                    <LocationSearchFilter />
                </FilterContainer>

                <FilterContainer>
                    <DeliveryStatusSearchFilter
                        includeAll
                        atom={deliveryStatusSearchFilterState}
                        className={"align-items-center"}
                    />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

export default FlightOrdersPageFilter;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;