import React from "react";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { css } from "@emotion/react";

const DropdownWithError = ({ className, dropdownClassName, error, ...props }) => (
    <span css={withErrorStyle(error)} className={className}>
        <Dropdown {...props} className={classNames(dropdownClassName, { "p-invalid": !!error })} />
    </span>
);

export default DropdownWithError;

const withErrorStyle = error => css`
    display: block;
    position: relative;
    padding-top: 3px;
    margin-bottom: 12px;

    ${error && css`
        :after {
            content: '${error}';
            position: absolute;
            top: 100%;
            left: 0.5rem;
            color: #f44336;
            width: 100%;
            height: 10px;
            font-size: 10px;
            line-height: 10px;
            margin-top: 2px;
        }
    `}
`;
