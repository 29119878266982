import React from "react";
import { css } from "@emotion/react";
import { RadioButton } from "primereact/radiobutton";
import { NOOP } from "../../includes/constants";


const DeliveryLocationRadioButtons = ({
  name = "DeliveryLocation",
  locatonCode = "",
  deliveryLocations = [],
  handleChange = NOOP,
  className = "",
}) => {


  return (
    <div className={className} css={clearMarginOfGridStyle}>
      {deliveryLocations?.map((location) =>
        <div className="col-12 md:col-4" key={location.Code}>
          <div className="field-radiobutton">
            <RadioButton

              inputId={`deliveryLocation${location.Code}`}
              name={name}
              value={location.Code}
              checked={locatonCode === location.Code}
              onChange={handleChange}
            />
            <label
              htmlFor={`deliveryLocation${location.Code}`}
              css={hoverClickableStyle}
            >
              {location.Name}
            </label>
          </div>
        </div>
      )}


    </div>
  )
}

export default DeliveryLocationRadioButtons;



const clearMarginOfGridStyle = css`
    margin: 0;
`;

const hoverClickableStyle = css`
    cursor: pointer;
`;

