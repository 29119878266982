import React, { useMemo, useState, useRef } from 'react'
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import { useHistory } from "react-router-dom";

import useAPI from "../../core/hooks/useAPI";
import { menuState } from "../../service/hooks/useMenus";
import { API_ORDER_URI } from "../../includes/constants";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import CardContainer from "../../components/layouts/CardContainer";
import OrderCreateItemList from "./components/OrderCreateItemList";
import OrderCreateUserInfo from "./components/OrderCreateUserInfo";
import OrderCreateReceiverInfo from "./components/OrderCreateReceiverInfo";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import AwaitButton from "../../components/AwaitButton";
import DataTableActions from "../../components/layouts/DataTableActions";
import useToast from "../../core/hooks/useToast";

const createOrderModel =
{
  UserId: 0,
  DeliveryLocationCode: "",
  IsReceiverOwn: true,
  ReceiverName: "",
  ReceiverEmail: "",
  ReceiverPhone: "",
  ReceiverMobile: "",
  ReceiverAddress1: "",
  ReceiverAddress2: "",
  ReceiverZipcode: "",
  PickupTypeCode: "2",
  PersonalCustomCode: "",
  ReceiverMessage: "",
  IsCombineItem: false,
  IsUseMileage: false,
  IsRepackage: false,
  OrderItems: [],
  UserInfo: [],
  IsPurchaseOrder: false
};


const OrderCreatePage = () => {

  const menuStateValue = useRecoilValue(menuState);
  const [createOrderDetail, setCreateOrderDetail] = useState(createOrderModel);
  const { closeConfirmation } = useAwaitingDialogContext();
  const { showError } = useToast();
  const receiverRef = useRef();
  const history = useHistory();


  const breadcrumbItems = useMemo(() => {
    return [
      ...menuStateValue.currentBreadcrumbItems,
      { label: '주문생성' }
    ]
  }, [menuStateValue]);

  const onDeleteItem = orderItemData => {

    const orderItemArrayIndex = createOrderDetail.OrderItems.findIndex(i => i.OrderItemId === orderItemData.OrderItemId);

    const newOrderItems = [...createOrderDetail.OrderItems];

    newOrderItems.splice(orderItemArrayIndex, 1);

    setCreateOrderDetail((p) => {
      return { ...p, OrderItems: newOrderItems };
    });

    closeConfirmation();
  }

  const onChangeItem = orderItemData => {


    const newOrderItemId = orderItemData.OrderItemId === 0 ? createOrderDetail.OrderItems.length + 1 : orderItemData.OrderItemId;
    const orderItemArrayIndex = createOrderDetail.OrderItems.findIndex(i => i.OrderItemId === newOrderItemId);

    let newOrderItems = [];

    const newOrderItem = {
      OrderItemId: newOrderItemId,
      ItemTypeCode: orderItemData.ItemTypeCode,
      ItemTypeName: orderItemData.ItemTypeName,
      ItemName: orderItemData.ItemName,
      ItemQuantity: orderItemData.ItemQuantity,
      ItemUrl: orderItemData.ItemUrl,
      ItemAmount: orderItemData.ItemAmount,
      DeliveryCompany: orderItemData.DeliveryCompany || '',
      TrackingNumber: orderItemData.TrackingNumber || '',
    }



    if (orderItemArrayIndex < 0) {
      //create
      newOrderItems = [...createOrderDetail.OrderItems, newOrderItem];
    }
    else {
      //update
      newOrderItems = [...createOrderDetail.OrderItems];
      //newOrderItems.splice(orderItemArrayIndex, 1);

      newOrderItems[orderItemArrayIndex] = newOrderItem;
    }

    setCreateOrderDetail(p => {
      return {
        ...p,
        OrderItems: newOrderItems
      }
    })

    closeConfirmation();
  }

  const onSaveOrderUserInfo = userData => {

    setCreateOrderDetail(p => {
      return { ...p, UserInfo: userData };
    })
  }

  /***********************************************************
   * 주문 생성
   ***********************************************************/
  const createOrder = useAPI({
    url: `${API_ORDER_URI}`,
    method: 'POST',
    successMessage: "주문을 성공적으로 생성하였습니다.",
  });


  const onSaveOrder = () => {

    if (createOrderDetail.UserInfo.length === 0) {
      showError({ message: "고객정보를 선택해 주세요" })
      return;
    }

    if (createOrderDetail.OrderItems.length === 0) {
      showError({ message: "상품정보를 선택해 주세요" })
      return;
    }

    const receiverData = receiverRef.current.handleSubmit();


    if (receiverData) {
      const data = {
        ...createOrderDetail,
        ...receiverData,
        UserId: createOrderDetail.UserInfo.UserId,
      };
      createOrder({
        data: data,
      }).then(() => {
        history.push('/order');
      });
    }

  }

  return (
    <div className="grid" css={orderDetailPageStyle}>


      <div className="col-12">
        <EnhancedBreadCrumb model={breadcrumbItems} showBack />

        <CardContainer>
          <OrderCreateUserInfo
            orderUserData={createOrderDetail.UserInfo}
            onSaveOrderUserInfo={onSaveOrderUserInfo} />

        </CardContainer>
        <CardContainer>
          <OrderCreateReceiverInfo ref={receiverRef}
            orderReceiverData={createOrderDetail.UserInfo}

          />
        </CardContainer>
        <OrderCreateItemList

          orderData={createOrderDetail}
          onChangeItem={onChangeItem}
          onDeleteItem={onDeleteItem}
        />


        <CardContainer>
          <DataTableActions className={"mb-2"} >
            <AwaitButton onClick={onSaveOrder} label={"주문 저장"} icon="pi pi-pencil" />
          </DataTableActions>
        </CardContainer>

      </div>
      <ScrollUpButton />
    </div>
  );
}


export default OrderCreatePage;

const orderDetailPageStyle = css`
      position: relative;
      `;

