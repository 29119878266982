import React, { memo } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

const FilterActionContainer = ({ children = '', className }) => (
    <div className={className} css={filterActionContainerStyle}>
        {children}
    </div>
);

FilterActionContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default memo(FilterActionContainer);

const filterActionContainerStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .p-button  {
        width: auto !important; 
    }
`;

