import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import useAxios from "axios-hooks";
import qs from "qs";
import { weightAmountListFilterState, weightAmountPaginationFilterState } from "../store";
import { stringifyFilter } from "../../../../includes/ajax";
import { API_WEIGHT_AMOUNT_URI } from "../../../../includes/constants";
import useOnloadSearch from "../../../../core/hooks/useOnloadSearch";
import useSearchWithFilters from "../../../../core/hooks/useSearchWithFilters";

const useWeightAmountsData = () => {
    const weightAmountListFilterValue = useRecoilValue(weightAmountListFilterState);

    const getParamAndQueryString = useCallback(() => {
        const params = {};

        if (weightAmountListFilterValue.locationCode) {
            params.locationCode = weightAmountListFilterValue.locationCode;
        }

        const queryString = qs.stringify(params, { filter: stringifyFilter });
        const queryStringForAPI = qs.stringify(params, { filter: stringifyFilter });

        return [queryStringForAPI, queryString];
    }, [weightAmountListFilterValue]);

    const [{ data, loading }, _getData] = useAxios({
            url: API_WEIGHT_AMOUNT_URI,
            method: 'GET',
        },
        { manual: true },
    );

    const getData = useSearchWithFilters({
        apiURI: API_WEIGHT_AMOUNT_URI,
        getData: _getData,
        paginationAtom: weightAmountPaginationFilterState,
        getParamAndQueryString,
    });

    useOnloadSearch({
        getData,
        getParamAndQueryString,
        paginationAtom: weightAmountPaginationFilterState
    });

    return [
        {
            data,
            loading
        },
        getData
    ];
}

export default useWeightAmountsData;