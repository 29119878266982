import useAxios from "axios-hooks";
import { useCallback, useEffect } from "react";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import useToast from "../../core/hooks/useToast";
import { API_CONSIGNMENT_URI } from "../../includes/constants";

const useDeleteConsignment = ({
    callbackAfterSuccess,
    consignmentId = 0,
    successMessage = "송장을 성공적으로 삭제하였습니다.",
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const { showSuccess, showError } = useToast();

    const [{
        data: deleteConsignmentData,
        error: deleteConsignmentError
    }, deleteConsignment] = useAxios({
            url: API_CONSIGNMENT_URI,
            method: 'DELETE',
        },
        { manual: true },
    );

    useEffect(() => {
        if (deleteConsignmentData) {
            showSuccess({ message: successMessage });
            let calledCloseConfirmation = false;

            if (callbackAfterSuccess) {
                const promise = callbackAfterSuccess();
                if (promise?.finally) {
                    promise.finally(closeConfirmation);
                    calledCloseConfirmation = true;
                }
            }

            if (!calledCloseConfirmation) {
                closeConfirmation();
            }
        }
        // refresh / closeConfirmation 넣으면 안됨.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteConsignmentData, showSuccess]);

    useEffect(() => {
        if (deleteConsignmentError) {
            showError({ message: deleteConsignmentError });
            closeConfirmation();
        }
    }, [deleteConsignmentError, closeConfirmation, showError]);

    const onConfirmDeleteConsignment = useCallback((deliveryStatusUpdateData) => {
        if (deliveryStatusUpdateData) {
            deleteConsignment({
                url: `${API_CONSIGNMENT_URI}/${consignmentId}`
            }).finally(closeConfirmation);
        } else {
            closeConfirmation();
        }
    }, [deleteConsignment, closeConfirmation, consignmentId]);

    return onConfirmDeleteConsignment;
}

export default useDeleteConsignment;