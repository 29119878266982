import React, { useEffect } from 'react';
import { css } from "@emotion/react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

import { noticeListFilterState, noticePaginationFilterState } from "../store";
import { NOOP } from "../../../../includes/constants";

import AwaitButton from "../../../../components/AwaitButton";
import FilterContainer from "../../../../components/filters/FilterContainer";
import PaginationFilter from "../../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../../components/filters/PageFilterContainer";
import FilterContainerGroups from "../../../../components/filters/FilterContainerGroups";
import TextFieldSearchFilter from "../../../../components/filters/TextFieldSearchFilter";
import FilterActionContainer from "../../../../components/filters/FilterActionContainer";
import DateSearchFilter, { dateSearchFilterState } from "../../../../components/filters/DateSearchFilter";
import ActiveStatusSearchFilter, { activeStatusSearchFilterState } from "../../../../components/filters/ActiveStatusSearchFilter";

const searchFieldItems = [
    { name: '작성일', searchDateField: 'CreateDate', isDefault: true },
];

const textFieldSearchDropdownItems = [
    { name: '제목', searchField: 'Title', isDefault: true },
];

export const noticeSearchFilterState = atom({
    key: 'noticeSearchFilterState',
    default: {
        searchField: textFieldSearchDropdownItems[0].searchField,
        searchStr: "",
    },
});

const NoticePageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(noticeListFilterState);
    const paginationFilterValue = useRecoilValue(noticePaginationFilterState);
    const noticeSearchFilterValue = useRecoilValue(noticeSearchFilterState);
    const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
    const activeStatusSearchFilterValue = useRecoilValue(activeStatusSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...noticeSearchFilterValue,
            }
        });
    }, [noticeSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...dateSearchFilterValue,
            }
        })
    }, [dateSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...activeStatusSearchFilterValue,
            }
        })
    }, [activeStatusSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString atom={noticePaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer>
                    <TextFieldSearchFilter
                        atom={noticeSearchFilterState}
                        label={"검색"}
                        dropdownItems={textFieldSearchDropdownItems}
                        supportQueryString
                        onSearch={onSearch}
                    />
                </FilterContainer>
                <FilterContainer>
                    <DateSearchFilter searchFieldItems={searchFieldItems} />
                </FilterContainer>
                <FilterContainer>
                    <ActiveStatusSearchFilter />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

export default NoticePageFilter;