import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import useAxios from "axios-hooks";
import { isEmpty } from "lodash";
import { css } from "@emotion/react";
import { DataTable } from "primereact/datatable";

import useAPI from "../../../core/hooks/useAPI";
import useToast from "../../../core/hooks/useToast";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import { API_MANAGER_CUSTOMER_MILEAGE_URI, NOOP } from "../../../includes/constants";

import Loading from "../../../components/loading/Loading";
import DataTableActions from "../../../components/layouts/DataTableActions";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import TableTitle from "../../../components/layouts/TableTitle";
import CardContainer from "../../../components/layouts/CardContainer";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import CreateCustomerMileageDialog, { createCustomerMileageDialogState } from "../../../components/dialogs/CreateCustomerMileageDialog";

const fieldColumnTitleMap = {
    MileageId: "마일리지 ID",
    UserId: "회원 ID",
    UserName: "회원명",
    MileageName: "마일리지명",
    MileagePoint: "마일리지",
}

const MileageList = ({
    refresh = NOOP,
    userId = 0,
    customerMileageData = null,
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const { showSuccess, showError } = useToast();
    const [selectedItem, setSelectedItem] = useState(null);
    const setCreateCustomerMileageDialogState = useSetRecoilState(createCustomerMileageDialogState);

    const {
        renderIndex,
        renderColumn,
        rowClassName,
        getSummaryData,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        getData: NOOP,
        dataSize: (customerMileageData?.Data?.length ?? -1) + 1,
    });

    const titleSection = useMemo(() => (
        <TableTitle linkName={"MileageList"}>
            <h5>마일리지</h5>
        </TableTitle>
    ), []);

    /***********************************************************
     * 마일리지 추가
     ***********************************************************/
    const [{
        data: createMileageData,
        error: createMileageError
    }, createMileage] = useAxios({
            url: `${API_MANAGER_CUSTOMER_MILEAGE_URI}`,
            method: 'POST',
        },
        { manual: true },
    );

    useEffect(() => {
        if (createMileageData) {
            showSuccess({ message: `마일리지를 성공적으로 저장하였습니다.` });
            refresh().finally(closeConfirmation);
        }
        // refresh / closeConfirmation 넣으면 안됨.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createMileageData, showSuccess]);

    useEffect(() => {
        if (createMileageError) {
            showError({ message: createMileageError });
            closeConfirmation();
        }
    }, [createMileageError, closeConfirmation, showError]);

    const onCreateMileage = useCallback((mileageData) => {
        if (mileageData) {
            createMileage({
                data: {
                    UserId: Number(userId),
                    MileageName: mileageData.MileageName,
                    MileagePoint: mileageData.MileagePoint,
                }
            });
        } else {
            closeConfirmation();
        }
    }, [createMileage, closeConfirmation, userId]);

    /***********************************************************
     * 마일리지 수정
     ***********************************************************/
    const updateMileage = useAPI({
        url: API_MANAGER_CUSTOMER_MILEAGE_URI,
        method: 'PUT',
        successMessage: "마일리지를 성공적으로 수정하였습니다.",
        callbackAfterSuccess: refresh,
    });

    const onUpdateItem = useCallback((mileageData) => {
        if (mileageData) {
            updateMileage({
                url: `${API_MANAGER_CUSTOMER_MILEAGE_URI}/${mileageData.MileageId}`,
                data: {
                    UserId: Number(mileageData.UserId),
                    MileageName: mileageData.MileageName,
                    MileagePoint: mileageData.MileagePoint,
                }
            });
        } else {
            closeConfirmation();
        }
    }, [updateMileage, closeConfirmation]);

    const onUpdateClicked = useCreateActionButton({
        onSelect: onUpdateItem,
        confirmMessage: "마일리지를 수정하시겠습니까?",
        atom: createCustomerMileageDialogState,
        dialogComponent: (props) => {
            return (
                <CreateCustomerMileageDialog title={"마일리지 수정"} {...props} />
            );
        },
    });

    useEffect(() => {
        if (selectedItem && !selectedItem.IsSummaryData) {
            setCreateCustomerMileageDialogState(selectedItem);
            onUpdateClicked().finally(() => setSelectedItem(null));
        }
        // onUpdateClicked 를 디펜던시에 넣으면 안되요!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, setCreateCustomerMileageDialogState]);

    /***********************************************************
     * 마일리지 리스트
     ***********************************************************/
    const mileageList = useMemo(() => {
        const list = customerMileageData?.Data;
        if (isEmpty(list)) {
            return [];
        }

        const summaryFields = [
            "MileagePoint",
        ];

        const summaryData = getSummaryData(list, summaryFields);

        // 마일리지는 소수점 없겠지?
        if (summaryData && summaryData['MileagePoint']) {
            summaryData['MileagePoint'] = Number(summaryData['MileagePoint'] || 0).toFixed(0);
        }

        return [
            ...list,
            {
                ...summaryData,
                MileageName: "합계",
            }
        ]
    }, [customerMileageData, getSummaryData]);

    return (
        <CardContainer css={mileageListStyle}>
            {!customerMileageData && <Loading />}

            <DataTableActions className={"mb-2"} titleSection={titleSection}>
                <CreateActionButton
                    icon="pi pi-plus"
                    label="마일리지 추가"
                    onSelect={onCreateMileage}
                    confirmMessage={"마일리지를 추가하시겠습니까?"}
                    atom={createCustomerMileageDialogState}
                    dialogComponent={CreateCustomerMileageDialog}
                    shouldShowConfirm={false}
                />
            </DataTableActions>

            <ScrollDataTableContainer loading={!customerMileageData} minWidth={1280}>
                <DataTable
                    resizableColumns
                    columnResizeMode="fit"
                    rowClassName={rowClassName}
                    loading={!customerMileageData}
                    value={mileageList}
                    selectionMode={"single"}
                    selection={selectedItem}
                    onSelectionChange={(e) => setSelectedItem(e.value)}
                    dataKey="MileageId"
                    className="datatable-responsive"
                    emptyMessage="No data found."
                >
                    {renderIndex("번호")}
                    {renderColumn("MileageName")}
                    {renderColumn("MileagePoint")}
                </DataTable>
            </ScrollDataTableContainer>
        </CardContainer>
    );
}

export default MileageList;

const mileageListStyle = css`
    .datatable-row-summary {
        td:first-of-type {
            > div {
                visibility: hidden;
            }
        }
    }
`;