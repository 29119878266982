import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const FilterContainerGroups = ({ children = '', className }) => (
    <div className={classNames("grid p-fluid col-12", className)}>
        {children}
    </div>
);

FilterContainerGroups.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default memo(FilterContainerGroups);