import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot, } from 'recoil';

import App from './App';
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import './includes/ajax';
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";

ReactDOM.render(
    <RecoilRoot>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </ThemeProvider>
        </BrowserRouter>
    </RecoilRoot>,
    document.getElementById('root')
);
