import React, { useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";

import { consignmentListFilterState, consignmentPaginationFilterState } from "../store";
import { NOOP } from "../../../includes/constants";

import AwaitButton from "../../../components/AwaitButton";
import FilterContainer from "../../../components/filters/FilterContainer";
import PaginationFilter from "../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../components/filters/PageFilterContainer";
import FilterContainerGroups from "../../../components/filters/FilterContainerGroups";
import FilterActionContainer from "../../../components/filters/FilterActionContainer";
import TextFieldSearchFilter from "../../../components/filters/TextFieldSearchFilter";
import FlightSelectSearchFilter from "../../../components/filters/FlightSelectSearchFilter";
import { deliveryStatusSearchFilterState, flightSelectSearchFilterState } from "../../../components/filters/store";
import DateSearchFilter, { dateSearchFilterState } from "../../../components/filters/DateSearchFilter";
import DeliveryStatusSearchFilter from "../../../components/filters/DeliveryStatusSearchFilter";

const searchFieldItems = [
    { name: '발급일', searchDateField: 'CreateDate', isDefault: true },
    { name: '주문일', searchDateField: 'OrderDate' },
];

const textFieldSearchDropdownItems = [
    { name: '송장번호', searchField: 'ConsignmentNumber', isDefault: true },
    { name: '주문자명', searchField: 'UserName' },
    { name: '주문번호', searchField: 'OrderNumber' },
    { name: '수령자명', searchField: 'ReceiverName' },
];

export const consignmentSearchFilterState = atom({
    key: 'consignmentSearchFilterState',
    default: {
        searchField: textFieldSearchDropdownItems[0].searchField,
        searchStr: "",
    },
});

const ConsignmentPageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(consignmentListFilterState);
    const consignmentSearchFilterValue = useRecoilValue(consignmentSearchFilterState);
    const paginationFilterValue = useRecoilValue(consignmentPaginationFilterState);
    const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
    const flightSelectSearchFilterValue = useRecoilValue(flightSelectSearchFilterState);
    const deliveryStatusSearchFilterValue = useRecoilValue(deliveryStatusSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...consignmentSearchFilterValue,
            }
        });
    }, [consignmentSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...dateSearchFilterValue,
            }
        })
    }, [dateSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...flightSelectSearchFilterValue,
            }
        })
    }, [flightSelectSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...deliveryStatusSearchFilterValue,
            }
        })
    }, [deliveryStatusSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString atom={consignmentPaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer className={"align-items-start"}>
                    <TextFieldSearchFilter
                        atom={consignmentSearchFilterState}
                        label={"송장 검색"}
                        dropdownItems={textFieldSearchDropdownItems}
                        supportQueryString
                        onSearch={onSearch}
                    />
                </FilterContainer>
                <FilterContainer>
                    <DateSearchFilter searchFieldItems={searchFieldItems} />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterContainerGroups>
                <FilterContainer>
                    <FlightSelectSearchFilter
                        state={flightSelectSearchFilterState}
                        supportQueryString
                    />
                </FilterContainer>
                <FilterContainer>
                    <DeliveryStatusSearchFilter
                        includeAll
                        atom={deliveryStatusSearchFilterState}
                        supportQueryString
                        className={"align-items-center mb-2"}
                    />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

export default ConsignmentPageFilter;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;