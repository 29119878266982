import moment from "moment";

export const DATE_FORMAT_REACT_PRIME = "dd/mm/yy";
export const DATE_FORMAT_FROM_MOMENT = "YYYY-MM-DD 00:00:00";
export const DATE_FORMAT_FROM_SERVER = "DD/MM/YYYY HH:mm:ss";

export const formatFromDate = (dateObject, formatString = DATE_FORMAT_FROM_MOMENT) => {
    return moment(dateObject).format(formatString);
}

export const stringToDate = (stringDate, formatString = DATE_FORMAT_FROM_SERVER) => {
    return moment(stringDate, formatString).toDate();
}

export const onlyDateFormatter = (rowData, fieldName, formatString = DATE_FORMAT_FROM_SERVER) => {
    const momentDate = moment(rowData[fieldName], formatString);
    if (momentDate.isValid()) {
        return momentDate.format("DD/MM/YYYY");
    }
    return "";
}

export const yearRange = (() => {
    const startYear = 2021; // 비즈니스가 시작된 연도
    const endYear = moment().add("years", +2).get("years");
    return `${startYear}:${endYear}`;
})();