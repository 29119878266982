import { useCallback } from "react";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { API_INVOICE_URI } from "../../includes/constants";
import { isEmpty } from "lodash";
import useAPI from "../../core/hooks/useAPI";

const usePaymentInvoice = ({
    callbackAfterSuccess,
    invoiceIds = [],
    successMessage = "선택하신 인보이스를 성공적으로 입금처리 하였습니다.",
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();

    const paymentInvoice = useAPI({
        method: 'PUT',
        successMessage,
        callbackAfterSuccess,
    });

    const onConfirmPaymentInvoice = useCallback((updateRequest) => {
        if (updateRequest && !isEmpty(invoiceIds)) {
            paymentInvoice({
                url: `${API_INVOICE_URI}/payment`,
                data: invoiceIds.map(invoiceId => {
                    return {
                        InvoiceId: invoiceId,
                        IsPaymentCompleted: true, // TODO 그냥 true 로?
                    }
                }),
            }).then(closeConfirmation);
        } else {
            closeConfirmation();
        }
    }, [paymentInvoice, closeConfirmation, invoiceIds]);

    return onConfirmPaymentInvoice;
}

export default usePaymentInvoice;