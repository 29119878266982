import React, { useCallback } from 'react';
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";
import FilterLabel from "./FilterLabel";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import moment from "moment";
import { formatFromDate } from "../../includes/dates";

const today = () => (moment().hours(0).minutes(0).seconds(0));

const beforeArrivalDates = {
    // startDate: formatFromDate(today().add(1, 'days').toDate()), // 오늘 + 1
    startDate: formatFromDate(today().toDate()), // 오늘부터
    endDate: formatFromDate(today().add(1, 'days').add(1, 'years').toDate()),   // 오늘 + 1 + 1년
}

const afterArrivalDates = {
    startDate: formatFromDate(today().add(-1, 'years').toDate()),   // 1년전부터
    endDate: formatFromDate(today().add(1, 'days').toDate()), // 오늘까지 하루 더하면 그 다음날 00:00:00 까지
}

const BEFORE_ARRIVAL_DATE = "BeforeArrivalDate";
const AFTER_ARRIVAL_DATE = "AfterArrivalDate";

const dropdownItems = [
    { Name: '도착전', Code: BEFORE_ARRIVAL_DATE },
    { Name: '도착완료', Code: AFTER_ARRIVAL_DATE },
];

export const flightStatusSearchFilterState = atom({
    key: 'flightStatusSearchFilterState',
    default: {
        flightStatusCode: dropdownItems[0].Code,
        searchDateField: "EstimatedArrivalDate",
        ...beforeArrivalDates,
    },
});

const FlightStatusSearchFilter = ({ className }) => {
    const setFlightStatusSearchFilterState = useSetRecoilState(flightStatusSearchFilterState);
    const flightStatusSearchFilterValue = useRecoilValue(flightStatusSearchFilterState);

    // TODO 여긴 URL에 저장할 필요 없잖아?
    // const setQueryStringToState = useMemo(() => {
    //     return (searchObject) => {
    //         setFlightStatusSearchFilterState({
    //             flightStatusCode: searchObject.flightStatusCode,
    //         });
    //     }
    // }, [setFlightStatusSearchFilterState]);
    //
    // useSetQueryStringToState(setQueryStringToState);

    const setDropdownItem = useCallback(e => {
        const flightStatusCode = e.value?.Code;
        setFlightStatusSearchFilterState(prev => ({
            ...prev,
            flightStatusCode,
            ...(flightStatusCode === BEFORE_ARRIVAL_DATE
                    ? beforeArrivalDates
                    : afterArrivalDates
            )
        }));
    }, [setFlightStatusSearchFilterState]);

    return (
        <div className={className} css={searchGroupStyle}>
            <FilterLabel htmlFor={"flightStatusCode"}>운항상태</FilterLabel>
            <Dropdown
                name="flightStatusCode"
                value={dropdownItems.find(item => item.Code === flightStatusSearchFilterValue.flightStatusCode)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="Name"
                placeholder="Select One"
            />
        </div>
    )
}

const searchGroupStyle = theme => css`
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: max-content;
    // display: flex;
    // align-items: center;
    // margin: 0;
    //
        // ${theme.breakpoints.down("xs")} {
    //     > * {
    //         width: 100%;
    //         margin: 2px 0;
    //         padding: 0;
    //     }
    // }
`;

export default FlightStatusSearchFilter;