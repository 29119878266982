import React, { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { Button } from "primereact/button";

import { orderListFilterState, orderPaginationFilterState } from "../store";
import { NOOP } from "../../../includes/constants";

import {
  deliveryStatusSearchFilterState,
  flightSelectSearchFilterState,
  itemEnteredStatusSearchFilterState,
  useMileageStatusSearchFilterState,
  combineItemStatusSearchFilterState,
  repackageStatusSearchFilterState
} from "../../../components/filters/store";
import AwaitButton from "../../../components/AwaitButton";
import FilterContainer from "../../../components/filters/FilterContainer";
import EnhancedCollapse from "../../../components/layouts/EnhancedCollapse";
import PaginationFilter from "../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../components/filters/PageFilterContainer";
import FilterContainerGroups from "../../../components/filters/FilterContainerGroups";
import FilterActionContainer from "../../../components/filters/FilterActionContainer";
import TextFieldSearchFilter from "../../../components/filters/TextFieldSearchFilter";
import FlightSelectSearchFilter from "../../../components/filters/FlightSelectSearchFilter";
import DeliveryStatusSearchFilter from "../../../components/filters/DeliveryStatusSearchFilter";
import ItemEnteredStatusSearchFilter from "../../../components/filters/ItemEnteredStatusSearchFilter";
import CombineItemStatusSearchFilter from "../../../components/filters/CombineItemStatusSearchFilter";
import RepackageStatusSearchFilter from "../../../components/filters/RepackageStatusSearchFilter";
import UseMileageStatusSearchFilter from "../../../components/filters/UseMileageStatusSearchFilter";
import DateSearchFilter, { dateSearchFilterState } from "../../../components/filters/DateSearchFilter";
import LocationSearchFilter, { locationSearchFilterState } from "../../../components/filters/LocationSearchFilter";
import PaymentStatusSearchFilter, { paymentStatusSearchFilterState } from "../../../components/filters/PaymentStatusSearchFilter";

const searchFieldItems = [
  { name: '주문일', searchDateField: 'OrderDate', isDefault: true },
];

const textFieldSearchDropdownItems = [
  { name: '주문자명', searchField: 'UserName', isDefault: true },
  { name: '주문번호', searchField: 'OrderNumber' },
  { name: '수령자명', searchField: 'ReceiverName' },
  { name: '수령자 전화번호', searchField: 'ReceiverPhone' },
  { name: '수령자 휴대폰', searchField: 'ReceiverMobile' },
];

export const orderSearchFilterState = atom({
  key: 'orderSearchFilterState',
  default: {
    searchField: textFieldSearchDropdownItems[0].searchField,
    searchStr: "",
  },
});

const OrderPageFilter = ({
  onSearch = NOOP,
  loading = false,
}) => {
  const [isOpenDetailSearch, setIsOpenDetailSearch] = useState(false);
  const setFilter = useSetRecoilState(orderListFilterState);
  const orderSearchFilterValue = useRecoilValue(orderSearchFilterState);
  const paginationFilterValue = useRecoilValue(orderPaginationFilterState);
  const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
  const locationSearchFilterValue = useRecoilValue(locationSearchFilterState);
  const deliveryStatusSearchFilterValue = useRecoilValue(deliveryStatusSearchFilterState);
  const paymentStatusSearchFilterValue = useRecoilValue(paymentStatusSearchFilterState);
  const flightSelectSearchFilterValue = useRecoilValue(flightSelectSearchFilterState);
  const itemEnteredStatusSearchFilterValue = useRecoilValue(itemEnteredStatusSearchFilterState);

  const useMileageStatusSearchFilterValue = useRecoilValue(useMileageStatusSearchFilterState);
  const repackageStatusSearchFilterValue = useRecoilValue(repackageStatusSearchFilterState);
  const combineItemStatusSearchFilterValue = useRecoilValue(combineItemStatusSearchFilterState);

  const toggleDetailSearch = useCallback(() => {
    setIsOpenDetailSearch(prev => !prev);
  }, []);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...paginationFilterValue,
      }
    });
  }, [paginationFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...orderSearchFilterValue,
      }
    });
  }, [orderSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...dateSearchFilterValue,
      }
    })
  }, [dateSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...locationSearchFilterValue,
      }
    })
  }, [locationSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...deliveryStatusSearchFilterValue,
      }
    })
  }, [deliveryStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...paymentStatusSearchFilterValue,
      }
    })
  }, [paymentStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...flightSelectSearchFilterValue,
      }
    })
  }, [flightSelectSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...itemEnteredStatusSearchFilterValue,
      }
    })
  }, [itemEnteredStatusSearchFilterValue, setFilter]);


  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...useMileageStatusSearchFilterValue,
      }
    })
  }, [useMileageStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...repackageStatusSearchFilterValue,
      }
    })
  }, [repackageStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...combineItemStatusSearchFilterValue,
      }
    })
  }, [combineItemStatusSearchFilterValue, setFilter]);
  return (
    <PageFilterContainer>

      <PaginationFilter supportQueryString atom={orderPaginationFilterState} />

      <FilterContainerGroups>
        <FilterContainer className={"align-items-start"}>
          <TextFieldSearchFilter
            atom={orderSearchFilterState}
            label={"주문 검색"}
            dropdownItems={textFieldSearchDropdownItems}
            supportQueryString
            onSearch={onSearch}
          />
        </FilterContainer>
        <FilterContainer>
          <DateSearchFilter searchFieldItems={searchFieldItems} supportQueryString />
        </FilterContainer>
      </FilterContainerGroups>

      <EnhancedCollapse isOpen={isOpenDetailSearch} css={detailSearchCollapseStyle}>
        <FilterContainerGroups css={detailSearchContainerStyle}>
          <FilterContainer>
            <LocationSearchFilter supportQueryString />
          </FilterContainer>
          <FilterContainer>
            <DeliveryStatusSearchFilter
              includeAll
              atom={deliveryStatusSearchFilterState}
              supportQueryString
              className={"align-items-center mb-2"}
            />
            <PaymentStatusSearchFilter supportQueryString />
          </FilterContainer>
          <FilterContainer>
            <FlightSelectSearchFilter
              state={flightSelectSearchFilterState}
              supportQueryString
            />
          </FilterContainer>
          <FilterContainer>
            <ItemEnteredStatusSearchFilter
              label={"입고 상태"}
              atom={itemEnteredStatusSearchFilterState}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>
          <FilterContainer>
            <UseMileageStatusSearchFilter
              atom={useMileageStatusSearchFilterState}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>
          <FilterContainer>
            <RepackageStatusSearchFilter
              atom={repackageStatusSearchFilterState}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>
          <FilterContainer>
            <CombineItemStatusSearchFilter
              atom={combineItemStatusSearchFilterState}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>
        </FilterContainerGroups>
      </EnhancedCollapse>

      <FilterActionContainer>
        <AwaitButton
          icon={"pi pi-search"}
          className="mr-2"
          css={buttonStyle}
          type="button"
          onClick={onSearch}
          label={"Search"}
          loading={loading}
        />
        <Button
          icon={isOpenDetailSearch ? "pi pi-angle-up" : "pi pi-angle-down"}
          label={isOpenDetailSearch ? "상세검색 닫기" : "상세검색 열기"}
          className="p-button-outlined mr-2"
          onClick={toggleDetailSearch}
        />
      </FilterActionContainer>
    </PageFilterContainer>
  )
}

export default OrderPageFilter;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

const detailSearchCollapseStyle = css`
    border: 1px solid var(--surface-border);
    border-radius: 3px;
    margin: 0.5rem -0.5rem 0.5rem;
`;

const detailSearchContainerStyle = css`
    margin: 0 !important;
`;