import Flight from "../../pages/Flight";
import Order from "../../pages/Order";
import OrderItem from "../../pages/OrderItem";
import Consignment from "../../pages/Consignment";
import Invoice from "../../pages/Invoice";
import Notice from "../../pages/board/Notice";
import Popup from "../../pages/board/Popup";
import Qna from "../../pages/board/Qna";
import ChargeType from "../../pages/system/ChargeType";
import WeightAmount from "../../pages/system/WeightAmount";
import ManagerAdminUser from "../../pages/system/ManagerAdminUser";
import Customer from "../../pages/Customer";
import Dashboard from "../../pages/Dashboard";
import { API_BASE, API_INVOICE_URI } from "../../includes/constants";
import Purchase from "../../pages/Purchase";
import PurchaseOrderItem from "../../pages/PurchaseOrderItem";

export const menuRouteMap = {
    '/flight': Flight,
    '/order': Order,
    '/orderitem': OrderItem,
    '/consignment': Consignment,
    '/invoice': Invoice,
    '/board/notice': Notice,
    '/board/popup': Popup,
    '/board/qna': Qna,
    '/system/charge': ChargeType,
    '/system/weightamount': WeightAmount,
    '/system/manager': ManagerAdminUser,
    '/customer': Customer,
    '/dashboard': Dashboard,
    '/purchaseorder': Purchase,
    '/purchaseorderitem': PurchaseOrderItem
};

export const getFlightPath = id => (`/flight/${id}`);
export const getOrderPath = id => (`/order/${id}`);
export const getPurchaseOrderPath = id => (`/purchaseorder/${id}`);
export const getQnaPath = id => (`/board/qna/${id}`);
export const getInvoiceDownloadPath = id => (`${API_BASE}${API_INVOICE_URI}/${id}/pdf`);