import React, { useCallback, useMemo, useState } from "react";
import { isEmpty, isFunction } from "lodash";
import { Link } from "react-router-dom";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { Column } from "primereact/column";

import { mainPathNameState } from "../../store";
import { NOOP } from "../../includes/constants";
import { getColumnValue } from "../../includes/datatable";

export const dataTableColumnsSortState = atom({
  key: 'dataTableColumnsSortState',
  default: {
    sortField: null,
    sortOrder: null,
  },
});

const useDataTableColumns = ({
  fieldColumnTitleMap = {},
  sortableFields = [],
  getData = NOOP,
  dataSize = 0,
} = {
    fieldColumnTitleMap: {},
    sortableFields: [],
    getData: NOOP,
    dataSize: 0,
  }) => {
  const mainPathNameStateValue = useRecoilValue(mainPathNameState);
  const [sortState, setSortState] = useRecoilState(dataTableColumnsSortState);
  const [pageObject, setPageObject] = useState({
    page: 0,
    rows: 0,
  });


  const onSort = useCallback(sortObject => {
    if (!dataSize) return;

    setSortState(sortObject);
    getData({
      orderField: sortObject.sortField,
      orderBy: sortObject.sortOrder === 1 ? "ASC" : "DESC",
    });
  }, [getData, dataSize, setSortState]);

  const onPage = useCallback(pageObject => {
    setPageObject(pageObject);
    // pageCount is the number of total pages.
    // rows is pageSize.
    getData({
      pageNo: pageObject.page + 1,
      pageSize: pageObject.rows, // pageSize가 잘못되어서 변경함 rows를 사용하자
    });
  }, [getData]);

  const getColumnTitle = useCallback(field => {
    return fieldColumnTitleMap[field] || (field === "IsSummaryData" ? "" : field);
  }, [fieldColumnTitleMap]);

  const getFieldTemplate = useCallback((field, options = {}) => {
    if (field === "DeliveryStatusName") {

      return (rowData, props) => {
        var value = getColumnValue(field, rowData[field])
        return (
        <div className={`column-${field}`} css={value === "주문취소"?alertBodyStyle:columnBodyStyle}>
        <span className="p-column-title">{getColumnTitle(field)}</span>
        {getColumnValue(field, rowData[field])}
      </div>
      )
    }
    } else if (field === "IsPurchaseOrder") {
      return (rowData, props) => {
        var value = getColumnValue(field, rowData[field]) === "True"? "구매대행":"배송대행";
        return (
        <div className={`column-${field}`} css={columnBodyStyle}>
        <span className="p-column-title">{getColumnTitle(field)}</span>
        {value}
      </div>
      )
    }
    }
    return (rowData, props) => (
      <div className={`column-${field}`} css={columnBodyStyle}>
        <span className="p-column-title">{getColumnTitle(field)}</span>
        {options.prefixAppender ? options.prefixAppender(field, rowData) : ""}
        {getColumnValue(field, (options.formatValue ? options.formatValue(rowData, field) : rowData[field]))}
        {options.suffixAppender ? options.suffixAppender(rowData, field) : ""}
      </div>
    );
  }, [getColumnTitle]);

  const getLinkTemplate = useCallback((field, linkField, getLinkPath = null, onClick = null, linkProps = null, otherProps = null) => {
    const getCompletedLinkPath = (linkFieldData) => {
      if (getLinkPath && isFunction(getLinkPath)) {
        return getLinkPath(linkFieldData) || "#";
      }
      return `${mainPathNameStateValue}/${linkFieldData}`;
    }

    const generateHandleClick = (rowData, fieldData, linkFieldData) => {
      return (e) => {
        onClick(e, rowData, fieldData, linkFieldData);
      }
    }

    return (rowData) => {
      const to = getCompletedLinkPath(rowData[linkField]);
      const handleClick = onClick && generateHandleClick(rowData, rowData[field], rowData[linkField]);

      return (
        <div className={`column-${field}`}>
          <span className="p-column-title">{getColumnTitle(field)}</span>
          {!onClick && to && (
            <>
              {to.startsWith("http")
                ? (
                  <a
                    href={to}
                    {...linkProps}
                    {...otherProps}
                  >
                    {rowData[field]}
                  </a>
                )
                : (
                  <Link
                    to={to}
                    {...linkProps}
                    {...otherProps}
                  >
                    {rowData[field]}
                  </Link>
                )}
            </>
          )}
          {onClick && (
            <a
              css={linkColumnStyle}
              onClick={handleClick}
              {...linkProps}
              {...otherProps}
            >
              {rowData[field]}
            </a>
          )}
        </div>
      );
    }
  }, [getColumnTitle, mainPathNameStateValue]);

  const isSortable = useCallback(field => {
    return sortableFields.includes(field);
  }, [sortableFields]);

  const renderColumn = useCallback((field, options = {}) => {
    return (
      <Column
        field={field}
        sortable={isSortable(field)}
        body={getFieldTemplate(field, options)}
        header={getColumnTitle(field)}
        headerClassName={`header-column-${field}`}
        {...options?.headerStyle && { headerStyle: options?.headerStyle }}
      />
    )
  }, [getColumnTitle, getFieldTemplate, isSortable]);

  const renderLinkColumn = useCallback((field, linkField, {
    onClick,
    getLinkPath,
    linkProps,
    ...otherProps
  } = { getLinkPath: null }
  ) => (
    <Column
      field={field}
      header={getColumnTitle(field)}
      sortable={isSortable(field)}
      headerClassName={`header-column-${field}`}
      body={getLinkTemplate(field, linkField, getLinkPath, onClick, linkProps, otherProps)}
    />
  ), [getColumnTitle, getLinkTemplate, isSortable]);

  const renderCheckboxColumn = useCallback(() => (
    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
  ), []);

  const getRowIndex = useCallback((data, props) => {
    return <div>{props.rowIndex + 1}</div>;
  }, []);

  const renderIndex = useCallback((header) => (
    <Column field="Index" header={header} body={getRowIndex} headerStyle={{ width: '100px' }} />
  ), [getRowIndex]);

  const rowClassName = useMemo(() => ({
    "datatable-row-summary": true
  }), []);

  const rowClass = useCallback((data) => {
    if (data?.IsSummaryData) {
      return rowClassName;
    }

    return null;
  }, [rowClassName]);


  const getSummaryData = useCallback((list, summaryFields) => {
    if (isEmpty(list)) {
      return null;
    }

    const summaryData = {
      IsSummaryData: true,
    }

    summaryFields.forEach(field => {
      summaryData[field] = 0;
    });

    list.reduce((sum, invoice) => {
      summaryFields.forEach(field => {
        summaryData[field] += Number(invoice[field]);
      });
      return summaryData;
    }, summaryData);

    summaryFields.forEach(field => {
      summaryData[field] = summaryData[field].toFixed(2);
    });

    return summaryData;
  }, []);

  return {
    sortPaginationProps: {
      ...sortState,
      onSort,
      onPage,
      first: pageObject.page * pageObject.rows
    },
    renderColumn,
    renderLinkColumn,
    renderCheckboxColumn,
    renderIndex,
    rowClassName: rowClass,
    getSummaryData,
  }
}

export default useDataTableColumns;

const columnBodyStyle = css`
    width: 100%;
`;

const linkColumnStyle = css`
    cursor:pointer;
`;

const alertBodyStyle = css`
    width: 100%;
    color: red;
`;