import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ConsignmentPage from "./ConsignmentPage";
// import ConsignmentDetailPage from "./ConsignmentDetailPage";
import { useSetRecoilState } from "recoil";
import { mainPathNameState } from "../../store";

const Consignment = () => {
    const setMainPathNameState = useSetRecoilState(mainPathNameState);
    const match = useRouteMatch();
    const mainPathName = match.path;

    useEffect(() => {
        setMainPathNameState(match.path);
    }, [match.path, setMainPathNameState]);

    return (
        <Switch>
            <Route path={`${mainPathName}`} exact component={ConsignmentPage} />
            {/*<Route path={`${mainPathName}/:consignmentId`} component={ConsignmentDetailPage} />*/}
        </Switch>
    );
}
export default Consignment;