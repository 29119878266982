import useAxios from "axios-hooks";
import { useCallback, useEffect } from "react";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import useToast from "../../core/hooks/useToast";
import { API_CONSIGNMENT_URI } from "../../includes/constants";

const useCreateConsignment = ({
    callbackAfterSuccess,
    orderIds = [],
    successMessage = "선택하신 주문의 송장을 성공적으로 발행하였습니다.",
}) => {

    const { closeConfirmation } = useAwaitingDialogContext();
    const { showSuccess, showError } = useToast();

    const [{
        data: createConsignmentData,
        error: createConsignmentError
    }, createConsignment] = useAxios({
            url: API_CONSIGNMENT_URI,
            method: 'POST',
        },
        { manual: true },
    );

    useEffect(() => {
        if (createConsignmentData) {
            showSuccess({ message: successMessage });
            let calledCloseConfirmation = false;

            if (callbackAfterSuccess) {
                const promise = callbackAfterSuccess();
                if (promise?.finally) {
                    promise.finally(closeConfirmation);
                    calledCloseConfirmation = true;
                }
            }

            if (!calledCloseConfirmation) {
                closeConfirmation();
            }
        }
        // refresh / closeConfirmation 넣으면 안됨.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createConsignmentData, showSuccess]);

    useEffect(() => {
        if (createConsignmentError) {
            showError({ message: createConsignmentError });
            closeConfirmation();
        }
    }, [createConsignmentError, closeConfirmation, showError]);

    const onConfirmCreateConsignment = useCallback((deliveryStatusUpdateData) => {
        if (deliveryStatusUpdateData) {
            createConsignment({
                data: {
                    OrderIds: orderIds
                }
            }).finally(closeConfirmation);
        } else {
            closeConfirmation();
        }
    }, [createConsignment, closeConfirmation, orderIds]);

    return [
        onConfirmCreateConsignment,
    ]
}

export default useCreateConsignment;