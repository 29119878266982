import React from "react";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { css } from "@emotion/react";

const InputTextareaWithError = ({ className, error, noBorder = false, ...props }) => (
    <span css={withErrorStyle(error, noBorder)}>
        <InputTextarea {...props} className={classNames(className, { "p-invalid": !!error })} />
    </span>
);

export default InputTextareaWithError;

const withErrorStyle = (error, noBorder) => css`
    display: flex;
    position: relative;
    padding-top: 3px;
    margin-bottom: 12px;
    width: 100%; 

    ${noBorder && css`
        .p-inputtext.p-component {
            border-color: transparent;
            //border-bottom-color: inherit;
            padding-left: 0;
        }

        .p-inputtext:enabled:focus {
            box-shadow: unset;
        }
    `}

    ${error && css`
        :after {
            content: '${error}';
            position: absolute;
            top: 100%;
            left: 0;
            color: #f44336;
            width: 100%;
            height: 10px;
            font-size: 10px;
            line-height: 10px;
            margin-top: 2px;
        }
    `}
`;
