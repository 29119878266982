import React from "react";
import { css } from "@emotion/react";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import { formatCurrency } from "../../../includes/numbers";
import { isNumber } from "lodash";

const OrderItemInfoItemTabPanelContent = ({ formik }) => {
  return (
    <div className="p-fluid formgrid grid col-12 ">
      <div className="field grid col-12" css={inputGroupStyle}>
        <label
          htmlFor="UserName"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={readOnlyInputLabelStyle}
        >
          주문자명
        </label>
        <InputTextWithError
          className={"col-12 md:col-10"}
          name="UserName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.UserName || ''}
          error={formik.errors.UserName}
          readOnly
          noBorder
          css={inputStyle}
        />
      </div>
      <div className="field grid col-12" css={inputGroupStyle}>
        <label
          htmlFor="ItemName"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={readOnlyInputLabelStyle}
        >
          상품명
        </label>
        <InputTextWithError
          className={"col-12 md:col-10"}
          name="ItemName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.ItemName || ''}
          error={formik.errors.ItemName}
          readOnly
          noBorder
          css={inputStyle}
        />
      </div>

      <div className="field grid col-12" css={inputGroupStyle}>
        <label
          htmlFor="DeliveryCompany"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={readOnlyInputLabelStyle}
        >
          배송업체
        </label>
        <InputTextWithError
          className={"col-12 md:col-10"}
          name="DeliveryCompany"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.DeliveryCompany || ''}
          error={formik.errors.DeliveryCompany}
          readOnly
          noBorder
          css={inputStyle}
        />
      </div>

      <div className="field grid col-12" css={inputGroupStyle}>
        <label
          htmlFor="TrackingNumber"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={readOnlyInputLabelStyle}
        >
          송장번호
        </label>
        <InputTextWithError
          className={"col-12 md:col-10"}
          name="TrackingNumber"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.TrackingNumber || ''}
          error={formik.errors.TrackingNumber}
          readOnly
          noBorder
          css={inputStyle}
        />
      </div>

      <div className="field grid col-12" css={inputGroupStyle}>
        <label
          htmlFor="ItemAmount"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={readOnlyInputLabelStyle}
        >
          상품가격
        </label>
        <InputTextWithError
          className={"col-12 md:col-10"}
          name="ItemAmount"
          type="text"
          onChange={formik.handleChange}
          value={isNumber(formik.values.ItemAmount) ? formatCurrency(formik.values, "ItemAmount") : ''}
          error={formik.errors.ItemAmount}
          readOnly
          noBorder
          css={inputStyle}
        />
      </div>
      <div className="field grid col-12" css={inputGroupStyle}>
        <label
          htmlFor="ItemQuantity"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={readOnlyInputLabelStyle}
        >
          상품수량
        </label>
        <InputTextWithError
          className={"col-12 md:col-10"}
          name="ItemQuantity"
          type="text"
          onChange={formik.handleChange}
          value={isNumber(formik.values.ItemQuantity) ? formik.values.ItemQuantity : ''}
          error={formik.errors.ItemQuantity}
          readOnly
          noBorder
          css={inputStyle}
        />
      </div>

      <div className="field grid col-12" css={inputGroupStyle}>
        <label
          htmlFor="EnteredDate"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={readOnlyInputLabelStyle}
        >
          입고일자
        </label>
        <InputTextWithError
          className={"col-12 md:col-10"}
          name="EnteredDate"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.EnteredDate || ''}
          error={formik.errors.EnteredDate}
          readOnly
          noBorder
          css={enteredDateInputStyle}
        />
        <label
          htmlFor="IsCombineItem"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={isCombineItemStyle}
        >
          {formik.values.IsRepackage ? "재포장 신청 주문 입니다" : ""}
        </label>
        <label
          htmlFor="IsRepackage"
          className="col-12 mb-2 md:col-2 md:mb-0"
          css={isCombineItemStyle}
        >
        </label>
      </div>
    </div>
  )
}

export default OrderItemInfoItemTabPanelContent;

const inputGroupStyle = css`
    min-height: 54px;
    position: relative;

    &.field.grid label {
        height: 3rem;
        min-width: 8rem;
    }

    > span {
        flex: 1;
    }

    .p-dropdown-label.p-inputtext {
        height: 3rem;
        font-size: 1.9rem;
        padding: 3px 0 0;
        margin: 0;
    }

    .p-inputtext.p-component.p-filled {
        height: 3rem;
        font-size: 1.9rem;
        padding: 3px 0 0;
        margin: 0;
    }

    span > .p-inputtext.p-component {
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        margin-top: unset;
    }
`;

const inputLabelStyle = css`
    width: 100px;
    font-size: 1.9rem;
    margin-top: 5px;
    margin-bottom: 12px !important;
`;

const readOnlyInputLabelStyle = css`
    ${inputLabelStyle};
    margin-right: 10px;
    position: relative;

    :after {
        content: ':';
        position: absolute;
        left: 100%;
    }
`;

const inputStyle = css`
`;

const enteredDateInputStyle = css`
    color: #f44336;
    font-weight: bold;
    ${inputStyle};
`;

const isCombineItemStyle = css`
    ${inputLabelStyle};
    position: absolute;
    width: 100%;
    top: 30px;
    margin-top: 3rem;
    color: #f44336;
`;


