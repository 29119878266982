import React, { useCallback, useMemo } from "react";
import { atom, useRecoilState } from "recoil";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { Button } from "primereact/button";

import EnhancedDialog from "./EnhancedDialog";
import useShowConfirm from "../../service/hooks/useShowConfirm";
import DropdownWithError from "../forms/DropdownWithError";

const isOrderedStatusDropdownItems = [
  { Name: '주문전', Code: 0 },
  { Name: '주문완료', Code: 1 },
  { Name: '배대지로이동중', Code: 2}
];

export const purchaseOrderItemOrderDialogState = atom({
  key: 'purchaseOrderItemOrderDialogState',
  default: {
    IsOrdered: 0
  },
});

const PurchaseOrderItemOrderDialog = ({
  onCancel = null,
  onConfirm,
  title = "주문처리",
  renderConfirm = null, // should be null as default
  readOnly = false,
  shouldShowConfirm = false,
}) => {


  const [state, setState] = useRecoilState(purchaseOrderItemOrderDialogState);

  const { handleConfirm, renderConfirmDialog, }
    = useShowConfirm({ renderConfirm, state, onCancel, onConfirm, shouldShowConfirm, });

  const onSaveClicked = useCallback((values) => {

    setState(prev => {
      return {
        ...prev,
        ...values,
      }
    });
    handleConfirm();
  }, [setState, handleConfirm]);

  const validate = (values) => {
    const errors = {};

    if (!values.isOrderedStatusDropdownItem) {
      errors.isOrderedStatusDropdownItem = "Required";
    }
    return errors;
  }


  const formik = useFormik({
    initialValues: {
      ...state,
      isOrderedStatusDropdownItem: isOrderedStatusDropdownItems.find(item => item.Code === state.IsOrdered),
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {

      const newValue = {
        ...values,
      };

      newValue.IsOrdered = newValue.isOrderedStatusDropdownItem.Code;
      delete newValue.isOrderedStatusDropdownItem;

      onSaveClicked(newValue);
    },
  });

  // useEffect(() => {

  //   console.log("useEffect.state", state);
  //   formik.setValues({
  //     ...state,
  //   });

  //   //initialise the drop down list item 
  //   formik.setFieldValue("isEnteredStatusDropdownItem", isEnteredStatusDropdownItems.find(item => item.Code === state.IsEntered));

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state, isEnteredStatusDropdownItems]);


  const onSubmitForm = useCallback(() => {

    return formik.submitForm();
  }, [formik]);

  const confirmationDialogFooter = useMemo(() => (
    <>
      {onCancel && (
        <Button
          type="button"
          label="Close"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-text"
        />
      )}


      {onConfirm && (
        <Button
          type="submit"
          label="Yes"
          icon="pi pi-check"
          autoFocus
          onClick={onSubmitForm}
        />
      )}

    </>
  ), [onConfirm, onCancel, onSubmitForm]);


  return (
    <EnhancedDialog
      title={title}
      onCancel={onCancel}
      css={createOrderItemDialogStyle}
      footer={confirmationDialogFooter}
      withLoading={false}
    >
      {renderConfirmDialog()}
      <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
        <div className="p-fluid">
          <div className="field grid">
            <label
              htmlFor={"IsOrdered"}
              className="col-12 mb-2 md:col-3 md:mb-0"
            >
              주문상태
            </label>
            <DropdownWithError
              className="col-12 md:col-9"
              name="isOrderedStatusDropdownItem"
              value={formik.values.isOrderedStatusDropdownItem}
              onChange={formik.handleChange}
              options={isOrderedStatusDropdownItems}
              error={formik.errors.isOrderedStatusDropdownItem}
              optionLabel="Name"
              placeholder="Select One"
              readOnly={readOnly}
            />
          </div>
        </div>
      </form>
    </EnhancedDialog>
  );
};

export default PurchaseOrderItemOrderDialog;

const createOrderItemDialogStyle = css`
    width: 450px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;