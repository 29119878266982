import React from "react";
import { css } from "@emotion/react";

const FromToLabel = ({children}) => (
    <div css={fromToLabelContainerStyle}>
        {children}
    </div>
);

export default FromToLabel;

const fromToLabelContainerStyle = css`
    min-width: 80px;
    text-align: left;
`;