import React, { useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";

import { flightListFilterState, flightPaginationFilterState } from "../store";
import { NOOP } from "../../../includes/constants";

import AwaitButton from "../../../components/AwaitButton";
import FilterContainer from "../../../components/filters/FilterContainer";
import PaginationFilter from "../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../components/filters/PageFilterContainer";
import TextFieldSearchFilter from "../../../components/filters/TextFieldSearchFilter";
import FilterContainerGroups from "../../../components/filters/FilterContainerGroups";
import FilterActionContainer from "../../../components/filters/FilterActionContainer";
import DateSearchFilter, { dateSearchFilterState } from "../../../components/filters/DateSearchFilter";
import LocationSearchFilter, { locationSearchFilterState } from "../../../components/filters/LocationSearchFilter";

const searchFieldItems = [
    { name: '출발일', searchDateField: 'DepartDate', isDefault: true },
    { name: '도착예정일', searchDateField: 'EstimatedArrivalDate' },
    { name: '도착일', searchDateField: 'arrivalDate' },
];

const textFieldSearchDropdownItems = [
    { name: '운항명', searchField: 'FlightName', isDefault: true },
    { name: '편명', searchField: 'FlightNumber' },
];

export const flightSearchFilterState = atom({
    key: 'flightSearchFilterState',
    default: {
        searchField: textFieldSearchDropdownItems[0].searchField,
        searchStr: "",
    },
});

const FlightPageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(flightListFilterState);
    const paginationFilterValue = useRecoilValue(flightPaginationFilterState);
    const flightSearchFilterValue = useRecoilValue(flightSearchFilterState);
    const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
    const locationSearchFilterValue = useRecoilValue(locationSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...flightSearchFilterValue,
            }
        });
    }, [flightSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...dateSearchFilterValue,
            }
        })
    }, [dateSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...locationSearchFilterValue,
            }
        })
    }, [locationSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString atom={flightPaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer>
                    <TextFieldSearchFilter
                        atom={flightSearchFilterState}
                        label={"운항 검색"}
                        dropdownItems={textFieldSearchDropdownItems}
                        supportQueryString
                        onSearch={onSearch}
                    />
                </FilterContainer>
                <FilterContainer>
                    <DateSearchFilter searchFieldItems={searchFieldItems} />
                </FilterContainer>
                <FilterContainer />
                <FilterContainer>
                    <LocationSearchFilter />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

export default FlightPageFilter;