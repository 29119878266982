import React, { createContext, useCallback, useContext, useMemo, useRef } from "react";
import * as PropTypes from "prop-types";

const ToastContext = createContext(null);

export const ToastContextProvider = ({ children }) => {
    const toastRef = useRef();

    const showSuccess = useCallback(({ title, message }) => {
        toastRef.current?.show({
            severity: 'success',
            summary: title || 'Success',
            detail: message || '',
            life: 5000
        });
    }, []);

    const showInfo = useCallback(({ title, message }) => {
        toastRef.current?.show({ severity: 'info', summary: title || 'Info', detail: message || '', life: 3000 });
    }, []);

    const showWarn = useCallback(({ title, message }) => {
        toastRef.current?.show({ severity: 'warn', summary: title || 'Warn', detail: message || '', life: 3000 });
    }, []);

    const showError = useCallback(({ title, message }) => {
        toastRef.current?.show({ severity: 'error', summary: title || 'Error', detail: message || '', life: 3000 });
    }, []);

    const value = useMemo(() => {
        return {
            toastRef,
            showSuccess,
            showInfo,
            showWarn,
            showError,
        }
    }, [showSuccess, showInfo, showWarn, showError]);

    return (
        <ToastContext.Provider value={value}>
            {children}
        </ToastContext.Provider>
    );
}

ToastContextProvider.propTypes = {
    children: PropTypes.node
};

export const useToastContext = () => {
    return useContext(ToastContext);
};