import React, { useCallback, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { trim } from "lodash";

import { TabMenu } from "primereact/tabmenu";
import { NOOP } from "../../../../includes/constants";

import AwaitButton from "../../../../components/AwaitButton";
import InputTextWithError from "../../../../components/forms/InputTextWithError";
import EnhancedEditor from "../../../../components/editor/EnhancedEditor";
import DataTableActions from "../../../../components/layouts/DataTableActions";


const wizardItems = [
    { label: '답변' },
];

const QnaCommentsWriter = ({
    onSave = NOOP,
    boardId = null,
    commentData = null,
}) => {
    const editorKey = useRef(Math.random());

    const onSaveClicked = useCallback((values) => {
        return onSave(values);
    }, [onSave]);

    const formik = useFormik({
        initialValues: {
            BoardId: '',
            Comment: '',
            WriterName: '',
        },
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            onSaveClicked(values);
        },
    });

    const resetForm = useCallback(() => {
        formik.setValues({
            BoardId: commentData?.BoardId || boardId,
            Comment: commentData?.Comment || '',
            WriterName: commentData?.WriterName || '관리자',
        });
        editorKey.current = Math.random();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentData, boardId]);

    const onContentsChanged = useCallback((contents) => {
        formik.setFieldValue("Comment", contents);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <div className="col-12">
            <form onSubmit={formik.handleSubmit}>

                <div className="p-fluid formgrid grid" css={maxWidthStyle}>
                    <TabMenu
                        model={wizardItems}
                        activeIndex={0}
                        onTabChange={NOOP}
                        css={tabMenuStyle}
                        className={"mb-3"}
                    />

                    <div className="field col-12">
                        <label htmlFor="WriterName">작성자</label>
                        <InputTextWithError
                            name="WriterName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.WriterName || ''}
                            error={formik.errors.WriterName}
                        />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="Comment">내용</label>
                        <EnhancedEditor
                            defaultContents={formik.values.Comment}
                            onChange={onContentsChanged}
                            error={formik.errors.Comment}
                            key={editorKey.current}
                        />
                    </div>

                    <DataTableActions className={"mb-5"} direction={"right"}>
                        <AwaitButton
                            icon="pi pi-check"
                            label={"답변 저장"}
                            type="submit"
                            css={buttonStyle}
                        />
                    </DataTableActions>
                </div>
            </form>
        </div>
    )
}

export default QnaCommentsWriter;

const validate = (values) => {
    const errors = {};

    if (!trim(values.BoardId)) {
        errors.BoardId = "Required";
    }
    if (!trim(values.WriterName)) {
        errors.WriterName = "Required";
    }
    if (!trim(values.Comment)) {
        errors.Comment = "Required";
    } else {
        const cleanText = values.Comment.replace(/<\/?[^>]+(>|$)/g, "");
        if (!trim(cleanText)) {
            errors.Comment = "Required";
        }
    }
    return errors;
}

const buttonStyle = css`
    margin-right: 10px;
`;

const maxWidthStyle = css`
    max-width: 800px;
`;

const tabMenuStyle = css`
    width: 100%;
`;