import React, { useCallback, useMemo } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";

import useCommonCode, { COMMON_CODE_DELIVERY_LOCATION } from "../../service/hooks/useCommonCode";
import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

import FilterLabel from "./FilterLabel";

const ALL_LOCATION_ITEM = { Name: '전체', Code: '', isDefault: true };

export const locationSearchFilterState = atom({
    key: 'locationSearchFilterState',
    default: {
        locationCode: ALL_LOCATION_ITEM.Code,
    },
});

const LocationSearchFilter = ({
    supportQueryString = false,
}) => {

    const setLocationSearchFilterState = useSetRecoilState(locationSearchFilterState);
    const locationSearchFilterValue = useRecoilValue(locationSearchFilterState);
    const { data: deliveryLocationData } = useCommonCode(COMMON_CODE_DELIVERY_LOCATION);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setLocationSearchFilterState({
                locationCode: searchObject.locationCode,
            });
        }
    }, [setLocationSearchFilterState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const dropdownItems = useMemo(() => {
        return [
            ALL_LOCATION_ITEM,
            ...deliveryLocationData,
        ];
    }, [deliveryLocationData]);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setLocationSearchFilterState(prev => {
            if (prev.locationCode) return prev;

            return {
                ...prev,
                locationCode: defaultItem.Code,
            }
        });
    }, [setLocationSearchFilterState]);

    useSetFilterDefaultValue({
        items: dropdownItems,
        isAlreadySet: !!locationSearchFilterValue.locationCode,
        setFilterDefaultValue,
    });

    // useEffect(() => {
    //     // 처음 한번만 세팅 해 주세요.
    //     if (!locationSearchFilterValue.locationCode) {
    //         if (!isEmpty(dropdownItems)) {
    //             const deliveryStatusItem = dropdownItems.find(i => i.isDefault);
    //             if (deliveryStatusItem) {
    //                 setLocationSearchFilterState(prev => {
    //                     if (prev.locationCode) return prev;
    //
    //                     return {
    //                         ...prev,
    //                         locationCode: deliveryStatusItem.Code,
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dropdownItems, setLocationSearchFilterState]);

    const setDropdownItem = useCallback(e => {
        setLocationSearchFilterState({
            locationCode: e.value?.Code
        })
    }, [setLocationSearchFilterState]);

    return (
        <div className="grid flex-grow-1" css={searchGroupStyle}>
            <FilterLabel htmlFor={"locationCode"}>배송 지역</FilterLabel>
            <Dropdown
                name="locationCode"
                value={dropdownItems.find(item => item.Code === locationSearchFilterValue.locationCode)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="Name"
                placeholder="전체"
            />
        </div>
    )
}

const searchGroupStyle = theme => css`
    display: flex;
    align-items: center;
    margin: 0;

    ${theme.breakpoints.down("xs")} {

        > * {
            width: 100%;
            margin: 2px 0;
            padding: 0;
        }
    }
`;

export default LocationSearchFilter;