import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import useAxios from "axios-hooks";

import { DataTable } from "primereact/datatable";
import { API_ORDER_URI } from "../../../includes/constants";
import { getOrderPath } from "../../../core/routes/routes";
import { getNewDataBadge } from "../../../includes/datatable";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";

import TableTitle from "../../../components/layouts/TableTitle";
import CardContainer from "../../../components/layouts/CardContainer";
import DataTableActions from "../../../components/layouts/DataTableActions";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";

const fieldColumnTitleMap = {
    ConsignmentNumber: "송장번호", // K택배 내부 송장번호
    DeliveryLocationCode: "배송국가 코드",
    DeliveryLocationName: "배송국가",
    DeliveryStatusCode: "배송상태 코드",
    DeliveryStatusName: "배송상태",
    DeliveryTypeCode: "배송타입 코드",
    DeliveryTypeName: "배송타입",
    FlightId: "운항 ID",
    FlightName: "운항명",
    IsAllItemEntered: "상품입고",
    LocalTrackingNumber: "배송국가 트래킹번호",
    OrderDate: "주문일",
    OrderNumber: "주문번호",
    PersonalCustomCode: "개인통관부호",
    PickupTypeCode: "수령방법코드",
    ReceiverAddress1: "수령자명 주소1",
    ReceiverAddress2: "수령자명 주소2",
    ReceiverMobile: "수령자명 휴대폰",
    ReceiverName: "수령자명",
    ReceiverPhone: "수령자 전화번호",
    ReceiverZipcode: "수령자명 우편번호",
    TotalChargeAmount: "결제금액", // 고객이 내야할 금액
    TotalExchangeItemPrice: "운송 및 세금",
    TotalItemCount: "전체 상품수",
    TotalItemAmount: "총상품가격(KRW)",
    TotalExchangeItemAmount: "총상품가격(NZD)",
    TotalPaidAmount: "전체 입금금액",
    TotalVolumeWeight: "전체 부피무게",
    TotalWeight: "무게",
    UserId: "주문자 ID",
    UserName: "주문자",
}

const sortableFields = [];

const RecentOrders = ({
    pageSize = 10,
}) => {

    const [{ data: ordersData, loading: ordersDataLoading }, getOrders] = useAxios({
            url: API_ORDER_URI,
            method: 'GET',
        },
        { manual: true },
    );

    const {
        renderColumn,
        renderLinkColumn,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getOrders,
        dataSize: ordersData?.Data?.length ?? 0,
    });

    useEffect(() => {
        getOrders({
            url: `${API_ORDER_URI}?pageNo=1&pageSize=${pageSize}&orderField=OrderDate&orderBy=DESC`
        });
    }, [getOrders, pageSize]);

    const titleSection = useMemo(() => (
        <TableTitle noPadding>
            <h5>최근 주문</h5>
        </TableTitle>
    ), []);


    return (
        <CardContainer>

            <DataTableActions className={"mb-2"} titleSection={titleSection}>
                <Link to={"/order"} className={"p-button p-component"}>
                    <span className="p-button-icon p-c pi pi-list p-button-icon-left" />
                    <span className="p-button-label p-c">최근 주문</span>
                </Link>
            </DataTableActions>

            <ScrollDataTableContainer loading={ordersDataLoading} minWidth={1024}>
                <DataTable
                    resizableColumns
                    columnResizeMode="fit"
                    loading={ordersDataLoading}
                    value={ordersData?.Data || []}
                    dataKey="OrderId"
                    lazy
                    totalRecords={ordersData?.Paging?.TotalNumberOfRecords}
                    className="datatable-responsive"
                    emptyMessage="No data found."
                >
                    {renderLinkColumn("OrderNumber", "OrderId", { getLinkPath: getOrderPath })}
                    {renderColumn("UserName")}
                    {renderColumn("OrderDate", {
                        suffixAppender: getNewDataBadge,
                        headerStyle: { width: '250px' },
                    })}
                    {renderColumn("FlightName")}
                    {renderColumn("DeliveryLocationName")}
                    {renderColumn("ConsignmentNumber")}
                </DataTable>
            </ScrollDataTableContainer>
        </CardContainer>
    );
}

export default RecentOrders;