import { useCallback, useEffect } from "react";
import useAxios from "axios-hooks";

import useToast from "../../core/hooks/useToast";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { API_ADD_WEIGHT_CHARGE_URI } from "../../includes/constants";

const useAddWeightCharge = ({
    callbackAfterSuccess,
    orderIds = [],
    successMessage = "선택하신 주문의 배송비를 성공적으로 계산하였습니다.",
}) => {

    const { closeConfirmation } = useAwaitingDialogContext();
    const { showSuccess, showError } = useToast();

    const [{
        data: addWeightChargeData,
        error: addWeightChargeError
    }, addWeightCharge] = useAxios({
            url: API_ADD_WEIGHT_CHARGE_URI,
            method: 'POST',
        },
        { manual: true },
    );

    useEffect(() => {
        if (addWeightChargeData) {
            showSuccess({ message: successMessage });
            let calledCloseConfirmation = false;

            if (callbackAfterSuccess) {
                const promise = callbackAfterSuccess();
                if (promise?.finally) {
                    promise.finally(closeConfirmation);
                    calledCloseConfirmation = true;
                }
            }

            if (!calledCloseConfirmation) {
                closeConfirmation();
            }
        }
        // refresh / closeConfirmation 넣으면 안됨.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addWeightChargeData, showSuccess]);

    useEffect(() => {
        if (addWeightChargeError) {
            showError({ message: addWeightChargeError });
            closeConfirmation();
        }
    }, [addWeightChargeError, closeConfirmation, showError]);

    const onConfirmCreateInvoice = useCallback((deliveryStatusUpdateData) => {
        if (deliveryStatusUpdateData) {
            addWeightCharge({
                data: {
                    OrderIds: orderIds
                }
            }).then(closeConfirmation);
        } else {
            closeConfirmation();
        }
    }, [addWeightCharge, closeConfirmation, orderIds]);

    return [
        onConfirmCreateInvoice,
    ]
}

export default useAddWeightCharge;