import React, { useCallback, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { atom, useRecoilState } from "recoil";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { trim } from "lodash";

import { Button } from "primereact/button";

import { toBooleanValue } from "../../includes/ajax";
import { validateNumber } from "../../includes/numbers";
import { taxRateDropdownItems } from "../../includes/constants";

import EnhancedDialog from "./EnhancedDialog";
import InputTextWithError from "../forms/InputTextWithError";
import DropdownWithError from "../forms/DropdownWithError";
import IsActiveRadioButtons from "../forms/IsActiveRadioButtons";
import useCommonCode, { COMMON_CODE_DELIVERY_LOCATION } from "../../service/hooks/useCommonCode";
import useShowConfirm from "../../service/hooks/useShowConfirm";

export const createChargeTypeDialogState = atom({
    key: 'createChargeTypeDialogState',
    default: {
        DeliveryLocationCode: null,
        ChargeTypeName: "",
        IsActive: true,
        ChargeAmount: null,
        TaxRate: null,
    },
});

const CreateChargeTypeDialog = ({
    onCancel = null,
    onDelete = null,
    onConfirm,
    title = "비용타입",
    renderConfirm = null, // should be null as default
    renderDeleteConfirm = null, // should be null as default
    shouldShowConfirm = true,
}) => {
    const [state, setState] = useRecoilState(createChargeTypeDialogState);
    const { data: deliveryLocationData } = useCommonCode(COMMON_CODE_DELIVERY_LOCATION);

    const {
        handleConfirm,
        renderConfirmDialog,
    } = useShowConfirm({
        renderDeleteConfirm,
        renderConfirm,
        state,
        onCancel,
        onConfirm,
        onDelete,
        shouldShowConfirm,
    });

    const onSaveClicked = useCallback((values) => {
        setState(prev => {
            return {
                ...prev,
                ...values,
            }
        });
        handleConfirm();
    }, [setState, handleConfirm]);

    const formik = useFormik({
        initialValues: {
            IsActive: true,
        },
        validateOnChange: false,
        validate,
        onSubmit: values => {
            const newValue = {
                ...values,
            };

            newValue.DeliveryLocationCode = newValue.deliveryLocationCodeItem.Code;
            newValue.TaxRate = newValue.taxRateItem.Code;
            delete newValue.deliveryLocationCodeItem;
            delete newValue.taxRateItem;

            onSaveClicked(newValue);
        },
    });

    useEffect(() => {
        const taxRateItem = taxRateDropdownItems.find(item => item.Code === state.TaxRate) ?? taxRateDropdownItems[0];
        formik.setFieldValue("taxRateItem", taxRateItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxRateDropdownItems]);

    useEffect(() => {
        if (state.ChargeTypeId) {
            const taxRateItem = taxRateDropdownItems.find(item => item.Code === state.TaxRate) ?? taxRateDropdownItems[0];

            formik.setValues({
                ...state,
                taxRateItem: taxRateItem,
                deliveryLocationCodeItem: deliveryLocationData.find(item => item.Code === state.DeliveryLocationCode)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const onSubmitForm = useCallback(() => {
        return formik.submitForm();
    }, [formik]);

    const confirmationDialogFooter = useMemo(() => (
        <>
            {onCancel && (
                <Button
                    type="button"
                    label="Close"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-text"
                />
            )}
            <Button
                type="submit"
                label="Yes"
                icon="pi pi-check"
                autoFocus
                onClick={onSubmitForm}
            />
        </>
    ), [onCancel, onSubmitForm]);

    return (
        <EnhancedDialog
            title={title}
            onCancel={onCancel}
            css={createChargeTypeDialogStyle}
            footer={confirmationDialogFooter}
            withLoading={false}
        >
            {renderConfirmDialog()}
            <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
                <div className="p-fluid">

                    <div className="field grid col">
                        <label
                            htmlFor={"deliveryLocationCodeItem"}
                            className="col-12"
                        >
                            배송국가
                        </label>
                        <div className="col-12 sm:col-6">
                            <DropdownWithError
                                name="deliveryLocationCodeItem"
                                value={formik.values.deliveryLocationCodeItem}
                                onChange={formik.handleChange}
                                options={deliveryLocationData}
                                error={formik.errors.deliveryLocationCodeItem}
                                optionLabel="Name"
                                placeholder="Select One"
                            />
                        </div>
                    </div>

                    <div className="field grid col">
                        <label htmlFor="ChargeTypeName" className="col-12">비용타입 명</label>
                        <div className="col-12">
                            <InputTextWithError
                                name="ChargeTypeName"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.ChargeTypeName || ''}
                                error={formik.errors.ChargeTypeName}
                            />
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="ChargeAmount" className="col-12">비용</label>
                            <div className="col-12">
                                <InputTextWithError
                                    name="ChargeAmount"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.ChargeAmount ?? ''}
                                    error={formik.errors.ChargeAmount}
                                    min={0}
                                    step={1}
                                />
                            </div>
                        </div>
                        <div className="field col">
                            <label htmlFor="taxRateItem" className="col-12">GST</label>
                            <div className="col-12">
                                <DropdownWithError
                                    name="taxRateItem"
                                    value={formik.values.taxRateItem}
                                    onChange={formik.handleChange}
                                    options={taxRateDropdownItems}
                                    error={formik.errors.taxRateItem}
                                    optionLabel="Name"
                                    placeholder="Select One"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="field grid col">
                        <label htmlFor="IsActive" className="col-12">사용여부</label>
                        <IsActiveRadioButtons
                            isActive={toBooleanValue(formik.values.IsActive)}
                            handleChange={formik.handleChange}
                        />
                    </div>
                </div>

            </form>

        </EnhancedDialog>
    )
}

CreateChargeTypeDialog.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    renderConfirm: PropTypes.func,
    shouldShowConfirm: PropTypes.bool,
}

export default CreateChargeTypeDialog;

const validate = (values) => {
    const errors = {};

    if (!values.deliveryLocationCodeItem) {
        errors.deliveryLocationCodeItem = "Required";
    }
    if (!values.taxRateItem) {
        errors.taxRateItem = "Required";
    }
    if (!trim(values.ChargeAmount)) {
        errors.ChargeAmount = "Required";
    } else if (!validateNumber(values.ChargeAmount)) {
        errors.ChargeAmount = "소수점 두자리까지 입력이 가능합니다.";
    }
    if (!trim(values.IsActive)) {
        errors.IsActive = "Required";
    }
    if (!trim(values.ChargeTypeName)) {
        errors.ChargeTypeName = "Required";
    }

    return errors;
}


const createChargeTypeDialogStyle = css`
    width: 550px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;