import { useCallback } from "react";
import { formatFromDate, stringToDate } from "../../../includes/dates";

const useFlightData = () => {
    const modelToDto = useCallback((model) => {
        return {
            ...model,
            flightId: model.FlightId,
            DeliveryLocationCode: model.DeliveryLocationCode,
            DepartDate: stringToDate(model.DepartDate),
            EstimatedArrivalDate: stringToDate(model.EstimatedArrivalDate),
            ArrivalDate: stringToDate(model.ArrivalDate),
        }
    }, []);

    const dtoToModel = useCallback((dto) => {
        return dto;
    }, []);

    const convertModelToSave = useCallback((model) => {
        return {
            ...model,
            ExchangeRate: model.ExchangeRate,
            DepartDate: model.DepartDate ? formatFromDate(model.DepartDate) : '',
            EstimatedArrivalDate: model.EstimatedArrivalDate ? formatFromDate(model.EstimatedArrivalDate) : '',
            ArrivalDate: model.ArrivalDate ? formatFromDate(model.ArrivalDate) : '',
        }
    }, []);

    return {
        modelToDto,
        dtoToModel,
        convertModelToSave,
    };
}

export default useFlightData;