import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";
import { css } from "@emotion/react";
import { isEmpty, times } from "lodash";
import pluralize from "pluralize";
import ReactToPrint from "react-to-print";
import { Button } from "primereact/button";

import { API_CONSIGNMENT_URI } from "../../../includes/constants";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import useToast from "../../../core/hooks/useToast";

import Loading from "../../../components/loading/Loading";
import AwaitButton from "../../../components/AwaitButton";
import EnhancedDialog from "../../../components/dialogs/EnhancedDialog";
import ConsignmentPrintItem from "./ConsignmentPrintItem";

// 72 dpi (web) = 595 X 842 pixels
// 300 dpi (print) = 2480 X 3508 pixels
// 600 dpi (high quality print) = 4960 X 7016 pixels

const COUNT_PER_PAGE = 4;

const PrintConsignmentDialog = ({
    onCancel,
    onConfirm,
    title = "송장출력",
    renderConfirm = null, // should be null as default
    consignmentIds = [],
}) => {
    const { showError } = useToast();
    const printAreaRef = useRef();
    const { closeConfirmation } = useAwaitingDialogContext();
    const [isPrinting, setIsPrinting] = useState(false);

    const [{
        data: printConsignmentData,
        loading: printConsignmentLoading,
        error: printConsignmentError,
    }, getPrintConsignments] = useAxios({
            url: `${API_CONSIGNMENT_URI}/print`,
            method: "POST",
        },
        { manual: true },
    );

    useEffect(() => {
        if (isEmpty(consignmentIds)) return;

        getPrintConsignments({
            data: {
                ConsignmentIds: consignmentIds
            }
        })
    }, [getPrintConsignments, consignmentIds]);

    useEffect(() => {
        if (printConsignmentError) {
            showError({ message: printConsignmentError });
            onCancel();
        }
    }, [printConsignmentError, showError, onCancel]);

    const consignmentItems = useMemo(() => {
        const consignmentItems = [];
        printConsignmentData?.forEach(item => {
            const totalPackageCount = Number(item.TotalPackageCount);
            times(totalPackageCount).forEach(packageNumber => {
                consignmentItems.push({
                    ...item,
                    PackageNumber: packageNumber + 1
                });
            })
        });

        return consignmentItems;
    }, [printConsignmentData]);

    const dialogTitle = useMemo(() => {
        const totalPage = Math.ceil(consignmentItems.length / COUNT_PER_PAGE);
        return `${title} (${pluralize("page", totalPage, true)})`;
    }, [title, consignmentItems]);

    const onAfterPrint = useCallback(() => {
        setIsPrinting(true);
        closeConfirmation();
    }, [closeConfirmation, setIsPrinting]);

    const onBeforePrint = useCallback(() => {
        setIsPrinting(true);
    }, [setIsPrinting]);

    const confirmationDialogFooter = useMemo(() => (
        <div css={footerContainerStyle}>
            {onCancel && (
                <Button
                    type="button"
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-text"
                />
            )}
            {onConfirm && (
                <ReactToPrint
                    trigger={() => (
                        <AwaitButton
                            type="button"
                            label="Print"
                            icon="pi pi-print"
                            loading={isPrinting}
                        />
                    )}
                    onBeforePrint={onBeforePrint}
                    onAfterPrint={onAfterPrint}
                    content={() => printAreaRef.current}
                />
            )}
        </div>
    ), [onCancel, onConfirm, onAfterPrint, onBeforePrint, isPrinting]);

    return (
        <EnhancedDialog
            title={dialogTitle}
            onCancel={onCancel}
            css={printConsignmentDialogStyle}
            footer={confirmationDialogFooter}
        >
            {printConsignmentLoading && (<Loading onTop />)}

            <div ref={printAreaRef} css={printPageContainerStyle}>
                {consignmentItems?.map((item, index) => (
                    <React.Fragment key={index}>
                        <ConsignmentPrintItem consignment={item} css={printItemStyle} />
                        {(index + 1) % COUNT_PER_PAGE === 0 && (
                            <div css={pageBreakStyle} />
                        )}
                    </React.Fragment>
                ))}
            </div>

        </EnhancedDialog>
    )
}

PrintConsignmentDialog.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    renderConfirm: PropTypes.func,
}

export default PrintConsignmentDialog;

const printConsignmentDialogStyle = css`
    width: 8.5in;
    width: 23cm; // TODO 얼마가 좋은지?

    .enhanced-dialog-content-body {
        // dark 모드에서도 송장은 하얀바탕으로~~
        background: white !important;
        color: black !important;
    }
    
    .p-dropdown {
        min-width: 200px;
    }
`;

const footerContainerStyle = css`
    margin-top: 1rem;
`;

const printPageContainerStyle = css`
    display: block;
    width: 21cm;
    padding: 0.3cm 0;

    // dark 모드에서도 송장은 하얀바탕으로~~
    background: white !important;
    color: black !important;
`;

const printItemStyle = css`
    width: 9cm;
    height: 14cm;
    padding: 10px;
    margin: 10px; /* the gutter */
    float: left;
    text-align: center;
    overflow: hidden;
    border: 2px #000 solid;
`;

const pageBreakStyle = css`
    clear: left;
    display: block;
    page-break-after: always;
`;