import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { atom, useResetRecoilState } from "recoil";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import { useCallback } from "react";
import { NOOP } from "../../includes/constants";


const defaultCreateActionButtonState = atom({
  key: 'defaultCreateActionButtonState',
  default: {},
});

const useCreateActionButton = ({
  onSelect = NOOP,
  confirmMessage = "상품을 추가하시겠습니까?",
  deleteConfirmMessage = "비용을 삭제하시겠습니까?",
  onDelete = null,
  atom = defaultCreateActionButtonState,
  dialogComponent,
  renderDialog = null,
  readOnly = false,
  shouldShowConfirm = true,
}) => {

  const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();
  const setCreateContentsDialogState = useResetRecoilState(atom);

  const renderDeleteConfirm = useCallback(({ onCancel, onConfirm, data }) => (
    <ConfirmDialog onCancel={onCancel} onConfirm={onConfirm}>
      {deleteConfirmMessage}
    </ConfirmDialog>
  ), [deleteConfirmMessage]);

  const renderConfirm = useCallback(({ onCancel, onConfirm, data }) => (
    <ConfirmDialog onCancel={onCancel} onConfirm={onConfirm}>
      {confirmMessage}
    </ConfirmDialog>
  ), [confirmMessage]);

  const contentsDialog = useCallback(() => {
    if (renderDialog) {
      return renderDialog({
        onCancel: closeConfirmation,
        onConfirm: confirm,
        renderConfirm: renderConfirm,
        shouldShowConfirm: shouldShowConfirm,
        ...onDelete && { renderDeleteConfirm },
      });
    }

    if (dialogComponent) {
      const DialogComponent = dialogComponent;
      return (
        <DialogComponent
          onCancel={closeConfirmation}
          onConfirm={confirm}
          renderConfirm={renderConfirm}
          shouldShowConfirm={shouldShowConfirm}
          onDelete={onDelete}
          renderDeleteConfirm={renderDeleteConfirm}
          readOnly={readOnly}
        />
      );
    }
    return null;

  }, [shouldShowConfirm, readOnly, renderDialog, dialogComponent, closeConfirmation, confirm, renderConfirm, onDelete, renderDeleteConfirm]);

  const onCreateOrUpdateClicked = useCallback(() => {
    return openConfirmation(contentsDialog())
      .then((confirmedData) => {
        console.log('confirmedData', confirmedData);
        onSelect(confirmedData);
      }).catch(() => {
        // ignore onCancel
      }).finally(() => {
        setCreateContentsDialogState();
      });
  }, [onSelect, contentsDialog, openConfirmation, setCreateContentsDialogState]);

  return onCreateOrUpdateClicked;
}

export default useCreateActionButton;