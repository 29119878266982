import { atom } from "recoil";

export const popupListState = atom({
    key: 'popupListState',
    default: [],
});

export const popupListFilterState = atom({
    key: 'popupListFilterState',
    default: {},
});

export const popupPaginationFilterState = atom({
    key: 'popupPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy : null,
    },
});

// export const filteredPopupListState = selector({
//     key: 'filteredPopupListState',
//     get: ({ get }) => {
//         const filter = get(popupListFilterState);
//         const list = get(popupListState);
//
//         switch (filter) {
//             case 'Show Completed':
//                 return list.filter((item) => item.isComplete);
//             case 'Show Uncompleted':
//                 return list.filter((item) => !item.isComplete);
//             default:
//                 return list;
//         }
//     },
// });
