import { useCallback } from "react";
import useAxios from "axios-hooks";

const useUpdateExchangeRate = () => {
    const [{
        data,
        loading,
        error
    }, updateData] = useAxios(
        { method: "PUT" },
        { manual: true },
    );

    const updateExchangeRate = useCallback(({ flightId, exchangeRate }) => {
        return updateData({
            url: `/manager/flight/${flightId}/exchangerate?exchangeRate=${exchangeRate}`,
        });
    }, [updateData]);

    return [
        {
            data,
            loading,
            error
        },
        updateExchangeRate,
    ];
}

export default useUpdateExchangeRate;