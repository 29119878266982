import React, { useCallback, useMemo } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";

import { DATE_FORMAT_FROM_MOMENT, formatFromDate, stringToDate, yearRange } from "../../includes/dates";
import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

import FilterLabel from "./FilterLabel";

export const dateSearchFilterState = atom({
    key: 'dateSearchFilterState',
    default: {
        searchDateField: null,
        startDate: null,
        endDate: null,
        allDate: false,
    },
});

const DateSearchFilter = ({
    searchFieldItems = [],
    supportQueryString = false,
    atom = dateSearchFilterState,
}) => {
    const setDateSearchFilterState = useSetRecoilState(atom);
    const dateSearchFilterValue = useRecoilValue(atom);
    // const alreadySet = useRef(false);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setDateSearchFilterState({
                startDate: searchObject.startDate ? stringToDate(searchObject.startDate, DATE_FORMAT_FROM_MOMENT) : searchObject.startDate,
                endDate: searchObject.endDate ? stringToDate(searchObject.endDate, DATE_FORMAT_FROM_MOMENT) : searchObject.endDate,
                searchDateField: searchObject.searchDateField,
                allDate: searchObject.allDate === "true",
            });
        }
    }, [setDateSearchFilterState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setDateSearchFilterState(prev => {
            if (prev.searchDateField) return prev;

            return {
                ...prev,
                allDate: (!prev.startDate && !prev.endDate),
                searchDateField: defaultItem.searchDateField,
            }
        });
    }, [setDateSearchFilterState]);

    useSetFilterDefaultValue({
        items: searchFieldItems,
        isAlreadySet: !!dateSearchFilterValue.searchDateField,
        setFilterDefaultValue,
    });

    // useEffect(() => {
    //     if (alreadySet.current) return;
    //     if (isEmpty(searchFieldItems)) return;
    //
    //     const defaultSearchFieldItem = searchFieldItems.find(i => i.isDefault);
    //     if (defaultSearchFieldItem) {
    //         setDateSearchFilterState(prev => {
    //             if (prev.searchDateField) return prev;
    //
    //             return {
    //                 ...prev,
    //                 allDate: !prev.startDate && !prev.endDate,
    //                 searchDateField: defaultSearchFieldItem.searchDateField,
    //             }
    //         });
    //     }
    // }, [searchFieldItems, setDateSearchFilterState]);
    // useEffect(() => {
    //     if (alreadySet.current) return;
    //
    //     if (dateSearchFilterValue.searchDateField) {
    //         alreadySet.current = true;
    //     }
    // }, [dateSearchFilterValue]);

    const setStartDate = useCallback((e) => {
        const startDate = e.value;
        setDateSearchFilterState(prev => {
            return {
                ...prev,
                startDate: formatFromDate(startDate),
            }
        });
    }, [setDateSearchFilterState]);

    const setEndDate = useCallback((e) => {
        const endDate = e.value;
        setDateSearchFilterState(prev => {
            return {
                ...prev,
                endDate: formatFromDate(endDate),
            }
        });
    }, [setDateSearchFilterState]);

    const setDropdownItem = useCallback(e => {
        setDateSearchFilterState(prev => {
            return {
                ...prev,
                searchDateField: e.value?.searchDateField,
            }
        });
    }, [setDateSearchFilterState]);

    const onCheckboxChange = useCallback((e) => {
        setDateSearchFilterState(prev => {
            return {
                ...prev,
                allDate: e.checked,
            }
        });
    }, [setDateSearchFilterState]);

    return (
        <div className="grid col-12 p-fluid align-items-center">
            <div className={"grid formgrid align-items-center p-2"} css={formGroupStyle}>
                <FilterLabel htmlFor={"searchDateField"}>날짜 검색</FilterLabel>
                <Dropdown
                    name="searchDateField"
                    value={searchFieldItems.find(item => item.searchDateField === dateSearchFilterValue.searchDateField)}
                    onChange={setDropdownItem}
                    options={searchFieldItems}
                    optionLabel="name"
                    css={searchDataFieldStyle}
                    {...dateSearchFilterValue.allDate
                        ? { placeholder: "전체", disabled: true }
                        : { placeholder: "Select One" }
                    }
                />
            </div>

            <div className={"grid formgrid  align-items-center p-2"} css={formGroupStyle}>
                <div className="field-checkbox m-0">
                    <Checkbox
                        inputId="allDate"
                        name="allDate"
                        value={dateSearchFilterValue.allDate}
                        checked={dateSearchFilterValue.allDate}
                        onChange={onCheckboxChange}
                    />
                    <label htmlFor="allDate">전체</label>
                </div>
            </div>

            <div className={"grid formgrid align-items-center p-2"} css={calendarFormGroupStyle}>
                <Calendar
                    name={"startDate"}
                    showIcon
                    showButtonBar
                    value={dateSearchFilterValue.startDate}
                    onChange={setStartDate}
                    className={""}
                    css={calendarStyle}
                    monthNavigator
                    yearNavigator
                    yearRange={yearRange}
                    {...dateSearchFilterValue.allDate && { disabled: true }}
                    {...!dateSearchFilterValue.searchDateField && { disabled: true }}
                />
                <span css={betweenCalendarStyle}>~</span>
                <Calendar
                    name={"endDate"}
                    showIcon
                    showButtonBar
                    value={dateSearchFilterValue.endDate}
                    onChange={setEndDate}
                    className={""}
                    css={calendarStyle}
                    monthNavigator
                    yearNavigator
                    yearRange={yearRange}
                    {...dateSearchFilterValue.allDate && { disabled: true }}
                    {...!dateSearchFilterValue.searchDateField && { disabled: true }}
                />
            </div>
        </div>
    )
}

const formGroupStyle = theme => css`
    margin-right: 1rem;

    ${theme.breakpoints.down("sm")} {
        margin-right: 0;
    }

    ${theme.breakpoints.down("xs")} {
        flex: 1;
    }
`;

const calendarFormGroupStyle = theme => css`
    ${formGroupStyle(theme)};

    margin-right: 0;

    ${theme.breakpoints.down("sm")} {
        min-width: 100%;
        margin-right: -1rem;
    }

`;

const calendarStyle = theme => css`
    max-width: 150px;

    ${theme.breakpoints.down("sm")} {
        max-width: unset;
        width: 48%;
    }

    ${theme.breakpoints.down("xs")} {
        min-width: 100%;
        margin-right: -8px;
    }
`;

const betweenCalendarStyle = theme => css`
    height: 100%;

    ${theme.breakpoints.down("xs")} {
        min-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const searchDataFieldStyle = css`
    max-width: 136px;
    min-width: 136px;
`;

export default DateSearchFilter;