import axios from "axios";
import qs from "qs";
import { atom } from "recoil";
import { isNumber, toInteger } from "lodash";
import { getNowSeconds } from "./numbers";

export const logout = () => {
  setToken(null);
}

export const getToken = () => {
  const expires_in = toInteger(localStorage.getItem("expires_in")) || 0;
  if (expires_in < getNowSeconds()) {
    logout();
    return null;
  }

  return localStorage.getItem("access_token");
}

const setExpiresIn = (expires_in) => {
  if (isNumber(expires_in)) {
    const calculatedExpiredIn = getNowSeconds() + toInteger(expires_in);
    localStorage.setItem("expires_in", calculatedExpiredIn);
  }
}

export const setToken = (access_token) => {
  localStorage.setItem("access_token", access_token ?? "");
  if (!access_token) {
    setUserToStorage(null);
  }
}

export const getUserFromStorage = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
}

export const setUserToStorage = (user) => {
  if (user) {
    setExpiresIn(user.expires_in);
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.setItem("user", user);
  }
}

export const login = (username, password) => {
  const data = {
    "grant_type": "password",
    scope: "Admin",
    username: username,
    password: password,
  };
  return axios.post(
    "/token",
    qs.stringify(data),
    {
      headers: {
        'content-type': 'x-www-form-urlencoded',
        accept: "application/json",
      }
    },
  )
    .then(result => {
      if (result.status === 200 && result.data) {
        setUserToStorage(result.data);
        setToken(result.data.access_token);
      }
    })
}

export const currentUserState = atom({
  key: 'CurrentUserState',
  default: null,
});