import { atom } from "recoil";

export const managerAdminUserListState = atom({
    key: 'managerAdminUserListState',
    default: [],
});

export const managerAdminUserListFilterState = atom({
    key: 'managerAdminUserListFilterState',
    default: {},
});

export const managerAdminUserPaginationFilterState = atom({
    key: 'managerAdminUserPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy: null,
    },
});
