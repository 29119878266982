import React from "react";
import { css } from "@emotion/react";
import FromToLabel from "./FromToLabel";
import AddressBoxContainer from "./AddressBoxContainer";

const DeliverToBox = ({
    consignment,
    className,
}) => (
    <div css={deliverToBoxContainerStyle} className={className}>
        <FromToLabel>Deliver to:</FromToLabel>
        <AddressBoxContainer>
            <div>{consignment.ReceiverName}</div>
            <div>{consignment.ReceiverAddress1} {consignment.ReceiverAddress2} {consignment.ReceiverZipcode}</div>
            <div>{consignment.ReceiverMobile}</div>
            <div>{consignment.ReceiverPhone}</div>
        </AddressBoxContainer>
    </div>
);

export default DeliverToBox;

const deliverToBoxContainerStyle = css`
    display: flex;
    flex-direction: row;
`;