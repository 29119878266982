import { atom } from "recoil";

export const invoiceListState = atom({
    key: 'invoiceListState',
    default: [],
});

export const invoiceListFilterState = atom({
    key: 'invoiceListFilterState',
    default: {},
});

export const invoicePaginationFilterState = atom({
    key: 'invoicePaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy: null,
    },
});

export const selectedInvoicesState = atom({
    key: 'selectedInvoicesState',
    default: [],
});
