import React, { useMemo } from "react";
import { css } from "@emotion/react";

const Weight = ({
    consignment,
}) => {
    const totalWeight = useMemo(() => {
        return Number(consignment.TotalWeight);
    }, [consignment]);

    const chargeableWeight = useMemo(() => {
        return Math.max(
            Number(consignment.TotalVolumeWeight),
            Number(consignment.TotalWeight)
        );
    }, [consignment]);

    return (
        <div css={weightContainerStyle}>
            <div>Total Weight: {totalWeight}kg</div>
            <div>Chargeable Weight: {chargeableWeight}kg</div>
        </div>
    );
}

export default Weight;

const weightContainerStyle = css`
    padding: 10px;
    text-align: left;
`;