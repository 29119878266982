import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation, useParams } from "react-router-dom";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import { css } from "@emotion/react";
import { TabMenu } from "primereact/tabmenu";

import useAPI from "../../core/hooks/useAPI";
import useTabMenu from "../../core/hooks/useTabMenu";
import useOrderData from "./hooks/useOrderData";
import { menuState } from "../../service/hooks/useMenus";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { currentUserState } from "../../includes/auth";
import { PERMISSION_ADMIN } from "../../includes/constants";

import { orderDetailPageDeliveryStatusState, orderDetailPageEmailSendState, orderDetailPageFlightSelectState, orderDetailState } from "./store";
import OrderInfo from "./components/OrderInfo";
import Loading from "../../components/loading/Loading";
import OrderItemList from "./components/OrderItemList";
import OrderChargeList from "./components/OrderChargeList";
import OrderReceiverInfo from "./components/OrderReceiverInfo";
import OrderUserInfo from "./components/OrderUserInfo";
import OrderInvoiceList from "./components/OrderInvoiceList";
import OrderNoteList from "./components/OrderNoteList";
import CardContainer from "../../components/layouts/CardContainer";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";

const wizardItems = [
  { label: '주문정보', isTop: true, },
  { label: '상품정보', linkName: "#OrderItemList" },
  { label: '결제정보', linkName: "#OrderChargeList" },
  { label: '주문자정보', linkName: "#OrderUserInfo" },
  { label: '수령자정보', linkName: "#OrderReceiverInfo" },
  { label: '인보이스', linkName: "#OrderInvoiceList" },
  { label: '관리메모', linkName: "#OrderNoteList" },
];

const OrderDetailPage = () => {
  const menuStateValue = useRecoilValue(menuState);
  const [state, setState] = useRecoilState(orderDetailState);
  const [deliveryStatusState, setDeliveryStatusState] = useRecoilState(orderDetailPageDeliveryStatusState);
  const [flightState, setFlightsState] = useRecoilState(orderDetailPageFlightSelectState);
  const { orderId } = useParams();
  const { closeConfirmation } = useAwaitingDialogContext();
  const location = useLocation();
  const { onTabChange, activeIndex, initTab } = useTabMenu(wizardItems);

  const [currentUser,] = useRecoilState(currentUserState);

  const [{
    data: orderData,
    loading: orderLoading,
  }, getOrder] = useOrderData();

  const alreadyInitTab = useRef(false);

  useEffect(() => {
    if (alreadyInitTab.current) return;

    if (location.hash && !orderLoading) {
      alreadyInitTab.current = true;
      // 데이터를 로딩하면서 위치가 바뀌기 때문에 1초 후에 이동하는것으으로 한다.
      setTimeout(() => {
        initTab(location.hash);
      }, 1000);
    }
  }, [orderLoading, initTab, location.hash]);

  useEffect(() => {
    if (orderData) {
      console.log(orderData)
      setState(orderData);
      setDeliveryStatusState({
        deliveryStatusCode: orderData.DeliveryStatusCode,
        deliveryStatusName: orderData.DeliveryStatusName,
        isSendEmail: orderData.isSendEmail
      });
      setFlightsState({
        flightId: orderData.FlightId,
        flightName: orderData.FlightName,
      });
     
    }
  }, [orderData, setState, setDeliveryStatusState, setFlightsState]);

  const refresh = useCallback(() => {
    return getOrder(orderId);
  }, [getOrder, orderId]);

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId, getOrder]);

  /***********************************************************
   * 주문정보 수정
   ***********************************************************/
  const updateOrderInfo = useAPI({
    url: `/manager/order/${orderId}/orderinfo`,
    method: 'PUT',
    successMessage: "주문정보를 성공적으로 수정하였습니다.",
    callbackAfterSuccess: null,
  });

  const onSaveOrderInfo = useCallback((result) => {
    if (result) {
      updateOrderInfo({
        data: {
          flightId: flightState.flightId,
          DeliveryStatusCode: deliveryStatusState.deliveryStatusCode
        }
      }).finally(closeConfirmation);
    } else {
      closeConfirmation();
    }
  }, [updateOrderInfo, closeConfirmation, deliveryStatusState, flightState]);

  /***********************************************************
   * 주문자(회원) 정보 수정
   ***********************************************************/
  const updateOrderUserInfo = useAPI({
    url: `/manager/customer/${state?.UserId}`,
    method: 'PUT',
    successMessage: "주문자(회원) 정보를 성공적으로 수정하였습니다.",
    callbackAfterSuccess: null,
  });

  const onSaveOrderUserInfo = useCallback((result) => {
    if (result) {
      updateOrderUserInfo({
        data: {
          ...result,
          IsChangePassword: false, // 무조건 false로 보내야 한다.
          UserPassword: "", // 무조건 "" 로 보내야 한다.
        }
      }).finally(closeConfirmation);
    } else {
      closeConfirmation();
    }
  }, [updateOrderUserInfo, closeConfirmation]);

  /***********************************************************
   * 수령자 정보 수정
   ***********************************************************/
  const updateOrderReceiverInfo = useAPI({
    url: `/manager/order/${orderId}/orderreceiver`,
    method: 'PUT',
    successMessage: "수령자 정보를 성공적으로 수정하였습니다.",
    callbackAfterSuccess: null,
  });

  const onSaveOrderReceiverInfo = useCallback((result) => {
    if (result) {
      updateOrderReceiverInfo({
        data: {
          ...result,
          PickupType: result.PickupTypeCode,
        }
      }).finally(closeConfirmation);
    } else {
      closeConfirmation();
    }
  }, [updateOrderReceiverInfo, closeConfirmation]);

  /***********************************************************
   * 수령자 정보 수정
   ***********************************************************/
  const updateOrderLocalTrackingNumber = useAPI({
    url: `/manager/order/${orderId}/localtracking`,
    method: 'PUT',
    successMessage: "송장번호를 성공적으로 수정하였습니다.",
    callbackAfterSuccess: null,
  });

  const onSaveOrderLocalTrackingNumber = useCallback((result) => {
    if (result) {
      updateOrderLocalTrackingNumber({
        data: {
          TrackingNumber: result.LocalTrackingNumber
        }
      })
        .then(refresh)
        .finally(closeConfirmation);
    } else {
      closeConfirmation();
    }
  }, [updateOrderLocalTrackingNumber, closeConfirmation, refresh]);

  const breadcrumbItems = useMemo(() => {
    return [
      ...menuStateValue.currentBreadcrumbItems,
      { label: '주문상세' }
    ]
  }, [menuStateValue]);

  return (
    <div className="grid" css={orderDetailPageStyle}>
      {orderLoading && <Loading overlay onTop />}

      <div className="col-12">
        <EnhancedBreadCrumb model={breadcrumbItems} showBack />

        <CardContainer>
          <TabMenu
            model={wizardItems}
            activeIndex={activeIndex}
            onTabChange={onTabChange}
          />
          <OrderInfo
            refresh={refresh}
            orderData={state}
            onSaveOrderInfo={onSaveOrderInfo}
          />
        </CardContainer>

        <OrderItemList refresh={refresh} orderData={state} />

        {currentUser.permission === PERMISSION_ADMIN &&
          <OrderChargeList refresh={refresh} orderData={state} />
        }

        <OrderUserInfo
          orderUserData={state.UserInfo}
          onSaveOrderUserInfo={onSaveOrderUserInfo}
        />

        <OrderReceiverInfo
          orderData={state}
          onSaveOrderReceiverInfo={onSaveOrderReceiverInfo}
          onSaveOrderLocalTrackingNumber={onSaveOrderLocalTrackingNumber}
        />

        {currentUser.permission === PERMISSION_ADMIN &&
          <OrderInvoiceList refresh={refresh} orderData={state} />
        }

        <OrderNoteList refresh={refresh} orderData={state} />

      </div>
      <ScrollUpButton />
    </div>
  );
}

export default OrderDetailPage;

const orderDetailPageStyle = css`
    position: relative;
`;