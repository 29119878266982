import React, { createContext, useContext, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";

const AwaitingDialogContext = createContext(null);

export const AwaitingDialogContextProvider = ({ children }) => {
  const [dialogNode, setDialogNode] = useState(null);

  const awaitingPromiseRef = useRef();
  const buttonLoadingResolutionRef = useRef();

  const openConfirmation = node => {
    setDialogNode(node);

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const closeConfirmation = () => {
    awaitingPromiseRef?.current?.reject?.();
    // Remove loading state from button.
    buttonLoadingResolutionRef.current?.();
    setDialogNode(null);
  };

  const confirm = (confirmData) => {
    if (awaitingPromiseRef.current) {
      // Return a promise so that the confirm button will be in a loading state
      // until the consumer calls `closeConfirmation`.
      return new Promise(resolve => {
        buttonLoadingResolutionRef.current = resolve;
        awaitingPromiseRef.current.resolve(confirmData);
      });
    } else {
      setDialogNode(null);
    }
  };

  const value = useMemo(() => {
    return {
      openConfirmation,
      closeConfirmation,
      confirm,
    }
  }, []);

  return (
    <AwaitingDialogContext.Provider value={value}>
      {children}
      {dialogNode}
    </AwaitingDialogContext.Provider>
  );
}

AwaitingDialogContextProvider.propTypes = {
  children: PropTypes.node
};

export const useAwaitingDialogContext = () => {
  return useContext(AwaitingDialogContext);
};
