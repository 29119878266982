import React, { forwardRef, useMemo, useCallback } from 'react'
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";

import { NOOP } from "../../../includes/constants";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import EnhancedDivider from "../../../components/layouts/EnhancedDivider";
import DataTableActions from "../../../components/layouts/DataTableActions";
import AwaitButton from "../../../components/AwaitButton";
import TableTitle from "../../../components/layouts/TableTitle";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import UserSelectSearchDialog from "../../../components/dialogs/UserSelectSearchDialog";
const OrderCreateUserInfo = ({
  onSaveOrderUserInfo = NOOP,
  orderUserData = null,
  className = "", }) => {

  const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

  const titleSection = useMemo(() => (
    <TableTitle noPadding linkName={"OrderUserInfo"}>
      <h5>주문자(회원) 정보</h5>
    </TableTitle>
  ), []);

  const saveUserInfoDialog = useMemo(() => (
    <UserSelectSearchDialog onCancel={closeConfirmation} onConfirm={confirm} shouldShowConfirm={false} />
  ), [closeConfirmation, confirm]);

  const confirmSaveUserInfo = useCallback(() => {
    // const searchStr = trim(e.target.value);
    return openConfirmation(saveUserInfoDialog)
      .then((confirmedData) => {
        if (confirmedData) {
          onSaveOrderUserInfo(confirmedData);
        }
        closeConfirmation();
        // _onSaveFlightInfo(data).then(closeConfirmation);
      }).catch(() => {
        // ignore onCancel
      });
  }, [openConfirmation, saveUserInfoDialog, closeConfirmation, onSaveOrderUserInfo]);


  return (

    <>
      <DataTableActions className={"mb-2"} titleSection={titleSection}>
        <AwaitButton
          onClick={confirmSaveUserInfo}
          label={"주문자 선택"} icon="pi pi-pencil" />
      </DataTableActions>
      <EnhancedDivider />

      <div className="p-fluid formgrid grid">

        <div className="field col-12 md:col-4">
          <label htmlFor="UserName">주문자명</label>
          <InputTextWithError
            name="UserName"
            type="text"
            readOnly
            noBorder
            onChange={NOOP}
            value={orderUserData?.UserName || ''}
          />
        </div>

        <div className="field col-12 md:col-4">
          <label htmlFor="EmailAddress">아이디</label>
          <InputTextWithError
            name="EmailAddress"
            type="email"
            readOnly
            noBorder
            onChange={NOOP}
            value={orderUserData?.EmailAddress || ''}
          />
        </div>

        <div className="field col-12 md:col-4">
          <label htmlFor="EmailAddress">이메일</label>
          <InputTextWithError
            name="EmailAddress"
            type="email"
            readOnly
            noBorder
            onChange={NOOP}
            value={orderUserData?.EmailAddress || ''}
          />
        </div>

      </div>
    </>
  )
}

export default OrderCreateUserInfo;


const gridGroupNoMarginStyle = css`
    margin: 0 0 1rem;
    padding: 0;
`;

const addressDividerStyle = css`
    font-size: 1.2rem;
`;