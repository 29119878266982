import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash";

import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { selectedOrderItemsState } from "../store";
import { API_ORDER_URI, NOOP, PERMISSION_ADMIN } from "../../../includes/constants";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import useCreateConsignment from "../../../service/hooks/useCreateConsignment";
import useAddWeightCharge from "../../../service/hooks/useAddWeightCharge";
import useCreateInvoice from "../../../service/hooks/useCreateInvoice";
import useAPI from "../../../core/hooks/useAPI";

import DataTableActions from "../../../components/layouts/DataTableActions";
import DeliveryStatusActionButton from "../../../components/action-buttons/DeliveryStatusActionButton";
import { currentUserState } from "../../../includes/auth";



const PurchaseOrderPageDataTableActions = ({
  className = '',
  refresh = NOOP,
}) => {

  const history = useHistory();
  const [selectedOrderItems, setSelectedOrderItems] = useRecoilState(selectedOrderItemsState);
  const { closeConfirmation } = useAwaitingDialogContext();
  const [currentUser,] = useRecoilState(currentUserState);
  const refreshAndInitialize = useCallback(() => {
    setSelectedOrderItems([]);
    return refresh();
  }, [refresh, setSelectedOrderItems]);


  /***********************************************************
   * 배송상태 변경
   ***********************************************************/
  const updateOrderDeliveryStatus = useAPI({
    url: `${API_ORDER_URI}/deliverystatus`,
    method: 'PUT',
    successMessage: "선택하신 주문의 배송상태를 성공적으로 변경하였습니다.",
    callbackAfterSuccess: refreshAndInitialize,
  });

  const onSelectDeliveryStatus = useCallback((deliveryStatusUpdateData) => {
    if (deliveryStatusUpdateData) {
      updateOrderDeliveryStatus({
        data: {
          DeliveryStatusCode: deliveryStatusUpdateData.deliveryStatusCode,
          IsSendEmail: deliveryStatusUpdateData.isSendEmail,
          OrderIds: selectedOrderItems.map(order => order.OrderId),
        }
      });
    } else {
      closeConfirmation();
    }
  }, [updateOrderDeliveryStatus, closeConfirmation, selectedOrderItems]);

 

  return (
    <DataTableActions className={className}>
      <Button
        type="button"
        label="주문생성"
        icon={"pi pi-pencil"}
        onClick={() => { history.push('/order/createpurchaseorder') }}
        className={"mb-2 ml-2"}
      />
      
      <DeliveryStatusActionButton onSelect={onSelectDeliveryStatus} disabled={isEmpty(selectedOrderItems)} />
      
    </DataTableActions>
  );
}

PurchaseOrderPageDataTableActions.propTypes = {
  className: PropTypes.string,
  refresh: PropTypes.func,
}

export default PurchaseOrderPageDataTableActions;