import React from "react";
import PropTypes from "prop-types";
import { Divider } from "primereact/divider";
import { css } from "@emotion/react";

const EnhancedDivider = ({ children, className, ...props }) => (
    <Divider css={enhancedDividerStyle} className={className} {...props}>
        {children}
    </Divider>
);

EnhancedDivider.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default EnhancedDivider;

const enhancedDividerStyle = css`
    &.p-divider.p-divider-horizontal {
        margin: 0 0 2rem;
        padding: 0;
    }
`;

