import React, { useEffect } from 'react';
import { css } from "@emotion/react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

import { qnaListFilterState, qnaPaginationFilterState } from "../store";
import { NOOP } from "../../../../includes/constants";

import AwaitButton from "../../../../components/AwaitButton";
import FilterContainer from "../../../../components/filters/FilterContainer";
import PaginationFilter from "../../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../../components/filters/PageFilterContainer";
import FilterContainerGroups from "../../../../components/filters/FilterContainerGroups";
import TextFieldSearchFilter from "../../../../components/filters/TextFieldSearchFilter";
import FilterActionContainer from "../../../../components/filters/FilterActionContainer";
import DateSearchFilter, { dateSearchFilterState } from "../../../../components/filters/DateSearchFilter";
import ReplyStatusSearchFilter, { replyStatusSearchFilterState } from "../../../../components/filters/ReplyStatusSearchFilter";

const searchFieldItems = [
    { name: '작성일', searchDateField: 'CreateDate', isDefault: true },
];

const textFieldSearchDropdownItems = [
    { name: '제목', searchField: 'Title', isDefault: true },
];

export const qnaSearchFilterState = atom({
    key: 'qnaSearchFilterState',
    default: {
        searchField: textFieldSearchDropdownItems[0].searchField,
        searchStr: "",
    },
});

const QnaPageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(qnaListFilterState);
    const paginationFilterValue = useRecoilValue(qnaPaginationFilterState);
    const qnaSearchFilterValue = useRecoilValue(qnaSearchFilterState);
    const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
    const replyStatusSearchFilterValue = useRecoilValue(replyStatusSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...qnaSearchFilterValue,
            }
        });
    }, [qnaSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...dateSearchFilterValue,
            }
        })
    }, [dateSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...replyStatusSearchFilterValue,
            }
        })
    }, [replyStatusSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString atom={qnaPaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer>
                    <TextFieldSearchFilter
                        atom={qnaSearchFilterState}
                        label={"검색"}
                        dropdownItems={textFieldSearchDropdownItems}
                        supportQueryString
                        onSearch={onSearch}
                    />
                </FilterContainer>
                <FilterContainer>
                    <DateSearchFilter searchFieldItems={searchFieldItems} />
                </FilterContainer>
                <FilterContainer>
                    <ReplyStatusSearchFilter />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

export default QnaPageFilter;