import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { throttle } from "lodash";
import { DataTable } from "primereact/datatable";

import useAPI from "../../../core/hooks/useAPI";
import useWeightAmountsData from "./hooks/useWeightAmountsData";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import useDataTableExportCSV from "../../../core/hooks/useDataTableExportCSV";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import { formatNumber, getNewZealandCurrencyMark } from "../../../includes/numbers";
import { API_WEIGHT_AMOUNT_URI, DEFAULT_PAGE_SIZE, rowsPerPageOptions } from "../../../includes/constants";

import WeightAmountPageFilter from "./components/WeightAmountPageFilter";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import DataTableActions from "../../../components/layouts/DataTableActions";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import CreateWeightAmountDialog, { createWeightAmountDialogState } from "../../../components/dialogs/CreateWeightAmountDialog";

const fieldColumnTitleMap = {
    WeightAmountId: "배송비용 ID",
    DeliveryLocationCode: "배송지역 코드 ",
    DeliveryLocationName: "배송지역",
    WeightFrom: "최소무게 kg (<)",
    WeightTo: "최대무게 kg (>=)",
    Amount: "단위금액",
    MaxAmount: "최대 배송비",
}

const sortableFields = [
    "WeightAmountId",
    "DeliveryLocationCode",
    "WeightFrom",
    "WeightTo",
    "Amount",
];

const WeightAmountPage = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: weightAmountsData, loading: weightAmountsDataLoading }, getWeightAmounts] = useWeightAmountsData();
    const { closeConfirmation } = useAwaitingDialogContext();
    const setCreateWeightAmountDialogState = useSetRecoilState(createWeightAmountDialogState);

    const onSearch = useMemo(() => {
        return throttle((e) => {
            return getWeightAmounts();
        }, 1000);
    }, [getWeightAmounts]);

    const {
        sortPaginationProps,
        renderColumn,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getWeightAmounts,
        dataSize: weightAmountsData?.Data?.length ?? 0,
    });

    /***********************************************************
     * 배송비용 생성
     ***********************************************************/
    const createWeightAmount = useAPI({
        url: API_WEIGHT_AMOUNT_URI,
        method: 'POST',
        successMessage: "배송비용을 성공적으로 생성하였습니다.",
        callbackAfterSuccess: getWeightAmounts,
    });

    const onCreateItem = useCallback((weightAmountData) => {
        if (weightAmountData) {
            createWeightAmount({
                data: {
                    DeliveryLocationCode: weightAmountData.DeliveryLocationCode,
                    WeightFrom: weightAmountData.WeightFrom,
                    WeightTo: weightAmountData.WeightTo,
                    Amount: weightAmountData.Amount,
                    MaxAmount: weightAmountData.MaxAmount || 0,
                }
            });
        } else {
            closeConfirmation();
        }
    }, [createWeightAmount, closeConfirmation]);

    /***********************************************************
     * 배송비용 삭제
     ***********************************************************/
    const deleteWeightAmount = useAPI({
        url: API_WEIGHT_AMOUNT_URI,
        method: 'DELETE',
        successMessage: "배송비용을 성공적으로 삭제하였습니다.",
        callbackAfterSuccess: getWeightAmounts,
    });

    const onDeleteItem = useCallback((weightAmountData) => {
        if (weightAmountData) {
            deleteWeightAmount({
                url: `${API_WEIGHT_AMOUNT_URI}/${weightAmountData.WeightAmountId}`,
            });
        } else {
            closeConfirmation();
        }
    }, [deleteWeightAmount, closeConfirmation]);

    /***********************************************************
     * 배송비용 수정
     ***********************************************************/
    const updateWeightAmount = useAPI({
        url: API_WEIGHT_AMOUNT_URI,
        method: 'PUT',
        successMessage: "배송비용을 성공적으로 수정하였습니다.",
        callbackAfterSuccess: getWeightAmounts,
    });

    const onUpdateItem = useCallback((weightAmountData) => {
        if (weightAmountData) {
            updateWeightAmount({
                url: `${API_WEIGHT_AMOUNT_URI}/${weightAmountData.WeightAmountId}`,
                data: {
                    DeliveryLocationCode: weightAmountData.DeliveryLocationCode,
                    WeightFrom: weightAmountData.WeightFrom,
                    WeightTo: weightAmountData.WeightTo,
                    Amount: weightAmountData.Amount,
                    MaxAmount: weightAmountData.MaxAmount || 0,
                }
            });
        } else {
            closeConfirmation();
        }
    }, [updateWeightAmount, closeConfirmation]);

    const onUpdateClicked = useCreateActionButton({
        onSelect: onUpdateItem,
        confirmMessage: "배송비용을 수정하시겠습니까?",
        deleteConfirmMessage: "배송비용을 삭제하시겠습니까?",
        onDelete: onDeleteItem,
        atom: createWeightAmountDialogState,
        dialogComponent: (props) => {
            return (
                <CreateWeightAmountDialog title={"배송비용 수정"} {...props} />
            );
        },
    });

    useEffect(() => {
        if (selectedItem && !selectedItem.IsSummaryData) {
            setCreateWeightAmountDialogState(selectedItem);
            onUpdateClicked().finally(() => setSelectedItem(null));
        }
        // onUpdateClicked 를 디펜던시에 넣으면 안되요!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, setCreateWeightAmountDialogState]);

    return (
        <div className="grid" css={weightAmountPageStyle}>
            <div className="col-12">

                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>배송비용 리스트</h5>
                    <WeightAmountPageFilter onSearch={onSearch} loading={weightAmountsDataLoading} />
                </div>

                <div className="card">

                    <DataTableActions className={"mb-2"}>
                        <CreateActionButton
                            label="배송비용 생성"
                            onSelect={onCreateItem}
                            confirmMessage={"배송비용을 추가하시겠습니까?"}
                            atom={createWeightAmountDialogState}
                            dialogComponent={CreateWeightAmountDialog}
                            shouldShowConfirm={false}
                        />
                    </DataTableActions>

                    <ScrollDataTableContainer loading={weightAmountsDataLoading} minWidth={800}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={weightAmountsDataLoading}
                            ref={dataTableRef}
                            value={weightAmountsData?.Data || []}
                            selectionMode={"single"}
                            selection={selectedItem}
                            onSelectionChange={(e) => setSelectedItem(e.value)}
                            dataKey="WeightAmountId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={weightAmountsData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={weightAmountsData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                        >
                            {renderColumn("DeliveryLocationName")}
                            {renderColumn("WeightFrom", { formatValue: formatNumber })}
                            {renderColumn("WeightTo", { formatValue: formatNumber })}
                            {renderColumn("Amount", {
                                formatValue: formatNumber,
                                prefixAppender: getNewZealandCurrencyMark
                            })}
                            {renderColumn("MaxAmount", {
                                formatValue: formatNumber,
                                prefixAppender: getNewZealandCurrencyMark
                            })}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}
export default WeightAmountPage;

const weightAmountPageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-Amount,
        &.header-column-MaxAmount,
        &.header-column-WeightTo,
        &.header-column-WeightFrom {
            text-align: right;
            max-wdith: 15%;
        }
        
        &.header-column-MaxAmount, {
            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-MaxAmount,
    .column-Amount,
    .column-WeightTo,
    .column-WeightFrom {
        text-align: right;
        padding-right: 20px;
    }
`;