import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { mainPathNameState } from "../../store";
import PurchaseOrderPage from "./PurchaseOrderPage";
import PurchaseOrderDetailPage from "./PurchaseOrderDetailPage";
import PurchaseOrderCreatePage from "./PurchaseOrderCreatePage";

const Purchase = () => {
  const setMainPathNameState = useSetRecoilState(mainPathNameState);
  const match = useRouteMatch();
  const mainPathName = match.path;

  useEffect(() => {
    setMainPathNameState(match.path);
  }, [match.path, setMainPathNameState]);

  return (
    <Switch>
      <Route path={`${mainPathName}`} exact component={PurchaseOrderPage} />
      <Route path={`${mainPathName}/create`} exact component={PurchaseOrderCreatePage} />
      <Route path={`${mainPathName}/:orderId`} component={PurchaseOrderDetailPage} />

    </Switch>
  );
}
export default Purchase;