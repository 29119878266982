import useAxios from "axios-hooks";
import { useEffect, useMemo, useState } from "react";
import { isTrue } from "../../includes/ajax";
import { API_ORDER_CHARGE_TYPE_URI } from "../../includes/constants";

const useOrderChargeType = () => {
    const [orderChargeTypeList, setOrderChargeTypeList] = useState([]);

    const defaultParameters = useMemo(() => {
        return `?pageNo=1&pageSize=200&activeStatus=Active`;
    }, []);

    const [{ data, loading, error }] = useAxios({
            url: `${API_ORDER_CHARGE_TYPE_URI}/${defaultParameters}`,
            method: 'GET',
        },
    );

    useEffect(() => {
        if (data) {
            setOrderChargeTypeList(data?.Data?.filter(codeItem => isTrue(codeItem.IsActive)) ?? []);
        }
    }, [data]);

    return {
        data: orderChargeTypeList,
        loading,
        error,
    }
}

export default useOrderChargeType;