import React, { useCallback, useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { isEmpty } from "lodash";
import { useSetRecoilState } from "recoil";
import { DataTable } from "primereact/datatable";

import useAPI from "../../../core/hooks/useAPI";
import { formatNumber, getNewZealandCurrencyMark } from "../../../includes/numbers";
import { API_ORDER_CHARGE_URI, NOOP } from "../../../includes/constants";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import useAddWeightCharge from "../../../service/hooks/useAddWeightCharge";

import Loading from "../../../components/loading/Loading";
import TableTitle from "../../../components/layouts/TableTitle";
import CardContainer from "../../../components/layouts/CardContainer";
import DataTableActions from "../../../components/layouts/DataTableActions";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import CreateOrderChargeDialog, { createOrderChargeDialogState } from "../../../components/dialogs/CreateOrderChargeDialog";
import ConfirmActionButton from "../../../components/action-buttons/ConfirmActionButton";
import { createOrderChargeDialogDeliveryLocationCodeState } from "../store";

const fieldColumnTitleMap = {
    ChargeId: "비용 ID",
    OrderId: "주문 ID",
    ChargeTypeId: "비용 타입 ID",
    ChargeTypeName: "비용 타입명",
    ChargeName: "항목명",
    UnitAmount: "단위금액",
    UnitQty: "수량",
    ItemAmount: "금액",
    TaxRate: "세금율",
    TaxAmount: "세금",
    TotalAmount: "총 금액",
    IsAddToInvoice: "인보이스 발행 여부",
    InvoiceId: "인보이스 ID",
}

const OrderChargeList = ({
    refresh = NOOP,
    orderData = null,
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const [selectedItem, setSelectedItem] = useState(null);
    const setCreateOrderChargeDialogState = useSetRecoilState(createOrderChargeDialogState);
    const setCreateOrderChargeDialogDeliveryLocationCodeState = useSetRecoilState(createOrderChargeDialogDeliveryLocationCodeState);

    const {
        renderColumn,
        rowClassName,
        getSummaryData,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        getData: NOOP,
        dataSize: (orderData?.OrderChargeList?.length ?? -1) + 1,
    });

    const titleSection = useMemo(() => (
        <TableTitle linkName={"OrderChargeList"}>
            <h5>결제금액</h5>
        </TableTitle>
    ), []);

    /***********************************************************
     * 비용 추가
     ***********************************************************/
    const [onConfirmAddWeightCharge] = useAddWeightCharge({
        callbackAfterSuccess: refresh,
        orderIds: [Number(orderData.OrderId)],
        successMessage: "배송비를 성공적으로 계산하였습니다.",
    });

    const getCreateOrUpdateOrderChargeData = useCallback((orderChargeData, orderId) => ({
        OrderId: orderId,
        ChargeTypeId: Number(orderChargeData.ChargeTypeId),
        ChargeName: orderChargeData.ChargeName,
        ChargeAmount: Number(orderChargeData.ChargeAmount),
        UnitQty: Number(orderChargeData.UnitQty),
        TaxRate: Number(orderChargeData.TaxRate),
        IsManualInput: orderChargeData.IsManualInput,
        ...orderChargeData.IsManualInput
            ? {
                ItemAmount: Number(orderChargeData.ItemAmount),
                TaxAmount: Number(orderChargeData.TaxAmount),
                TotalAmount: Number(orderChargeData.TotalAmount),
            }
            : { TotalAmount: 0 }
    }), []);

    const createOrderCharge = useAPI({
        url: API_ORDER_CHARGE_URI,
        method: 'POST',
        successMessage: "비용을 성공적으로 저장하였습니다.",
        callbackAfterSuccess: refresh,
    });

    const onCreateOrderCharge = useCallback((orderChargeData) => {
        if (orderChargeData) {
            createOrderCharge({
                data: getCreateOrUpdateOrderChargeData(orderChargeData, Number(orderData.OrderId)),
            });
        } else {
            closeConfirmation();
        }
    }, [createOrderCharge, closeConfirmation, orderData, getCreateOrUpdateOrderChargeData]);

    /***********************************************************
     * 비용 삭제
     ***********************************************************/
    const deleteOrderCharge = useAPI({
        url: API_ORDER_CHARGE_URI,
        method: 'DELETE',
        successMessage: "비용을 성공적으로 삭제하였습니다.",
        callbackAfterSuccess: refresh,
    });

    const onDeleteItem = useCallback((orderChargeData) => {
        if (orderChargeData) {
            deleteOrderCharge({
                url: `${API_ORDER_CHARGE_URI}/${orderChargeData.ChargeId}`,
            });
        } else {
            closeConfirmation();
        }
    }, [deleteOrderCharge, closeConfirmation]);

    /***********************************************************
     * 비용 수정
     ***********************************************************/
    const updateOrderCharge = useAPI({
        url: API_ORDER_CHARGE_URI,
        method: 'PUT',
        successMessage: "비용을 성공적으로 수정하였습니다.",
        callbackAfterSuccess: refresh,
    });

    const onUpdateItem = useCallback((orderChargeData) => {
        if (orderChargeData) {
            updateOrderCharge({
                url: `${API_ORDER_CHARGE_URI}/${orderChargeData.ChargeId}`,
                data: getCreateOrUpdateOrderChargeData(orderChargeData, Number(orderChargeData.OrderId)),
            });
        } else {
            closeConfirmation();
        }
    }, [updateOrderCharge, closeConfirmation, getCreateOrUpdateOrderChargeData]);

    const onUpdateClicked = useCreateActionButton({
        onSelect: onUpdateItem,
        confirmMessage: "비용을 수정하시겠습니까?",
        deleteConfirmMessage: "비용을 삭제하시겠습니까?",
        onDelete: onDeleteItem,
        atom: createOrderChargeDialogState,
        dialogComponent: (props) => {
            return (
                <CreateOrderChargeDialog title={"비용 수정"} {...props} />
            );
        },
    });

    useEffect(() => {
        if (selectedItem && !selectedItem.IsSummaryData) {
            setCreateOrderChargeDialogState(prev => ({
                ...prev,
                ...selectedItem,
            }));
            onUpdateClicked().finally(() => setSelectedItem(null));
        }
        // onUpdateClicked 를 디펜던시에 넣으면 안되요!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, setCreateOrderChargeDialogState]);

    useEffect(() => {
        if (orderData) {
            setCreateOrderChargeDialogDeliveryLocationCodeState(prev => ({
                ...prev,
                DeliveryLocationCode: orderData?.DeliveryLocationCode, // string
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderData, setCreateOrderChargeDialogDeliveryLocationCodeState]);

    /***********************************************************
     * 비용 리스트
     ***********************************************************/
    const orderChargeList = useMemo(() => {
        const list = orderData?.OrderChargeList;
        if (isEmpty(list)) {
            return [];
        }

        const summaryFields = [
            "ItemAmount",
            "TaxAmount",
            "TotalAmount",
        ];

        const summaryData = getSummaryData(list, summaryFields);
        return [
            ...list,
            {
                ...summaryData,
                ChargeName: "합계",
            }
        ]
    }, [orderData, getSummaryData]);

    return (
        <CardContainer css={orderChargeListStyle}>
            {!orderData && <Loading />}

            <DataTableActions className={"mb-2"} titleSection={titleSection}>
                <ConfirmActionButton
                    icon={"pi pi-calendar-times"}
                    label={"배송비 계산"}
                    onOkayClicked={onConfirmAddWeightCharge}
                >
                    배송비를 계산하시겠습니까?
                </ConfirmActionButton>
                <CreateActionButton
                    icon="pi pi-plus"
                    label="비용추가"
                    onSelect={onCreateOrderCharge}
                    confirmMessage={"비용을 추가하시겠습니까?"}
                    atom={createOrderChargeDialogState}
                    dialogComponent={CreateOrderChargeDialog}
                    shouldShowConfirm={false}
                />
            </DataTableActions>

            <ScrollDataTableContainer loading={!orderData} minWidth={800}>
                <DataTable
                    resizableColumns
                    columnResizeMode="fit"
                    rowClassName={rowClassName}
                    loading={!orderData}
                    value={orderChargeList}
                    selectionMode={"single"}
                    selection={selectedItem}
                    onSelectionChange={(e) => setSelectedItem(e.value)}
                    dataKey="ChargeId"
                    className="datatable-responsive"
                    emptyMessage="No data found."
                >
                    {renderColumn("ChargeName")}
                    {renderColumn("ItemAmount", {
                        formatValue: formatNumber,
                        prefixAppender: getNewZealandCurrencyMark
                    })}
                    {renderColumn("TaxAmount", {
                        formatValue: formatNumber,
                        prefixAppender: getNewZealandCurrencyMark
                    })}
                    {renderColumn("TotalAmount", {
                        formatValue: formatNumber,
                        prefixAppender: getNewZealandCurrencyMark
                    })}
                    {renderColumn("IsAddToInvoice")}
                </DataTable>
            </ScrollDataTableContainer>
        </CardContainer>
    );
}

export default OrderChargeList;

const orderChargeListStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalAmount,
        &.header-column-TaxAmount,
        &.header-column-ItemAmount {
            text-align: right;
            width: 180px;

            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-TotalAmount,
    .column-TaxAmount,
    .column-ItemAmount {
        text-align: right;
        padding-right: 20px;
    }
`;