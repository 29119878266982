import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { mainPathNameState } from "../../store";
import PurchaseOrderItemPage from "./PurchaseOrderItemPage";
import PurchaseOrderItemDetailPage from "./PurchaseOrderItemDetailPage";

const PurchaseOrderItem = () => {
    const setMainPathNameState = useSetRecoilState(mainPathNameState);
    const match = useRouteMatch();
    const mainPathName = match.path;

    useEffect(() => {
        setMainPathNameState(match.path);
    }, [match.path, setMainPathNameState]);

    return (
        <Switch>
            <Route path={`${mainPathName}`} exact component={PurchaseOrderItemPage} />
            <Route path={`${mainPathName}/:purchaseOrderItemId`} component={PurchaseOrderItemDetailPage} />
        </Switch>
    );
}
export default PurchaseOrderItem;