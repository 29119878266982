import { useEffect, useState } from "react";

const DEFAULT_PICKUP_TYPES = [
  { Code: "1", Name: '직접수령' },
  { Code: "2", Name: '택배발송' },
];

const usePickupType = (typeId) => {
  const [pickupTypeList] = useState(DEFAULT_PICKUP_TYPES);
  const [pickupType, setPickupType] = useState(null);

  useEffect(() => {
    if (typeId) {
      const pickupType = pickupTypeList.find(item => item.Code === typeId);
      if (pickupType) {
        setPickupType(pickupType)
      }
    }
  }, [typeId, pickupTypeList]);

  return {
    data: typeId ? pickupType : pickupTypeList,
    loading: false,
    error: null,
  }
}

export default usePickupType;