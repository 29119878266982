import { atom } from "recoil";

export const orderItemListState = atom({
    key: 'orderItemListState',
    default: [],
});

export const orderItemListFilterState = atom({
    key: 'orderItemListFilterState',
    default: {},
});

export const orderItemPaginationFilterState = atom({
    key: 'orderItemPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy : null,
    },
});
