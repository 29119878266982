import { atom } from "recoil";

export const customerListState = atom({
    key: 'customerListState',
    default: [],
});

export const customerListFilterState = atom({
    key: 'customerListFilterState',
    default: {},
});

export const customerPaginationFilterState = atom({
    key: 'customerPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy: null,
    },
});

export const selectedCustomersState = atom({
    key: 'selectedCustomersState',
    default: [],
});
