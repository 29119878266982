import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { throttle } from "lodash";
import { DataTable } from "primereact/datatable";

import { mainPathNameState } from "../../../store";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../../includes/constants";
import useDataTableExportCSV from "../../../core/hooks/useDataTableExportCSV";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import useNoticesData from "./hooks/useNoticesData";
import NoticePageFilter from "./components/NoticePageFilter";
import AwaitButton from "../../../components/AwaitButton";
import DataTableActions from "../../../components/layouts/DataTableActions";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import { css } from "@emotion/react";

const fieldColumnTitleMap = {
    BoardNoticeId: "글번호",
    CreateDate: "등록일",
    AdminUserId: "관리자 ID",
    WriterName: "작성자",
    Title: "제목",
    IsActive: "사용여부",
}

const sortableFields = [];

const NoticePage = () => {
    const history = useHistory();
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: noticesData, loading: noticesDataLoading }, getNotices] = useNoticesData();

    const onSearch = useMemo(() => {
        return throttle((e) => {
            if (noticesDataLoading) return getPromise();
            return getNotices();
        }, 1000);
    }, [getNotices, noticesDataLoading]);

    const {
        sortPaginationProps,
        renderColumn,
        renderLinkColumn,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getNotices,
        dataSize: noticesData?.Data?.length ?? 0,
    });

    const onCreateNoticeClicked = useCallback(() => {
        history.push(`${mainPathNameStateValue}/create`);
    }, [history, mainPathNameStateValue]);

    return (
        <div className="grid" css={noticePageStyle}>
            <div className="col-12">

                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>공지사항 </h5>
                    <NoticePageFilter onSearch={onSearch} loading={noticesDataLoading} />
                </div>

                <div className="card">
                    <DataTableActions>
                        <AwaitButton
                            icon="pi pi-plus"
                            type="button"
                            className={"mb-2"}
                            onClick={onCreateNoticeClicked}
                            label={"글쓰기"}
                        />
                    </DataTableActions>
                    <ScrollDataTableContainer loading={noticesDataLoading} minWidth={1024}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={noticesDataLoading}
                            ref={dataTableRef}
                            value={noticesData?.Data || []}
                            dataKey="BoardNoticeId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={noticesData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={noticesData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                        >
                            {renderLinkColumn("Title", "BoardNoticeId")}
                            {renderColumn("WriterName")}
                            {renderColumn("CreateDate")}
                            {renderColumn("IsActive")}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}

export default NoticePage;

const noticePageStyle = css`
    .column-Title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;