import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import useAxios from "axios-hooks";
import qs from "qs";
import { customerListFilterState, customerPaginationFilterState } from "../store";
import { isTrue, stringifyFilter } from "../../../includes/ajax";
import { API_MANAGER_ACCOUNT_URI } from "../../../includes/constants";
import useOnloadSearch from "../../../core/hooks/useOnloadSearch";
import useSearchWithFilters from "../../../core/hooks/useSearchWithFilters";
import { isDate, trim } from "lodash";
import { formatFromDate } from "../../../includes/dates";

const useCustomersData = () => {
  const listFilterValue = useRecoilValue(customerListFilterState);

  const getParamAndQueryString = useCallback(() => {
    const params = {
      ...listFilterValue,
    };

    if (isTrue(listFilterValue.allDate)) {
      params.allDate = true;
    }

    if (listFilterValue.startDate && listFilterValue.endDate) {
      params.startDate = isDate(listFilterValue.startDate) ? formatFromDate(listFilterValue.startDate) : listFilterValue.startDate;
      params.endDate = (isDate(listFilterValue.endDate) ? formatFromDate(listFilterValue.endDate) : listFilterValue.endDate).replace("00:00:00", "23:59:59");
      params.endDate = params.endDate.replace("00:00:00", "23:59:59");
    }

    if (listFilterValue.searchDateField) {
      params.searchDateField = listFilterValue.searchDateField;
    }

    if (listFilterValue.searchField) {
      params.searchField = listFilterValue.searchField;
    }
    if (trim(listFilterValue.searchStr)) { params.searchStr = trim(listFilterValue.searchStr); }

    if (!params.allDate) {
      delete params.allDate;
    }

    const queryString = qs.stringify(params, { filter: stringifyFilter });

    /**
     * 여기부터는 실제 서버에 요청할 데이터를 만든다.
     * 그래서 필요없는 필드는 삭제한다.
     */
    if (listFilterValue.allDate || !(listFilterValue.startDate && listFilterValue.endDate)) {
      delete params.searchDateField;
    }

    if (!params.searchDateField) {
      delete params.startDate;
      delete params.endDate;
    }

    if (!params.searchField) {
      delete params.searchStr;
    }

    // delete params.allDate;

    const queryStringForAPI = qs.stringify(params, { filter: stringifyFilter });

    return [queryStringForAPI, queryString];
  }, [listFilterValue]);

  const [{ data, loading }, _getData] = useAxios({
    method: 'GET',
  },
    { manual: true },
  );

  const getData = useSearchWithFilters({
    apiURI: API_MANAGER_ACCOUNT_URI,
    getData: _getData,
    paginationAtom: customerPaginationFilterState,
    getParamAndQueryString,
  });

  useOnloadSearch({
    getData,
    getParamAndQueryString,
    paginationAtom: customerPaginationFilterState
  });

  return [
    {
      data,
      loading
    },
    getData
  ];
}

export default useCustomersData;