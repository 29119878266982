import React, { memo } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Collapse from "@kunukn/react-collapse";

const EnhancedCollapse = ({
    isOpen = false,
    className = "",
    children,
}) => (
    <Collapse isOpen={isOpen} css={collapseStyle} className={className}>
        {children}
    </Collapse>
);

EnhancedCollapse.propTypes = {
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default memo(EnhancedCollapse);

const collapseStyle = css`
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;
