import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_ORDER_ITEM_URI } from "../../../includes/constants";

const useEnterOrderItem = () => {
    const [{
        data,
        loading,
        error
    }, updateData] = useAxios(
        { method: 'PUT' },
        { manual: true },
    );

    const updateOrderItemInfo = useCallback(({ orderItemId, data }) => {
        return updateData({
            url: `${API_ORDER_ITEM_URI}/${orderItemId}/enter`,
            data,
        });
    }, [updateData]);

    return [
        {
            data,
            loading,
            error
        },
        updateOrderItemInfo,
    ];
}

export default useEnterOrderItem;