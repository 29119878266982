import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash";
import { css } from "@emotion/react";
import { DataTable } from "primereact/datatable";

import { formatCurrency, formatDutyCurrency, formatNumber, formatItemAmount } from "../../../includes/numbers";
import { API_ORDER_ITEM_URI, API_ORDER_ITEM_BATCH_ENTER_URI, NOOP, API_PURCHASE_ORDER_ITEM_URI, API_PURCHASE_ORDER_ITEM_BATCH_ENTER_URI} from "../../../includes/constants";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import useAPI from "../../../core/hooks/useAPI";

import Loading from "../../../components/loading/Loading";
import DataTableActions from "../../../components/layouts/DataTableActions";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import TableTitle from "../../../components/layouts/TableTitle";
import CardContainer from "../../../components/layouts/CardContainer";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";
import CreateOrderItemDialog, { createOrderItemDialogState } from "../../../components/dialogs/CreateOrderItemDialog";
import CreatePurchaseOrderItemDialog, { createPurchaseOrderItemDialogState } from "../../../components/dialogs/CreatePurchaseOrderItemDialog";
import OrderItemEnterDialog, { orderItemEnterDialogState } from "../../../components/dialogs/OrderItemEnterDialog";


const fieldColumnTitleMap = {
  OrderItemId: "주문상품 ID",
  PurchaseOrderItemId: "주문상품 ID",
  DeliveryCompany: "배송회사",
  EnteredDate: "입고일",
  IsAddedToInvoice: "인보이스 추가 여부",
  IsEntered: "입고여부",
  ItemAmount: "상품가격",
  ExchangedItemAmount: "Duty Amount",
  ItemDepth: "깊이",
  ItemHeight: "높이",
  ItemQuantity: "상품수량",
  ItemName: "상품명",
  TrackingNumber: "송장번호",
  ItemWeight: "무게(kg)",
  ItemVolumeWeight: "부피무게(kg)",
  ItemTypeName: "아이템 타입",
  DeliveryLocationCode: "배송지역 코드 ",
  DeliveryLocationName: "배송지역",
  TotalChargeableWeight: "Chargeable Weight(kg)",
}

const OrderItemList = ({
  refresh = NOOP,
  orderData = null,
}) => {
  const { closeConfirmation } = useAwaitingDialogContext();
  const [selectedItems, setSelectedItems] = useState([]);
  const [, setCreateOrderItemDialogState] = useRecoilState(createOrderItemDialogState);
  const [, setCreatePurchaseOrderItemDialogState] = useRecoilState(createPurchaseOrderItemDialogState);


  useEffect(() => {
    setCreateOrderItemDialogState(prev => {

      return {
        ...prev,
        DeliveryLocationCode: orderData.DeliveryLocationCode,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  const {
    renderColumn,
    renderLinkColumn,
    rowClassName,
    getSummaryData,
    renderCheckboxColumn,
  } = useDataTableColumns({
    fieldColumnTitleMap,
    getData: NOOP,
    dataSize: (orderData?.OrderItemList?.length ?? -1) + 1,
  });

  const titleSection = useMemo(() => (
    <TableTitle linkName={"OrderItemList"}>
      <h5>상품정보</h5>
    </TableTitle>
  ), []);

  /***********************************************************
   * 상품 추가
   ***********************************************************/
  const createOrderItem = useAPI({
    url: orderData?.IsPurchaseOrder==="True"? API_PURCHASE_ORDER_ITEM_URI:API_ORDER_ITEM_URI,
    method: 'POST',
    successMessage: "상품을 성공적으로 저장하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onCreateOrderItem = useCallback((orderItemData) => {
    if (orderItemData) {
      createOrderItem(orderData.IsPurchaseOrder === "True"? 
        {
          data: {
            OrderId: orderData.OrderId,
            // OrderItemId: orderItemData.orderItemId,
            ItemTypeCode: orderItemData.ItemTypeCode,
            ItemName: orderItemData.ItemName,
            ItemQuantity: orderItemData.ItemQuantity,
            ItemUrl: orderItemData.ItemUrl,
            ItemAmount: orderItemData.ItemAmount,
            DeliveryCompany: orderItemData.DeliveryCompany,
            TrackingNumber: orderItemData.TrackingNumber || '',
            OrderNote: orderItemData.OrderNote || ''
            
          }
      }:{
        data: {
          OrderId: orderData.OrderId,
          // OrderItemId: orderItemData.orderItemId,
          ItemTypeCode: orderItemData.ItemTypeCode,
          ItemName: orderItemData.ItemName,
          ItemQuantity: orderItemData.ItemQuantity,
          ItemUrl: orderItemData.ItemUrl,
          ItemAmount: orderItemData.ItemAmount,
          DeliveryCompany: orderItemData.DeliveryCompany,
          TrackingNumber: orderItemData.TrackingNumber || '',
          
        }
      });
    } else {
      closeConfirmation();
    }
  }, [createOrderItem, closeConfirmation, orderData]);

  /***********************************************************
   * 상품 삭제
   ***********************************************************/
  const deleteOrderItem = useAPI({
    url: orderData?.IsPurchaseOrder==="True"? API_PURCHASE_ORDER_ITEM_URI:API_ORDER_ITEM_URI,
    method: 'DELETE',
    successMessage: "상품을 성공적으로 삭제하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onDeleteItem = useCallback((orderChargeData) => {
    if (orderChargeData) {
      deleteOrderItem({
        url: orderData?.IsPurchaseOrder==="True"?`${API_PURCHASE_ORDER_ITEM_URI}/${orderChargeData.PurchaseOrderItemId}`:`${API_ORDER_ITEM_URI}/${orderChargeData.OrderItemId}`,
      });
    } else {
      closeConfirmation();
    }
  }, [deleteOrderItem, closeConfirmation]);

  /***********************************************************
   * 상품 수정
   ***********************************************************/
  const updateOrderItem = useAPI({
    url: orderData?.IsPurchaseOrder==="True"? `${API_PURCHASE_ORDER_ITEM_URI}/order`:API_ORDER_ITEM_URI,
    method: 'PUT',
    successMessage: "상품을 성공적으로 수정하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onUpdateItem = useCallback((orderItemData) => {
    if (orderItemData) {
      updateOrderItem({
        url: orderData?.IsPurchaseOrder==="True"?`${API_PURCHASE_ORDER_ITEM_URI}/order/${orderItemData.PurchaseOrderItemId}`:`${API_ORDER_ITEM_URI}/${orderItemData.OrderItemId}`,
        data: orderData?.IsPurchaseOrder==="True"?{
          OrderId: orderItemData.OrderId,
          // OrderItemId: orderItemData.OrderItemId,
          ItemTypeCode: orderItemData.ItemTypeCode,
          ItemName: orderItemData.ItemName,
          ItemQuantity: orderItemData.ItemQuantity,
          ItemUrl: orderItemData.ItemUrl,
          ItemAmount: orderItemData.ItemAmount,
          DeliveryCompany: orderItemData.DeliveryCompany,
          TrackingNumber: orderItemData.TrackingNumber || '',
          OrderNote: orderItemData.OrderNote || ''
        }: {
          OrderId: orderItemData.OrderId,
          // OrderItemId: orderItemData.OrderItemId,
          ItemTypeCode: orderItemData.ItemTypeCode,
          ItemName: orderItemData.ItemName,
          ItemQuantity: orderItemData.ItemQuantity,
          ItemUrl: orderItemData.ItemUrl,
          ItemAmount: orderItemData.ItemAmount,
          DeliveryCompany: orderItemData.DeliveryCompany,
          TrackingNumber: orderItemData.TrackingNumber || '',
        }
      });
    } else {
      closeConfirmation();
    }
  }, [updateOrderItem, closeConfirmation]);

  const onUpdateClicked = useCreateActionButton({
    onSelect: onUpdateItem,
    confirmMessage: "상품을 수정하시겠습니까?",
    deleteConfirmMessage: "상품을 삭제하시겠습니까?",
    onDelete: onDeleteItem,
    atom: createOrderItemDialogState,
    readOnly: !!orderData?.ConsignmentNumber,
    dialogComponent: (props) => {
      if (orderData?.IsPurchaseOrder === "True") return (
        <CreatePurchaseOrderItemDialog title={"상품 수정"} {...props}/>
      )
      return (
       <CreateOrderItemDialog title={"상품 수정"} {...props} />
      );
    },
  });


  /***********************************************************
   * 상품 입고처리
   ***********************************************************/
  const orderItemEntered = useAPI({
    url: orderData?.IsPurchaseOrder === "True"? API_PURCHASE_ORDER_ITEM_BATCH_ENTER_URI:API_ORDER_ITEM_BATCH_ENTER_URI,
    method: 'POST',
    successMessage: "입고처리를 완료 하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onEnterOrderItem = useCallback((orderItemData) => {

    if (orderItemData) {
      orderItemEntered({
        data: orderData?.IsPurchaseOrder === "True"?
        {
          PurchaseOrderItemIds: selectedItems.map(i => i.PurchaseOrderItemId),
          ItemWeight: orderItemData.ItemWeight,
          ItemWidth: orderItemData.ItemWidth,
          ItemHeight: orderItemData.ItemHeight,
          ItemDepth: orderItemData.ItemDepth,
          IsEntered: orderItemData.IsEntered,
        }:{
          OrderItemIds: selectedItems.map(i => i.OrderItemId),
          ItemWeight: orderItemData.ItemWeight,
          ItemWidth: orderItemData.ItemWidth,
          ItemHeight: orderItemData.ItemHeight,
          ItemDepth: orderItemData.ItemDepth,
          IsEntered: orderItemData.IsEntered,
        }
      });
    } else {
      closeConfirmation();
    }
  }, [orderItemEntered, closeConfirmation, selectedItems]);




  /***********************************************************
   * 상품 리스트
   ***********************************************************/
  const orderItemList = useMemo(() => {
    const list = orderData?.IsPurchaseOrder === "True"? orderData.PurchaseOrderItemList:orderData.OrderItemList;

    if (isEmpty(list)) {
      return [];
    }

    const summaryFields = [
      "ItemAmount",
      "ItemQuantity",
      "ItemWeight",
      "ItemVolumeWeight",
      "ExchangedItemAmount",
      "TotalChargeableWeight",
    ];

    const summaryData = getSummaryData(list, summaryFields);

    return [
      ...list,
      {
        ...summaryData,
        DeliveryLocationCode: orderData?.DeliveryLocationCode,
        ItemQuantity: Number(summaryData.ItemQuantity).toFixed(0),
      },
    ]
  }, [orderData, getSummaryData]);

  const onOrderItemClicked = (e, rowData) => {
    if (orderData.IsPurchaseOrder) {
      setCreatePurchaseOrderItemDialogState(rowData);
    } else {
      setCreateOrderItemDialogState(rowData);
    }
    onUpdateClicked().finally();
  }


  return (
    <CardContainer css={orderItemListStyle}>
      {!orderData && <Loading />}

      {!orderData?.ConsignmentNumber &&

        <DataTableActions className={"mb-2"} titleSection={titleSection}>
          <CreateActionButton
            label="입고처리"
            icon={"pi pi-calendar-times"}
            onSelect={onEnterOrderItem}
            confirmMessage={""}
            atom={orderItemEnterDialogState}
            dialogComponent={OrderItemEnterDialog}
            shouldShowConfirm={false}
            disabled={isEmpty(selectedItems)}
          />
          <CreateActionButton
            label="상품추가"
            onSelect={onCreateOrderItem}
            confirmMessage={"상품을 추가하시겠습니까?"}
            atom={createOrderItemDialogState}
            dialogComponent={CreateOrderItemDialog}
            shouldShowConfirm={false}
          />
        </DataTableActions>
      }
      <ScrollDataTableContainer loading={!orderData} minWidth={1200}>
        <DataTable
          resizableColumns
          columnResizeMode="fit"
          rowClassName={rowClassName}
          loading={!orderData}
          value={orderItemList}
          selection={selectedItems}
          onSelectionChange={(e) => setSelectedItems(e.value)}
          dataKey={orderData?.IsPurchaseOrder === "True"?"PurchaseOrderItemId":"OrderItemId"}
          className="datatable-responsive"
          emptyMessage="No data found."
          selectionMode="checkbox"
          isDataSelectable={e => { 
            if (orderData?.IsPurchaseOrder === "True") return e.data.PurchaseOrderItemId? true:false
            return e.data.OrderItemId ? true : false }}
        >
          {renderCheckboxColumn()}
          {orderData?.IsPurchaseOrder === "True"?renderLinkColumn("ItemName", "PurchaseOrderItemId", { onClick: onOrderItemClicked }):renderLinkColumn("ItemName", "OrderItemId", { onClick: onOrderItemClicked })}
          {orderData?.IsPurchaseOrder === "True"?renderLinkColumn("ItemTypeName", "PurchaseOrderItemId", { onClick: onOrderItemClicked }):renderLinkColumn("ItemTypeName", "OrderItemId", { onClick: onOrderItemClicked })}
          {renderColumn("DeliveryCompany")}
          {renderColumn("TrackingNumber")}
          {renderColumn("IsEntered")}
          {renderColumn("IsSummaryData", { headerStyle: { width: '80px' } })}
          {renderColumn("ItemQuantity")}
          {renderColumn("ItemAmount", { formatValue: formatCurrency })}
          {renderColumn("ExchangedItemAmount", { formatValue: formatDutyCurrency })}
          {renderColumn("ItemWeight")}
          {renderColumn("ItemVolumeWeight")}
          {renderColumn("TotalChargeableWeight", { formatValue: formatNumber })}
        </DataTable>
      </ScrollDataTableContainer>
    </CardContainer>
  );
}

export default OrderItemList;

const orderItemListStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalChargeableWeight,
        &.header-column-ExchangedItemAmount,
        &.header-column-ItemQuantity,
        &.header-column-ItemWeight,
        &.header-column-ItemVolumeWeight,
        &.header-column-ItemAmount {
            text-align: right;

            .p-column-title {
                padding-right: 20px;
            }
        }

        &.header-column-TotalChargeableWeight {
            width: 190px;
        }
        
        &.header-column-ItemWeight {
            width: 160px;
        }

        &.header-column-ItemVolumeWeight {
            width: 140px;
        }

        &.header-column-ExchangedItemAmount,
        &.header-column-ItemAmount {
            width: 140px;
        }
    }

    .column-TotalChargeableWeight,
    .column-IsSummaryData,
    .column-ExchangedItemAmount,
    .column-ItemQuantity,
    .column-ItemWeight,
    .column-ItemVolumeWeight,
    .column-ItemAmount {
        text-align: right;
        padding-right: 20px;
    }
`;
