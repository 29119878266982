import React, { useCallback, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { validate as validateEmail } from "email-validator";
import { useFormik } from "formik";
import { trim } from "lodash";

import { InputTextarea } from "primereact/inputtextarea";

import { isCombineItemItems, isRepackageItems, isUseMileageItems, NOOP } from "../../../includes/constants";
import { isBooleanString, toBooleanValue } from "../../../includes/ajax";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import usePickupType from "../../../service/hooks/usePickupType";
import Loading from "../../../components/loading/Loading";
import TableTitle from "../../../components/layouts/TableTitle";
import AwaitButton from "../../../components/AwaitButton";
import DataTableActions from "../../../components/layouts/DataTableActions";
import EnhancedDivider from "../../../components/layouts/EnhancedDivider";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DropdownWithError from "../../../components/forms/DropdownWithError";
import CardContainer from "../../../components/layouts/CardContainer";
import IsReceiverOwnRadioButtons from "../../../components/forms/IsReceiverOwnRadioButtons";
import OrderLocalTrackingNumber from "./OrderLocalTrackingNumber";

const OrderReceiverInfo = ({
  onSaveOrderReceiverInfo = NOOP,
  onSaveOrderLocalTrackingNumber = NOOP,
  orderData = null,
}) => {
  const { data: pickupTypesData } = usePickupType();
  const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

  const orderItemDialog = useMemo(() => (
    <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm}>
      수령자 정보를 수정하시겠습니까?
    </ConfirmDialog>
  ), [closeConfirmation, confirm]);

  const onSaveClicked = useCallback((values) => {
    return openConfirmation(orderItemDialog)
      .then(() => {
        onSaveOrderReceiverInfo(values);
      }).catch(() => {
        // ignore onCancel
      }).finally(() => {
        /**
         * 이 페이지는 마지막에 취소를 해도 값을 지우지 말자!!!
         * formik.setValues({});
         */
      });
  }, [onSaveOrderReceiverInfo, orderItemDialog, openConfirmation]);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {
      const newValue = {
        ...values,
      };

      newValue.PickupTypeCode = newValue.pickupTypeDropdownItem.Code;
      newValue.IsCombineItem = newValue.isCombineItem.Code;
      newValue.IsRepackage = newValue.isRepackage.Code;
      newValue.IsUseMileage = newValue.isUseMileage.Code;
      delete newValue.pickupTypeDropdownItem;
      delete newValue.isCombineItem;
      delete newValue.isRepackage;
      delete newValue.isUseMileage;

      return onSaveClicked(newValue);
    },
  });

  useEffect(() => {
    if (orderData) {
      formik.setValues({
        IsReceiverOwn: isBooleanString(orderData.IsReceiverOwn) ? toBooleanValue(orderData.IsReceiverOwn) : true,
        ReceiverEmail: orderData.ReceiverEmail,
        ReceiverName: orderData.ReceiverName,
        ReceiverMobile: orderData.ReceiverMobile,
        ReceiverPhone: orderData.ReceiverPhone,
        ReceiverAddress1: orderData.ReceiverAddress1,
        ReceiverAddress2: orderData.ReceiverAddress2,
        ReceiverZipcode: orderData.ReceiverZipcode,
        PersonalCustomCode: orderData.PersonalCustomCode,
        ReceiverMessage: orderData.ReceiverMessage,
        pickupTypeDropdownItem: pickupTypesData.find(item => item.Code === orderData.PickupTypeCode),
        isCombineItem: isCombineItemItems.find(item => item.Code === toBooleanValue(orderData.IsCombineItem)),
        isRepackage: isRepackageItems.find(item => item.Code === toBooleanValue(orderData.IsRepackage)),
        isUseMileage: isUseMileageItems.find(item => item.Code === toBooleanValue(orderData.IsUseMileage)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, pickupTypesData]);

  const onSaveOrderReceiverInfoClicked = useCallback(() => {
    return formik.submitForm();
  }, [formik]);

  const titleSection = useMemo(() => (
    <TableTitle noPadding linkName={"OrderReceiverInfo"}>
      <h5>수령자 정보</h5>
    </TableTitle>
  ), []);

  return (
    <CardContainer>
      <form onSubmit={formik.handleSubmit} noValidate>
        {!orderData && <Loading />}
        <DataTableActions className={"mb-2"} titleSection={titleSection}>
          <AwaitButton onClick={onSaveOrderReceiverInfoClicked} label={"수령자 수정"} icon="pi pi-pencil" />
        </DataTableActions>
        <EnhancedDivider />

        <div className="p-fluid formgrid grid">

          <div className="field col-12 md:col-6">
            <IsReceiverOwnRadioButtons
              className="grid m-0"
              isActive={toBooleanValue(formik.values.IsReceiverOwn)}
              handleChange={formik.handleChange}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverEmail">이메일</label>
            <InputTextWithError
              name="ReceiverEmail"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.ReceiverEmail || ''}
              error={formik.errors.ReceiverEmail}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="isCombineItem">합배송신청</label>
            <DropdownWithError
              name="isCombineItem"
              value={formik.values.isCombineItem}
              onChange={formik.handleChange}
              options={isCombineItemItems}
              error={formik.errors.isCombineItem}
              optionLabel="Name"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="isRepackage">재포장신청</label>
            <DropdownWithError
              name="isRepackage"
              value={formik.values.isRepackage}
              onChange={formik.handleChange}
              options={isRepackageItems}
              error={formik.errors.isRepackage}
              optionLabel="Name"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="isUseMileage">마일리지사용</label>
            <DropdownWithError
              name="isUseMileage"
              value={formik.values.isUseMileage}
              onChange={formik.handleChange}
              options={isUseMileageItems}
              error={formik.errors.isUseMileage}
              optionLabel="Name"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverMobile">휴대폰</label>
            <InputTextWithError
              name="ReceiverMobile"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ReceiverMobile || ''}
              error={formik.errors.ReceiverMobile}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverName">수령자명</label>
            <InputTextWithError
              name="ReceiverName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ReceiverName || ''}
              error={formik.errors.ReceiverName}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverPhone">전화번호</label>
            <InputTextWithError
              name="ReceiverPhone"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ReceiverPhone || ''}
              error={formik.errors.ReceiverPhone}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="pickupTypeDropdownItem">수령방식</label>
            <DropdownWithError
              name="pickupTypeDropdownItem"
              value={formik.values.pickupTypeDropdownItem}
              onChange={formik.handleChange}
              options={pickupTypesData}
              error={formik.errors.pickupTypeDropdownItem}
              optionLabel="Name"
              placeholder="Select One"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="PersonalCustomCode">개인통관부호</label>
            <InputTextWithError
              name="PersonalCustomCode"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.PersonalCustomCode || ''}
              error={formik.errors.PersonalCustomCode}
            />
          </div>

          {/*****************************************
                     * 주소 덩어리
                     *****************************************/}
          <div className="field grid col-12 md:col-6" css={gridGroupNoMarginStyle}>
            <div className="field col-12 md:col-12">
              <label htmlFor="ReceiverAddress1">주소</label>
              <InputTextWithError
                name="ReceiverAddress1"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ReceiverAddress1 || ''}
                error={formik.errors.ReceiverAddress1}
              />
            </div>
            <div className="field col-12 lg:col-8">
              <label htmlFor="ReceiverAddress2">상세주소</label>
              <InputTextWithError
                name="ReceiverAddress2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ReceiverAddress2 || ''}
                error={formik.errors.ReceiverAddress2}
              />
            </div>
            <div className="field col-12 lg:col-4">
              <label htmlFor="ReceiverZipcode">우편번호</label>
              <InputTextWithError
                name="ReceiverZipcode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ReceiverZipcode || ''}
                error={formik.errors.ReceiverZipcode}
              />
            </div>

          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverMessage">배송 메시지</label>
            <InputTextarea
              name="ReceiverMessage"
              placeholder="배송 메시지"
              rows="7"
              value={formik.values.ReceiverMessage || ''}
              readOnly
            />
          </div>

          {/*****************************************
                     * 개인통관부호 + 배송 메시지 덩어리
                     *****************************************/}
          {/*<div className="field grid col-12 md:col-6" css={gridGroupNoMarginStyle}>*/}
          {/*    <div className="field col-12 md:col-12">*/}
          {/*        <label htmlFor="PersonalCustomCode">개인통관부호</label>*/}
          {/*        <InputTextWithError*/}
          {/*            name="PersonalCustomCode"*/}
          {/*            type="text"*/}
          {/*            onChange={formik.handleChange}*/}
          {/*            value={formik.values.PersonalCustomCode || ''}*/}
          {/*            error={formik.errors.PersonalCustomCode}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*    <div className="field col-12 md:col-12">*/}
          {/*        <label htmlFor="ReceiverMessage">배송 메시지</label>*/}
          {/*        <InputTextarea*/}
          {/*            name="ReceiverMessage"*/}
          {/*            placeholder="배송 메시지"*/}
          {/*            rows="6"*/}
          {/*            value={formik.values.ReceiverMessage || ''}*/}
          {/*            readOnly*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}

          <EnhancedDivider css={dividerStyle} />

          <div className="grid field col-12 md:col-6" css={gridGroupNoMarginStyle}>
            <OrderLocalTrackingNumber
              orderData={orderData}
              css={orderLocalTrackingNumberStyle}
              onSaveOrderLocalTrackingNumber={onSaveOrderLocalTrackingNumber}
            />
          </div>


        </div>
      </form>
    </CardContainer>
  )
}

export default OrderReceiverInfo;


const validate = (values) => {
  const errors = {};

  if (!trim(values.IsReceiverOwn)) {
    errors.IsReceiverOwn = "Required";
  }
  if (!trim(values.ReceiverName)) {
    errors.ReceiverName = "Required";
  }
  if (!trim(values.ReceiverEmail)) {
    errors.ReceiverEmail = "Required";
  } else if (!validateEmail(values.ReceiverEmail)) {
    errors.ReceiverEmail = 'Invalid email';
  }

  if (!values.pickupTypeDropdownItem) {
    errors.pickupTypeDropdownItem = "Required";
  }

  if (!values.isCombineItem) {
    errors.isCombineItem = "Required";
  }

  if (!trim(values.ReceiverPhone)) {
    errors.ReceiverPhone = "Required";
  }
  if (!trim(values.ReceiverMobile)) {
    errors.ReceiverMobile = "Required";
  }
  if (!trim(values.ReceiverAddress1)) {
    errors.ReceiverAddress1 = "Required";
  }
  if (!trim(values.ReceiverZipcode)) {
    errors.ReceiverZipcode = "Required";
  }

  return errors;
}

const gridGroupNoMarginStyle = css`
    margin: 0 0 1rem;
    padding: 0;
`;

const orderLocalTrackingNumberStyle = css`
    width: 100%;
`;

const dividerStyle = css`
    margin: 2rem auto 4rem !important;
`;