import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from "recoil";
import { Dropdown } from "primereact/dropdown";
import { useMileageStatusSearchFilterState } from "./store";
import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";
import FilterLabel from "./FilterLabel";

const useMileageStatusDropdownItems = [
  { Name: '전체', Code: 'All', isDefault: true },
  { Name: 'Yes', Code: 'True' },
  { Name: 'No', Code: 'False' },
];

const UseMileageStatusSearchFilter = ({
  label = "마일리지사용",
  atom = useMileageStatusSearchFilterState,
  supportQueryString = false,
  className = "",
  dropdownItems = useMileageStatusDropdownItems,
}) => {


  const [useMileageStatusSearchFilterValue, setUseMileageStatusSearchFilterValue] = useRecoilState(atom);

  const setQueryStringToState = useMemo(() => {
    return (searchObject) => {
      setUseMileageStatusSearchFilterValue({
        useMileage: searchObject.useMileage,
      });
    }
  }, [setUseMileageStatusSearchFilterValue]);

  useSetQueryStringToState(supportQueryString && setQueryStringToState);

  const setFilterDefaultValue = useCallback((defaultItem) => {
    setUseMileageStatusSearchFilterValue(prev => {
      if (prev.useMileage) return prev;

      return {
        ...prev,
        useMileageStatus: defaultItem.Code,
      }
    });
  }, [setUseMileageStatusSearchFilterValue]);


  useSetFilterDefaultValue({
    items: dropdownItems,
    isAlreadySet: !!useMileageStatusSearchFilterValue.useMileageStatus,
    setFilterDefaultValue,
  });

  const setDropdownItem = useCallback(e => {
    setUseMileageStatusSearchFilterValue({
      useMileageStatus: e.value?.Code
    })
  }, [setUseMileageStatusSearchFilterValue]);


  return (
    <div className={className}>
      <FilterLabel htmlFor={"useMileageStatus"}>{label}</FilterLabel>
      <Dropdown
        name="useMileageStatus"
        value={dropdownItems.find(item => item.Code === useMileageStatusSearchFilterValue.useMileageStatus)}
        onChange={setDropdownItem}
        options={dropdownItems}
        optionLabel="Name"
        placeholder="전체"
      />
    </div>
  )
}

export default UseMileageStatusSearchFilter
