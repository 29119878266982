
/**
 * Convert a hexadecimal integer to a decimal integer.
 *
 * @param {string} hex Hexadecimal integer.
 * @returns {number} Decimal integer.
 */
const hexToInt = hex => parseInt(parseInt(hex, 16).toString(10));

/**
 * Convert a hex colour with preceding "#" to array of rgb.
 *
 * @param hex
 * @returns {[*, *, *]}
 */
const hexToRgb = hex => [hexToInt(hex.substring(1, 3)), hexToInt(hex.substring(3, 5)), hexToInt(hex.substring(5, 7))];

/**
 * Get an rgba colour from a given 6-digit hex colour and an alpha value (defaults to 1).
 *
 * @param {string} colour 6-digit hex rgb colour or a property of the above colours object.
 * @param {number} alpha Alpha channel value.
 * @returns {string} rgba colour.
 */
const rgba = (colour, alpha = 1) => {
    if (typeof colour !== "string") {
        throw Error(`colour argument must be a string, got ${colour}`);
    }
    let hex;
    if (colour[0] === "#") {
        hex = colour;
        if (hex.length !== 7) {
            throw Error(`Hex colour must have length of 7, got ${colour}`);
        }
    } else {
        hex = colour;
        if (!hex) {
            throw Error(`Could not find a defined colour for ${colour}`)
        }
    }

    const rgb = hexToRgb(hex);

    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
};

const utils = {
    hexToInt,
    hexToRgb,
    rgba,
};

export default {
    utils,
};
