import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_MANAGER_ADMIN_URI } from "../../../../includes/constants";

const useManagerAdminUserData = () => {
    const [{ data, loading, error }, getData] = useAxios({
            method: 'GET',
        },
        { manual: true },
    );

    const getManagerAdminUser = useCallback((adminId) => {
        return getData({
            url: `${API_MANAGER_ADMIN_URI}/${adminId}`
        });
    }, [getData]);

    return [
        {
            data,
            loading,
            error,
        },
        getManagerAdminUser,
    ];
}

export default useManagerAdminUserData;