import EnhancedDialog from "./EnhancedDialog";
import React, { useCallback, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { atom, useRecoilState } from "recoil";
import PropTypes from "prop-types";
import { useFormik } from "formik";

import { Button } from "primereact/button";
import { trim } from "lodash";
import InputTextareaWithError from "../forms/InputTextareaWithError";
import useShowConfirm from "../../service/hooks/useShowConfirm";

export const createOrderNoteDialogState = atom({
  key: 'createOrderNoteDialogState',
  default: {
    OrderId: 0,
    OrderNoteId: 0,
    Note: "",
  },
});

const CreateOrderNoteDialog = ({
  onCancel = null,
  onDelete = null,
  onConfirm,
  title = "메모 생성",
  renderConfirm = null, // should be null as default
  renderDeleteConfirm = null, // should be null as default
  shouldShowConfirm = true,
}) => {

  const [state, setState] = useRecoilState(createOrderNoteDialogState);

  const {
    handleDelete,
    handleConfirm,
    renderConfirmDialog,
  } = useShowConfirm({
    renderDeleteConfirm,
    renderConfirm,
    state,
    onCancel,
    onConfirm,
    onDelete,
    shouldShowConfirm,
  });

  const onSaveClicked = useCallback((values) => {
    setState(values);
    handleConfirm();
  }, [setState, handleConfirm]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {
      onSaveClicked(values);
    },
  });

  useEffect(() => {
    // 수정할때 적용되는 값들
    if (state.ChargeId) {
      formik.setValues({
        ...state,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onSubmitForm = useCallback(() => {
    return formik.submitForm();
  }, [formik]);

  const confirmationDialogFooter = useMemo(() => (
    <>
      {onCancel && (
        <Button
          type="button"
          label="Close"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-text"
        />
      )}
      {onDelete && (
        <Button
          type="button"
          label="Delete"
          icon="pi pi-trash"
          onClick={handleDelete}
          className="p-button-danger"
        />
      )}
      <Button
        type="submit"
        label="Yes"
        icon="pi pi-check"
        autoFocus
        onClick={onSubmitForm}
      />
    </>
  ), [onCancel, onDelete, handleDelete, onSubmitForm]);

  return (
    <EnhancedDialog
      title={title}
      onCancel={onCancel}
      css={createOrderNoteDialogStyle}
      footer={confirmationDialogFooter}
      withLoading={false}
    >
      {renderConfirmDialog()}
      <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
        <div className="p-fluid">

          <div className="field grid">
            <label
              htmlFor={"Note"}
              className="col-12 mb-2 md:col-3 md:mb-0"
            >
              메모내용
            </label>

            <InputTextareaWithError
              name="Note"
              placeholder="메모"
              rows="10"
              onChange={formik.handleChange}
              value={formik.values.Note || ''}
              error={formik.errors.Note}
            />
          </div>
        </div>
      </form>
    </EnhancedDialog>
  );
}

CreateOrderNoteDialog.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  renderConfirm: PropTypes.func,
}

export default CreateOrderNoteDialog;

const validate = (values) => {
  const errors = {};

  if (!trim(values.Note)) {
    errors.Note = "Required";
  }

  return errors;
}


const createOrderNoteDialogStyle = css`
    width: 450px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;
