import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import FlightPage from "./FlightPage";
import FlightDetailPage from "./FlightDetailPage";
import { useSetRecoilState } from "recoil";
import { mainPathNameState } from "../../store";

const Flight = () => {
    const setMainPathNameState = useSetRecoilState(mainPathNameState);
    const match = useRouteMatch();
    const mainPathName = match.path;

    useEffect(() => {
        setMainPathNameState(match.path);
    }, [match.path, setMainPathNameState]);

    return (
        <Switch>
            <Route path={`${mainPathName}`} exact component={FlightPage} />
            <Route path={`${mainPathName}/:flightId`} component={FlightDetailPage} />
        </Switch>
    );
}
export default Flight;