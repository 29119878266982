import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { menuState } from "../../service/hooks/useMenus";
import { isEmpty } from "lodash";

const EnhancedBreadCrumb = ({
    toHome = "/",
    model = [],
    showBack = false,
}) => {
    const menuStateValue = useRecoilValue(menuState);
    const history = useHistory();

    const breadcrumbItems = useMemo(() => {
        if (!isEmpty(model)) return model;
        return menuStateValue.currentBreadcrumbItems;
    }, [menuStateValue, model]);

    const getLabel = useCallback((item, index) => {
        if (index + 1 === breadcrumbItems.length) {
            return (<b>{item.label}</b>)
        }

        return item.label;
    }, [breadcrumbItems]);

    return (
        <nav className="p-breadcrumb p-component" aria-label="Breadcrumb" css={navStyle}>
            <ul>
                {showBack && (
                    <Button
                        type="button"
                        icon="pi pi-arrow-left" className="p-button-secondary"
                        onClick={history.goBack}
                        css={buttonStyle}
                        tooltip="Click to back"
                        tooltipOptions={{ position: 'bottom' }}
                    />
                )}

                {toHome && (
                    <>
                        <li className="p-breadcrumb-home">
                            <Link to={toHome} className="p-menuitem-link">
                                <span className="p-menuitem-icon pi pi-home" />
                            </Link>
                        </li>
                        <li className="p-breadcrumb-chevron pi pi-chevron-right" />
                    </>
                )}
                {breadcrumbItems.map((item, index) => (
                    <React.Fragment key={`${index}-${item.label}`}>
                        <li className="">
                            {item.to
                                ? (
                                    <Link
                                        to={item.to}
                                        className="p-menuitem-link"
                                    >
                                        <span className="p-menuitem-text">
                                            {getLabel(item, index)}
                                        </span>
                                    </Link>
                                )
                                : (
                                    <span className="p-menuitem-link">
                                        <span className="p-menuitem-text">
                                            {getLabel(item, index)}
                                        </span>
                                    </span>
                                )
                            }
                        </li>
                        {breadcrumbItems.length - 1 > index && (
                            <li className="p-breadcrumb-chevron pi pi-chevron-right" />
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </nav>
    )
}

EnhancedBreadCrumb.propTypes = {
    showBack: PropTypes.bool,
    toHome: PropTypes.string,
    model: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string,
    })),
}

export default EnhancedBreadCrumb;

const navStyle = css`
    width: 100%;
    border: 0;
    background-color: var(--surface-card);
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%) !important;
`;

const buttonStyle = css`
    margin-right: 1rem;
    height: 100%;
    min-width: 30px;
`;