import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { mainPathNameState } from "../../../store";
import WeightAmountPage from "./WeightAmountPage";

const WeightAmount = () => {
    const setMainPathNameState = useSetRecoilState(mainPathNameState);
    const match = useRouteMatch();
    const mainPathName = match.path;

    useEffect(() => {
        setMainPathNameState(match.path);
    }, [match.path, setMainPathNameState]);

    return (
        <Switch>
            <Route path={`${mainPathName}`} exact component={WeightAmountPage} />
        </Switch>
    );
}
export default WeightAmount;