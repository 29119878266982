import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AwaitButton from "../AwaitButton";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { NOOP } from "../../includes/constants";
import ConfirmDialog from "../dialogs/ConfirmDialog";

const ConfirmActionButton = ({
    icon,
    className = '',
    disabled = false,
    onOkayClicked = NOOP,
    children = "",
    label = "Confirm",
}) => {
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const confirmDialog = useMemo(() => (
        <ConfirmDialog
            onCancel={closeConfirmation}
            onConfirm={confirm}
        >
            {children}
        </ConfirmDialog>
    ), [closeConfirmation, confirm, children]);

    const onButtonClicked = useCallback(() => {
        return openConfirmation(confirmDialog)
            .then(() => {
                onOkayClicked(true);
            }).catch(() => {
                // ignore onCancel
            });
    }, [onOkayClicked, confirmDialog, openConfirmation]);

    return (
        <AwaitButton
            icon={icon}
            type="button"
            className={classNames("", className)}
            onClick={onButtonClicked}
            label={label}
            disabled={disabled}
        />
    )
}

ConfirmActionButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onOkayClicked: PropTypes.func,
    children: PropTypes.node,
    label: PropTypes.string,
}

export default ConfirmActionButton;