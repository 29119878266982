import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import useAxios from "axios-hooks";

import { DataTable } from "primereact/datatable";

import { getQnaPath } from "../../../core/routes/routes";
import { getNewDataBadge } from "../../../includes/datatable";
import { API_BOARD_QNA_URI } from "../../../includes/constants";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";

import TableTitle from "../../../components/layouts/TableTitle";
import CardContainer from "../../../components/layouts/CardContainer";
import DataTableActions from "../../../components/layouts/DataTableActions";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";

const fieldColumnTitleMap = {
    BoardQnaId: "글번호",
    CreateDate: "등록일",
    AdminUserId: "관리자 ID",
    WriterName: "작성자",
    Title: "제목",
    IsActive: "사용여부",
    UserId: "회원 ID",
    IsSecret: "비밀 여부",
    CommentCount: "답변여부"
}

const sortableFields = [];

const RecentQnas = ({
    pageSize = 10,
}) => {

    const [{ data: qnasData, loading: qnasDataLoading }, getQnas] = useAxios({
            url: API_BOARD_QNA_URI,
            method: 'GET',
        },
        { manual: true },
    );

    const {
        renderColumn,
        renderLinkColumn,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getQnas,
        dataSize: qnasData?.Data?.length ?? 0,
    });

    useEffect(() => {
        getQnas({
            url: `${API_BOARD_QNA_URI}?pageNo=1&pageSize=${pageSize}&orderField=CreateDate&orderBy=DESC`
        });
    }, [getQnas, pageSize]);

    const titleSection = useMemo(() => (
        <TableTitle noPadding>
            <h5>최근 Q&A</h5>
        </TableTitle>
    ), []);

    return (
        <CardContainer>
            <DataTableActions className={"mb-2"} titleSection={titleSection}>
                <Link to={"/board/qna"} className={"p-button p-component"}>
                    <span className="p-button-icon p-c pi pi-list p-button-icon-left" />
                    <span className="p-button-label p-c">Q&A 목록</span>
                </Link>
            </DataTableActions>

            <ScrollDataTableContainer loading={qnasDataLoading} minWidth={1024}>
                <DataTable
                    resizableColumns
                    columnResizeMode="fit"
                    loading={qnasDataLoading}
                    value={qnasData?.Data || []}
                    dataKey="BoardQnaId"
                    lazy
                    totalRecords={qnasData?.Paging?.TotalNumberOfRecords ?? 0}
                    className="datatable-responsive"
                    emptyMessage="No data found."
                >
                    {renderLinkColumn("Title", "BoardQnaId", { getLinkPath: getQnaPath })}
                    {renderColumn("WriterName")}
                    {renderColumn("CreateDate", {
                        suffixAppender: getNewDataBadge,
                        headerStyle: { width: '250px' },
                    })}
                    {renderColumn("CommentCount")}
                    {renderColumn("IsSecret")}
                </DataTable>
            </ScrollDataTableContainer>
        </CardContainer>
    );
}

export default RecentQnas;