import React from 'react';
import { useReactPrimeThemeContext } from "../../core/contexts/ReactPrimeThemeContext";

export const AppFooter = (props) => {

    const {
        layoutColorMode,
    } = useReactPrimeThemeContext();

    return (
        <div className="layout-footer">
            <img
                src={layoutColorMode === 'light' ? '/assets/layout/images/kaircargo-logo-dark.svg' : '/assets/layout/images/kaircargo-logo-white.svg'}
                alt="Logo"
                height="20"
                className="mr-2"
            />
        </div>
    );
}
