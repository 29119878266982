import { atom } from "recoil";

export const weightAmountListFilterState = atom({
    key: 'weightAmountListFilterState',
    default: {},
});

export const weightAmountPaginationFilterState = atom({
    key: 'weightAmountPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy: null,
    },
});
