import React, { useCallback, useMemo } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";

import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

import FilterLabel from "./FilterLabel";

const ALL_ACTIVE_STATUS_ITEM = { Name: '전체', Code: "All", isDefault: true };

const replyStatusDropdownItems = [
    ALL_ACTIVE_STATUS_ITEM,
    { Name: '답변완료', Code: "Yes" },
    { Name: '답변전', Code: "No" },
];

export const replyStatusSearchFilterState = atom({
    key: 'replyStatusSearchFilterState',
    default: {
        replyStatus: ALL_ACTIVE_STATUS_ITEM.Code,
    },
});

const ReplyStatusSearchFilter = ({
    label = "답변여부",
    placeholder = "전체",
}) => {
    const setReplyStatusSearchFilterState = useSetRecoilState(replyStatusSearchFilterState);
    const replyStatusSearchFilterValue = useRecoilValue(replyStatusSearchFilterState);
    // const alreadySet = useRef(false);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setReplyStatusSearchFilterState({
                replyStatus: searchObject.replyStatus,
            });
        }
    }, [setReplyStatusSearchFilterState]);

    useSetQueryStringToState(setQueryStringToState);

    const setDropdownItem = useCallback(e => {
        setReplyStatusSearchFilterState({
            replyStatus: e.value?.Code
        })
    }, [setReplyStatusSearchFilterState]);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setReplyStatusSearchFilterState(prev => {
            if (prev.replyStatus) return prev;

            return {
                ...prev,
                replyStatus: defaultItem.Code,
            }
        });
    }, [setReplyStatusSearchFilterState]);

    useSetFilterDefaultValue({
        items: replyStatusDropdownItems,
        isAlreadySet: !!replyStatusSearchFilterValue.replyStatus,
        setFilterDefaultValue,
    });

    // useEffect(() => {
    //     if (alreadySet.current) return;
    //     if (isEmpty(replyStatusDropdownItems)) return;
    //
    //     const defaultSearchFieldItem = replyStatusDropdownItems.find(i => i.isDefault);
    //     if (defaultSearchFieldItem) {
    //         setReplyStatusSearchFilterState(prev => {
    //             if (prev.replyStatus) return prev;
    //
    //             return {
    //                 ...prev,
    //                 replyStatus: defaultSearchFieldItem.Code
    //             }
    //         });
    //     }
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [replyStatusDropdownItems, setReplyStatusSearchFilterState]);

    // useEffect(() => {
    //     if (alreadySet.current) return;
    //
    //     if (replyStatusSearchFilterValue.replyStatus) {
    //         alreadySet.current = true;
    //     }
    // }, [replyStatusSearchFilterValue]);

    return (
        <div className="grid flex-grow-1" css={searchGroupStyle}>
            <FilterLabel htmlFor={"replyStatus"}>{label}</FilterLabel>
            <Dropdown
                name="replyStatus"
                value={replyStatusDropdownItems.find(item => item.Code === replyStatusSearchFilterValue.replyStatus)}
                onChange={setDropdownItem}
                options={replyStatusDropdownItems}
                optionLabel="Name"
                placeholder={placeholder}
            />
        </div>
    )
}

export default ReplyStatusSearchFilter;

const searchGroupStyle = theme => css`
    display: flex;
    align-items: center;
    margin: 0;

    ${theme.breakpoints.down("xs")} {

        > * {
            width: 100%;
            margin: 2px 0;
            padding: 0;
        }
    }
`;

