import React, { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { isEmpty, throttle } from "lodash";
import { css } from "@emotion/react";
import { DataTable } from "primereact/datatable";

import { selectedConsignmentsState } from "./store";
import { DEFAULT_PAGE_SIZE, getPromise, NOOP, rowsPerPageOptions } from "../../includes/constants";
import useConsignmentsData from "./hooks/useConsignmentsData";
import useDataTableExportCSV from "../../core/hooks/useDataTableExportCSV";
import useDataTableColumns from "../../core/hooks/useDataTableColumns";
import useDeleteConsignment from "../../service/hooks/useDeleteConsignment";
import useCreateActionButton from "../../service/hooks/useCreateActionButton";
import { getFlightPath, getOrderPath } from "../../core/routes/routes";

import AwaitButton from "../../components/AwaitButton";
import DataTableActions from "../../components/layouts/DataTableActions";
import ConsignmentPageFilter from "./components/ConsignmentPageFilter";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import PrintConsignmentDialog from "./components/PrintConsignmentDialog";
import ScrollDataTableContainer from "../../components/layouts/ScrollDataTableContainer";

const fieldColumnTitleMap = {
    ConsignmentId: "송장 ID",
    ConsignmentNumber: "송장번호",
    OrderId: "주문 ID",
    OrderNumber: "주문번호",
    UserName: "주문자",
    CreateDate: "주문일",
    TotalPackageCount: "아이템 갯수",
    FlightId: "운항 ID",
    FlightName: "운항명",
    DeliveryStatusCode: "배송상태 코드",
    DeliveryStatusName: "배송상태",
    TotalWeight: "무게(kg)",
    TotalVolumeWeight: "부피무게(kg)",
}

const sortableFields = [
    "ConsignmentId",
    "UserName",
    "OrderNumber",
    "ConsignmentNumber",
    "CreateDate",
    "OrderDate",
];

const ConsignmentPage = () => {
    const [selectedItems, setSelectedItems] = useRecoilState(selectedConsignmentsState);
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: consignmentsData, loading: consignmentsDataLoading }, getConsignments] = useConsignmentsData();

    const onSearch = useMemo(() => {
        return throttle((e) => {
            if (consignmentsDataLoading) return getPromise();
            return getConsignments();
        }, 1000);
    }, [getConsignments, consignmentsDataLoading]);

    const {
        sortPaginationProps,
        renderColumn,
        renderLinkColumn,
        renderCheckboxColumn
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getConsignments,
        dataSize: consignmentsData?.Data?.length ?? 0,
    });

    const renderDialog = useCallback(({
        onCancel,
        onConfirm,
        renderConfirm,
    }) => {
        const consignmentIds = selectedItems.map(item => item.ConsignmentId);
        return (
            <PrintConsignmentDialog
                onCancel={onCancel}
                onConfirm={onConfirm}
                title="송장출력"
                consignmentIds={consignmentIds}
            />
        )
    }, [selectedItems]);

    const onCreateClicked = useCreateActionButton({
        onSelect: NOOP,
        confirmMessage: "선택한 송장들을 일괄 출력하시겠습니까?",
        renderDialog: renderDialog
    });

    /***************************************************
     * 송장 삭제
     ***************************************************/
    const refresh = useCallback(() => {
        return onSearch().finally(() => {
            setSelectedItems([]);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch]);

    const onConfirmDeleteConsignment = useDeleteConsignment({
        callbackAfterSuccess: refresh,
        consignmentId: selectedItems?.[0]?.ConsignmentId,
        successMessage: "송장을 성공적으로 삭제하였습니다.",
    });

    return (
        <div className="grid" css={consignmentPageStyle}>
            <div className="col-12">

                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>송장 리스트</h5>
                    <ConsignmentPageFilter onSearch={onSearch} loading={consignmentsDataLoading} />
                </div>

                <div className="card">
                    <DataTableActions className={"mb-2"}>
                        <AwaitButton
                            icon="pi pi-trash"
                            type="button"
                            className={"mr-2"}
                            buttonClassName={"p-button-danger"}
                            onClick={onConfirmDeleteConsignment}
                            label={"삭제"}
                            disabled={!(selectedItems?.length === 1)}
                        />
                        <AwaitButton
                            icon="pi pi-print"
                            type="button"
                            onClick={onCreateClicked}
                            label={"일괄출력"}
                            disabled={isEmpty(selectedItems)}
                        />
                    </DataTableActions>

                    <ScrollDataTableContainer loading={consignmentsDataLoading} minWidth={1400}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={consignmentsDataLoading}
                            ref={dataTableRef}
                            value={consignmentsData?.Data || []}
                            selection={selectedItems}
                            onSelectionChange={(e) => setSelectedItems(e.value)}
                            dataKey="ConsignmentId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={consignmentsData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={consignmentsData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                            selectionMode="checkbox"
                        >
                            {renderCheckboxColumn()}
                            {renderColumn("ConsignmentNumber")}
                            {renderLinkColumn("OrderNumber", "OrderId", { getLinkPath: getOrderPath })}
                            {renderColumn("UserName")}
                            {renderColumn("CreateDate")}
                            {renderColumn("TotalPackageCount")}
                            {renderLinkColumn("FlightName", "FlightId", { getLinkPath: getFlightPath })}
                            {renderColumn("DeliveryStatusName")}
                            {renderColumn("TotalWeight")}
                            {renderColumn("TotalVolumeWeight")}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}

export default ConsignmentPage;

const consignmentPageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalWeight,
        &.header-column-TotalVolumeWeight,
        &.header-column-TotalPackageCount {
            text-align: right;
        }

        &.header-column-TotalWeight,
        &.header-column-TotalVolumeWeight,
        &.header-column-TotalPackageCount {
            width: 120px;

            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-TotalWeight,
    .column-TotalVolumeWeight,
    .column-TotalPackageCount {
        text-align: right;
        padding-right: 20px;
    }
`;