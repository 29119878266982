import { useCallback, useRef } from "react";

const useDataTableExportCSV = () => {
    const dataTableRef = useRef(null);

    const exportCSV = useCallback(() => {
        dataTableRef.current.exportCSV();
    }, []);

    return {
        dataTableRef,
        exportCSV,
    }
}

export default useDataTableExportCSV;