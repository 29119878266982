import React, { useMemo } from "react";
import { formatFromDate, stringToDate } from "../../../../includes/dates";
import { css } from "@emotion/react";

const Footer = ({
    consignment,
}) => {

    const formattedDate = useMemo(() => {
        const date = stringToDate(consignment.CreateDate, "DD/MM/YYYY");
        return formatFromDate(date, "ddd DD MMM YYYY");
    }, [consignment]);

    return (
        <div css={footerContainerStyle}>
            {formattedDate}
        </div>
    );
}

export default Footer;

const footerContainerStyle = css`
    padding: 10px;
    text-align: left;
`;