import React, { useCallback, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { trim } from "lodash";
import { validate as validateEmail } from "email-validator";

import { toBooleanValue } from "../../../../includes/ajax";
import { NOOP } from "../../../../includes/constants";

import AwaitButton from "../../../../components/AwaitButton";
import InputTextWithError from "../../../../components/forms/InputTextWithError";
import DropdownWithError from "../../../../components/forms/DropdownWithError";
import HiddenFormField from "../../../../components/forms/HiddenFormField";
import { adminRoleIdSearchFilterDropdownItems } from "../../../../components/filters/AdminRoleIdSearchFilter";
import { Checkbox } from "primereact/checkbox";
import IsActiveRadioButtons from "../../../../components/forms/IsActiveRadioButtons";

const ManagerAdminUserInfo = ({
    onSaveManagerAdminUserInfo = NOOP,
    managerAdminUserData = null,
}) => {
    const hasAlreadyTriedToSave = useRef(false);

    const tryToSave = useCallback(() => {
        hasAlreadyTriedToSave.current = true;
    }, []);

    const deliveryLocationData = adminRoleIdSearchFilterDropdownItems;

    const onSaveClicked = useCallback((values) => {
        return onSaveManagerAdminUserInfo(values);
    }, [onSaveManagerAdminUserInfo]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            const newValues = {
                ...values,
                AdminRoleId: values.adminRoleIdItem.Code,
            };

            delete newValues.adminRoleIdItem;

            if (!values.IsChangePassword) {
                delete newValues.AdminPassword;
            }

            onSaveClicked(newValues);
        },
    });

    const resetForm = useCallback(() => {
        formik.setValues({
            AdminUserId: managerAdminUserData?.AdminUserId ?? 0,
            IsChangePassword: managerAdminUserData?.IsChangePassword ?? (managerAdminUserData?.AdminUserId ? false : true),
            AdminPassword: managerAdminUserData?.AdminUserId ? '' : (managerAdminUserData?.AdminPassword || ''),
            AdminName: managerAdminUserData?.AdminName || '',
            EmailAddress: managerAdminUserData?.EmailAddress || '',
            IsActive: toBooleanValue(managerAdminUserData?.IsActive, true),
            AdminRoleId: managerAdminUserData?.AdminRoleId || '',
            PhoneNumber: managerAdminUserData?.PhoneNumber || '',
            MobileNumber: managerAdminUserData?.MobileNumber || '',
            adminRoleIdItem: deliveryLocationData.find(item => item.Code === Number(managerAdminUserData?.AdminRoleId ?? 0)),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managerAdminUserData]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
                <AwaitButton
                    buttonClassName={"p-button-secondary"}
                    icon="pi pi-replay"
                    css={buttonStyle}
                    label={"리셋"}
                    type="button"
                    onClick={resetForm}
                />
                <AwaitButton
                    icon="pi pi-check"
                    className={"mb-5"}
                    label={"저장"}
                    type="submit"
                    onClick={tryToSave}
                    css={buttonStyle}
                />

                <div className="p-fluid formgrid grid" css={maxWidthStyle}>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="AdminName">관리자명</label>
                        <InputTextWithError
                            name="AdminName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.AdminName || ''}
                            error={formik.errors.AdminName}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="EmailAddress">이메일</label>
                        <InputTextWithError
                            name="EmailAddress"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.EmailAddress || ''}
                            error={formik.errors.EmailAddress}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="AdminPassword">비밀번호</label>
                        <InputTextWithError
                            name="AdminPassword"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.AdminPassword || ''}
                            error={formik.errors.AdminPassword}
                            readOnly={!formik.values.IsChangePassword}
                        />
                    </div>

                    {formik.values.AdminUserId
                        ? (
                            <div className="field col-12 md:col-6 field-checkbox" css={checkboxContainerStyle}>
                                <Checkbox
                                    inputId="IsChangePassword"
                                    name="IsChangePassword"
                                    value={formik.values.IsChangePassword}
                                    onChange={formik.handleChange}
                                    checked={formik.values.IsChangePassword}
                                />
                                <label
                                    htmlFor="IsChangePassword"
                                    className="p-checkbox-label"
                                    css={checkboxLabelStyle}
                                >
                                    비밀번호 수정
                                </label>
                            </div>
                        )
                        : (
                            <HiddenFormField />
                        )
                    }


                    <div className="field col-12 md:col-6">
                        <label htmlFor="PhoneNumber">전화번호</label>
                        <InputTextWithError
                            name="PhoneNumber"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.PhoneNumber || ''}
                            error={formik.errors.PhoneNumber}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="MobileNumber">휴대폰</label>
                        <InputTextWithError
                            name="MobileNumber"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.MobileNumber || ''}
                            error={formik.errors.MobileNumber}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="adminRoleIdItem">권한</label>
                        <DropdownWithError
                            name="adminRoleIdItem"
                            options={deliveryLocationData}
                            optionLabel="Name"
                            placeholder="Select One"
                            onChange={formik.handleChange}
                            value={deliveryLocationData.find(item => item.Code === formik.values.adminRoleIdItem?.Code)}
                            error={formik.errors.adminRoleIdItem}
                        />
                    </div>
                    <HiddenFormField />

                    <div className="field col-12 md:col-6">
                        <label htmlFor="IsActive">사용여부</label>
                        <IsActiveRadioButtons
                            isActive={toBooleanValue(formik.values.IsActive)}
                            handleChange={formik.handleChange}
                        />
                    </div>
                    <HiddenFormField />
                </div>
            </form>
        </div>
    )
}

export default ManagerAdminUserInfo;

const validate = (values) => {
    const errors = {};

    if (!trim(values.AdminName)) {
        errors.AdminName = "Required";
    }

    if (!trim(values.EmailAddress)) {
        errors.EmailAddress = "Required";
    } else if (!validateEmail(values.EmailAddress)) {
        errors.EmailAddress = 'Invalid email';
    }

    if (values.IsChangePassword) {
        if (!trim(values.AdminPassword)) {
            errors.AdminPassword = "Required";
        } else if (trim(values.AdminPassword).length < 8) {
            errors.AdminPassword = "Password must be large than 8 characters";
        }
    }

    if (!trim(values.adminRoleIdItem)) {
        errors.adminRoleIdItem = "Required";
    }

    if (!trim(values.IsActive)) {
        errors.IsActive = "Required";
    }

    if (!trim(values.MobileNumber)) {
        errors.MobileNumber = "Required";
    }

    if (!trim(values.PhoneNumber)) {
        errors.PhoneNumber = "Required";
    }

    return errors;
}

const buttonStyle = css`
    margin-right: 10px;
`;

const maxWidthStyle = css`
    max-width: 800px;
`;

const checkboxLabelStyle = css`
    cursor: pointer;
    margin-left: 0.5rem;
    margin-bottom: 0 !important;
    user-select: none;
`;

const checkboxContainerStyle = theme => css`
    &.field.col-12 {
        padding-top: 20px;
        display: flex;
        align-items: center;

        ${theme.breakpoints.up("md")} {
            height: 60px;
        }

        ${theme.breakpoints.down("sm")} {
            padding-top: 0;
            margin-bottom: 2rem;
        }
    }
`;