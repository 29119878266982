import React, { useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";

import { invoiceListFilterState, invoicePaginationFilterState } from "../store";
import { NOOP } from "../../../includes/constants";

import AwaitButton from "../../../components/AwaitButton";
import FilterContainer from "../../../components/filters/FilterContainer";
import PaginationFilter from "../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../components/filters/PageFilterContainer";
import FilterContainerGroups from "../../../components/filters/FilterContainerGroups";
import FilterActionContainer from "../../../components/filters/FilterActionContainer";
import TextFieldSearchFilter from "../../../components/filters/TextFieldSearchFilter";
import FlightSelectSearchFilter from "../../../components/filters/FlightSelectSearchFilter";
import { flightSelectSearchFilterState } from "../../../components/filters/store";
import DateSearchFilter, { dateSearchFilterState } from "../../../components/filters/DateSearchFilter";
import PaymentStatusSearchFilter, { paymentStatusSearchFilterState } from "../../../components/filters/PaymentStatusSearchFilter";

const searchFieldItems = [
    { name: '발행일', searchDateField: 'InvoiceDate', isDefault: true },
    { name: '주문일', searchDateField: 'OrderDate' },
];

const textFieldSearchDropdownItems = [
    { name: '주문자명', searchField: 'UserName', isDefault: true },
    { name: '인보이스번호', searchField: 'InvoiceNumber' },
    { name: '주문번호', searchField: 'OrderNumber' },
    { name: '수령자명', searchField: 'ReceiverName' },
];

const paymentStatusSearchDropdownItems = [
    { Name: '전체', Code: 'All', isDefault: true },
    { Name: '입금완료', Code: 'Payment' },
    { Name: '입금전', Code: 'Unpayment' },
];

export const invoiceSearchFilterState = atom({
    key: 'invoiceSearchFilterState',
    default: {
        searchField: textFieldSearchDropdownItems[0].searchField,
        searchStr: "",
    },
});

const InvoicePageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(invoiceListFilterState);
    const invoiceSearchFilterValue = useRecoilValue(invoiceSearchFilterState);
    const paginationFilterValue = useRecoilValue(invoicePaginationFilterState);
    const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
    const flightSelectSearchFilterValue = useRecoilValue(flightSelectSearchFilterState);
    const paymentStatusSearchFilterValue = useRecoilValue(paymentStatusSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...invoiceSearchFilterValue,
            }
        });
    }, [invoiceSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...dateSearchFilterValue,
            }
        })
    }, [dateSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...flightSelectSearchFilterValue,
            }
        })
    }, [flightSelectSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paymentStatusSearchFilterValue,
            }
        })
    }, [paymentStatusSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString atom={invoicePaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer className={"align-items-start"}>
                    <TextFieldSearchFilter
                        atom={invoiceSearchFilterState}
                        label={"인보이스 검색"}
                        dropdownItems={textFieldSearchDropdownItems}
                        supportQueryString
                        onSearch={onSearch}
                    />
                </FilterContainer>
                <FilterContainer>
                    <DateSearchFilter searchFieldItems={searchFieldItems} />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterContainerGroups>
                <FilterContainer>
                    <PaymentStatusSearchFilter
                        label={"인보이스 상태"}
                        dropdownItems={paymentStatusSearchDropdownItems}
                    />
                </FilterContainer>
                <FilterContainer>
                    <FlightSelectSearchFilter
                        state={flightSelectSearchFilterState}
                        supportQueryString
                    />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

export default InvoicePageFilter;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;
