import React, { useEffect, useMemo } from "react";
import { EmptyPage } from "../../../EmptyPage";
import { useRecoilState } from "recoil";
import { throttle } from "lodash";

import { DataTable } from "primereact/datatable";

import { flightOrdersListFilterState, selectedFlightOrderItemsState } from "./store";
import { DEFAULT_PAGE_SIZE, rowsPerPageOptions } from "../../../../includes/constants";
import useOrdersData from "../../../Order/hooks/useOrdersData";

import useDataTableExportCSV from "../../../../core/hooks/useDataTableExportCSV";
import useDataTableColumns from "../../../../core/hooks/useDataTableColumns";
import ScrollDataTableContainer from "../../../../components/layouts/ScrollDataTableContainer";
import FlightOrdersPageFilter from "./FlightOrdersPageFilter";
import Spacer from "../../../../components/layouts/Spacer";
import FlightOrdersPageDataTableActions from "./FlightOrdersPageDataTableActions";
import EnhancedDivider from "../../../../components/layouts/EnhancedDivider";

const fieldColumnTitleMap = {
    ConsignmentNumber: "송장번호", // K택배 내부 송장번호
    DeliveryLocationCode: "배송국가 코드",
    DeliveryLocationName: "배송국가",
    DeliveryStatusCode: "배송상태 코드",
    DeliveryStatusName: "배송상태",
    DeliveryTypeCode: "배송타입 코드",
    DeliveryTypeName: "배송타입",
    FlightId: "운항 ID",
    FlightName: "운항명",
    IsAllItemEntered: "상품입고",
    LocalTrackingNumber: "배송국가 트래킹번호",
    OrderDate: "주문일",
    OrderNumber: "주문번호",
    PersonalCustomCode: "개인통관부호",
    PickupTypeCode: "수령방법코드",
    ReceiverAddress1: "수령자명 주소1",
    ReceiverAddress2: "수령자명 주소2",
    ReceiverMobile: "수령자명 휴대폰",
    ReceiverName: "수령자명",
    ReceiverPhone: "수령자 전화번호",
    ReceiverZipcode: "수령자명 우편번호",
    TotalChargeAmount: "결제금액", // 고객이 내야할 금액
    TotalExchangeItemPrice: "운송 및 세금",
    TotalItemCount: "전체 상품수",
    TotalItemAmount: "총상품가격(KRW)",
    TotalExchangeItemAmount: "총상품가격(NZD)",
    TotalPaidAmount: "전체 입금금액",
    TotalVolumeWeight: "전체 부피무게",
    TotalWeight: "무게",
    UserId: "주문자 ID",
    UserName: "주문자",
}

const sortableFields = [
    "OrderId",
    "OrderNumber",
    "UserName",
    "OrderDate",
    "TotalItemAmount",
    "TotalWeight",
    "TotalPaymentAmount",
];

const FlightOrders = ({
    flightId
}) => {

    const [selectedOrderItems, setSelectedOrderItems] = useRecoilState(selectedFlightOrderItemsState);
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: ordersData, loading: ordersDataLoading }, getOrders] = useOrdersData({
        flightId,
        atom: flightOrdersListFilterState,
    });

    useEffect(() => {
        setSelectedOrderItems([]);
    }, [setSelectedOrderItems]);

    const onSearch = useMemo(() => {
        return throttle((e) => {
            return getOrders();
        }, 1000);
    }, [getOrders]);

    const {
        sortPaginationProps,
        renderColumn,
        renderLinkColumn,
        renderCheckboxColumn
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getOrders,
        dataSize: ordersData?.Data?.length ?? 0,
    });

    if (!flightId) {
        return <EmptyPage />
    }

    return (
        <div className="">

            <FlightOrdersPageFilter onSearch={onSearch} loading={ordersDataLoading} />

            <Spacer />
            <EnhancedDivider />
            <Spacer />

            <FlightOrdersPageDataTableActions refresh={getOrders} />

            <ScrollDataTableContainer loading={ordersDataLoading} minWidth={1700}>
                <DataTable
                    resizableColumns
                    columnResizeMode="fit"
                    loading={ordersDataLoading}
                    ref={dataTableRef}
                    value={ordersData?.Data || []}
                    selection={selectedOrderItems}
                    onSelectionChange={(e) => setSelectedOrderItems(e.value)}
                    dataKey="OrderId"
                    paginator
                    {...sortPaginationProps}
                    lazy
                    totalRecords={ordersData?.Paging?.TotalNumberOfRecords}
                    rows={ordersData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                    rowsPerPageOptions={rowsPerPageOptions}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    emptyMessage="No data found."
                    selectionMode="checkbox"
                >
                    {renderCheckboxColumn()}
                    {renderLinkColumn("OrderNumber", "OrderId", { target: "_blank", rel: "noreferrer noopener" })}
                    {renderColumn("UserName")}
                    {renderColumn("OrderDate")}
                    {renderColumn("IsAllItemEntered")}
                    {renderColumn("FlightName")}
                    {renderColumn("DeliveryLocationName")}
                    {renderColumn("DeliveryStatusName")}
                    {renderColumn("TotalItemAmount")}
                    {renderColumn("TotalExchangeItemAmount")}
                    {renderColumn("TotalWeight")}
                    {renderColumn("TotalExchangeItemPrice")}
                    {renderColumn("TotalChargeAmount")}
                    {renderColumn("ConsignmentNumber")}
                </DataTable>
            </ScrollDataTableContainer>
        </div>
    );
}

export default FlightOrders;