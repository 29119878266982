import React from "react";
import { css } from "@emotion/react";
import { RadioButton } from "primereact/radiobutton";
import { NOOP } from "../../includes/constants";

const IsActiveRadioButtons = ({
    name = "IsActive",
    isActive = false,
    handleChange = NOOP,
}) => {
    return (
        <div className="grid col-12" css={clearMarginOfGridStyle}>
            <div className="col-12 md:col-6">
                <div className="field-radiobutton">
                    <RadioButton
                        inputId={`${name}True`}
                        name={name}
                        value={true}
                        checked={isActive}
                        onChange={handleChange}
                    />
                    <label
                        htmlFor={`${name}True`}
                        css={hoverClickableStyle}
                    >
                        사용
                    </label>
                </div>
            </div>
            <div className="col-12 md:col-6">
                <div className="field-radiobutton">
                    <RadioButton
                        inputId={`${name}False`}
                        name={name}
                        value={false}
                        checked={!isActive}
                        onChange={handleChange}
                    />
                    <label
                        htmlFor={`${name}False`}
                        css={hoverClickableStyle}
                    >
                        미사용
                    </label>
                </div>
            </div>
        </div>
    )
}

export default IsActiveRadioButtons;


const clearMarginOfGridStyle = css`
    margin: 0;
    max-width: 200px;
`;

const hoverClickableStyle = css`
    cursor: pointer;
`;


