import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash";
import { css } from "@emotion/react";
import { DataTable } from "primereact/datatable";
import { API_PURCHASE_ORDER_ITEM_URI, API_PURCHASE_ORDER_ITEM_BATCH_ORDER_URI,API_PURCHASE_ORDER_ITEM_BATCH_PAID_URI, NOOP } from "../../../includes/constants";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import useAPI from "../../../core/hooks/useAPI";

import Loading from "../../../components/loading/Loading";
import DataTableActions from "../../../components/layouts/DataTableActions";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import TableTitle from "../../../components/layouts/TableTitle";
import CardContainer from "../../../components/layouts/CardContainer";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";
import CreatePurchaseOrderItemDialog, { createPurchaseOrderItemDialogState } from "../../../components/dialogs/CreatePurchaseOrderItemDialog";
import PurchaseOrderItemOrderDialog, { purchaseOrderItemOrderDialogState } from "../../../components/dialogs/PurchaseOrderItemOrderDialog";
import PurchaseOrderItemPaidDialog, { purchaseOrderItemPaidDialogState } from "../../../components/dialogs/PurchaseOrderItemPaidDialog";


const fieldColumnTitleMap = {
  PurchaseOrderItemId: "주문상품 ID",
  OrderedDate: "주문일",
  IsOrdered: "주문여부",
  ItemQuantity: "상품수량",
  ItemName: "상품명",
  ItemUrl: "상품 URL",
  ItemAmount: "상품 가격",
  DeliveryLocationCode: "배송지역 코드",
  DeliveryLocationName: "배송지역",
  OrderNote: "상품 Comment",
  DeliveryCompany: "배송회사",
  EnteredDate: "입고일",
  IsAddedToInvoice: "인보이스 추가 여부",
  IsEntered: "입고여부",
  TrackingNumber: "송장번호",
  ItemTypeName: "아이템 타입",
  IsPaid: '결제여부'

}

const PurchaseOrderItemList = ({
  refresh = NOOP,
  purchaseOrderData = null,
}) => {
  const { closeConfirmation } = useAwaitingDialogContext();
  const [selectedItems, setSelectedItems] = useState([]);
  const [, setCreatePurchaseOrderItemDialogState] = useRecoilState(createPurchaseOrderItemDialogState);

  useEffect(() => {
    setCreatePurchaseOrderItemDialogState(prev => {

      return {
        ...prev,
        DeliveryLocationCode: purchaseOrderData.DeliveryLocationCode,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderData]);

  const {
    renderColumn,
    renderLinkColumn,
    rowClassName,
    getSummaryData,
    renderCheckboxColumn,
  } = useDataTableColumns({
    fieldColumnTitleMap,
    getData: NOOP,
    dataSize: (purchaseOrderData?.PurchaseOrderItemList?.length ?? -1) + 1,
  });

  const titleSection = useMemo(() => (
    <TableTitle linkName={"PurchaseOrderItemList"}>
      <h5>상품정보</h5>
    </TableTitle>
  ), []);

  /***********************************************************
   * 상품 추가
   ***********************************************************/
  const createOrderItem = useAPI({
    url: `${API_PURCHASE_ORDER_ITEM_URI}`,
    method: 'POST',
    successMessage: "상품을 성공적으로 저장하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onCreateOrderItem = useCallback((orderItemData) => {
    if (orderItemData) {
      createOrderItem({
        data: {
          OrderId: purchaseOrderData.OrderId,
          // OrderItemId: orderItemData.orderItemId,
          ItemTypeCode: orderItemData.ItemTypeCode,
          ItemName: orderItemData.ItemName,
          ItemQuantity: orderItemData.ItemQuantity,
          ItemUrl: orderItemData.ItemUrl,
          ItemAmount: orderItemData.ItemAmount,
          DeliveryCompany: orderItemData.DeliveryCompany,
          TrackingNumber: orderItemData.TrackingNumber || '',
          OrderNote: orderItemData.OrderNote || ''
        }
      });
    } else {
      closeConfirmation();
    }
  }, [createOrderItem, closeConfirmation, purchaseOrderData]);

  /***********************************************************
   * 상품 삭제
   ***********************************************************/
  const deleteOrderItem = useAPI({
    url: API_PURCHASE_ORDER_ITEM_URI,
    method: 'DELETE',
    successMessage: "상품을 성공적으로 삭제하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onDeleteItem = useCallback((orderChargeData) => {
    if (orderChargeData) {
      deleteOrderItem({
        url: `${API_PURCHASE_ORDER_ITEM_URI}/${orderChargeData.PurchaseOrderItemId}`,
      });
    } else {
      closeConfirmation();
    }
  }, [deleteOrderItem, closeConfirmation]);

  /***********************************************************
   * 상품 수정
   ***********************************************************/
  const updateOrderItem = useAPI({
    url: API_PURCHASE_ORDER_ITEM_URI,
    method: 'PUT',
    successMessage: "상품을 성공적으로 수정하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onUpdateItem = useCallback((orderItemData) => {
    if (orderItemData) {
      updateOrderItem({
        url: `${API_PURCHASE_ORDER_ITEM_URI}/${orderItemData.PurchaseOrderItemId}`,
        data: {
          OrderId: orderItemData.OrderId,
          // OrderItemId: orderItemData.OrderItemId,
          ItemTypeCode: orderItemData.ItemTypeCode,
          ItemName: orderItemData.ItemName,
          ItemQuantity: orderItemData.ItemQuantity,
          ItemUrl: orderItemData.ItemUrl,
          ItemAmount: orderItemData.ItemAmount,
          DeliveryCompany: orderItemData.DeliveryCompany,
          TrackingNumber: orderItemData.TrackingNumber || '',
          OrderNote: orderItemData.OrderNote || ''

        }
      });
    } else {
      closeConfirmation();
    }
  }, [updateOrderItem, closeConfirmation]);

  const onUpdateClicked = useCreateActionButton({
    onSelect: onUpdateItem,
    confirmMessage: "상품을 수정하시겠습니까?",
    deleteConfirmMessage: "상품을 삭제하시겠습니까?",
    onDelete: onDeleteItem,
    atom: createPurchaseOrderItemDialogState,
    // readOnly: !!purchaseOrderData?.ConsignmentNumber,
    dialogComponent: (props) => {
      return (
        <CreatePurchaseOrderItemDialog title={"상품 수정"} {...props} />
      );
    },
  });


  /***********************************************************
   * 상품 주문처리
   ***********************************************************/
  const orderItemOrdered = useAPI({
    url: API_PURCHASE_ORDER_ITEM_BATCH_ORDER_URI,
    method: 'POST',
    successMessage: "주문처리를 완료 하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onOrderedOrderItem = useCallback((purchaseOrderItemData) => {

    if (purchaseOrderItemData) {
      orderItemOrdered({
        data: {
          PurchaseOrderItemIds: selectedItems.map(i => i.PurchaseOrderItemId),
          IsOrdered: purchaseOrderItemData.IsOrdered,
        }
      });
    } else {
      closeConfirmation();
    }
  }, [orderItemOrdered, closeConfirmation, selectedItems]);

/***********************************************************
   * 상품 결제여부처리
   ***********************************************************/
const orderItemPaid = useAPI({
  url: API_PURCHASE_ORDER_ITEM_BATCH_PAID_URI,
  method: 'POST',
  successMessage: "결제처리를 완료 하였습니다.",
  callbackAfterSuccess: refresh,
});

const onPaidOrderItem = useCallback((purchaseOrderItemData) => {

  if (purchaseOrderItemData) {
    orderItemPaid({
      data: {
        PurchaseOrderItemIds: selectedItems.map(i => i.PurchaseOrderItemId),
        IsPaid: purchaseOrderItemData.IsPaid,
      }
    });
  } else {
    closeConfirmation();
  }
}, [orderItemPaid, closeConfirmation, selectedItems]);
  /***********************************************************
   * 상품 리스트
   ***********************************************************/
  const purchaseOrderItemList = useMemo(() => {
    const list = purchaseOrderData?.PurchaseOrderItemList;
    if (isEmpty(list)) {
      return [];
    }



    return [
      ...list,
      {
        DeliveryLocationCode: purchaseOrderData?.DeliveryLocationCode,
      },
    ]
  }, [purchaseOrderData, getSummaryData]);

  const onOrderItemClicked = (e, rowData) => {
    setCreatePurchaseOrderItemDialogState(rowData);
    onUpdateClicked().finally();
  }


  return (
    <CardContainer css={orderItemListStyle}>
      {!purchaseOrderData && <Loading />}

{  !purchaseOrderData?.ConsignmentNumber &&
    <DataTableActions className={"mb-2"} titleSection={titleSection}>
      <CreateActionButton
              label="주문처리"
              icon={"pi pi-calendar-times"}
              onSelect={onOrderedOrderItem}
              confirmMessage={""}
              atom={purchaseOrderItemOrderDialogState}
              dialogComponent={PurchaseOrderItemOrderDialog}
              shouldShowConfirm={false}
              disabled={isEmpty(selectedItems)}
        />
         <CreateActionButton
              label="결제처리"
              icon={"pi pi-calendar-times"}
              onSelect={onPaidOrderItem}
              confirmMessage={""}
              atom={purchaseOrderItemPaidDialogState}
              dialogComponent={PurchaseOrderItemPaidDialog}
              shouldShowConfirm={false}
              disabled={isEmpty(selectedItems)}
        />
      <CreateActionButton
        label="상품추가"
        onSelect={onCreateOrderItem}
        confirmMessage={"상품을 추가하시겠습니까?"}
        atom={createPurchaseOrderItemDialogState}
        dialogComponent={CreatePurchaseOrderItemDialog}
        shouldShowConfirm={false}
      />
    </DataTableActions>}

      
      <ScrollDataTableContainer loading={!purchaseOrderData} minWidth={1200}>
        <DataTable
          resizableColumns
          columnResizeMode="fit"
          rowClassName={rowClassName}
          loading={!purchaseOrderData}
          value={purchaseOrderItemList}
          selection={selectedItems}
          onSelectionChange={(e) => setSelectedItems(e.value)}
          dataKey="PurchaseOrderItemId"
          className="datatable-responsive"
          emptyMessage="No data found."
          selectionMode="checkbox"
          isDataSelectable={e => { return e.data.PurchaseOrderItemId ? true : false }}
        >
          {renderCheckboxColumn()}
          {renderLinkColumn("ItemName", "PurchaseOrderItemId", { onClick: onOrderItemClicked })}
          {renderLinkColumn("ItemTypeName", "PurchaseOrderItemId", { onClick: onOrderItemClicked })}
          {renderColumn("DeliveryCompany")}
          {renderColumn("TrackingNumber")}
          {renderColumn("IsPaid")}
          {renderColumn("IsEntered")}
          {renderColumn("IsOrdered")}
          {renderColumn("ItemQuantity")}
          {renderColumn("ItemAmount")}
          {renderColumn("ItemUrl")}
          {renderColumn("OrderNote")}



        </DataTable>
      </ScrollDataTableContainer>
    </CardContainer>
  );
}

export default PurchaseOrderItemList;

const orderItemListStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-ItemQuantity,
        &.header-column-ItemAmount,
        &.header-column-ItemUrl {
            text-align: left;

            .p-column-title {
                padding-right: 20px;
            }
        }

        
    }

    
    .column-ItemQuantity,
    .column-ItemAmount,
    .column-ItemUrl {
        text-align: left;
        padding-right: 20px;
    }
`;
