import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";

import { TabPanel } from "primereact/tabview";

import { mainPathNameState } from "../../store";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { menuState } from "../../service/hooks/useMenus";
import useToast from "../../core/hooks/useToast";

import FlightInfo from "./components/FlightInfo";
import Loading from "../../components/loading/Loading";
import ExchangeRateInfo from "./components/ExchangeRateInfo";
import CardContainer from "../../components/layouts/CardContainer";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import EnhancedTabView from "../../components/tabview/EnhancedTabView";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import useFlightData from "./hooks/useFlightData";
import useFlightMapper from "./hooks/useFlightMapper";
import useUpdateFlightInfo from "./hooks/useUpdateFlightInfo";
import useUpdateExchangeRate from "./hooks/useUpdateExchangeRate";
import FlightOrders from "./components/FlightOrders";

const FlightDetailPage = () => {
    const menuStateValue = useRecoilValue(menuState);
    const [flightDataDto, setFlightDataDto] = useState(null);
    const history = useHistory();
    const { flightId } = useParams();
    const { showError, showSuccess } = useToast();
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const isCreateMode = useMemo(() => flightId === "create", [flightId]);
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const [{
        data: flightData,
        loading: flightLoading,
    }, getFlight] = useFlightData();

    const { modelToDto, convertModelToSave } = useFlightMapper();

    useEffect(() => {
        if (flightData) {
            setFlightDataDto(modelToDto(flightData));
        }
    }, [flightData, modelToDto]);

    const [{
        data: updateFlightData,
        error: updateFlightError
    }, updateFlightInfo] = useUpdateFlightInfo();

    const [{
        data: updateExchangeRateData,
        error: updateExchangeRateError
    }, updateExchangeRate] = useUpdateExchangeRate();

    const _onSaveFlightInfo = useCallback((data) => {
        const dataToSave = {
            ...flightDataDto,
            ...data,
        };

        return updateFlightInfo({
            ...!isCreateMode && { flightId: flightDataDto.FlightId },
            data: convertModelToSave(dataToSave),
        }).then(() => {
            setFlightDataDto(dataToSave);
        });
    }, [updateFlightInfo, isCreateMode, flightDataDto, convertModelToSave]);

    const saveFlightInfoDialog = useMemo(() => (
        <ConfirmDialog
            onCancel={closeConfirmation}
            onConfirm={confirm}
            title={isCreateMode ? "운항정보 생성" : "운항정보 수정"}
        >
            운항정보를 {isCreateMode ? "생성" : "수정"}하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm, isCreateMode]);

    const confirmSaveFlightInfo = useCallback((data) => {
        if (isCreateMode) {
            _onSaveFlightInfo(data).then(closeConfirmation);
        }
        else {
            return openConfirmation(saveFlightInfoDialog)
                .then((confirmedData) => {
                    _onSaveFlightInfo(data).then(closeConfirmation);
                }).catch(() => {
                    // ignore onCancel
                });
        }
    }, [isCreateMode, _onSaveFlightInfo, saveFlightInfoDialog, openConfirmation, closeConfirmation]);

    const _onSaveExchangeRate = useCallback((data) => {
        return updateExchangeRate({
            flightId: flightDataDto.FlightId,
            exchangeRate: data.ExchangeRate,
        }).then(() => {
            setFlightDataDto(prevData => {
                return {
                    ...prevData,
                    ...data,
                }
            });
        });
    }, [updateExchangeRate, flightDataDto]);

    const confirmExchangeRateDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"적용환율 수정"}>
            적용환율을 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const onSaveExchangeRate = useCallback((data) => {
        return openConfirmation(confirmExchangeRateDialog)
            .then((confirmedData) => {
                _onSaveExchangeRate(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onSaveExchangeRate, confirmExchangeRateDialog, openConfirmation, closeConfirmation]);

    const showSuccessMessage = useCallback(() => {
        if (isCreateMode) {
            showSuccess({ title: "운항정보 등록", message: "운항정보를 성공적으로 등록하였습니다." });
        } else {
            showSuccess({ title: "운항정보 수정", message: "운항정보를 성공적으로 수정하였습니다." });
        }
    }, [isCreateMode, showSuccess]);

    useEffect(
        () => {
            /**
             * 여기에 isCreateMode 나 showSuccessMessage 를 디펜던시로 추가하면 안된다.
             * 그러면 생성하고 생성메시지와 수정 메시지가 동시에 나온다. :(
             */
            if (updateFlightData) {
                showSuccessMessage();
                history.replace(`${mainPathNameStateValue}/${updateFlightData.FlightId}`);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateFlightData, history]
    );

    useEffect(() => {
        if (updateExchangeRateData) {
            showSuccess({ title: "적용환율 수정", message: "적용환율을 성공적으로 수정하였습니다." });
        }
    }, [updateExchangeRateData, showSuccess]);

    useEffect(() => {
        if (updateFlightError) {
            showError({ message: updateFlightError });
            closeConfirmation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateFlightError, showError]);

    useEffect(() => {
        if (updateExchangeRateError) {
            showError({ message: updateExchangeRateError });
        }
    }, [updateExchangeRateError, showError]);

    useEffect(() => {
        if (!isCreateMode && flightId) {
            getFlight(flightId);
        }
    }, [flightId, isCreateMode, getFlight]);

    const breadcrumbItems = useMemo(() => {
        return [
            ...menuStateValue.currentBreadcrumbItems,
            { label: '운항상세' }
        ]
    }, [menuStateValue]);

    return (
        <div className="grid" css={flightDetailPageStyle}>
            {flightLoading && <Loading overlay />}
            <div className="col-12">
                <EnhancedBreadCrumb model={breadcrumbItems} showBack />

                <CardContainer>
                    <EnhancedTabView>
                        <TabPanel header="운항 정보" key={"운항 정보"}>
                            <FlightInfo
                                flightData={flightDataDto}
                                onSaveFlightInfo={confirmSaveFlightInfo}
                            />
                        </TabPanel>
                        {!isCreateMode && (
                            <TabPanel header="적용 환율" key={"적용 환율"}>
                                <ExchangeRateInfo
                                    onSaveExchangeRate={onSaveExchangeRate}
                                    exchangeRate={
                                        flightDataDto?.ExchangeRate
                                            ? Number(flightDataDto?.ExchangeRate)
                                            : flightDataDto?.ExchangeRate
                                    }
                                />
                            </TabPanel>
                        )}
                        {!isCreateMode && (
                            <TabPanel header="주문 정보" key={"주문 정보"}>
                                <FlightOrders flightId={flightDataDto?.FlightId} />
                            </TabPanel>
                        )}
                    </EnhancedTabView>
                </CardContainer>
            </div>
        </div>
    );
}

export default FlightDetailPage;

const flightDetailPageStyle = css`
    position: relative;
`;