import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import { throttle } from "lodash";
import { css } from "@emotion/react";
import { quillFormats, quillModules } from "../../includes/editorConfig";
import { NOOP } from "../../includes/constants";

const EnhancedEditor = ({
    defaultContents = '',
    error = '',
    onChange = NOOP,
}) => {
    const editorWrapperRef = useRef();
    const [contents, setContents] = useState(defaultContents);

    const handleChange = useMemo(() => {
        return throttle((value) => {
            return onChange(value);
        }, 1000);
    }, [onChange]);

    const handleContentsChanged = useCallback(value => {
        setContents(value);
        handleChange(value);
    }, [handleChange]);

    useEffect(() => {
        const fn = () => {
            editorWrapperRef.current?.querySelector(".ql-editor")?.focus();
        }
        editorWrapperRef.current?.querySelector(".ql-container")?.addEventListener("click", fn);

        const editorWrapperDOM = editorWrapperRef.current;

        return () => {
            if (editorWrapperDOM) {
                editorWrapperDOM.querySelector(".ql-container").removeEventListener("click", fn);
            }
        }
    }, []);

    return (
        <div css={withErrorStyle(error)} ref={editorWrapperRef}>
            <ReactQuill
                value={contents}
                onChange={handleContentsChanged}
                modules={quillModules}
                formats={quillFormats}
                placeholder={"Write something..."}
                css={reactQuillContainerStyle(error)}
            />
        </div>
    )
}

export default EnhancedEditor;

const reactQuillContainerStyle = error => css`
    .ql-container {
        min-height: 400px;
    }

    ${error && css`
        outline: 1px solid #f44336;
    `}
`;

const withErrorStyle = (error) => css`
    position: relative;
    margin-bottom: 12px;
    width: 100%;

    ${error && css`
        :after {
            content: '${error}';
            position: absolute;
            top: 100%;
            left: 0;
            color: #f44336;
            width: 100%;
            height: 10px;
            font-size: 10px;
            line-height: 10px;
            margin-top: 2px;
        }
    `}
`;
