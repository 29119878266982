import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { initAxios } from "../../includes/ajax";
import { setToken, setUserToStorage } from "../../includes/auth";
import { useToastContext } from "../contexts/ToastContext";

const useAxiosAuth = () => {
    const { showError } = useToastContext();
    const history = useHistory();

    useEffect(() => {
        initAxios({
            errorHandler: (error) => {
                if (error?.response?.status === 401) {
                    setUserToStorage("");
                    setToken("");
                    showError({ title: "Auth Error", message: error.message });
                    history.push("/login");
                }
                throw error?.response ?? error;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showError]);
}

export default useAxiosAuth;