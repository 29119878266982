import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AwaitButton from "../AwaitButton";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { NOOP } from "../../includes/constants";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import DeliveryStatusDialog, { deliveryStatusDialogState } from "../dialogs/DeliveryStatusDialog";
import { useSetRecoilState } from "recoil";
import { css } from "@emotion/react";

const DeliveryStatusActionButton = ({
    className = '',
    disabled = false,
    onSelect = NOOP,
}) => {
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();
    const setDeliveryStatusDialogState = useSetRecoilState(deliveryStatusDialogState);

    const renderConfirm = useCallback(({ onCancel, onConfirm, data }) => (
        <ConfirmDialog onCancel={onCancel} onConfirm={onConfirm}>
            선택하신 주문의 배송상태를&nbsp;<span css={boldTextStyle}>{data.deliveryStatusName}</span>(으)로 변경하시겠습니까?
        </ConfirmDialog>
    ), []);

    const flightInfoDialog = useMemo(() => (
        <DeliveryStatusDialog
            onCancel={closeConfirmation}
            onConfirm={confirm}
            renderConfirm={renderConfirm}
        />
    ), [closeConfirmation, confirm, renderConfirm]);

    const onUpdateFlightClicked = useCallback(() => {
        setDeliveryStatusDialogState({});
        return openConfirmation(flightInfoDialog)
            .then((confirmedData) => {
                onSelect(confirmedData);
            }).catch(() => {
                // ignore onCancel
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelect, flightInfoDialog, openConfirmation]);

    return (
        <AwaitButton
            icon={"pi pi-pencil"}
            type="button"
            className={classNames("mb-2 ml-2", className)}
            onClick={onUpdateFlightClicked}
            label={"배송상태 변경"}
            disabled={disabled}
        />
    )
}

DeliveryStatusActionButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
}

export default DeliveryStatusActionButton;

const boldTextStyle = css`
    font-weight: bold;
`;