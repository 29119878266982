import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from "recoil";
import { Dropdown } from "primereact/dropdown";
import { combineItemStatusSearchFilterState } from "./store";
import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";
import FilterLabel from "./FilterLabel";

const combineItemStatusDropdownItems = [
  { Name: '전체', Code: 'All', isDefault: true },
  { Name: 'Yes', Code: 'True' },
  { Name: 'No', Code: 'False' },
];

const CombineItemStatusSearchFilter = ({
  label = "합배송신청",
  atom = combineItemStatusSearchFilterState,
  supportQueryString = false,
  className = "",
  dropdownItems = combineItemStatusDropdownItems,
}) => {

  const [combineItemStatusSearchFilterValue, setCombineItemStatusSearchFilterValue] = useRecoilState(atom);

  const setQueryStringToState = useMemo(() => {
    return (searchObject) => {
      setCombineItemStatusSearchFilterValue({
        combineItemStatus: searchObject.combineItemStatus,
      });
    }
  }, [setCombineItemStatusSearchFilterValue]);

  useSetQueryStringToState(supportQueryString && setQueryStringToState);

  const setFilterDefaultValue = useCallback((defaultItem) => {
    setCombineItemStatusSearchFilterValue(prev => {
      if (prev.combineItemStatus) return prev;

      return {
        ...prev,
        combineItemStatus: defaultItem.Code,
      }
    });
  }, [setCombineItemStatusSearchFilterValue]);

  useSetFilterDefaultValue({
    items: dropdownItems,
    isAlreadySet: !!combineItemStatusSearchFilterValue.combineItemStatus,
    setFilterDefaultValue,
  });

  const setDropdownItem = useCallback(e => {
    setCombineItemStatusSearchFilterValue({
      combineItemStatus: e.value?.Code
    })
  }, [setCombineItemStatusSearchFilterValue]);


  return (
    <div className={className}>
      <FilterLabel htmlFor={"combineItemStatus"}>{label}</FilterLabel>
      <Dropdown
        name="combineItemStatus"
        value={dropdownItems.find(item => item.Code === combineItemStatusSearchFilterValue.combineItemStatus)}
        onChange={setDropdownItem}
        options={dropdownItems}
        optionLabel="Name"
        placeholder="전체"
      />
    </div>
  )
}

export default CombineItemStatusSearchFilter
