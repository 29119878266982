import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { trim } from "lodash";
import { Button } from "primereact/button";

import { NOOP } from "../../../../includes/constants";
import { toBooleanValue } from "../../../../includes/ajax";
import { useAwaitingDialogContext } from "../../../../core/contexts/AwaitingDialogContext";

import AwaitButton from "../../../../components/AwaitButton";
import InputTextWithError from "../../../../components/forms/InputTextWithError";
import EnhancedEditor from "../../../../components/editor/EnhancedEditor";
import DataTableActions from "../../../../components/layouts/DataTableActions";
import IsActiveRadioButtons from "../../../../components/forms/IsActiveRadioButtons";

const NoticeWriter = ({
    onSaveNotice = NOOP,
    noticeData = null,
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const history = useHistory();
    const editorKey = useRef(Math.random());

    const onSaveClicked = useCallback((values) => {
        return onSaveNotice(values);
    }, [onSaveNotice]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            onSaveClicked(values);
        },
    });

    const resetForm = useCallback(() => {
        formik.setValues({
            Title: noticeData?.Title || '',
            Contents: noticeData?.Contents || '',
            WriterName: noticeData?.WriterName || '관리자',
            IsActive: toBooleanValue(noticeData?.IsActive, true),
        });
        editorKey.current = Math.random();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noticeData]);

    const onContentsChanged = useCallback((contents) => {
        formik.setFieldValue("Contents", contents);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goBackToList = useCallback(() => {
        history.goBack();
        closeConfirmation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    const leftSectionButtons = useMemo(() => {
        return (
            <>
                <AwaitButton
                    buttonClassName={"p-button-secondary"}
                    icon="pi pi-replay"
                    css={buttonStyle}
                    label={"리셋"}
                    type="button"
                    onClick={resetForm}
                />
                <AwaitButton
                    icon="pi pi-check"
                    label={"저장"}
                    type="submit"
                    css={buttonStyle}
                />
            </>
        )
    }, [resetForm]);

    return (
        <div className="col-12">
            <form onSubmit={formik.handleSubmit}>

                <div className="p-fluid formgrid grid" css={maxWidthStyle}>
                    <DataTableActions className={"mb-5"} direction={"right"} titleSection={leftSectionButtons}>
                        <Button
                            className={"p-button-info"}
                            icon="pi pi-list"
                            label={"목록"}
                            type="button"
                            onClick={goBackToList}
                        />
                    </DataTableActions>


                    <div className="field col-12">
                        <label htmlFor="Title">제목</label>
                        <InputTextWithError
                            name="Title"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.Title || ''}
                            error={formik.errors.Title}
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="WriterName">작성자</label>
                        <InputTextWithError
                            name="WriterName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.WriterName || ''}
                            error={formik.errors.WriterName}
                        />
                    </div>

                    <div className="field grid col-12  md:col-6">
                        <label htmlFor="IsActive" className="col-12">사용여부</label>
                        <IsActiveRadioButtons
                            isActive={toBooleanValue(formik.values.IsActive)}
                            handleChange={formik.handleChange}
                        />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="Contents">내용</label>
                        <EnhancedEditor
                            defaultContents={formik.values.Contents}
                            onChange={onContentsChanged}
                            error={formik.errors.Contents}
                            key={editorKey.current}
                        />
                    </div>


                </div>
            </form>
        </div>
    )
}

export default NoticeWriter;

const validate = (values) => {
    const errors = {};

    if (!trim(values.Title)) {
        errors.Title = "Required";
    }
    if (!trim(values.WriterName)) {
        errors.WriterName = "Required";
    }

    if (!trim(values.Contents)) {
        errors.Contents = "Required";
    } else if (!values.Contents.includes("<img")) {
        const cleanText = values.Contents.replace(/<\/?[^>]+(>|$)/g, "");
        if (!trim(cleanText)) {
            errors.Contents = "Required";
        }
    }

    if (!trim(values.IsActive)) {
        errors.IsActive = "Required";
    }
    return errors;
}

const buttonStyle = css`
    margin-right: 10px;
`;

const maxWidthStyle = css`
    max-width: 800px;
`;