import { Quill } from 'react-quill';
import ImageResize from "../components/image-resize/ImageResize";
// import 'react-quill/dist/quill.snow.css';

export const initQuill = () => {
    Quill.register('modules/imageResize', ImageResize);
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
export const quillModules = {
    toolbar: [
        [{ 'font': [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [
            { 'list': 'ordered' },
            { 'list': 'bullet' },
            { 'indent': '-1' },
            { 'indent': '+1' }
        ],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['link', 'image'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
    // imageResize: {
    //     // parchment: Quill.import('parchment'),
    //     modules: ['Resize', 'DisplaySize', 'Toolbar']
    // },
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const quillFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'color', 'background', 'align',
];
