import { toInteger } from "lodash";
import { DELIVERY_LOCATION_CODE_TO_NZ, DELIVERY_LOCATION_CODE_TO_NZSEA, DELIVERY_LOCATION_CODE_TO_KR } from "./constants";

const deliveryLocationCodeToNZNumber = toInteger(DELIVERY_LOCATION_CODE_TO_NZ);
const deliveryLocationCodeToKRNumber = toInteger(DELIVERY_LOCATION_CODE_TO_KR);
const deliveryLocationCodeToNZSEANumber = toInteger(DELIVERY_LOCATION_CODE_TO_NZSEA);

export const isNZ = deliveryLocationCode => {
    return toInteger(deliveryLocationCode) === deliveryLocationCodeToNZNumber || toInteger(deliveryLocationCode) === deliveryLocationCodeToNZSEANumber
}

export const isKR = deliveryLocationCode => {
    return toInteger(deliveryLocationCode) === deliveryLocationCodeToKRNumber;
}