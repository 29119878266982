import React, { useMemo } from "react";
import { throttle } from "lodash";

import QnaPageFilter from "./components/QnaPageFilter";
import useQnasData from "./hooks/useQnasData";
import { DataTable } from "primereact/datatable";
import useDataTableExportCSV from "../../../core/hooks/useDataTableExportCSV";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../../includes/constants";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import { css } from "@emotion/react";

const fieldColumnTitleMap = {
    BoardQnaId: "글번호",
    CreateDate: "등록일",
    AdminUserId: "관리자 ID",
    WriterName: "작성자",
    Title: "제목",
    IsActive: "사용여부",
    UserId: "회원 ID",
    IsSecret: "비밀 여부",
    CommentCount: "답변여부"
}

const sortableFields = [];

const QnaPage = () => {
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: qnasData, loading: qnasDataLoading }, getQnas] = useQnasData();

    const onSearch = useMemo(() => {
        return throttle((e) => {
            if (qnasDataLoading) return getPromise();
            return getQnas();
        }, 1000);
    }, [getQnas, qnasDataLoading]);

    const {
        sortPaginationProps,
        renderColumn,
        renderLinkColumn,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getQnas,
        dataSize: qnasData?.Data?.length ?? 0,
    });

    return (
        <div className="grid" css={qnaPageStyle}>
            <div className="col-12">

                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>Q&A</h5>
                    <QnaPageFilter onSearch={onSearch} loading={qnasDataLoading} />
                </div>

                <div className="card">
                    <ScrollDataTableContainer loading={qnasDataLoading} minWidth={1024}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={qnasDataLoading}
                            ref={dataTableRef}
                            value={qnasData?.Data || []}
                            dataKey="BoardQnaId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={qnasData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={qnasData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                        >
                            {renderLinkColumn("Title", "BoardQnaId")}
                            {renderColumn("WriterName")}
                            {renderColumn("CreateDate")}
                            {renderColumn("CommentCount")}
                            {renderColumn("IsSecret")}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}

export default QnaPage;

const qnaPageStyle = css`
    .column-Title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;