import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { trim } from "lodash";
import { css } from "@emotion/react";
import useAxios from "axios-hooks";
import qs from "qs";
import { useRecoilValue } from "recoil";

import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import FlightStatusSearchFilter, { flightStatusSearchFilterState } from "../filters/FlightStatusSearchFilter";
import AwaitButton from "../AwaitButton";
import FilterContainer from "../filters/FilterContainer";
import Loading from "../loading/Loading";
import EnhancedDialog from "./EnhancedDialog";
import { stringifyFilter } from "../../includes/ajax";
import useHandleEnterEvent from "../../core/hooks/useHandleEnterEvent";

const fieldColumnTitleMap = {
    ArrivalDate: "도착일",
    CreateDate: "등록일",
    DepartDate: "출항일(ETD)",
    EstimatedArrivalDate: "도착 예정일(ETA)",
    ExchangeRate: "환율",
    FlightName: "운항명",
    FlightNumber: "편명",
    DeliveryLocationName: "배송지역",
}

const PAGE_SIZE = 5;
const API_URI = '/manager/flight';

const FlightSelectSearchDialog = ({
    onCancel,
    onConfirm,
    title = "운항정보",
    shouldShowConfirm = true,
    renderConfirm = null, // should be null as default
}) => {
    const flightStatusSearchFilterValue = useRecoilValue(flightStatusSearchFilterState);
    const [searchStr, setSearchStr] = useState("");
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const searchStrRef = useRef();

    const handleReturnConfirm = useCallback(() => {
        onConfirm(selectedFlight)
    }, [onConfirm, selectedFlight]);

    const handleConfirm = useCallback(() => {
        if (shouldShowConfirm && renderConfirm && selectedFlight) {
            setShowConfirm(true);
        } else {
            return onConfirm(selectedFlight);
        }
    }, [shouldShowConfirm, renderConfirm, onConfirm, selectedFlight, setShowConfirm]);

    const [{ data: flightsData, loading: flightsLoading }, getData] = useAxios({
            url: API_URI,
            method: 'GET',
        },
        { manual: true },
    );

    const getColumnTitle = useCallback(field => {
        return fieldColumnTitleMap[field] || field;
    }, []);

    const getFieldTemplate = useCallback((field) => {
        return (rowData) => (rowData[field]);
    }, []);

    const renderSortableColumn = useCallback((field) => (
        <Column
            field={field}
            header={getColumnTitle(field)}
            sortable
            body={getFieldTemplate(field)}
        />
    ), [getColumnTitle, getFieldTemplate]);

    const onChangeSearchStr = useCallback((e) => {
        setSearchStr(trim(e.target.value))
    }, []);

    const onSearch = useCallback((e, pageNo = 1, pageSize = PAGE_SIZE) => {
        setSelectedFlight(null);
        const queryString = qs.stringify({
            ...searchStr && { searchStr },
            searchDateField: flightStatusSearchFilterValue.searchDateField,
            startDate: flightStatusSearchFilterValue.startDate,
            endDate: flightStatusSearchFilterValue.endDate,
            pageNo,
            pageSize,
        }, { filter: stringifyFilter });

        return getData({
            url: `${API_URI}${queryString ? "?" + queryString : ""}`
        })
    }, [getData, searchStr, flightStatusSearchFilterValue]);

    const handleEnterEvent = useHandleEnterEvent(onSearch);

    const onPage = useCallback(pageObject => {
        onSearch(null, pageObject.page + 1, pageObject.pageCount);
    }, [onSearch]);

    const onFlightSelected = useCallback(e => {
        setSelectedFlight(e.value);
    }, []);

    const onConfirmCancel = useCallback(() => {
        setShowConfirm(false)
    }, [setShowConfirm]);

    useEffect(() => {
        // 처음에 바로 조회 한다.
        onSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!flightsLoading) {
            // 운항명 입력창에 포커스를 한다.
            searchStrRef?.current?.focus();
        }
    }, [flightsLoading]);

    return (
        <EnhancedDialog
            onCancel={onCancel}
            onConfirm={handleConfirm}
            title={title}
            css={flightSelectSearchDialogStyle}
            withLoading={false}
        >
            {showConfirm && renderConfirm && (
                renderConfirm({
                    onConfirm: handleReturnConfirm,
                    onCancel: onConfirmCancel,
                    data: selectedFlight,
                })
            )}
            <div>
                {flightsLoading && <Loading />}
                <div className="col-12" css={filtersStyle}>
                    <FlightStatusSearchFilter css={flightStatusSearchFilterStyle} className={"col-12"} />
                    <FilterContainer css={filterContainerStyle}>
                        <InputText
                            name="searchStr"
                            type="text"
                            defaultValue={searchStr}
                            onChange={onChangeSearchStr}
                            onKeyDown={handleEnterEvent}
                            css={inputStyle}
                            placeholder={"운항명"}
                            forwardRef={searchStrRef}
                        />
                        <AwaitButton
                            className=""
                            css={buttonStyle}
                            type="submit"
                            onClick={onSearch}
                            label={"Search"}
                        />
                    </FilterContainer>
                </div>
                <div className="col-12" css={filtersStyle}>
                    <DataTable
                        resizableColumns
                        columnResizeMode="fit"
                        value={flightsData?.Data || []}
                        selection={selectedFlight}
                        onSelectionChange={onFlightSelected}
                        dataKey="FlightId"
                        paginator
                        onPage={onPage}
                        lazy
                        totalRecords={flightsData?.Paging?.TotalNumberOfRecords ?? 0}
                        rows={PAGE_SIZE}
                        rowsPerPageOptions={[PAGE_SIZE]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        emptyMessage="No data found."
                        rowHover
                        selectionMode="single"
                    >
                        {renderSortableColumn("FlightName")}
                        {renderSortableColumn("ArrivalDate")}
                    </DataTable>
                </div>
            </div>
        </EnhancedDialog>
    )
}

FlightSelectSearchDialog.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    shouldShowConfirm: PropTypes.bool,
    renderConfirm: PropTypes.func,
}

export default FlightSelectSearchDialog;

const flightSelectSearchDialogStyle = css`
    width: 500px;
`;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

const filtersStyle = css`
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
    display: flex;
    align-items: center;
`;

const inputStyle = css`
    max-width: 140px;
    margin-right: 2px;
`;

const flightStatusSearchFilterStyle = css`
    margin: 0;

    .p-dropdown {
        min-width: 110px;
    }
`;

const filterContainerStyle = css`
    width: max-content;
`;