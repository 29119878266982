import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";

import { NOOP } from "../../includes/constants";
import { dataTableColumnsSortState } from "./useDataTableColumns";

const useOnloadSearch = ({
    paginationAtom,
    getData = NOOP,
    getParamAndQueryString = NOOP,
}) => {

    const location = useLocation();
    const [onload, setOnload] = useState(false);
    const paginationFilterState = useRecoilValue(paginationAtom);
    const setSortState = useSetRecoilState(dataTableColumnsSortState);

    /**
     * 처음 페이지 열렸을때, 검색하는 부분
     */
    useEffect(() => {
        if (onload) {
            return;
        }

        setTimeout(() => {
            setOnload(true);
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (onload) {
            // const [queryStringForAPI] = getParamAndQueryString();
            // const pageQueryString = qs.stringify(paginationFilterState, { filter: stringifyFilter });

            setSortState({
                sortField: paginationFilterState.orderField,
                sortOrder: paginationFilterState.orderBy ? (
                    paginationFilterState.orderBy === "ASC" ? 1 : -1
                ) : null,
            });

            // if (queryStringForAPI || pageQueryString) {
            // TODO 첫 로딩에는 무조건 검색하도록 한다.
            getData({
                pageNo: paginationFilterState.pageNo,
                pageSize: paginationFilterState.pageSize,
                orderField: paginationFilterState.orderField,
                orderBy: paginationFilterState.orderBy,
            });
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onload]);

}

export default useOnloadSearch;