import React, { useCallback, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import { css } from "@emotion/react";
import { validate as validateEmail } from "email-validator";
import { useFormik } from "formik";
import { trim, isEmpty } from "lodash";

import { InputTextarea } from "primereact/inputtextarea";

import { NOOP } from "../../../includes/constants";
import { toBooleanValue } from "../../../includes/ajax";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import usePickupType from "../../../service/hooks/usePickupType";
import TableTitle from "../../../components/layouts/TableTitle";
import DataTableActions from "../../../components/layouts/DataTableActions";
import EnhancedDivider from "../../../components/layouts/EnhancedDivider";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DropdownWithError from "../../../components/forms/DropdownWithError";
import DeliveryLocationRadioButtons from "../../../components/forms/DeliveryLocationRadioButtons";
import IsReceiverOwnRadioButtons from "../../../components/forms/IsReceiverOwnRadioButtons";
import useCommonCode, { COMMON_CODE_DELIVERY_LOCATION } from "../../../service/hooks/useCommonCode";

const OrderCreateReceiverInfo = forwardRef(({
  onSaveOrderReceiverInfo = NOOP,
  orderReceiverData = null,
}, ref) => {
  const { data: pickupTypesData } = usePickupType();
  const { data: deliveryLocationData } = useCommonCode(COMMON_CODE_DELIVERY_LOCATION);

  const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

  useImperativeHandle(ref, () => ({
    handleSubmit() {

      //Just submit for checking validation
      formik.handleSubmit();

      //return current formik values
      if (isEmpty(validate(formik.values))) {
        return formik.values;
      }

      return;
    }
  }));


  const orderItemDialog = useMemo(() => (
    <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm}>
      수령자 정보를 수정하시겠습니까?
    </ConfirmDialog>
  ), [closeConfirmation, confirm]);


  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {
      const newValue = {
        ...values,
      };

      newValue.PickupTypeCode = newValue.pickupTypeDropdownItem.Code;
      newValue.IsCombineItem = newValue.isCombineItem.Code;
      newValue.IsRepackage = newValue.isRepackage.Code;
      newValue.IsUseMileage = newValue.isUseMileage.Code;
      delete newValue.pickupTypeDropdownItem;
      delete newValue.isCombineItem;
      delete newValue.isRepackage;
      delete newValue.isUseMileage;

      return newValue;
    },
  });

  const initForm = useCallback(() => {
    formik.setValues({
      DeliveryLocationCode: deliveryLocationData?.[0]?.Code,
      IsReceiverOwn: true,
      ReceiverEmail: orderReceiverData?.EmailAddress,
      ReceiverName: orderReceiverData?.UserName,
      ReceiverMobile: orderReceiverData?.MobileNumber,
      ReceiverPhone: orderReceiverData?.PhoneNumber,
      ReceiverAddress1: orderReceiverData?.UserAddressNZ1,
      ReceiverAddress2: orderReceiverData?.UserAddressNZ2,
      ReceiverZipcode: orderReceiverData?.ZipCodeNZ,
      PersonalCustomCode: orderReceiverData?.PersonalCustomCode,

    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderReceiverData, deliveryLocationData]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onLocationChanged = (e) => {
    if (toBooleanValue(formik.values.IsReceiverOwn)) {
      if (e.value === "2") {
        formik.setFieldValue('ReceiverAddress1', orderReceiverData?.UserAddressKR1);
        formik.setFieldValue('ReceiverAddress2', orderReceiverData?.UserAddressKR2);
        formik.setFieldValue('ReceiverZipcode', orderReceiverData?.ZipCodeKR);
      }
      else {
        formik.setFieldValue('ReceiverAddress1', orderReceiverData?.UserAddressNZ1);
        formik.setFieldValue('ReceiverAddress2', orderReceiverData?.UserAddressNZ2);
        formik.setFieldValue('ReceiverZipcode', orderReceiverData?.ZipCodeNZ);
      }
    }

  }

  //TODO: need to implement later
  const onIsReceiverOwnChanged = (e) => {
    // if (e.value) {
    //   onLocationChanged({ value: formik.values.DeliveryLocation });
    // }
    // else {
    //   initForm();
    // }
  }

  // const onSaveOrderReceiverInfoClicked = useCallback(() => {
  //   return formik.submitForm();
  // }, [formik]);

  const titleSection = useMemo(() => (
    <TableTitle noPadding linkName={"OrderReceiverInfo"}>
      <h5>수령자 정보</h5>
    </TableTitle>
  ), []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>

        <DataTableActions className={"mb-2"} titleSection={titleSection} />

        <EnhancedDivider />

        <div className="p-fluid formgrid grid">

          <div className="field col-12 md:col-6">
            <label htmlFor="DeliveryLocation">배송지역</label>
            <DeliveryLocationRadioButtons
              name="DeliveryLocationCode"
              className="grid m-0"
              deliveryLocations={deliveryLocationData}
              locatonCode={formik.values.DeliveryLocationCode}
              handleChange={e => { formik.handleChange(e); onLocationChanged(e); }}

            />
          </div>
          <EnhancedDivider />
          <div className="field col-12 md:col-6">
            <label htmlFor="PickupType"></label>
            <IsReceiverOwnRadioButtons
              className="grid m-0"
              isActive={toBooleanValue(formik.values.IsReceiverOwn)}
              handleChange={e => { formik.handleChange(e); onIsReceiverOwnChanged(e) }}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="pickupTypeDropdownItem">수령방식</label>
            <DropdownWithError
              name="pickupTypeDropdownItem"
              value={formik.values.pickupTypeDropdownItem}
              onChange={formik.handleChange}
              options={pickupTypesData}
              error={formik.errors.pickupTypeDropdownItem}
              optionLabel="Name"
              placeholder="Select One"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverEmail">이메일</label>
            <InputTextWithError
              name="ReceiverEmail"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.ReceiverEmail || ''}
              error={formik.errors.ReceiverEmail}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverMobile">휴대폰</label>
            <InputTextWithError
              name="ReceiverMobile"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ReceiverMobile || ''}
              error={formik.errors.ReceiverMobile}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverName">수령자명</label>
            <InputTextWithError
              name="ReceiverName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ReceiverName || ''}
              error={formik.errors.ReceiverName}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverPhone">전화번호</label>
            <InputTextWithError
              name="ReceiverPhone"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.ReceiverPhone || ''}
              error={formik.errors.ReceiverPhone}
            />
          </div>




          {/*****************************************
          * 주소 덩어리
          *****************************************/}
          <div className="field grid col-12 md:col-12" css={gridGroupNoMarginStyle}>
            <div className="field col-12 md:col-6">
              <label htmlFor="ReceiverAddress1">주소</label>
              <InputTextWithError
                name="ReceiverAddress1"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ReceiverAddress1 || ''}
                error={formik.errors.ReceiverAddress1}
              />
            </div>
            <div className="field col-12 lg:col-4">
              <label htmlFor="ReceiverAddress2">상세주소</label>
              <InputTextWithError
                name="ReceiverAddress2"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ReceiverAddress2 || ''}
                error={formik.errors.ReceiverAddress2}
              />
            </div>
            <div className="field col-12 lg:col-2">
              <label htmlFor="ReceiverZipcode">우편번호</label>
              <InputTextWithError
                name="ReceiverZipcode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ReceiverZipcode || ''}
                error={formik.errors.ReceiverZipcode}
              />
            </div>

          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="PersonalCustomCode">개인통관부호</label>
            <InputTextWithError
              name="PersonalCustomCode"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.PersonalCustomCode || ''}
              error={formik.errors.PersonalCustomCode}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="ReceiverMessage">배송 메시지</label>
            <InputTextarea
              name="ReceiverMessage"
              placeholder="배송 메시지"
              rows="7"
              value={formik.values.ReceiverMessage || ''}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </form>
    </>
  )
});

export default OrderCreateReceiverInfo;


const validate = (values) => {
  const errors = {};

  if (!trim(values.IsReceiverOwn)) {
    errors.IsReceiverOwn = "Required";
  }
  if (!trim(values.ReceiverName)) {
    errors.ReceiverName = "Required";
  }
  if (!trim(values.ReceiverEmail)) {
    errors.ReceiverEmail = "Required";
  } else if (!validateEmail(values.ReceiverEmail)) {
    errors.ReceiverEmail = 'Invalid email';
  }

  if (!values.pickupTypeDropdownItem) {
    errors.pickupTypeDropdownItem = "Required";
  }



  if (!trim(values.ReceiverPhone)) {
    errors.ReceiverPhone = "Required";
  }
  if (!trim(values.ReceiverMobile)) {
    errors.ReceiverMobile = "Required";
  }
  if (!trim(values.ReceiverAddress1)) {
    errors.ReceiverAddress1 = "Required";
  }
  if (!trim(values.ReceiverZipcode)) {
    errors.ReceiverZipcode = "Required";
  }

  return errors;
}

const gridGroupNoMarginStyle = css`
    margin: 0 0 1rem;
    padding: 0;
`;

const orderLocalTrackingNumberStyle = css`
    width: 100%;
`;

const dividerStyle = css`
    margin: 2rem auto 4rem !important;
`;