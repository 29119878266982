import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useAwaitingDialogContext } from "../contexts/AwaitingDialogContext";
import useToast from "./useToast";

const useAPI = ({
    url = "",
    method = "POST",
    successMessage = "성공적으로 수정하였습니다.",
    callbackAfterSuccess = null,
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const { showSuccess, showError } = useToast();

    const [{
        data,
        error
    }, callAPI] = useAxios({
            url: url,
            method: method,
        },
        { manual: true },
    );

    useEffect(() => {
        if (data) {
            if (successMessage) {
                showSuccess({ message: successMessage });
            }
            if (callbackAfterSuccess) {
                callbackAfterSuccess().finally(closeConfirmation);
            } else {
                closeConfirmation();
            }
        }
        // refresh / closeConfirmation 넣으면 안됨.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, showSuccess]);

    useEffect(() => {
        if (error) {
            showError({ message: error });
            closeConfirmation();
        }
    }, [error, closeConfirmation, showError]);

    return callAPI;

}

export default useAPI;
