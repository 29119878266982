import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";

import { NOOP, PERMISSION_ADMIN } from "../../../includes/constants";
import useCreateConsignment from "../../../service/hooks/useCreateConsignment";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";

import { orderDetailPageDeliveryStatusState } from "../store";
import Loading from "../../../components/loading/Loading";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import DataTableActions from "../../../components/layouts/DataTableActions";
import DeliveryStatusSearchFilter from "../../../components/filters/DeliveryStatusSearchFilter";
import ConfirmActionButton from "../../../components/action-buttons/ConfirmActionButton";
import { currentUserState } from "../../../includes/auth";

const OrderInfo = ({
  refresh = NOOP,
  onSaveOrderInfo = NOOP,
  orderData = null,
  className = "",
}) => {

  const currentUser = useRecoilValue(currentUserState);



  return (
    <div className={classNames("col-12", className)}>
      <form noValidate>
        {!orderData && <Loading />}

        {/* <DataTableActions className={"mb-2"}>

        

          <ConfirmActionButton
            icon="pi pi-pencil"
            className={"mb-2 ml-2"}
            label={"주문정보 수정"}
            onOkayClicked={onSaveOrderInfo}
          >
            주문정보를 수정하시겠습니까?
          </ConfirmActionButton>
        </DataTableActions> */}

        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-3">
            <label htmlFor="OrderId">주문번호</label>

            <InputTextWithError
              name="OrderId"
              type="text"
              value={orderData?.OrderNumber || ''}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="UserName">주문자</label>
            <InputTextWithError
              name="UserName"
              type="text"
              value={orderData?.UserName || ''}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="DeliveryLocationName">배송지역</label>
            <InputTextWithError
              name="DeliveryLocationName"
              type="text"
              value={orderData?.DeliveryLocationName || ''}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>


         

          <div className="field col-12 md:col-3">
            <label htmlFor="OrderDate">주문일</label>
            <InputTextWithError
              name="OrderDate"
              type="text"
              value={orderData?.OrderDate || ''}
              onChange={NOOP}
              readOnly
              noBorder
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default OrderInfo;

const deliveryStatusSearchFilterStyle = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;