import { useCallback, useState } from 'react';

const useSetState = (defaultState) => {

    const [state, setState] = useState(defaultState);

    const updateState = useCallback((newState, merge = true) => {
        setState(prevState => {
            // if we have a function, pass previous state
            const updatedState = typeof newState === 'function' ? newState(prevState) : newState;
            let stateUpdate = null;

            // merge new state with previous state
            if (merge) {
                stateUpdate = {
                    ...prevState,
                    ...updatedState,
                };

                // if merge is disabled, merge with the default state
            } else {
                stateUpdate = {
                    ...defaultState,
                    ...updatedState,
                };
            }

            return stateUpdate;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [state, updateState];
};

export default useSetState;
