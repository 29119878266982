import React from 'react';
import { Redirect } from "react-router-dom";
import { getToken, getUserFromStorage, setToken } from "../../includes/auth";
import RecentQnas from "./components/RecentQnas";
import RecentOrders from "./components/RecentOrders";

export const Dashboard = () => {
    const token = getToken();
    const user = getUserFromStorage();

    if (!token || !user) {
        setToken(null);
        return <Redirect to={"/login"} />;
    }

    return (
        <div className="grid">

            <div className={"col-12"}>
                <RecentQnas pageSize={10} />
            </div>

            <div className={"col-12"}>
                <RecentOrders pageSize={10} />
            </div>

        </div>
    );
}

export default Dashboard;