import React from "react";
import { css } from "@emotion/react";

const Spacer = () => (
    <div css={spacerStyle} />
);

export default Spacer;

const spacerStyle = css`
    min-height: 1rem;
    width: 100%;
`;