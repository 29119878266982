import React, { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from "recoil";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";

import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";
import useCommonCode, { COMMON_CODE_DELIVERY_STATUS } from "../../service/hooks/useCommonCode";

import FilterLabel from "./FilterLabel";

const ALL_DELIVERY_STATUS_ITEM = { Name: '전체', Code: '', isDefault: true };

const DeliveryStatusSearchFilter = ({
    atom,
    label = "배송 상태",
    supportQueryString = false,
    includeAll = false,
    className = "",
}) => {
    const setDeliveryStatusSearchFilterState = useSetRecoilState(atom);
    const deliveryStatusSearchFilterValue = useRecoilValue(atom);
    const { data: deliveryStatusData } = useCommonCode(COMMON_CODE_DELIVERY_STATUS);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setDeliveryStatusSearchFilterState({
                deliveryStatusCode: searchObject.deliveryStatusCode,
            });
        }
    }, [setDeliveryStatusSearchFilterState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const dropdownItems = useMemo(() => {
        const list = [
            includeAll && ALL_DELIVERY_STATUS_ITEM,
            ...deliveryStatusData,
        ];

        return list.filter(i => i);
    }, [deliveryStatusData, includeAll]);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setDeliveryStatusSearchFilterState(prev => {
            if (prev.deliveryStatusCode) return prev;

            return {
                ...prev,
                deliveryStatusCode: defaultItem.Code,
                deliveryStatusName: defaultItem.Name,
            }
        });
    }, [setDeliveryStatusSearchFilterState]);

    useSetFilterDefaultValue({
        items: dropdownItems,
        isAlreadySet: !!deliveryStatusSearchFilterValue.deliveryStatusCode,
        setFilterDefaultValue,
    });

    const setDropdownItem = useCallback(e => {
        setDeliveryStatusSearchFilterState(prev => {
            return {
                ...prev,
                deliveryStatusCode: e.value?.Code,
                deliveryStatusName: e.value?.Name,
            }
        })
    }, [setDeliveryStatusSearchFilterState]);

    return (
        <div className={classNames("grid col-12 p-fluid", className)}>
            <FilterLabel htmlFor={"deliveryStatusCode"}>{label}</FilterLabel>
            <Dropdown
                name="deliveryStatusCode"
                value={dropdownItems.find(item => item.Code === deliveryStatusSearchFilterValue.deliveryStatusCode)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="Name"
                placeholder="Select One"
            />
        </div>
    )
}

export default DeliveryStatusSearchFilter;