import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_BOARD_NOTICE_URI } from "../../../../includes/constants";

const useNoticeData = () => {
    const [{ data, loading, error }, getData] = useAxios({
            method: 'GET',
        },
        { manual: true },
    );

    const getNotice = useCallback((adminId) => {
        return getData({
            url: `${API_BOARD_NOTICE_URI}/${adminId}`
        });
    }, [getData]);

    return [
        {
            data,
            loading,
            error,
        },
        getNotice,
    ];
}

export default useNoticeData;