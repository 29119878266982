import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { mainPathNameState } from "../../../store";
import QnaPage from "./QnaPage";
import QnaDetailPage from "./QnaDetailPage";

const Qna = () => {
    const setMainPathNameState = useSetRecoilState(mainPathNameState);
    const match = useRouteMatch();
    const mainPathName = match.path;

    useEffect(() => {
        setMainPathNameState(match.path);
    }, [match.path, setMainPathNameState]);

    return (
        <Switch>
            <Route path={`${mainPathName}`} exact component={QnaPage} />
            <Route path={`${mainPathName}/:boardQnaId`} component={QnaDetailPage} />
        </Switch>
    );
}
export default Qna;