import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AwaitButton from "../AwaitButton";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import FlightSelectSearchDialog from "../dialogs/FlightSelectSearchDialog";
import { NOOP } from "../../includes/constants";
import ConfirmDialog from "../dialogs/ConfirmDialog";

const FlightSelectActionButton = ({
    className = '',
    disabled = false,
    onSelect = NOOP,
}) => {
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const renderConfirm = useCallback(({ onCancel, onConfirm, data }) => (
        <ConfirmDialog onCancel={onCancel} onConfirm={onConfirm}>
            {`선택하신 주문의 운항정보를 ${data.FlightName}(으)로 변경하시겠습니까?`}
        </ConfirmDialog>
    ), []);

    const flightInfoDialog = useMemo(() => (
        <FlightSelectSearchDialog
            onCancel={closeConfirmation}
            onConfirm={confirm}
            renderConfirm={renderConfirm}
        />
    ), [closeConfirmation, confirm, renderConfirm]);

    const onUpdateFlightClicked = useCallback(() => {
        return openConfirmation(flightInfoDialog)
            .then((confirmedData) => {
                onSelect(confirmedData);
            }).catch(() => {
                // ignore onCancel
            });
    }, [onSelect, flightInfoDialog, openConfirmation]);

    return (
        <AwaitButton
            type="button"
            icon={"pi pi-plus"}
            className={classNames("mb-2 ml-2", className)}
            onClick={onUpdateFlightClicked}
            label={"운항정보 입력"}
            disabled={disabled}
        />
    )
}

FlightSelectActionButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
}

export default FlightSelectActionButton;