import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_ORDER_ITEM_URI } from "../../../includes/constants";

export const TYPE_ORDER_ITEM = "TYPE_ORDER_ITEM";
export const TYPE_TRACKING_NUMBER = "TYPE_TRACKING_NUMBER";

const useOrderItemData = (type = TYPE_ORDER_ITEM) => {
    const [{ data, loading, error }, getData] = useAxios({
            method: 'GET',
        },
        { manual: true },
    );

    const getOrderItem = useCallback((orderItemId) => {
        return getData({
            url: `${API_ORDER_ITEM_URI}/${orderItemId}`
        });
    }, [getData]);

    const getOrderItemByTrackingNumber = useCallback((trackingNumber) => {
        return getData({
            url: `${API_ORDER_ITEM_URI}/tracking?trackingNumber=${trackingNumber}`,
        });
    }, [getData]);

    return [
        {
            data,
            loading,
            error,
        },
        type === TYPE_ORDER_ITEM ? getOrderItem : getOrderItemByTrackingNumber,
    ];
}

export default useOrderItemData;