import { useToastContext } from "../contexts/ToastContext";
import { useCallback } from "react";
import { isObject } from "lodash";

const useToast = () => {
    const {
        showSuccess,
        showInfo,
        showWarn,
        showError
    } = useToastContext();

    const handleShowError = useCallback(({ title, message }) => {
        if (isObject(message)) {
            message?.data?.Contents?.forEach(error => {
                showError({ title, message: error?.Message?.toString() ?? "Unknown error" });
            });

            if (message?.data?.Message) {
                showError({ title, message: message?.data?.Message });
            }
        } else {
            showError({ title, message });
        }
    }, [showError]);

    return {
        showSuccess,
        showInfo,
        showWarn,
        showError: handleShowError
    };
};

export default useToast;
