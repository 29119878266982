import { atom } from "recoil";

export const flightListState = atom({
    key: 'flightListState',
    default: [],
});

export const flightListFilterState = atom({
    key: 'flightListFilterState',
    default: {},
});

export const flightPaginationFilterState = atom({
    key: 'flightPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy : null,
    },
});

// export const filteredFlightListState = selector({
//     key: 'filteredFlightListState',
//     get: ({ get }) => {
//         const filter = get(flightListFilterState);
//         const list = get(flightListState);
//
//         switch (filter) {
//             case 'Show Completed':
//                 return list.filter((item) => item.isComplete);
//             case 'Show Uncompleted':
//                 return list.filter((item) => !item.isComplete);
//             default:
//                 return list;
//         }
//     },
// });
