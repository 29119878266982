import React, { memo } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

const TableTitle = ({
    children,
    onClick = null,
    className = '',
    noPadding = false,
    linkName = null,
}) => (
    <span css={tableTitleStyle(!!onClick, noPadding)} className={className} onClick={onClick}>
        <span id={linkName} css={anchorStyle} />
        {children}
    </span>
);

TableTitle.propTypes = {
    children: PropTypes.node,
}

export default memo(TableTitle);

const tableTitleStyle = (clickable, noPadding) => css`
    display: inline-block;
    padding-left: 1rem;
    
    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
    }

    ${noPadding && css`
        padding: 0;
    `}

    ${clickable && css`
        cursor: pointer;
    `}
`;

const anchorStyle = css`
    position: absolute;
    top: -6rem; // Top nav bar height
    user-select: none;
    width: 0;
    height: 0;
`;