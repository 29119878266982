import React, { useEffect, useState } from 'react';
import { css, keyframes } from "@emotion/react";

const BackgroundAnimationContainer = ({
    className = '',
    triggerKey = null,
    children = null,
}) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (!triggerKey) return;

        setAnimate(true);

        const clear = setTimeout(() => {
            setAnimate(false);
        }, 2000);

        return () => {
            clearTimeout(clear);
        }
    }, [triggerKey]);

    return (
        <div css={backgroundAnimationContainerStyle(animate)} className={className}>
            {children}
        </div>
    )
}

export default BackgroundAnimationContainer;

const backgroundKeyframe = keyframes`
    0% {
        background-color: inherit;
    }
    50% {
        background-color: var(--primary-color);
    }
    100% {
        background-color: inherit;
    }
`;

const backgroundAnimationContainerStyle = animate => css`
    position: relative;

    ${animate && css`
        animation: ${backgroundKeyframe} 1s cubic-bezier(0.5, 0, 0.5, 1) 1;
    `}

`;