import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { TabPanel } from "primereact/tabview";

import { mainPathNameState } from "../../../store";
import useAPI from "../../../core/hooks/useAPI";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";

import useManagerAdminUserData from "./hooks/useManagerAdminUserData";
import { API_MANAGER_ADMIN_URI } from "../../../includes/constants";

import { menuState } from "../../../service/hooks/useMenus";
import Loading from "../../../components/loading/Loading";
import ManagerAdminUserInfo from "./components/ManagerAdminUserInfo";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EnhancedTabView from "../../../components/tabview/EnhancedTabView";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import CardContainer from "../../../components/layouts/CardContainer";

const ManagerAdminUserDetailPage = () => {
    const menuStateValue = useRecoilValue(menuState);
    const [managerAdminUserDataDto, setManagerAdminUserDataDto] = useState(null);
    const history = useHistory();
    const { adminUserId } = useParams();
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const isCreateMode = useMemo(() => adminUserId === "create", [adminUserId]);
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const [{
        data: managerAdminUserData,
        loading: managerAdminUserLoading,
    }, getManagerAdminUser] = useManagerAdminUserData();

    useEffect(() => {
        if (managerAdminUserData) {
            setManagerAdminUserDataDto(managerAdminUserData);
        }
    }, [managerAdminUserData]);

    useEffect(() => {
        if (!isCreateMode && adminUserId) {
            getManagerAdminUser(adminUserId);
        }
    }, [adminUserId, isCreateMode, getManagerAdminUser]);

    /***********************************************************
     * 관리자 생성
     ***********************************************************/
    const createManagerAdminUserInfo = useAPI({
        url: `${API_MANAGER_ADMIN_URI}`,
        method: 'POST',
        successMessage: "관리자를 성공적으로 생성하였습니다.",
    });

    const _onCreateManagerAdminUserInfo = useCallback((data) => {
        return createManagerAdminUserInfo({
            data: data,
        }).then(({ data: returnData }) => {
            setManagerAdminUserDataDto({ ...returnData });
            history.replace(`${mainPathNameStateValue}/${returnData.AdminUserId}`);
        });
    }, [createManagerAdminUserInfo, history, mainPathNameStateValue]);

    const confirmCreateManagerAdminUserInfo = useCallback((data) => {
        return _onCreateManagerAdminUserInfo(data).then(closeConfirmation);
    }, [_onCreateManagerAdminUserInfo, closeConfirmation]);

    /***********************************************************
     * 관리자 수정
     ***********************************************************/
    const updateManagerAdminUserInfo = useAPI({
        url: `${API_MANAGER_ADMIN_URI}/${managerAdminUserDataDto?.AdminUserId}`,
        method: 'PUT',
        successMessage: "관리자를 성공적으로 수정하였습니다.",
    });

    const _onUpdateManagerAdminUserInfo = useCallback((data) => {
        return updateManagerAdminUserInfo({
            data: data,
        }).then(({ data: returnData }) => {
            setManagerAdminUserDataDto(returnData);
        });
    }, [updateManagerAdminUserInfo]);

    const updateManagerAdminUserInfoDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"관리자 수정"}>
            관리자를 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const confirmUpdateManagerAdminUserInfo = useCallback((data) => {
        return openConfirmation(updateManagerAdminUserInfoDialog)
            .then((confirmedData) => {
                _onUpdateManagerAdminUserInfo(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onUpdateManagerAdminUserInfo, updateManagerAdminUserInfoDialog, openConfirmation, closeConfirmation]);

    const breadcrumbItems = useMemo(() => {
        return [
            ...menuStateValue.currentBreadcrumbItems,
            { label: '관리자상세' }
        ]
    }, [menuStateValue]);

    return (
        <div className="grid" css={managerAdminUserDetailPageStyle}>
            {managerAdminUserLoading && <Loading overlay />}
            <div className="col-12">
                <EnhancedBreadCrumb model={breadcrumbItems} showBack />

                <CardContainer>
                    <EnhancedTabView>
                        <TabPanel header="관리자 정보" key={"관리자 정보"}>
                            <ManagerAdminUserInfo
                                managerAdminUserData={managerAdminUserDataDto}
                                onSaveManagerAdminUserInfo={
                                    isCreateMode
                                        ? confirmCreateManagerAdminUserInfo
                                        : confirmUpdateManagerAdminUserInfo
                                }
                            />
                        </TabPanel>
                    </EnhancedTabView>
                </CardContainer>
            </div>
        </div>
    );
}

export default ManagerAdminUserDetailPage;

const managerAdminUserDetailPageStyle = css`
    position: relative;
`;