import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { TabPanel } from "primereact/tabview";

import { mainPathNameState } from "../../../store";
import useAPI from "../../../core/hooks/useAPI";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import useNoticeData from "./hooks/useNoticeData";
import { API_BOARD_NOTICE_URI } from "../../../includes/constants";
import { menuState } from "../../../service/hooks/useMenus";

import NoticeWriter from "./components/NoticeWriter";
import Loading from "../../../components/loading/Loading";
import CardContainer from "../../../components/layouts/CardContainer";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EnhancedTabView from "../../../components/tabview/EnhancedTabView";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";

const NoticeDetailPage = () => {
    const menuStateValue = useRecoilValue(menuState);
    const [noticeDataDto, setNoticeDataDto] = useState(null);
    const history = useHistory();
    const { boardNoticeId } = useParams();
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const isCreateMode = useMemo(() => boardNoticeId === "create", [boardNoticeId]);
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const [{
        data: noticeData,
        loading: noticeLoading,
    }, getNotice] = useNoticeData();

    useEffect(() => {
        if (noticeData) {
            setNoticeDataDto(noticeData);
        }
    }, [noticeData]);

    useEffect(() => {
        if (!isCreateMode && boardNoticeId) {
            getNotice(boardNoticeId);
        }
    }, [boardNoticeId, isCreateMode, getNotice]);

    /***********************************************************
     * 공지사항 생성
     ***********************************************************/
    const createNotice = useAPI({
        url: `${API_BOARD_NOTICE_URI}`,
        method: 'POST',
        successMessage: "공지사항를 성공적으로 생성하였습니다.",
    });

    const _onCreateNotice = useCallback((data) => {
        return createNotice({
            data: data,
        }).then(({ data: returnData }) => {
            setNoticeDataDto({ ...returnData });
            history.replace(`${mainPathNameStateValue}/${returnData.BoardNoticeId}`);
        });
    }, [createNotice, history, mainPathNameStateValue]);

    const confirmCreateNotice = useCallback((data) => {
        return  _onCreateNotice(data).then(closeConfirmation);
    }, [_onCreateNotice, closeConfirmation]);

    /***********************************************************
     * 공지사항 수정
     ***********************************************************/
    const updateNotice = useAPI({
        url: `${API_BOARD_NOTICE_URI}/${noticeDataDto?.BoardNoticeId}`,
        method: 'PUT',
        successMessage: "공지사항를 성공적으로 수정하였습니다.",
    });

    const _onUpdateNotice = useCallback((data) => {
        return updateNotice({
            data: data,
        }).then(({ data: returnData }) => {
            setNoticeDataDto(returnData);
        });
    }, [updateNotice, setNoticeDataDto]);

    const updateNoticeDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"공지사항 수정"}>
            공지사항를 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const confirmUpdateNotice = useCallback((data) => {
        return openConfirmation(updateNoticeDialog)
            .then((confirmedData) => {
                _onUpdateNotice(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onUpdateNotice, updateNoticeDialog, openConfirmation, closeConfirmation]);

    const breadcrumbItems = useMemo(() => {
        return [
            ...menuStateValue.currentBreadcrumbItems,
            { label: isCreateMode ? '글작성' : '글수정' }
        ]
    }, [menuStateValue, isCreateMode]);

    return (
        <div className="grid" css={noticeDetailPageStyle}>
            {noticeLoading && <Loading overlay />}
            <div className="col-12">
                <EnhancedBreadCrumb model={breadcrumbItems} showBack />

                <CardContainer>
                    <EnhancedTabView>
                        <TabPanel header="공지사항" key={"공지사항"}>
                            <NoticeWriter
                                noticeData={noticeDataDto}
                                onSaveNotice={
                                    isCreateMode
                                        ? confirmCreateNotice
                                        : confirmUpdateNotice
                                }
                            />
                        </TabPanel>
                    </EnhancedTabView>
                </CardContainer>
            </div>
        </div>
    );
}

export default NoticeDetailPage;

const noticeDetailPageStyle = css`
    position: relative;
`;