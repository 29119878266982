import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PageFilterContainer = ({ children = '', className }) => (
    <div className={classNames("col-12 grid p-fluid mt-3", className)}>
        {children}
    </div>
);

PageFilterContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default memo(PageFilterContainer);