import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";

import useAPI from "../../core/hooks/useAPI";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import useCustomerData from "./hooks/useCustomerData";
import { API_MANAGER_ACCOUNT_URI } from "../../includes/constants";
import { menuState } from "../../service/hooks/useMenus";

import Loading from "../../components/loading/Loading";
import CustomerInfo from "./components/CustomerInfo";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import CardContainer from "../../components/layouts/CardContainer";
import { TabMenu } from "primereact/tabmenu";
import useTabMenu from "../../core/hooks/useTabMenu";
import MileageList from "./components/MileageList";
import useCustomerMileageDat from "./hooks/useCustomerMileageData";

const wizardItems = [
    { label: '회원정보', isTop: true, },
    { label: '마일리지', linkName: "#MileageList" },
];

const CustomerDetailPage = () => {
    const menuStateValue = useRecoilValue(menuState);
    const [customerDataDto, setCustomerDataDto] = useState(null);
    const { customerId } = useParams();
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();
    const { onTabChange, activeIndex } = useTabMenu(wizardItems);

    const [{
        data: customerData,
        loading: customerLoading,
    }, getCustomer] = useCustomerData();

    const [{
        data: customerMileageData,
    }, getCustomerMileage] = useCustomerMileageDat();

    const refreshMileage = useCallback(() => {
        return getCustomerMileage(customerId);
    }, [customerId, getCustomerMileage]);

    useEffect(() => {
        if (customerData) {
            setCustomerDataDto(customerData);
        }
    }, [customerData]);

    useEffect(() => {
        if (customerId) {
            getCustomer(customerId);
        }
    }, [customerId, getCustomer]);

    useEffect(() => {
        if (customerId) {
            refreshMileage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);


    /***********************************************************
     * 회원 수정
     ***********************************************************/
    const updateCustomerInfo = useAPI({
        url: `${API_MANAGER_ACCOUNT_URI}/${customerDataDto?.UserId}`,
        method: 'PUT',
        successMessage: "회원 정보를 성공적으로 수정하였습니다.",
    });

    const _onUpdateCustomerInfo = useCallback((data) => {
        return updateCustomerInfo({
            data: data,
        }).then(({ data: returnData }) => {
            setCustomerDataDto(returnData);
        });
    }, [updateCustomerInfo, setCustomerDataDto]);

    const updateCustomerInfoDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm} title={"회원 수정"}>
            회원 정보를 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const confirmUpdateCustomerInfo = useCallback((data) => {
        return openConfirmation(updateCustomerInfoDialog)
            .then((confirmedData) => {
                _onUpdateCustomerInfo(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [_onUpdateCustomerInfo, updateCustomerInfoDialog, openConfirmation, closeConfirmation]);

    const breadcrumbItems = useMemo(() => {
        return [
            ...menuStateValue.currentBreadcrumbItems,
            { label: '회원상세' }
        ]
    }, [menuStateValue]);

    return (
        <div className="grid" css={customerDetailPageStyle}>
            {customerLoading && <Loading overlay />}
            <div className="col-12">
                <EnhancedBreadCrumb model={breadcrumbItems} showBack />

                <CardContainer>
                    <TabMenu
                        model={wizardItems}
                        activeIndex={activeIndex}
                        onTabChange={onTabChange}
                    />
                    <CustomerInfo
                        customerData={customerDataDto}
                        onSaveCustomerInfo={confirmUpdateCustomerInfo}
                    />
                </CardContainer>

                <MileageList
                    refresh={refreshMileage}
                    userId={customerDataDto?.UserId}
                    customerMileageData={customerMileageData}
                />

            </div>
        </div>
    );
}

export default CustomerDetailPage;

const customerDetailPageStyle = css`
    position: relative;
`;