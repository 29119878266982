import React, { memo } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

const DataTableActions = ({
    direction = "right",
    className,
    children,
    titleSection,
}) => (
    <div css={dataTableActionsStyle} className={className}>
        <div css={sectionStyle}>
            {titleSection && (
                <div>{titleSection}</div>
            )}
            {direction === "left" && (children)}
        </div>
        <div css={sectionStyle}>
            {direction === "right" && (children)}
        </div>
    </div>
);

DataTableActions.propTypes = {
    direction: PropTypes.oneOf(["left", "right"]),
    className: PropTypes.string,
    children: PropTypes.node,
    titleSection: PropTypes.node,
}

export default memo(DataTableActions);

const dataTableActionsStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    h1, h2, h3, h4, h5, h6, h7, h8 {
        margin: 0;
    }
`;

const sectionStyle = css`
    display: flex;
    align-items: center;
    flex-direction: row;
`;
