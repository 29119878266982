import React from 'react';
import * as PropTypes from "prop-types";
import { ToastContextProvider } from "./ToastContext";
import { ReactPrimeThemeContextProvider } from "./ReactPrimeThemeContext";

const ContextProviderContainer = ({ children }) => (
    <ToastContextProvider>
        <ReactPrimeThemeContextProvider>
            {children}
        </ReactPrimeThemeContextProvider>
    </ToastContextProvider>
);

ContextProviderContainer.propTypes = {
    children: PropTypes.node
};

export const withToastContext = (WrappedComponent) => {
    // eslint-disable-next-line react/display-name
    const wrapper = originalProps => (
        <ContextProviderContainer>
            <WrappedComponent {...originalProps} />
        </ContextProviderContainer>
    );

    // Similar to how React.memo works. So that other files can still access the original component.
    wrapper.type = WrappedComponent;
    return wrapper;
};

export default ContextProviderContainer;
