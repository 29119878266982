import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { isTrue } from "../../includes/ajax";

export const COMMON_CODE_DELIVERY_LOCATION = "deliverylocation";
export const COMMON_CODE_DELIVERY_STATUS = "deliverystatus";
export const COMMON_CODE_ORDER_ITEM_TYPE = "orderItemType";

const useCommonCode = (codeKey = COMMON_CODE_DELIVERY_LOCATION) => {
    const [commonCodeList, setCommonCodeList] = useState([]);

    const [{ data, loading, error }] = useAxios({
            url: `/common/code/${codeKey}`,
            method: 'GET',
        },
    );

    // {
    //     Code: "1"
    //     Name: "NZ"
    //     IsActive: "True"
    //     CreateDate: ""
    // }
    useEffect(() => {
        if (data) {
            setCommonCodeList(data.filter(codeItem => isTrue(codeItem.IsActive)));
        }
    }, [data]);

    return {
        data: commonCodeList,
        loading,
        error,
    }
}

export default useCommonCode;