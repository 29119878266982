import { atom } from "recoil";

export const noticeListState = atom({
    key: 'noticeListState',
    default: [],
});

export const noticeListFilterState = atom({
    key: 'noticeListFilterState',
    default: {},
});

export const noticePaginationFilterState = atom({
    key: 'noticePaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy : null,
    },
});
