import React, { useCallback, useMemo } from 'react';
import { css } from "@emotion/react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import FilterLabel from "./FilterLabel";
import { useRecoilState } from "recoil";
import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import AwaitButton from "../AwaitButton";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import FlightSelectSearchDialog from "../dialogs/FlightSelectSearchDialog";
import { NOOP } from "../../includes/constants";

const DEFAULT_STATE = {
    flightId: null,
    flightName: null,
};

const FlightSelectSearchFilter = ({
    label = "운항 검색",
    state,
    supportQueryString = false,
    className = "",
    deletable = true,
}) => {
    const [flightSelectSearchFilterState, setFlightSelectSearchFilterState] = useRecoilState(state);
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setFlightSelectSearchFilterState({
                flightId: searchObject.flightId,
                flightName: searchObject.flightName,
            });
        }
    }, [setFlightSelectSearchFilterState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const saveFlightInfoDialog = useMemo(() => (
        <FlightSelectSearchDialog onCancel={closeConfirmation} onConfirm={confirm} shouldShowConfirm={false} />
    ), [closeConfirmation, confirm]);

    const confirmSaveFlightInfo = useCallback(() => {
        // const searchStr = trim(e.target.value);
        return openConfirmation(saveFlightInfoDialog)
            .then((confirmedData) => {
                if (confirmedData) {
                    setFlightSelectSearchFilterState({
                        flightId: confirmedData.FlightId,
                        flightName: confirmedData.FlightName,
                    });
                }
                closeConfirmation();
                // _onSaveFlightInfo(data).then(closeConfirmation);
            }).catch(() => {
                // ignore onCancel
            });
    }, [setFlightSelectSearchFilterState, saveFlightInfoDialog, openConfirmation, closeConfirmation]);

    const onDeleteSelectedFlight = useCallback(e => {
        setFlightSelectSearchFilterState({
            ...DEFAULT_STATE,
        })
    }, [setFlightSelectSearchFilterState]);

    return (
        <div className={classNames("grid formgrid", className)} css={searchGroupStyle}>
            <FilterLabel htmlFor={"searchField"}>{label}</FilterLabel>
            <div className="col-6 lg:col-6" css={lastInputStyle}>
                <span className="p-input-icon-right" css={rightIconInputContainerStyle}>
                    {deletable && (
                        <i
                            className="pi pi-plus-circle"
                            css={deleteIconStyle(!!(flightSelectSearchFilterState.flightName || ''))}
                            onClick={onDeleteSelectedFlight}
                        />
                    )}
                    <InputText
                        name="searchStr"
                        type="text"
                        value={flightSelectSearchFilterState.flightName || ''}
                        onChange={NOOP}
                        readOnly
                        onFocus={confirmSaveFlightInfo}
                        css={inputTextStyle}
                    />
                </span>
                <AwaitButton
                    css={buttonStyle}
                    type="submit"
                    onClick={confirmSaveFlightInfo}
                    label={"검색"}
                />
            </div>
        </div>
    )
}

export default FlightSelectSearchFilter;

const searchGroupStyle = theme => css`
    display: flex;
    align-items: center;
    width: max-content;
    margin: 0;
    // display: flex;
    // align-items: center;
    // margin: 0;
    //
        // ${theme.breakpoints.down("xs")} {
    //     > * {
    //         width: 100%;
    //         margin: 2px 0;
    //         padding: 0;
    //     }
    // }
`;

const lastInputStyle = theme => css`
    padding-left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;

    ${theme.breakpoints.down("lg")} {
        display: flex;
        flex: 1;
    }
`;

const buttonStyle = css`
    min-width: 60px;
    margin-left: 10px;

    &.p-button {
        width: unset;
    }
`;

const deleteIconStyle = isShow => css`
    transition: all 300ms ease-in-out;
    will-change: auto;
    display: none;

    ${isShow && css`
        cursor: pointer;
        display: block;

        :hover {
            color: var(--pink-500);
            font-weight: bold;
        }
    `}
`;

const rightIconInputContainerStyle = css`
    &.p-input-icon-right {
        width: unset;
    }

    &.p-input-icon-right > i:last-of-type {
        padding: 5px;
        right: 0;
        top: 0;
        margin: 0;
        font-size: 20px;
        border-radius: 50%;
        transform: rotate(45deg);
        color: var(--pink-400);
    }
`;

const inputTextStyle = css`
    width: 100%;
    max-width: 200px;
    min-width: 10.4rem;
`;
