import {useState, useCallback, useLayoutEffect} from "react";

const getSize = (el) => {
    if (!el) {
        return {
            loaded: false,
            width: 0,
            height: 0
        }
    }

    return {
        loaded: true,
        width: el.offsetWidth,
        height: el.offsetHeight
    }
}

const useComponentSize = (ref) => {
    const [componentSize, setComponentSize] = useState(getSize(ref ? ref.current : {}));

    const handleResize = useCallback( () => {
        if (ref.current) {
            setComponentSize(getSize(ref.current));
        }
    }, [ref]);

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        handleResize();

        if (typeof ResizeObserver === 'function') {
            let resizeObserver = new ResizeObserver(entries => {
                // Wrap handleResize around requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    handleResize();
                });
            });

            resizeObserver.observe(ref.current)

            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                resizeObserver.disconnect(ref.current);
                resizeObserver = null;
            }
        } else {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    return componentSize;
}

export default useComponentSize;
