import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { DEFAULT_PAGE_SIZE, NOOP } from "../../includes/constants";
import { generateUrl, getTotalQueryString, stringifyFilter } from "../../includes/ajax";

const useSearchWithFilters = ({
    apiURI,
    getData = NOOP,
    paginationAtom,
    getParamAndQueryString = NOOP,
}) => {
    const history = useHistory();
    const [paginationFilterState, setPaginationFilterState] = useRecoilState(paginationAtom);

    const getDataWithSortAndPagination = useCallback(({
        pageNo,
        pageSize,
        orderField, // For API
        orderBy, // For API
        sortField, // From DataTable
        sortOrder, // From DataTable
    } = {}) => {
        const [queryStringForAPI, queryString] = getParamAndQueryString();

        const pageObject = {
            ...paginationFilterState,
            ...pageNo && { pageNo },
            ...pageSize && { pageSize },
            ...orderField && { orderField },
            ...orderBy && { orderBy },
            ...sortField && { orderField: sortField ? sortField : null },
            ...sortOrder && { orderBy: sortOrder === 1 ? "ASC" : "DESC" },
        }

        if (!pageObject.pageNo) {
            pageObject.pageNo = 1;
            pageObject.pageSize = DEFAULT_PAGE_SIZE;
        }

        const pageQueryString = qs.stringify(pageObject, { filter: stringifyFilter });

        setPaginationFilterState(prev => {
            return {
                ...prev,
                pageNo,
                pageSize,
                ...orderField && { orderField },
                ...orderBy && { orderBy },
                ...sortField && { orderField: sortField ? sortField : null },
                ...sortOrder && { orderBy: sortOrder === 1 ? "ASC" : "DESC" },
            }
        });

        history.replace(generateUrl(history.location.pathname, getTotalQueryString(pageQueryString, queryString)));
        return getData({ url: generateUrl(apiURI, getTotalQueryString(pageQueryString, queryStringForAPI)) });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getParamAndQueryString, history, getData]);

    return getDataWithSortAndPagination;
}

export default useSearchWithFilters;