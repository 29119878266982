import React, { useCallback, useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";

const useShowConfirm = ({
  renderConfirm,
  renderDeleteConfirm,
  state,
  onConfirm,
  onDelete,
  onCancel,
  shouldShowConfirm = true,
}) => {
  // create / update / delete / null
  const [showConfirm, setShowConfirm] = useState(null);

  const handleConfirm = useCallback((showConfirmType = "create") => {
    setShowConfirm(showConfirmType);
  }, [setShowConfirm]);

  const handleDelete = useCallback(() => {
    setShowConfirm("delete");
  }, [setShowConfirm]);

  const handleReturnConfirm = useCallback(() => {
    onConfirm(state)
  }, [onConfirm, state]);

  const handleReturnDelete = useCallback(() => {
    onDelete(state);
  }, [onDelete, state]);

  const onConfirmCancel = useCallback(() => {
    setShowConfirm(false)
  }, [setShowConfirm]);

  //console.log('showConfirm', showConfirm);

  const render = useCallback(() => {
    if (showConfirm) {

      if (shouldShowConfirm) {
        return (
          <>
            {showConfirm === 'delete'
              ? (
                renderDeleteConfirm({
                  onConfirm: handleReturnDelete,
                  onCancel: onConfirmCancel,
                  data: state,
                })
              )
              : (
                renderConfirm({
                  onConfirm: handleReturnConfirm,
                  onCancel: onConfirmCancel,
                  data: state,
                })
              )
            }
          </>
        );
      } else {
        return (<Loading onTop />);
      }
    }
    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfirm, shouldShowConfirm]);

  useEffect(() => {
    if (!shouldShowConfirm && showConfirm) {
      if (showConfirm === 'delete') {
        handleReturnDelete();
      }
      else {
        handleReturnConfirm();
      }

    }
  }, [shouldShowConfirm, showConfirm, handleReturnConfirm, handleReturnDelete]);



  return {
    showConfirm,
    setShowConfirm,
    handleDelete,
    handleConfirm,
    handleReturnConfirm,
    handleReturnDelete,
    renderConfirmDialog: render,
  };
}

export default useShowConfirm;