import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { atom, useRecoilState } from "recoil";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { trim } from "lodash";
import { Button } from "primereact/button";

import { validateNumber } from "../../includes/numbers";
import InputTextWithError from "../forms/InputTextWithError";
import DropdownWithError from "../forms/DropdownWithError";
import useCommonCode, { COMMON_CODE_DELIVERY_LOCATION } from "../../service/hooks/useCommonCode";
import useShowConfirm from "../../service/hooks/useShowConfirm";
import EnhancedDialog from "./EnhancedDialog";

export const createWeightAmountDialogState = atom({
    key: 'createWeightAmountDialogState',
    default: {
        WeightAmountId: null,
        DeliveryLocationCode: null,
        WeightFrom: "",
        WeightTo: null,
        Amount: null,
        MaxAmount: null,
    },
});

const CreateWeightAmountDialog = ({
    onCancel = null,
    onDelete = null,
    onConfirm,
    title = "배송비용 추가",
    renderConfirm = null, // should be null as default
    renderDeleteConfirm = null, // should be null as default
    shouldShowConfirm = true,
}) => {
    const [state, setState] = useRecoilState(createWeightAmountDialogState);
    const { data: deliveryLocationData } = useCommonCode(COMMON_CODE_DELIVERY_LOCATION);

    const {
        handleDelete,
        handleConfirm,
        renderConfirmDialog,
    } = useShowConfirm({
        renderDeleteConfirm,
        renderConfirm,
        state,
        onCancel,
        onConfirm,
        onDelete,
        shouldShowConfirm,
    });

    const onSaveClicked = useCallback((values) => {
        setState(prev => {
            return {
                ...prev,
                ...values,
            }
        });
        handleConfirm();
    }, [setState, handleConfirm]);

    const formik = useFormik({
        initialValues: {},
        validateOnChange: false,
        validate,
        onSubmit: values => {
            const newValue = {
                ...values,
            };

            newValue.DeliveryLocationCode = newValue.deliveryLocationCodeItem.Code;
            delete newValue.deliveryLocationCodeItem;

            onSaveClicked(newValue);
        },
    });

    useEffect(() => {
        formik.setFieldValue("deliveryLocationCodeItem", deliveryLocationData.find(item => item.Code === state.DeliveryLocationCode));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryLocationData]);

    useEffect(() => {
        formik.setValues({
            ...state
        });

        if (deliveryLocationData) {
            formik.setFieldValue("deliveryLocationCodeItem", deliveryLocationData.find(item => item.Code === state.DeliveryLocationCode));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, deliveryLocationData]);

    const onSubmitForm = useCallback(() => {
        return formik.submitForm();
    }, [formik]);

    const confirmationDialogFooter = useMemo(() => (
        <>
            {onCancel && (
                <Button
                    type="button"
                    label="Close"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-text"
                />
            )}
            {onDelete && (
                <Button
                    type="button"
                    label="Delete"
                    icon="pi pi-trash"
                    onClick={handleDelete}
                    className="p-button-danger"
                />
            )}
            <Button
                type="submit"
                label="Yes"
                icon="pi pi-check"
                autoFocus
                onClick={onSubmitForm}
            />
        </>
    ), [onCancel, onDelete, handleDelete, onSubmitForm]);

    return (
        <EnhancedDialog
            title={title}
            onCancel={onCancel}
            css={createWeightAmountDialogStyle}
            footer={confirmationDialogFooter}
            withLoading={false}
        >
            {renderConfirmDialog()}
            <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
                <div className="p-fluid">
                    <div className="field grid col">
                        <label
                            htmlFor={"deliveryLocationCodeItem"}
                            className="col-12"
                        >
                            배송국가
                        </label>
                        <div className="col-12 sm:col-6">
                            <DropdownWithError
                                name="deliveryLocationCodeItem"
                                value={formik.values.deliveryLocationCodeItem}
                                onChange={formik.handleChange}
                                options={deliveryLocationData}
                                error={formik.errors.deliveryLocationCodeItem}
                                optionLabel="Name"
                                placeholder="Select One"
                            />
                        </div>
                    </div>

                    <div className="field grid col">
                        <label htmlFor="WeightFrom" className="col-12">최소무게</label>
                        <div className="col-12">
                            <InputTextWithError
                                name="WeightFrom"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.WeightFrom ?? ''}
                                error={formik.errors.WeightFrom}
                                min={0}
                                step={0.01}
                            />
                        </div>
                    </div>

                    <div className="field grid col">
                        <label htmlFor="WeightTo" className="col-12">최대무게</label>
                        <div className="col-12">
                            <InputTextWithError
                                name="WeightTo"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.WeightTo ?? ''}
                                error={formik.errors.WeightTo}
                                min={0}
                                step={0.01}
                            />
                        </div>
                    </div>

                    <div className="field grid col">
                        <label htmlFor="Amount" className="col-12">단위금액</label>
                        <div className="col-12">
                            <InputTextWithError
                                name="Amount"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.Amount ?? ''}
                                error={formik.errors.Amount}
                                min={0}
                                step={0.01}
                            />
                        </div>
                    </div>
                    <div className="field grid col">
                        <label htmlFor="MaxAmount" className="col-12">최대배송비</label>
                        <div className="col-12">
                            <InputTextWithError
                                name="MaxAmount"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.MaxAmount ?? ''}
                                error={formik.errors.MaxAmount}
                                min={0}
                                step={0.01}
                            />
                            <small className="p-d-block" css={maxAmountHelpTextStyle}>
                                {MaxAmountHelpText}
                            </small>
                        </div>
                    </div>
                </div>
            </form>

        </EnhancedDialog>
    )
}

CreateWeightAmountDialog.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    renderConfirm: PropTypes.func,
}

export default CreateWeightAmountDialog;

const MaxAmountHelpText = "* 사용하지 않는 경우 0으로 입력해 주세요";

const validate = (values) => {
    const errors = {};

    if (!values.deliveryLocationCodeItem) {
        errors.deliveryLocationCodeItem = "Required";
    }
    if (trim(values.WeightFrom) && !validateNumber(values.WeightFrom)) {
        errors.WeightTo = "소수점 두자리까지 입력이 가능합니다.";
    }
    if (trim(values.WeightTo) && !validateNumber(values.WeightTo)) {
        errors.WeightTo = "소수점 두자리까지 입력이 가능합니다.";
    }
    if (!trim(values.Amount)) {
        errors.Amount = "Required";
    } else if (!validateNumber(values.Amount)) {
        errors.Amount = "소수점 두자리까지 입력이 가능합니다.";
    }
    if (!trim(values.MaxAmount)) {
        errors.MaxAmount = "Required";
    } else if (!validateNumber(values.MaxAmount)) {
        errors.MaxAmount = "소수점 두자리까지 입력이 가능합니다.";
    }

    return errors;
}


const createWeightAmountDialogStyle = css`
    width: 550px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
    
    .field.grid.col {
        margin-bottom: 0;
        > label {
            margin-bottom: 0;
        }
    }
`;

const maxAmountHelpTextStyle = css`
    font-size: 1rem;
    color: #f44336;
`;