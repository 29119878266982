import { atom } from "recoil";

export const deliveryStatusSearchFilterState = atom({
  key: 'deliveryStatusSearchFilterState',
  default: {
    deliveryStatusCode: null,
  },
});

export const flightSelectSearchFilterState = atom({
  key: 'flightSelectSearchFilterState',
  default: {
    flightId: null,
    flightName: null,
  },
});

export const itemEnteredStatusSearchFilterState = atom({
  key: 'itemEnteredStatusSearchFilterState',
  default: {
    itemEnteredStatus: null,
  },
});


export const useMileageStatusSearchFilterState = atom({
  key: 'useMileageStatusSearchFilterState',
  default: {
    useMileageStatus: null,
  },
});

export const combineItemStatusSearchFilterState = atom({
  key: 'combineItemStatusSearchFilterState',
  default: {
    combineItemStatus: null,
  },
});


export const repackageStatusSearchFilterState = atom({
  key: 'repackageStatusSearchFilterState',
  default: {
    repackageStatus: null,
  },
});