import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { currentUserState } from "../../includes/auth";

const LoginUserProfile = () => {
    const currentUser = useRecoilValue(currentUserState);

    if (!currentUser) {
        return null;
    }

    return (
        <div className={"flex flex-column align-items-center"}>
            <div css={userNameStyle}>{currentUser?.name ?? ''}님</div>
            <div css={emailStyle}>{currentUser?.user_login_id ?? ''}</div>
        </div>
    )
}

export default memo(LoginUserProfile);

const userNameStyle = css`
    font-size: 1.2rem;
    font-weight: 600;
`;
const emailStyle = css`
    font-size: 1rem;
    padding-top: 0.3rem;
`;