import React from "react";
import { css } from "@emotion/react";
import Barcode from "react-hooks-barcode";

const config = {
    background: "#f5f5f5",
    width: 2,
    height: 50,
};

const BarcodeBox = ({
    consignment,
    className,
}) => (
    <div css={barCodeBoxContainerStyle} className={className}>
        <div css={consignmentNumberStyle}>Consignment No. {consignment.ConsignmentNumber}</div>
        <Barcode value={consignment.ConsignmentNumber} {...config} />
    </div>
);

export default BarcodeBox;

const barCodeBoxContainerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const consignmentNumberStyle = css`
    text-align: center;
`;