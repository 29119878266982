import React from "react";
import { css } from "@emotion/react";

const Header = ({
    consignment,
}) => {
    return (
        <div css={headerContainer}>
            <div>
                <img
                    src={'/assets/layout/images/kaircargo-logo-dark.svg'}
                    alt="Logo"
                    height="20"
                    className="mr-2"
                />
            </div>
            <div css={headerRightStyle}>
                <div css={consignmentHeaderStyle}>{consignment.ConsignmentHeader}</div>
                <div css={packageCountHeaderStyle}>{consignment.PackageNumber} of {consignment.TotalPackageCount}</div>
            </div>
        </div>
    )
}

export default Header;

const headerContainer = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const headerRightStyle = css`
    text-align: right;
`;

const consignmentHeaderStyle = css`
    font-size: 24px;
`;

const packageCountHeaderStyle = css`
    font-size: 20px;
`;