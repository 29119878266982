import { useEffect, useMemo, useRef, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { Route } from "react-router-dom";
import { isEmpty } from "lodash";
import useAxios from "axios-hooks";

import useToast from "../../core/hooks/useToast";
import { mainPathNameState } from "../../store";
import { menuRouteMap } from "../../core/routes/routes";
import { currentUserState } from "../../includes/auth";
import { API_COMMON_CODE_ADMIN_MENU_ROLE_URI } from "../../includes/constants";

import Empty from "../../pages/Empty";

export const menuState = atom({
    key: 'menuState',
    default: {
        menus: [],
        menuRoutes: [],
        currentMenu: {},
        menuMap: {},
        currentBreadcrumbItems: [],
    },
});

const useMenus = () => {
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const [menuStateState, setMenuStateState] = useRecoilState(menuState);
    const { showError } = useToast();
    const [menus, setMenus] = useState([]);
    const [menuMap, setMenuMap] = useState({});
    const currentUssrInfo = useRecoilValue(currentUserState);
    const loadingRef = useRef(false);

    // https://www.npmjs.com/package/axios-hooks#manual-requests
    const [{ data: menusFromServer, error, loading }, getMenu] = useAxios({
            url: API_COMMON_CODE_ADMIN_MENU_ROLE_URI,
            method: 'GET',
        },
        { manual: true },
    );

    useEffect(() => {
        if (currentUssrInfo && !loadingRef.current) {
            loadingRef.current = true;
            getMenu();
        }
    }, [currentUssrInfo, getMenu]);

    useEffect(() => {
        if (error) {
            loadingRef.current = false;
            showError(error);
        }
    }, [error, showError]);

    useEffect(() => {
        if (mainPathNameStateValue && menuStateState.menus) {
            let currentMenu = null;

            menuStateState.menus.some(menu => {
                const menuItem = menu.items.find(menuItem => {
                    return menuItem.to === mainPathNameStateValue
                });

                if (menuItem) {
                    currentMenu = {
                        ...menu,
                        items: [{
                            ...menuItem,
                        }],
                    }
                    return true;
                }
                return false;
            });

            let currentBreadcrumbItems = null;

            if (currentMenu) {
                currentBreadcrumbItems = [
                    { label: currentMenu.label },
                    { ...currentMenu.items[0] },
                ];
            }

            setMenuStateState(prev => {
                return {
                    ...prev,
                    currentMenu,
                    ...currentBreadcrumbItems && { currentBreadcrumbItems }
                };
            })
        }
    }, [mainPathNameStateValue, setMenuStateState, menuStateState.menus]);

    const menuRoutes = useMemo(() => {
        const routes = [];

        if (isEmpty(menus)) {
            return routes;
        }

        menus.forEach(menu => {
            if (isEmpty(menu.items)) {
                return;
            }

            menu.items.forEach(menuItem => {
                if (menuRouteMap[menuItem.to]) {
                    const Component = menuRouteMap[menuItem.to];
                    routes.push(<Route path={`${menuItem.to}`} component={Component} key={menuItem.to} />);
                } else {
                    routes.push(
                        <Route path={menuItem.to} exact key={menuItem.to}>
                            <Empty path={menuItem.to} />
                        </Route>
                    );
                }
            });
        });

        return routes;
    }, [menus]);

    useEffect(() => {
        if (menusFromServer) {
            loadingRef.current = false;
            setMenus(convertMenus(menusFromServer));
            setMenuMap(convertMenuMap(menusFromServer));
        }
    }, [menusFromServer]);

    useEffect(() => {
        setMenuStateState(prev => {
            return {
                ...prev,
                menus: menus,
                menuMap: menuMap,
                menuRoutes: menuRoutes,
            }
        })
    }, [menus, menuRoutes, setMenuStateState, menuMap]);

    return { menus, menuRoutes, loading };
}

const convertMenuMap = menusFromServer => {
    const menuMap = {};

    menusFromServer.forEach(menuItem => {
        menuMap[menuItem.Route] = true;
    });

    return menuMap;
}

const convertMenus = menusFromServer => {
    const menus = menusFromServer.map(menuFromServer => convertMenu(menuFromServer));

    const menuGroups = [];

    menus.forEach(menu => {
        if (menuGroups[menu.label]) {
            menuGroups[menu.label].items = [...menuGroups[menu.label].items, ...menu.items];
        } else {
            menuGroups[menu.label] = menu;
        }
    });

    const newMenus = menus.map(menu => {
        const menuGroup = menuGroups[menu.label];
        delete menuGroups[menu.label];
        return menuGroup;
    })

    return newMenus.filter(i => i);
}

const convertMenu = (menuFromServer) => {
    return {
        label: menuFromServer.MenuHeader,
        items: [
            {
                label: menuFromServer.MenuName,
                // icon: 'pi pi-fw pi-bars',
                to: menuFromServer.Route,
            },
        ]
    }
}

export default useMenus;