import React from "react";
import classNames from "classnames";
import { css } from "@emotion/react";

const CardContainer = ({ className, children }) => (
    <div className={classNames("card", className)} css={cardContainerStyle}>
        {children}
    </div>
)

export default CardContainer;

const cardContainerStyle = css`
    position: relative;
`;