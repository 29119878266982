import React, { memo, useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useFormik } from "formik";
import { Link, Redirect, useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { trim } from "lodash";
import { validate as validateEmail } from "email-validator";
import { InputText } from "primereact/inputtext";

import useToast from "../../core/hooks/useToast";
import AwaitButton from "../../components/AwaitButton";
import { currentUserState, getToken, getUserFromStorage, login } from "../../includes/auth";
import { useReactPrimeThemeContext } from "../../core/contexts/ReactPrimeThemeContext";

const Login = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const setCurrentUssrInfo = useSetRecoilState(currentUserState);
    const { showError } = useToast();
    const token = getToken();
    const user = getUserFromStorage();

    const {
        layoutColorMode,
    } = useReactPrimeThemeContext();

    const onLoginClicked = useCallback(({ username, password }) => {
        setLoading(true);
        return login(username, password).then(async () => {
            setCurrentUssrInfo(getUserFromStorage());
            if (history.location?.pathname === "/login") {
                history.replace("/");
            } else {
                history.push(history.location?.pathname ?? "/");
            }
        }).catch((e) => {
            setLoading(false);
            showError({ message: e.message });
        });
    }, [history, setCurrentUssrInfo, showError]);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validateOnChange: false,
        validate,
        onSubmit: values => {
            onLoginClicked(values);
        },
    });

    useEffect(() => {
        if (!token) {
            setCurrentUssrInfo(null);
            document.documentElement.style.fontSize = "16px";
        }
    }, [token, setCurrentUssrInfo]);

    if (token && user) {
        return <Redirect to={"/"} />;
    }

    return (
        <div className="col-12 md:col-6" css={loginStyle}>
            <div className="card p-fluid relative">

                <Link to="/" className="layout-topbar-logo">
                    <img
                        src={layoutColorMode === 'light'
                            ? '/assets/layout/images/kaircargo-logo-dark.svg'
                            : '/assets/layout/images/kaircargo-logo-white.svg'
                        }
                        alt="logo"
                        css={logoStyle}
                    />
                </Link>

                <form
                    css={formStyle}
                    className="card p-fluid"
                    onSubmit={formik.handleSubmit}
                >
                    <h5>Please sign in</h5>
                    <div className="field">
                        <label htmlFor="username">Email</label>
                        <InputText
                            name="username"
                            type="text"
                            placeholder={"email"}
                            onChange={formik.handleChange}
                            value={formik.values.username || ''}
                            {...formik.errors.username && {
                                className: "p-invalid"
                            }}
                        />
                        {formik.errors.username && (
                            <small className="p-error">{formik.errors.username}</small>
                        )}
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <InputText
                            name="password"
                            type="password"
                            placeholder={"password"}
                            onChange={formik.handleChange}
                            value={formik.values.password || ''}
                            {...formik.errors.password && {
                                className: "p-invalid"
                            }}
                        />
                        {formik.errors.password && (
                            <small className="p-error">{formik.errors.password}</small>
                        )}
                    </div>
                    <AwaitButton
                        className="mr-2 mb-2"
                        css={buttonStyle}
                        disabled={!formik.isValid && !formik.dirty}
                        loading={loading}
                        type="submit"
                    >
                        Sign in
                    </AwaitButton>
                </form>
            </div>
        </div>
    );
}

export default memo(Login);

const validate = (values) => {
    const errors = {};

    if (!trim(values.username)) {
        errors.username = "Required";
    } else if (!validateEmail(values.username)) {
        errors.username = 'Invalid username';
    }

    if (!trim(values.password)) {
        errors.password = "Required";
    }

    return errors;
}

const formStyle = css`
    position: relative;
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
`;

const loginStyle = css`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
`;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

const logoStyle = css`
    width: 80%;
    display: flex;
    margin: 10px auto;
`;