import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { atom, useRecoilState } from "recoil";
import { useFormik } from "formik";
import { trim } from "lodash";

import { Button } from "primereact/button";
import InputTextWithError from "../forms/InputTextWithError";
import EnhancedDialog from "./EnhancedDialog";

export const createCustomerMileageDialogState = atom({
    key: 'createCustomerMileageDialogState',
    default: {
        UserId: 0,
        MileageId: 0,
        MileageName: "",
        MileagePoint: 0,
    },
});

const CreateCustomerMileageDialog = ({
    onCancel,
    onConfirm,
    title = "마일리지 추가",
    renderConfirm = null, // should be null as default
}) => {
    const [state, setState] = useRecoilState(createCustomerMileageDialogState);
    const [showConfirm, setShowConfirm] = useState(false);

    const onSaveClicked = useCallback((values) => {
        setState(values);
        setShowConfirm(true);
    }, [setState]);

    const formik = useFormik({
        initialValues: {
            ...state,
        },
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            const newValue = {
                ...values,
            };

            onSaveClicked(newValue);
        },
    });

    const handleReturnConfirm = useCallback(() => {
        onConfirm(state)
    }, [onConfirm, state]);

    useEffect(() => {
        // 수정할때 적용되는 값들
        if (state.MileageId) {
            formik.setValues({
                ...state,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const onSubmitForm = useCallback(() => {
        return formik.submitForm();
    }, [formik]);

    const confirmationDialogFooter = useMemo(() => (
        <>
            {onCancel && (
                <Button
                    type="button"
                    label="Close"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-text"
                />
            )}
            <Button
                type="submit"
                label="Yes"
                icon="pi pi-check"
                autoFocus
                onClick={onSubmitForm}
            />
        </>
    ), [onCancel, onSubmitForm]);

    const onConfirmCancel = useCallback(() => {
        setShowConfirm(false)
    }, [setShowConfirm]);

    return (
        <EnhancedDialog
            title={title}
            onCancel={onCancel}
            css={createCustomerMileageDialogStyle}
            footer={confirmationDialogFooter}
        >
            {showConfirm && renderConfirm && (
                renderConfirm({
                    onConfirm: handleReturnConfirm,
                    onCancel: onConfirmCancel,
                    data: state,
                })
            )}
            <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
                <div className="p-fluid">

                    <div className="field grid">
                        <label htmlFor="MileageName" className="col-12">마일리지명</label>
                        <div className="col-12">
                            <InputTextWithError
                                name="MileageName"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.MileageName || ''}
                                error={formik.errors.MileageName}
                            />
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="MileagePoint" className="col-12">
                            마일리지 포인트
                        </label>
                        <div className="col-12">
                            <InputTextWithError
                                name="MileagePoint"
                                type="number"
                                onChange={formik.handleChange}
                                defaultValue={formik.values.MileagePoint ?? ''}
                                error={formik.errors.MileagePoint}
                                min={0}
                                step={1}
                            />
                        </div>
                    </div>

                </div>

            </form>
        </EnhancedDialog>
    )
}

CreateCustomerMileageDialog.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    renderConfirm: PropTypes.func,
}

export default CreateCustomerMileageDialog;

const validate = (values) => {
    const errors = {};

    if (!trim(values.MileageName)) {
        errors.MileageName = "Required";
    }
    if (!trim(values.MileagePoint) || !values.MileagePoint) {
        errors.MileagePoint = "Required";
    }

    return errors;
}


const createCustomerMileageDialogStyle = css`
    width: 350px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;