import React, { useCallback, useMemo } from "react";
import { atom, useRecoilState } from "recoil";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { Button } from "primereact/button";

import EnhancedDialog from "./EnhancedDialog";
import useShowConfirm from "../../service/hooks/useShowConfirm";
import DropdownWithError from "../forms/DropdownWithError";

const isPaidStatusDropdownItems = [
  { Name: '결제 전', Code: 0 },
  { Name: '결제 완료', Code: 1 }
];

export const purchaseOrderItemPaidDialogState = atom({
  key: 'purchaseOrderItemPaidDialogState',
  default: {
    IsPaid: 0
  },
});

const PurchaseOrderItemPurchaseDialog = ({
  onCancel = null,
  onConfirm,
  title = "결제처리",
  renderConfirm = null, // should be null as default
  readOnly = false,
  shouldShowConfirm = false,
}) => {


  const [state, setState] = useRecoilState(purchaseOrderItemPaidDialogState);

  const { handleConfirm, renderConfirmDialog, }
    = useShowConfirm({ renderConfirm, state, onCancel, onConfirm, shouldShowConfirm, });

  const onSaveClicked = useCallback((values) => {

    setState(prev => {
      return {
        ...prev,
        ...values,
      }
    });
    handleConfirm();
  }, [setState, handleConfirm]);

  const validate = (values) => {
    const errors = {};

    if (!values.isPaidStatusDropdownItems) {
      errors.isPaidStatusDropdownItems = "Required";
    }
    return errors;
  }

  console.log(state.IsPaid)

  const formik = useFormik({
    initialValues: {
      ...state,
      isPaidStatusDropdownItems: isPaidStatusDropdownItems.find(item => item.Code === state.IsPaid),
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {

      const newValue = {
        ...values,
      };

      newValue.IsPaid = newValue.isPaidStatusDropdownItems.Code;
      delete newValue.isPaidStatusDropdownItems;

      onSaveClicked(newValue);
    },
  });

  // useEffect(() => {

  //   console.log("useEffect.state", state);
  //   formik.setValues({
  //     ...state,
  //   });

  //   //initialise the drop down list item 
  //   formik.setFieldValue("isEnteredStatusDropdownItem", isEnteredStatusDropdownItems.find(item => item.Code === state.IsEntered));

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state, isEnteredStatusDropdownItems]);


  const onSubmitForm = useCallback(() => {

    return formik.submitForm();
  }, [formik]);

  const confirmationDialogFooter = useMemo(() => (
    <>
      {onCancel && (
        <Button
          type="button"
          label="Close"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-text"
        />
      )}


      {onConfirm && (
        <Button
          type="submit"
          label="Yes"
          icon="pi pi-check"
          autoFocus
          onClick={onSubmitForm}
        />
      )}

    </>
  ), [onConfirm, onCancel, onSubmitForm]);


  return (
    <EnhancedDialog
      title={title}
      onCancel={onCancel}
      css={createOrderItemDialogStyle}
      footer={confirmationDialogFooter}
      withLoading={false}
    >
      {renderConfirmDialog()}
      <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
        <div className="p-fluid">
          <div className="field grid">
            <label
              htmlFor={"IsOrdered"}
              className="col-12 mb-2 md:col-3 md:mb-0"
            >
              결제상태
            </label>
            <DropdownWithError
              className="col-12 md:col-9"
              name="isPaidStatusDropdownItems"
              value={formik.values.isPaidStatusDropdownItems}
              onChange={formik.handleChange}
              options={isPaidStatusDropdownItems}
              error={formik.errors.isPaidStatusDropdownItems}
              optionLabel="Name"
              placeholder="Select One"
              readOnly={readOnly}
            />
          </div>
        </div>
      </form>
    </EnhancedDialog>
  );
};

export default PurchaseOrderItemPurchaseDialog;

const createOrderItemDialogStyle = css`
    width: 450px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;