import React from "react";
import { css } from "@emotion/react";
import { RadioButton } from "primereact/radiobutton";
import { NOOP } from "../../includes/constants";


const IsReceiverOwnRadioButtons = ({
  name = "IsReceiverOwn",
  isActive = false,
  handleChange = NOOP,
  className = "",
}) => {



  return (
    <div className={className} css={clearMarginOfGridStyle}>
      <div className="col-12 md:col-4">
        <div className="field-radiobutton">
          <RadioButton
            inputId={`${name}True`}
            name={name}
            value={true}
            checked={isActive}
            onChange={handleChange}
          />
          <label
            htmlFor={`${name}True`}
            css={hoverClickableStyle}
          >
            본인수령
          </label>
        </div>
      </div>
      <div className="col-12 md:col-4">
        <div className="field-radiobutton">
          <RadioButton
            inputId={`${name}False`}
            name={name}
            value={false}
            checked={!isActive}
            onChange={handleChange}
          />
          <label
            htmlFor={`${name}False`}
            css={hoverClickableStyle}
          >
            대리수령
          </label>
        </div>
      </div>
    </div>
  )
}

export default IsReceiverOwnRadioButtons;


const clearMarginOfGridStyle = css`
    margin: 0;
`;

const hoverClickableStyle = css`
    cursor: pointer;
`;


