import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_MANAGER_ACCOUNT_URI } from "../../../includes/constants";

const useCustomerMileageDat = () => {
    const [{ data, loading, error }, getData] = useAxios({
            method: 'GET',
        },
        { manual: true },
    );

    const getCustomerMileage = useCallback((userId) => {
        return getData({
            url: `${API_MANAGER_ACCOUNT_URI}/${userId}/mileage`
        });
    }, [getData]);

    return [
        {
            data,
            loading,
            error,
        },
        getCustomerMileage,
    ];
}

export default useCustomerMileageDat;