import React from "react";
import { css } from "@emotion/react";
import FromToLabel from "./FromToLabel";
import AddressBoxContainer from "./AddressBoxContainer";

const FromBox = ({
    consignment,
    className,
}) => (
    <div css={fromBoxContainerStyle} className={className}>
        <FromToLabel>From:</FromToLabel>
        <AddressBoxContainer>
            {console.log(consignment)}
            <div>{consignment.UserName}</div>
            <div>{consignment.SenderAddress1} {consignment.SenderAddress2} {consignment.SenderZipcode}</div>
            <div>{consignment.SenderMobileNumber}</div>
            <div>{consignment.SenderPhoneNumber}</div>
        </AddressBoxContainer>
    </div>
);

export default FromBox;

const fromBoxContainerStyle = css`
    display: flex;
    flex-direction: row;
`;