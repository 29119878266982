import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import useComponentSize from "../../core/hooks/useComponentSize";
import breakpoints from "../../theme/breakpoints";

const ScrollDataTableContainer = ({
    children,
    minWidth = breakpoints.sizes["3xl"],
    loading = true,
}) => {
    const [containerWidth, setContainerWidth] = useState();

    const containerRef = useRef();
    const componentSize = useComponentSize(containerRef);

    useEffect(() => {
        setContainerWidth(componentSize?.width);
    }, [componentSize]);

    return (
        <div css={scrollContainerStyle} ref={containerRef}>
            <div css={innerScrollContainerStyle({
                minWidth,
                loading,
                paginationWith: containerWidth
            })}>
                {children}
            </div>
        </div>
    )
}

ScrollDataTableContainer.propTypes = {
    children: PropTypes.node,
    minWidth: PropTypes.number,
    loading: PropTypes.bool,
}

export default ScrollDataTableContainer;

const scrollContainerStyle = css`
    overflow: auto;
    position: relative;

    .p-datatable {
        position: unset;
    }

    .p-toolbar {
        flex-wrap: wrap;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    /* Responsive */

    .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.datatable-responsive {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    border-bottom: 1px solid var(--surface-d);

                    > td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }

                        .actions {
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
`;

const innerScrollContainerStyle = ({ minWidth, loading, paginationWith }) => css`
    .p-datatable .p-datatable-tbody > tr.datatable-row-summary {
        background-color: var(--surface-c); 
    }
    
    .p-paginator.p-component.p-paginator-bottom {
        position: sticky;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    
    @media screen and (min-width: 961px) {
        min-width: ${minWidth}px;

        ${!loading && css`
            .p-datatable-loading-overlay.p-component-overlay {
                width: 100%;
            }
        `}

        ${paginationWith && css`
            .p-paginator.p-component.p-paginator-bottom {
                max-width: ${paginationWith}px;
            }
        `}
    }
`;