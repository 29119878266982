import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_FLIGHT_URI } from "../../../includes/constants";

const useFlightData = () => {
    const [{ data, loading, error }, getData] = useAxios({
            method: 'GET',
        },
        { manual: true },
    );

    const getFlight = useCallback((flightId) => {
        return getData({
            url: `${API_FLIGHT_URI}/${flightId}`
        });
    }, [getData]);

    return [
        {
            data,
            loading,
            error,
        },
        getFlight,
    ];
}

export default useFlightData;