import moment from "moment";
import { css } from "@emotion/react";
import { DATE_FORMAT_FROM_SERVER } from "./dates";
import { isTrue } from "./ajax";

const newBadgeContainerStyle = css`
    position: relative;
    margin-left: 10px
`;

const newBadgeStyle = css`
    position: absolute;
    top: -3px
`;

export const getNewDataBadge = (rowData, field) => {
  if (!rowData[field]) {
    return "";
  }

  const timeDiff = moment().diff(moment(rowData[field], DATE_FORMAT_FROM_SERVER), "h");

  if (timeDiff < 24) {
    return (
      <span css={newBadgeContainerStyle}>
        <span
          className=" p-tag p-tag-rounded p-tag-success"
          css={newBadgeStyle}
        >
          new
        </span>
      </span>
    )
  }

  return "";
};

const isNoMeaningfulValue = (value) => {
  return (value === undefined || value === null || value === "");
}

/**
 * 단순하게 boolean 값으로 리턴값이 결정되는 경우.
 */
const columnBooleanValues = {
  IsAllItemEntered: {
    [true]: "입고완료",
    [false]: "입고전",
  },
  IsPaymentCompleted: {
    [true]: "입금완료",
    [false]: "입금전",
  },
  IsEntered: {
    [true]: "입고완료",
    [false]: "입고전",
  },
  IsActive: {
    [true]: "사용",
    [false]: "미사용",
  },
  IsSecret: {
    [true]: "비밀글",
    [false]: "공개글",
  },
  IsAddToInvoice: {
    [true]: "발행완료",
    [false]: "발행전",
  },
  IsSummaryData: {
    [true]: "합계",
    [false]: "",
  },
  CommentCount: {
    [true]: "답변완료",
    [false]: "답변전",
  },
  IsCombineItem: {
    [true]: "Yes",
    [false]: "No",
  },
  IsUseMileage: {
    [true]: "Yes",
    [false]: "No",
  },
  IsRepackage: {
    [true]: "Yes",
    [false]: "No",
  },
  PickupTypeCode: {
    [true]: "픽업",
    [false]: "배송",
  },
  IsPaid: {
    [true]: "결제완료",
    [false]: "결제전",
  }
  // IsOrdered: {
  //   0: '주문전',
  //   1: '주문완료',
  //   2: '배대지이동중'
  // }
}

/**
 * 함수를 홀용해서 로직이 필요한 항목들
 */
const columnCommandValues = {
  CommentCount: (value) => {
    return columnBooleanValues["CommentCount"][Number(value) > 0];
  },
  PickupTypeCode: (value) => {
    // eslint-disable-next-line eqeqeq
    return columnBooleanValues["PickupTypeCode"][value == 1];
  },
  // IsOrdered: (value) => {
  //   console.log(value)
  //   return columnBooleanValues["IsOrdered"][value];
  // }
}

const columnNonBooleanValues = {
   IsOrdered: {
    0: '주문전',
    1: '주문완료',
    2: '배대지이동중'
  }
}


/**
 * 서버에서 온 field/value 값을 화면에 노출할 수 있는 의미있는 값으로 변경해서 리턴한다.
 * @param field
 * @param value
 * @returns {string|*}
 */
export const getColumnValue = (field, value) => {
  if (isNoMeaningfulValue(value)) return "";
  if (columnNonBooleanValues[field]) {
    return columnNonBooleanValues[field][value]
  }

  return columnCommandValues[field]?.(value) ?? (
    columnBooleanValues[field]?.[isTrue(value)] ?? value
  );
}