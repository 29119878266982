import React, { useCallback, useMemo } from "react";
import { throttle } from "lodash";

import ManagerAdminUserPageFilter from "./components/ManagerAdminUserPageFilter";
import useManagerAdminUsersData from "./hooks/useManagerAdminUsersData";
import { DataTable } from "primereact/datatable";
import useDataTableExportCSV from "../../../core/hooks/useDataTableExportCSV";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../../includes/constants";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import DataTableActions from "../../../components/layouts/DataTableActions";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import AwaitButton from "../../../components/AwaitButton";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { mainPathNameState } from "../../../store";

const fieldColumnTitleMap = {
    AdminUserId: "관리자 ID",
    AdminLoginId: "로그인 ID",
    AdminName: "관리자명",
    EmailAddress: "이메일",
    IsActive: "사용여부",
    AdminRoleId: "권한 ID",
    AdminRoleName: "권한명",
    PhoneNumber: "전화번호",
    MobileNumber: "휴대폰번호",
}

const sortableFields = [];

const ManagerAdminUserPage = () => {
    const history = useHistory();
    const mainPathNameStateValue = useRecoilValue(mainPathNameState);
    const { dataTableRef } = useDataTableExportCSV();
    const [{
        data: managerAdminUsersData,
        loading: managerAdminUsersDataLoading
    }, getManagerAdminUsers] = useManagerAdminUsersData();

    const onSearch = useMemo(() => {
        return throttle((e) => {
            if (managerAdminUsersDataLoading) return getPromise();
            return getManagerAdminUsers();
        }, 1000);
    }, [getManagerAdminUsers, managerAdminUsersDataLoading]);

    const onCreateManagerAdminUserClicked = useCallback(() => {
        history.push(`${mainPathNameStateValue}/create`);
    }, [history, mainPathNameStateValue]);

    const {
        sortPaginationProps,
        renderColumn,
        renderLinkColumn,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getManagerAdminUsers,
        dataSize: managerAdminUsersData?.Data?.length ?? 0,
    });

    return (
        <div className="grid">
            <div className="col-12">

                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>관리자 리스트</h5>
                    <ManagerAdminUserPageFilter onSearch={onSearch} loading={managerAdminUsersDataLoading} />
                </div>

                <div className="card">

                    <DataTableActions className={"mb-2"}>
                        <AwaitButton
                            icon="pi pi-plus"
                            type="button"
                            className={"mb-2"}
                            onClick={onCreateManagerAdminUserClicked}
                            label={"관리자 등록"}
                        />
                    </DataTableActions>

                    <ScrollDataTableContainer loading={managerAdminUsersDataLoading} minWidth={1440}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={managerAdminUsersDataLoading}
                            ref={dataTableRef}
                            value={managerAdminUsersData?.Data || []}
                            dataKey="AdminUserId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={managerAdminUsersData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={managerAdminUsersData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                        >
                            {renderColumn("AdminLoginId")}
                            {renderLinkColumn("AdminName", "AdminUserId")}
                            {renderColumn("EmailAddress")}
                            {renderColumn("IsActive")}
                            {renderColumn("AdminRoleName")}
                            {renderColumn("PhoneNumber")}
                            {renderColumn("MobileNumber")}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}
export default ManagerAdminUserPage;