import React, { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from "recoil";

import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

import FilterLabel from "../filters/FilterLabel";
import DropdownWithError from "./DropdownWithError";

const DropdownField = ({
    label = null,
    atom,
    supportQueryString = false,
    className = "",
    itemTemplate = null,
    dropdownClassName = "",
    dropdownItems = [],
    stateKey = "item",
    name = "",
    placeholder = "Select One",
    error = null,
}) => {
    const setState = useSetRecoilState(atom);
    const state = useRecoilValue(atom);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setState({
                [stateKey]: searchObject[stateKey],
            });
        }
    }, [stateKey, setState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setState(prev => {
            if (prev[stateKey]) return prev;

            return {
                ...prev,
                [stateKey]: defaultItem,
            }
        });
    }, [setState, stateKey]);

    useSetFilterDefaultValue({
        items: dropdownItems,
        isAlreadySet: !!state[stateKey],
        setFilterDefaultValue,
    });

    // useEffect(() => {
    //     // 처음 한번만 세팅 해 주세요.
    //     if (!state[stateKey]) {
    //         if (!isEmpty(dropdownItems)) {
    //             const defaultItem = dropdownItems.find(i => i.isDefault);
    //             if (defaultItem) {
    //                 setState(prev => {
    //                     if (prev[stateKey]) return prev;
    //
    //                     return {
    //                         ...prev,
    //                         [stateKey]: defaultItem,
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //     // TODO setState
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dropdownItems, setState]);

    const setDropdownItem = useCallback(e => {
        setState({
            [stateKey]: e.value,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setState]);

    return (
        <div className={className}>
            {label && (
                <FilterLabel htmlFor={name}>{label}</FilterLabel>
            )}
            <DropdownWithError
                name={name}
                value={dropdownItems.find(item => item.Code === state[stateKey]?.Code)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="Name"
                placeholder={placeholder}
                className={dropdownClassName}
                error={error}
                {...itemTemplate && { itemTemplate }}
            />
        </div>
    )
}

export default DropdownField;