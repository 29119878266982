import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { trim } from "lodash";
import { Checkbox } from "primereact/checkbox";

import { NOOP } from "../../../../includes/constants";
import { toBooleanValue } from "../../../../includes/ajax";
import { useAwaitingDialogContext } from "../../../../core/contexts/AwaitingDialogContext";

import AwaitButton from "../../../../components/AwaitButton";
import InputTextWithError from "../../../../components/forms/InputTextWithError";
import EnhancedEditor from "../../../../components/editor/EnhancedEditor";
import DataTableActions from "../../../../components/layouts/DataTableActions";
import { Button } from "primereact/button";

const QnaWriter = ({
    onSave = NOOP,
    toggleEditModeClicked = NOOP,
    qnaData = null,
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const history = useHistory();
    const editorKey = useRef(Math.random());

    const onSaveClicked = useCallback((values) => {
        return onSave(values);
    }, [onSave]);

    const formik = useFormik({
        initialValues: {
            Title: '',
            Contents: '',
            WriterName: '',
            IsActive: true,
            IsSecret: false,
        },
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            onSaveClicked(values);
        },
    });

    const resetForm = useCallback(() => {
        formik.setValues({
            Title: qnaData?.Title || '',
            Contents: qnaData?.Contents || '',
            WriterName: qnaData?.WriterName || '관리자',
            IsActive: toBooleanValue(qnaData?.IsActive, true),
            IsSecret: toBooleanValue(qnaData?.IsSecret, false),
        });
        editorKey.current = Math.random();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qnaData]);

    const onContentsChanged = useCallback((contents) => {
        formik.setFieldValue("Contents", contents);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goBackToList = useCallback(() => {
        history.goBack();
        closeConfirmation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    const leftSectionButtons = useMemo(() => {
        return (
            <>
                <AwaitButton
                    buttonClassName={"p-button-outlined"}
                    icon="pi pi-replay"
                    css={buttonStyle}
                    label={"수정 취소"}
                    type="button"
                    onClick={toggleEditModeClicked}
                />
                <AwaitButton
                    buttonClassName={"p-button-secondary"}
                    icon="pi pi-replay"
                    css={buttonStyle}
                    label={"리셋"}
                    type="button"
                    onClick={resetForm}
                />
                <AwaitButton
                    icon="pi pi-check"
                    label={"저장"}
                    type="submit"
                    css={buttonStyle}
                />
            </>
        )
    }, [resetForm, toggleEditModeClicked]);

    return (
        <div className="col-12">
            <form onSubmit={formik.handleSubmit}>

                <div className="p-fluid formgrid grid" css={maxWidthStyle}>
                    <DataTableActions className={"mb-5"} direction={"right"} titleSection={leftSectionButtons}>
                        <Button
                            className={"p-button-info"}
                            icon="pi pi-list"
                            label={"목록"}
                            type="button"
                            onClick={goBackToList}
                        />
                    </DataTableActions>


                    <div className="field col-12">
                        <div className={"col-12 grid"} css={titleContainerStyle}>
                            <label htmlFor="Title">제목</label>
                            <div className="field-checkbox mb-1" css={checkboxContainerStyle}>
                                <Checkbox
                                    inputId="IsSecret"
                                    name="IsSecret"
                                    value={formik.values.IsSecret}
                                    onChange={formik.handleChange}
                                    checked={formik.values.IsSecret}
                                />
                                <label
                                    htmlFor="IsSecret"
                                    className="p-checkbox-label"
                                    css={checkboxLabelStyle}
                                >
                                    비밀글
                                </label>
                            </div>
                        </div>
                        <InputTextWithError
                            name="Title"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.Title || ''}
                            error={formik.errors.Title}
                        />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="Contents">내용</label>
                        <EnhancedEditor
                            defaultContents={formik.values.Contents}
                            onChange={onContentsChanged}
                            error={formik.errors.Contents}
                            key={editorKey.current}
                        />
                    </div>


                </div>
            </form>
        </div>
    )
}

export default QnaWriter;

const validate = (values) => {
    const errors = {};

    if (!trim(values.Title)) {
        errors.Title = "Required";
    }
    if (!trim(values.WriterName)) {
        errors.WriterName = "Required";
    }
    if (!trim(values.Contents)) {
        errors.Contents = "Required";
    } else if (!values.Contents.includes("<img")) {
        const cleanText = values.Contents.replace(/<\/?[^>]+(>|$)/g, "");
        if (!trim(cleanText)) {
            errors.Contents = "Required";
        }
    }

    if (!trim(values.IsActive)) {
        errors.IsActive = "Required";
    }
    return errors;
}

const buttonStyle = css`
    margin-right: 10px;
`;

const maxWidthStyle = css`
    max-width: 800px;
`;

const checkboxContainerStyle = theme => css`
    display: flex;
    align-items: flex-end;
`;

const checkboxLabelStyle = css`
    cursor: pointer;
    margin-left: 0.5rem;
    margin-bottom: 0 !important;
    user-select: none;
`;

const titleContainerStyle = css`
    display: flex;
    justify-content: space-between;
    margin-top: 0;
`;