import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { useFormik } from "formik";
import { toInteger, trim } from "lodash";

import { Button } from "primereact/button";

import { toNumber, validateNumber } from "../../includes/numbers";
import { DELIVERY_LOCATION_CODE_TO_NZ, NOOP, taxRateDropdownItems } from "../../includes/constants";
import useOrderChargeType from "../../service/hooks/useOrderChargeType";

import Loading from "../loading/Loading";
import EnhancedDialog from "./EnhancedDialog";
import DropdownWithError from "../forms/DropdownWithError";
import InputTextWithError from "../forms/InputTextWithError";
import useShowConfirm from "../../service/hooks/useShowConfirm";
import { Checkbox } from "primereact/checkbox";
import { isTrue, toBooleanValue } from "../../includes/ajax";
import { createOrderChargeDialogDeliveryLocationCodeState } from "../../pages/Order/store";

export const createOrderChargeDialogState = atom({
    key: 'createOrderChargeDialogState',
    default: {
        OrderId: 0,
        ChargeId: 0,
        ChargeTypeId: 0,
        ChargeName: "",
        ChargeAmount: 0,
        UnitQty: 0,
        TaxRate: 0,
        DeliveryLocationCode: DELIVERY_LOCATION_CODE_TO_NZ,
        IsManualInput: false,
        IsAddToInvoice: false,
    },
});

const CreateOrderChargeDialog = ({
    onCancel = null,
    onDelete = null,
    onConfirm,
    title = "비용추가",
    renderConfirm = null, // should be null as default
    renderDeleteConfirm = null, // should be null as default
    shouldShowConfirm = true,
}) => {

    const [state, setState] = useRecoilState(createOrderChargeDialogState);
    const deliveryLocationCodeStateValue = useRecoilValue(createOrderChargeDialogDeliveryLocationCodeState);
    const { data: orderChargeTypesData, loading: orderChargeTypesLoading } = useOrderChargeType();
    const readOnly = isTrue(state?.IsAddToInvoice);
    const [loading, setLoading] = useState(true);
    const alreadySet = useRef(false);

    const {
        handleDelete,
        handleConfirm,
        renderConfirmDialog,
    } = useShowConfirm({
        renderDeleteConfirm,
        renderConfirm,
        state,
        onCancel,
        onConfirm,
        onDelete,
        shouldShowConfirm,
    });

    const filteredOrderChargeTypesData = useMemo(() => {
        if (orderChargeTypesLoading) {
            return [];
        }
        // string DeliveryLocationCode
        return orderChargeTypesData.filter(item => item.DeliveryLocationCode === deliveryLocationCodeStateValue.DeliveryLocationCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderChargeTypesLoading, orderChargeTypesData]);

    const onSaveClicked = useCallback((values) => {
        setState(prev => ({
            ...prev,
            ...values,
        }));
        handleConfirm();
    }, [setState, handleConfirm]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            const newValue = {
                ...values,
            };

            newValue.ChargeTypeId = newValue.orderChargeTypeDropdownItem.ChargeTypeId;
            newValue.TaxRate = Number(newValue.taxRateItem.Code);

            if (newValue.IsManualInput) {
                newValue.ItemAmount = newValue.itemAmountCalculated;
                newValue.TaxAmount = newValue.taxAmountCalculated;
                newValue.TotalAmount = newValue.totalAmountCalculated;
                newValue.UnitQty = newValue.UnitQty || 1;
            } else {
                newValue.TotalAmount = 0;
            }

            delete newValue.itemAmountCalculated;
            delete newValue.taxAmountCalculated;
            delete newValue.totalAmountCalculated;
            delete newValue.taxRateItem;
            delete newValue.orderChargeTypeDropdownItem;
            delete newValue.UnitAmount;

            onSaveClicked(newValue);
        },
    });

    const handleChangeUnitQty = useCallback(e => {
        const nativeEvent = e.nativeEvent || e;

        if (!validateNumber(nativeEvent.target.value ?? 0)) {
            nativeEvent.preventDefault();
            nativeEvent.stopPropagation();
            return false;
        } else {
            formik.handleChange(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            taxRateItem: taxRateDropdownItems[0],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!alreadySet.current) return NOOP;

        if (formik.values.orderChargeTypeDropdownItem) {
            const {
                ChargeTypeName,
                TaxRate,
                ChargeAmount,
            } = formik.values.orderChargeTypeDropdownItem;

            formik.setValues(prev => {
                return {
                    ...prev,
                    ChargeName: ChargeTypeName,
                    taxRateItem: taxRateDropdownItems.find(item => Number(item.Code) === Number(TaxRate)) ?? taxRateDropdownItems[0],
                    ChargeAmount: Number(ChargeAmount || "0"),
                    UnitQty: 1,
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.orderChargeTypeDropdownItem]);

    const updateCalculatedValues = useCallback((
        ChargeAmount = 0,
        UnitQty = 0,
        taxRateItem = taxRateDropdownItems[0],
    ) => {
        const totalAmountCalculated = toNumber(ChargeAmount * UnitQty);
        // const itemAmountCalculated = toNumber(totalAmountCalculated / (100 + Number(taxRateItem.Code)) * 100);
        const UnitAmount = toNumber(ChargeAmount / (100 + Number(taxRateItem.Code)) * 100);
        const taxAmountCalculated = toNumber(totalAmountCalculated - (UnitAmount * UnitQty)) ?? 0;

        formik.setValues(prev => {
            return {
                ...prev,
                UnitAmount: UnitAmount,
                itemAmountCalculated: totalAmountCalculated - taxAmountCalculated,
                taxAmountCalculated,
                totalAmountCalculated,
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!alreadySet.current) return NOOP;
        const {
            ChargeAmount = 0,
            UnitQty = 0,
            taxRateItem = taxRateDropdownItems[0],
            IsManualInput,
        } = formik.values;
        if (IsManualInput) return NOOP;

        updateCalculatedValues(ChargeAmount, UnitQty, taxRateItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.ChargeAmount, formik.values.UnitQty, formik.values.taxRateItem]);

    useEffect(() => {
        // 수정할때 적용되는 값들
        if (state.ChargeId) {
            setLoading(true);

            const ChargeAmount = toNumber(state.ChargeAmount);
            const UnitQty = toNumber(state.UnitQty);
            const taxRateItem = taxRateDropdownItems.find(item => Number(item.Code) === Number(state.TaxRate)) ?? taxRateDropdownItems[0]

            formik.setValues({
                ...formik.values,
                ...state,
                ChargeAmount,
                UnitQty,
                IsManualInput: toBooleanValue(state?.IsManualInput),
                ...isTrue(state.IsManualInput) && {
                    itemAmountCalculated: Number(state.ItemAmount),
                    taxAmountCalculated: Number(state.TaxAmount),
                    totalAmountCalculated: Number(state.TotalAmount),
                },
                taxRateItem,
                orderChargeTypeDropdownItem: filteredOrderChargeTypesData.find(item => Number(item.ChargeTypeId) === Number(state.ChargeTypeId)),
            });

            setTimeout(() => {
                alreadySet.current = true;

                setTimeout(() => {
                    setLoading(false);
                    !isTrue(state.IsManualInput) && updateCalculatedValues(ChargeAmount, UnitQty, taxRateItem);
                }, 200)

            }, 200);

        } else {
            const setNewOrderChargeTimeout = setTimeout(() => {
                alreadySet.current = true;
                setLoading(false);
            }, 1000);
            return () => {
                clearTimeout(setNewOrderChargeTimeout);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, filteredOrderChargeTypesData]);

    const onSubmitForm = useCallback(() => {
        return formik.submitForm();
    }, [formik]);

    const confirmationDialogFooter = useMemo(() => (
        <>
            {onCancel && (
                <Button
                    type="button"
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-text"
                />
            )}
            {!readOnly && (
                <>
                    {onDelete && (
                        <Button
                            type="button"
                            label="Delete"
                            icon="pi pi-trash"
                            onClick={handleDelete}
                            className="p-button-danger"
                        />
                    )}
                    <Button
                        type="submit"
                        label="Save"
                        icon="pi pi-check"
                        autoFocus
                        onClick={onSubmitForm}
                    />
                </>
            )}
        </>
    ), [readOnly, onCancel, onDelete, handleDelete, onSubmitForm]);

    return (
        <EnhancedDialog
            title={title}
            onCancel={onCancel}
            css={createOrderChargeDialogStyle}
            footer={confirmationDialogFooter}
            withLoading={false}
        >
            {renderConfirmDialog()}
            <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
                {(loading || orderChargeTypesLoading) && (<Loading onTop />)}

                <div className="p-fluid">

                    <div className="field grid">
                        <label
                            htmlFor={"orderChargeTypeDropdownItem"}
                            className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                            비용타입
                        </label>
                        {readOnly
                            ? (
                                <div className="col-12 md:col-9">
                                    <InputTextWithError
                                        name="orderChargeTypeDropdownItem"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.orderChargeTypeDropdownItem?.ChargeTypeName || ''}
                                        error={formik.errors.orderChargeTypeDropdownItem}
                                        readOnly={readOnly}
                                    />
                                </div>
                            )
                            : (
                                <DropdownWithError
                                    className="col-12 md:col-9"
                                    name="orderChargeTypeDropdownItem"
                                    options={filteredOrderChargeTypesData}
                                    onChange={formik.handleChange}
                                    value={formik.values.orderChargeTypeDropdownItem || ''}
                                    error={formik.errors.orderChargeTypeDropdownItem}
                                    optionLabel="ChargeTypeName"
                                    placeholder="Select One"
                                    readOnly={readOnly}
                                />)
                        }
                    </div>

                    <div className="field grid">
                        <label htmlFor="ChargeName" className="col-12 mb-2 md:col-3 md:mb-0">비용명</label>
                        <div className="col-12 md:col-9">
                            <InputTextWithError
                                name="ChargeName"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.ChargeName || ''}
                                error={formik.errors.ChargeName}
                                readOnly={readOnly}
                            />
                        </div>
                    </div>


                    {/*****************************************************
                     * 첫번째 금액 Row
                     *****************************************************/}
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="ChargeAmount">비용</label>
                            <InputTextWithError
                                name="ChargeAmount"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.ChargeAmount ?? ''}
                                error={formik.errors.ChargeAmount}
                                disabled={!!formik.values.IsManualInput}
                                min={0}
                                step={0.01}
                                readOnly={readOnly}
                            />
                        </div>
                        <div className="field col">
                            <label htmlFor="taxRateItem">GST</label>
                            {readOnly
                                ? (
                                    <InputTextWithError
                                        name="TaxRate"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={`${toInteger(formik.values.TaxRate ?? 0)}%`}
                                        disabled={!!formik.values.IsManualInput}
                                        readOnly={readOnly}
                                    />
                                )
                                : (
                                    <DropdownWithError
                                        name="taxRateItem"
                                        options={taxRateDropdownItems}
                                        onChange={formik.handleChange}
                                        value={formik.values.taxRateItem}
                                        error={formik.errors.taxRateItem}
                                        disabled={readOnly || !!formik.values.IsManualInput}
                                        optionLabel="Name"
                                        placeholder="Select One"
                                        dropdownClassName={'createOrderChargeTaxRateStyle'}
                                        readOnly={readOnly}
                                    />)
                            }
                        </div>
                        <div className="field col">
                            <label htmlFor="UnitQty">수량</label>
                            <InputTextWithError
                                name="UnitQty"
                                type="number"
                                onChange={handleChangeUnitQty}
                                value={formik.values.UnitQty ?? ''}
                                error={formik.errors.UnitQty}
                                disabled={!!formik.values.IsManualInput}
                                min={0}
                                step={0.01}
                                readOnly={readOnly}
                            />
                        </div>
                        <div className="field col">
                            <label htmlFor="UnitAmount">단위금액</label>
                            <InputTextWithError
                                name="UnitAmount"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.UnitAmount ?? ''}
                                disabled={!!formik.values.IsManualInput}
                                min={0}
                                step={1}
                                readOnly={true}
                            />
                        </div>
                    </div>


                    {/*****************************************************
                     * 두번째 금액 Row
                     *****************************************************/}
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="itemAmountCalculated">금액</label>
                            <InputTextWithError
                                name="itemAmountCalculated"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.itemAmountCalculated ?? ''}
                                readOnly={readOnly || !formik.values.IsManualInput}
                            />
                        </div>

                        <div className="field col">
                            <label htmlFor="taxAmountCalculated">GST</label>
                            <InputTextWithError
                                name="taxAmountCalculated"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.taxAmountCalculated ?? ''}
                                readOnly={readOnly || !formik.values.IsManualInput}
                            />
                        </div>

                        <div className="field col">
                            <label htmlFor="totalAmountCalculated">총금액</label>
                            <InputTextWithError
                                name="totalAmountCalculated"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.totalAmountCalculated ?? ''}
                                readOnly={readOnly || !formik.values.IsManualInput}
                            />
                        </div>
                    </div>

                    <div className="field col-12 md:col-6 field-checkbox flex align-items-center">
                        <Checkbox
                            inputId="IsManualInput"
                            name="IsManualInput"
                            value={formik.values.IsManualInput}
                            onChange={formik.handleChange}
                            checked={!!formik.values.IsManualInput}
                            readOnly={readOnly}
                        />
                        <label
                            htmlFor="IsManualInput"
                            className="p-checkbox-label"
                            css={checkboxLabelStyle}
                        >
                            비용 직접 입력
                        </label>
                    </div>

                </div>

            </form>
        </EnhancedDialog>
    );
}

CreateOrderChargeDialog.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    renderConfirm: PropTypes.func,
    shouldShowConfirm: PropTypes.bool,
}

export default CreateOrderChargeDialog;

const validate = (values) => {
    const errors = {};

    if (!trim(values.ChargeName)) {
        errors.ChargeName = "Required";
    }

    if (values.IsManualInput) {
        if (!trim(values.itemAmountCalculated)) {
            errors.itemAmountCalculated = "Required";
        }
        if (!trim(values.taxAmountCalculated)) {
            errors.taxAmountCalculated = "Required";
        }
        if (!values.totalAmountCalculated) {
            errors.totalAmountCalculated = "Required";
        }
    } else {
        if (!trim(values.ChargeAmount)) {
            errors.ChargeAmount = "Required";
        }
        if (!trim(values.UnitAmount)) {
            errors.UnitAmount = "Required";
        }
        if (!trim(values.UnitQty)) {
            errors.UnitQty = "Required";
        } else if (!validateNumber(values.UnitQty)) {
            errors.UnitQty = "소수점 두자리까지 입력이 가능합니다.";
        }

        if (!values.taxRateItem) {
            errors.taxRateItem = "Required";
        }
    }

    return errors;
}

const createOrderChargeDialogStyle = css`
    width: 450px;

    .p-dropdown {
        min-width: 200px;
    }
    
    .createOrderChargeTaxRateStyle {
        min-width: 100px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;

const checkboxLabelStyle = css`
    cursor: pointer;
    margin-left: 0.5rem;
    margin-bottom: 0 !important;
    user-select: none;
`;