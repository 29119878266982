import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { atom, useRecoilState } from "recoil";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { trim } from "lodash";
// import isUrl from 'is-valid-http-url';
import { Button } from "primereact/button";

import { deliveryCompanyItems } from "../../includes/constants";
import useCommonCode, { COMMON_CODE_ORDER_ITEM_TYPE } from "../../service/hooks/useCommonCode";
import EnhancedDialog from "./EnhancedDialog";
import InputTextWithError from "../forms/InputTextWithError";
import DropdownWithError from "../forms/DropdownWithError";
import useShowConfirm from "../../service/hooks/useShowConfirm";
import { isNZ } from "../../includes/countries";

export const createOrderItemDialogState = atom({
  key: 'createOrderItemDialogState',
  default: {
    OrderItemId: 0,
    ItemTypeCode: 0,
    ItemName: "",
    ItemQuantity: 0,
    ItemUrl: "",
    ItemAmount: 0,
    DeliveryCompany: "",
    TrackingNumber: "",
    DeliveryLocationCode: "",
  },
});

const CreateOrderItemDialog = ({
  onCancel = null,
  onDelete = null,
  onConfirm,
  title = "상품추가",
  renderConfirm = null, // should be null as default
  renderDeleteConfirm = null, // should be null as default
  readOnly = false,
  shouldShowConfirm = true,
}) => {
  const [state, setState] = useRecoilState(createOrderItemDialogState);
  const { data: orderItemTypesData } = useCommonCode(COMMON_CODE_ORDER_ITEM_TYPE);


  const {
    handleDelete,
    handleConfirm,
    renderConfirmDialog,
  } = useShowConfirm({
    renderDeleteConfirm,
    renderConfirm,
    state,
    onCancel,
    onConfirm,
    onDelete,
    shouldShowConfirm,
  });

  const onSaveClicked = useCallback((values) => {

    setState(prev => {
      return {
        ...prev,
        ...values,
      }
    });
    handleConfirm();
  }, [setState, handleConfirm]);

  const validate = useMemo(() => {
    return (values) => {
      const errors = {};

      if (!values.itemTypeDropdownItem) {
        errors.itemTypeDropdownItem = "Required";
      }

      // 택배사는 필수가 아님.
      // if (isNZ(state.DeliveryLocationCode)) {
      //     if (!values.deliveryCompanyDropdownItem) {
      //         errors.deliveryCompanyDropdownItem = "Required";
      //     }
      // } else {
      //     if (!values.DeliveryCompany) {
      //         errors.DeliveryCompany = "Required";
      //     }
      // }
      if (!trim(values.ItemName)) {
        errors.ItemName = "Required";
      }

      if (!trim(values.ItemAmount)) {
        errors.ItemAmount = "Required";
      }

      if (!trim(values.ItemQuantity)) {
        errors.ItemQuantity = "Required";
      }

      return errors;
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      // ...state,
      // itemTypeDropdownItem: orderItemTypesData.find(item => item.Code === state.ItemTypeCode),
      // deliveryCompanyDropdownItem: deliveryCompanyItems.find(item => item.Code === state.DeliveryCompany),
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate,
    onSubmit: values => {
      console.log('onSubmit.values', values);
      const newValue = {
        ...values,
        ItemQuantity: values.ItemQuantity || "1", // TODO 일단 1개로
      };

      if (isNZ(state.DeliveryLocationCode)) {
        newValue.DeliveryCompany = newValue.deliveryCompanyDropdownItem?.Code ?? "";
      }
      newValue.ItemTypeCode = newValue.itemTypeDropdownItem.Code;
      newValue.ItemTypeName = orderItemTypesData.find(item => item.Code === newValue.itemTypeDropdownItem.Code)?.Name;
      delete newValue.itemTypeDropdownItem;
      delete newValue.deliveryCompanyDropdownItem;
      delete newValue.DeliveryLocationCode; // 이건 필요 없는 정보지?

      onSaveClicked(newValue);
    },
  });

  useEffect(() => {
    if (state.OrderItemId) {
      console.log("useEffect.state", state);
      formik.setValues({
        ...state,
        ItemQuantity: state.ItemQuantity || 1,
      });

      formik.setFieldValue("itemTypeDropdownItem", orderItemTypesData.find(item => item.Code === state.ItemTypeCode));
      formik.setFieldValue("deliveryCompanyDropdownItem", deliveryCompanyItems.find(item => item.Code === state.DeliveryCompany));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, orderItemTypesData, deliveryCompanyItems]);

  const onSubmitForm = useCallback(() => {
    return formik.submitForm();
  }, [formik]);

  const confirmationDialogFooter = useMemo(() => (
    <>
      {onCancel && (
        <Button
          type="button"
          label="Close"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-text"
        />
      )}
      {!readOnly && (
        <>
          {onDelete && (
            <Button
              type="button"
              label="Delete"
              icon="pi pi-trash"
              onClick={handleDelete}
              className="p-button-danger"
            />
          )}
          {onConfirm && (
            <Button
              type="submit"
              label="Yes"
              icon="pi pi-check"
              autoFocus
              onClick={onSubmitForm}
            />
          )}
        </>
      )}
    </>
  ), [readOnly, onConfirm, onCancel, onDelete, handleDelete, onSubmitForm]);

  return (
    <EnhancedDialog
      title={title}
      onCancel={onCancel}
      css={createOrderItemDialogStyle}
      footer={confirmationDialogFooter}
      withLoading={false}
    >
      {renderConfirmDialog()}
      <form onSubmit={formik.handleSubmit} noValidate css={formStyle}>
        <div className="p-fluid">

          <div className="field grid">
            <label
              htmlFor={"itemTypeDropdownItem"}
              className="col-12 mb-2 md:col-3 md:mb-0"
            >
              상품분류
            </label>
            <DropdownWithError
              className="col-12 md:col-9"
              name="itemTypeDropdownItem"
              value={formik.values.itemTypeDropdownItem}
              onChange={formik.handleChange}
              options={orderItemTypesData}
              error={formik.errors.itemTypeDropdownItem}
              optionLabel="Name"
              placeholder="Select One"
              readOnly={readOnly}
            />
          </div>

          <div className="field grid">
            <label htmlFor="ItemName" className="col-12 mb-2 md:col-3 md:mb-0">상품명</label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="ItemName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ItemName ?? ''}
                error={formik.errors.ItemName}
                readOnly={readOnly}
              />
            </div>
          </div>

          <div className="field grid">
            <label htmlFor="ItemAmount" className="col-12 mb-2 md:col-3 md:mb-0">상품가격</label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="ItemAmount"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ItemAmount ?? ''}
                error={formik.errors.ItemAmount}
                min={0}
                step={1}
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="ItemQuantity" className="col-12 mb-2 md:col-3 md:mb-0">상품수량</label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="ItemQuantity"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ItemQuantity ?? ''}
                error={formik.errors.ItemQuantity}
                min={0}
                step={1}
                readOnly={readOnly}
              />
            </div>
          </div>

          {isNZ(state.DeliveryLocationCode)
            ? (
              <div className="field grid">
                <label
                  htmlFor={"deliveryCompanyDropdownItem"}
                  className="col-12 mb-2 md:col-3 md:mb-0"
                >
                  택배사
                </label>
                <DropdownWithError
                  className="col-12 md:col-9"
                  name="deliveryCompanyDropdownItem"
                  value={formik.values.deliveryCompanyDropdownItem}
                  onChange={formik.handleChange}
                  options={deliveryCompanyItems}
                  error={formik.errors.deliveryCompanyDropdownItem}
                  optionLabel="Name"
                  placeholder="Select One"
                  readOnly={readOnly}
                />
              </div>
            )
            : (
              <div className="field grid">
                <label htmlFor="DeliveryCompany" className="col-12 mb-2 md:col-3 md:mb-0">택배사</label>
                <div className="col-12 md:col-9">
                  <InputTextWithError
                    name="DeliveryCompany"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.DeliveryCompany}
                    error={formik.errors.DeliveryCompany}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            )
          }

          <div className="field grid">
            <label
              htmlFor="TrackingNumber"
              className="col-12 mb-2 md:col-3 md:mb-0"
            >
              송장번호
            </label>
            <div className="col-12 md:col-9">
              <InputTextWithError
                name="TrackingNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.TrackingNumber ?? ''}
                error={formik.errors.TrackingNumber}
                readOnly={readOnly}
              />
            </div>
          </div>

        </div>


      </form>


    </EnhancedDialog>
  );
}

CreateOrderItemDialog.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  renderConfirm: PropTypes.func,
}

export default CreateOrderItemDialog;

const createOrderItemDialogStyle = css`
    width: 450px;

    .p-dropdown {
        min-width: 200px;
    }
`;

const formStyle = css`
    width: 100%;
    padding-top: 1rem;
`;