import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAxios from "axios-hooks";
import { useSetRecoilState } from "recoil";

import { DataTable } from "primereact/datatable";

import useAPI from "../../../core/hooks/useAPI";
import useToast from "../../../core/hooks/useToast";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import { API_ORDER_NOTE_URI, NOOP } from "../../../includes/constants";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";

import Loading from "../../../components/loading/Loading";
import TableTitle from "../../../components/layouts/TableTitle";
import CardContainer from "../../../components/layouts/CardContainer";
import DataTableActions from "../../../components/layouts/DataTableActions";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import CreateOrderNoteDialog, { createOrderNoteDialogState } from "../../../components/dialogs/CreateOrderNoteDialog";

const fieldColumnTitleMap = {
  OrderNoteId: "메모 ID",
  OrderId: "주문 ID",
  AdminUserId: "관리자 ID",
  AdminUserName: "관리자명",
  Note: "내용",
  CreateDate: "작성일",
}

const OrderNoteList = ({
  refresh = NOOP,
  orderData = null,
}) => {
  const { closeConfirmation } = useAwaitingDialogContext();
  const { showSuccess, showError } = useToast();
  const [selectedItem, setSelectedItem] = useState(null);
  const setCreateOrderNoteDialogState = useSetRecoilState(createOrderNoteDialogState);

  const {
    renderColumn,
  } = useDataTableColumns({
    fieldColumnTitleMap,
    getData: NOOP,
    dataSize: orderData?.OrderNoteList?.length ?? 0,
  });

  const titleSection = useMemo(() => (
    <TableTitle linkName={"OrderNoteList"}>
      <h5>관리메모</h5>
    </TableTitle>
  ), []);

  /***********************************************************
   * 메모 추가
   ***********************************************************/
  const [{
    data: createOrderNoteData,
    error: createOrderNoteError
  }, createOrderNote] = useAxios({
    url: `${API_ORDER_NOTE_URI}`,
    method: 'POST',
  },
    { manual: true },
  );

  useEffect(() => {
    if (createOrderNoteData) {
      showSuccess({ message: `메모를 성공적으로 저장하였습니다.` });
      refresh().finally(closeConfirmation);
    }
    // refresh / closeConfirmation 넣으면 안됨.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderNoteData, showSuccess]);

  useEffect(() => {
    if (createOrderNoteError) {
      showError({ message: createOrderNoteError });
      closeConfirmation();
    }
  }, [createOrderNoteError, closeConfirmation, showError]);

  const onCreateOrderNote = useCallback((orderNoteData) => {
    if (orderNoteData) {
      createOrderNote({
        data: {
          OrderId: Number(orderData.OrderId),
          Note: orderNoteData.Note,
        }
      });
    }
  }, [createOrderNote, orderData]);

  /***********************************************************
   * 메모 삭제
   ***********************************************************/
  const deleteOrderNote = useAPI({
    url: API_ORDER_NOTE_URI,
    method: 'DELETE',
    successMessage: "메모를 성공적으로 삭제하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onDeleteItem = useCallback((orderNoteData) => {
    if (orderNoteData) {
      deleteOrderNote({
        url: `${API_ORDER_NOTE_URI}/${orderNoteData.OrderNoteId}`,
      });
    } else {
      closeConfirmation();
    }
  }, [deleteOrderNote, closeConfirmation]);

  /***********************************************************
   * 메모 수정
   ***********************************************************/
  const updateOrderItem = useAPI({
    url: API_ORDER_NOTE_URI,
    method: 'PUT',
    successMessage: "메모를 성공적으로 수정하였습니다.",
    callbackAfterSuccess: refresh,
  });

  const onUpdateItem = useCallback((orderNoteData) => {
    if (orderNoteData) {
      updateOrderItem({
        url: `${API_ORDER_NOTE_URI}/${orderNoteData.OrderNoteId}`,
        data: {
          OrderId: Number(orderNoteData.OrderId),
          Note: orderNoteData.Note,
        }
      });
    } else {
      closeConfirmation();
    }
  }, [updateOrderItem, closeConfirmation]);

  const onUpdateClicked = useCreateActionButton({
    onSelect: onUpdateItem,
    confirmMessage: "메모를 수정하시겠습니까?",
    deleteConfirmMessage: "메모를 삭제하시겠습니까?",
    onDelete: onDeleteItem,
    atom: createOrderNoteDialogState,
    dialogComponent: (props) => {
      return (
        <CreateOrderNoteDialog title={"메모 수정"} {...props} />
      );
    },
  });

  useEffect(() => {
    if (selectedItem && !selectedItem.IsSummaryData) {
      setCreateOrderNoteDialogState(selectedItem);
      onUpdateClicked().finally(() => setSelectedItem(null));
    }
    // onUpdateClicked 를 디펜던시에 넣으면 안되요!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, setCreateOrderNoteDialogState]);

  return (
    <CardContainer>
      {!orderData && <Loading />}

      <DataTableActions className={"mb-2"} titleSection={titleSection}>
        <CreateActionButton
          icon="pi pi-plus"
          label="메모생성"
          onSelect={onCreateOrderNote}
          confirmMessage={"메모를 생성하시겠습니까?"}
          atom={createOrderNoteDialogState}
          dialogComponent={CreateOrderNoteDialog}
          shouldShowConfirm={false}
        />
      </DataTableActions>

      <ScrollDataTableContainer loading={!orderData} minWidth={768}>
        <DataTable
          resizableColumns
          columnResizeMode="fit"
          loading={!orderData}
          value={orderData?.OrderNoteList || []}
          selectionMode={"single"}
          selection={selectedItem}
          onSelectionChange={(e) => setSelectedItem(e.value)}
          dataKey="OrderNoteId"
          className="datatable-responsive"
          emptyMessage="No data found."
        >
          {renderColumn("AdminUserName")}
          {renderColumn("CreateDate")}
          {renderColumn("Note")}
        </DataTable>
      </ScrollDataTableContainer>
    </CardContainer>
  );
}

export default OrderNoteList;
