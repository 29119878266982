import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import InvoicePage from "./InvoicePage";
import { useSetRecoilState } from "recoil";
import { mainPathNameState } from "../../store";

const Invoice = () => {
    const setMainPathNameState = useSetRecoilState(mainPathNameState);
    const match = useRouteMatch();
    const mainPathName = match.path;

    useEffect(() => {
        setMainPathNameState(match.path);
    }, [match.path, setMainPathNameState]);

    return (
        <Switch>
            <Route path={`${mainPathName}`} exact component={InvoicePage} />
        </Switch>
    );
}
export default Invoice;