import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { throttle } from "lodash";
import { DataTable } from "primereact/datatable";

import { formatCurrency, formatNumber } from "../../includes/numbers";
import { mainPathNameState } from "../../store";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../includes/constants";
import useOrderItemsData from "./hooks/useOrderItemsData";
import useDataTableColumns from "../../core/hooks/useDataTableColumns";
import useDataTableExportCSV from "../../core/hooks/useDataTableExportCSV";
import AwaitButton from "../../components/AwaitButton";
import ScrollDataTableContainer from "../../components/layouts/ScrollDataTableContainer";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import OrderItemPageFilter from "./components/OrderItemPageFilter";
import DataTableActions from "../../components/layouts/DataTableActions";

const fieldColumnTitleMap = {
  OrderId: "주문 ID",
  OrderNumber: "주문번호",
  UserId: "주문자 ID",
  UserName: "주문자명",
  OrderItemId: "상품 ID",
  ItemTypeCode: "상품타입코드",
  ItemTypeName: "상품타입명",
  ItemName: "상품명",
  ItemAmount: "상품가격",
  ItemQuantity: "상품수량",
  ItemUrl: "상품구매주소",
  DeliveryCompany: "배송회사",
  TrackingNumber: "송장번호",
  ItemWeight: "무게(kg)",
  ItemWidth: "상품폭",
  ItemHeight: "상품높이",
  ItemDepth: "상품깊이",
  ItemVolumeWeight: "부피무게(kg)",
  IsEntered: "입고상태",
  EnteredDate: "입고일",
  IsAddedToInvoice: "인보이스 발행여부",
  DeliveryLocationCode: "배송지역 코드 ",
  DeliveryLocationName: "배송지역",
  TotalChargeableWeight: "Chargeable Wt(kg)",
  IsUseMileage: "마일리지사용",
  IsCombineItem: "합배송",
  IsRepackage: "재포장",
}

const sortableFields = [
  "OrderItemId",
  "OrderNumber",
  "UserName",
  "OrderDate",
  "ItemName",
  "IsEntered",
  "EnteredDate",
  "ItemWeight",
  "ItemVolumeWeight"
];

const OrderItemPage = () => {
  const history = useHistory();
  const mainPathNameStateValue = useRecoilValue(mainPathNameState);
  const [selectedItems, setSelectedItems] = useState(null);
  const { dataTableRef } = useDataTableExportCSV();
  const [{ data: orderItemsData, loading: orderItemsDataLoading }, getOrderItems] = useOrderItemsData();

  const onSearch = useMemo(() => {
    return throttle((e) => {
      if (orderItemsDataLoading) return getPromise();
      return getOrderItems();
    }, 1000);
  }, [getOrderItems, orderItemsDataLoading]);

  const onCreateOrderItemClicked = useCallback(() => {
    history.push(`${mainPathNameStateValue}/tracking`);
  }, [history, mainPathNameStateValue]);

  const {
    sortPaginationProps,
    renderColumn,
    renderLinkColumn,
  } = useDataTableColumns({
    fieldColumnTitleMap,
    sortableFields,
    getData: getOrderItems,
    dataSize: orderItemsData?.Data?.length ?? 0,
  });

  return (
    <div className="grid" css={orderItemPageStyle}>
      <div className="col-12">

        <EnhancedBreadCrumb />

        <div className="card">
          <h5>상품 입고 리스트</h5>
          <OrderItemPageFilter onSearch={onSearch} loading={orderItemsDataLoading} />
        </div>

        <div className="card">
          <DataTableActions>
            <AwaitButton
              icon="pi pi-file"
              type="button"
              className={"mb-2"}
              onClick={onCreateOrderItemClicked}
              label={"상품 입고 처리"}
            />
          </DataTableActions>

          <ScrollDataTableContainer loading={orderItemsDataLoading} minWidth={1400}>
            <DataTable
              resizableColumns
              columnResizeMode="fit"
              loading={orderItemsDataLoading}
              ref={dataTableRef}
              value={orderItemsData?.Data || []}
              selection={selectedItems}
              onSelectionChange={(e) => setSelectedItems(e.value)}
              dataKey="OrderItemId"
              paginator
              {...sortPaginationProps}
              lazy
              totalRecords={orderItemsData?.Paging?.TotalNumberOfRecords ?? 0}
              rows={orderItemsData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
              rowsPerPageOptions={rowsPerPageOptions}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              emptyMessage="No data found."
            >
              {renderColumn("OrderNumber")}
              {renderLinkColumn("ItemName", "OrderItemId")}
              {renderColumn("UserName")}
              {renderColumn("ItemTypeName")}
              {renderColumn("DeliveryLocationName")}
              {renderColumn("ItemAmount", { formatValue: formatCurrency })}
              {renderColumn("ItemQuantity")}
              {renderColumn("DeliveryCompany")}
              {renderColumn("TrackingNumber")}
              {renderColumn("IsEntered")}
              {renderColumn("ItemWeight")}
              {renderColumn("ItemVolumeWeight")}
              {renderColumn("TotalChargeableWeight", { formatValue: formatNumber })}
              {renderColumn("IsCombineItem")}
              {renderColumn("IsUseMileage")}
              {renderColumn("IsRepackage")}
            </DataTable>
          </ScrollDataTableContainer>
        </div>
      </div>
    </div>
  );
}

export default OrderItemPage;

const orderItemPageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalChargeableWeight,
        &.header-column-ItemWeight,
        &.header-column-ItemVolumeWeight,
        &.header-column-ItemQuantity,
        &.header-column-ItemAmount {
            text-align: right;
        }

        &.header-column-ItemWeight {
            width: 100px;
        }

        &.header-column-ItemVolumeWeight {
            width: 140px;
        }
        
        &.header-column-TotalChargeableWeight {
            width: 190px;
        }

        &.header-column-ItemAmount,
        &.header-column-ItemQuantity {
            width: 120px;

            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-TotalChargeableWeight,
    .column-ItemWeight,
    .column-ItemVolumeWeight,
    .column-ItemQuantity,
    .column-ItemAmount {
        text-align: right;
        padding-right: 20px;
    }
`;