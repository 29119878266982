const breakpoints = {
    sizes: {
        'xs': 0,
        'sm': 568,
        'md': 768,
        'lg': 1024,
        'xl': 1280,
        'xxl': 1440,
        '3xl': 1600,
        '4xl': 1900,
        '5xl': 2200,
    },
    order: [
        'sm',
        'md',
        'lg',
        'xl',
        'xxl',
        '3xl',
        '4xl',
        '5xl'
    ],
    breakpointsToScale: [
        '3xl',
        '4xl',
        '5xl',
    ],
    scale: {
        '3xl': 1.1,
        '4xl': 1.18,
        '5xl': 1.26
    }
};

export const getNextBreakpoint = size => breakpoints.order[breakpoints.order.indexOf(size) + 1];

export const getBreakpointMin = size => breakpoints.sizes[size];

export const getBreakpointMax = size => (breakpoints.sizes[getNextBreakpoint(size)] - .02) || null;

/**
 * Returns the media query for a single breakpoint.
 *
 * min represents the breakpoint matching the size prop.
 * max represents the next highest breakpoint from the size prop, minus .02.
 *
 * Example use:
 * ${theme.breakpoints.down('lg')} {
 *     height: 100px;
 * }
 * @param size
 * @returns {string}
 */
export const breakpointOnly = size => {
    const min = getBreakpointMin(size);
    const max = getBreakpointMax(size);
    return `@media (min-width: ${min}px)${max ? ` and (max-width: ${max}px)` : ""}`;
};

export const breakpointNot = size => {
    const min = getBreakpointMin(size);
    const max = getBreakpointMax(size);
    return `@media not all and (min-width: ${min}px) and (max-width: ${max}px)`;
};

/**
 * Returns the media query for a minimum breakpoint.
 *
 * min represents the breakpoint matching the size prop.
 *
 * Example use:
 * ${theme.breakpoints.up('lg')} {
 *     height: 100px;
 * }
 * @param size
 * @returns {string}
 */
export const breakpointUp = size => `@media (min-width: ${getBreakpointMin(size)}px)`;

/**
 * Returns the media query for a maximum breakpoint.
 *
 * max represents the next highest breakpoint from the size prop, minus .02.
 *
 * Example use:
 * ${theme.breakpoints.down('lg')} {
 *     height: 100px;
 * }
 * @param size
 * @returns {string}
 */
export const breakpointDown = size => {
    const max = getBreakpointMax(size);
    return max ? `@media (max-width: ${max}px)` : "@media (min-width: 0)";
}

/**
 * Returns the media query for a variable number of breakpoints.
 *
 * min represents the breakpoint matching the low prop.
 * max represents the breakpoint matching the next highest breakpoint from the high prop, minus .02.
 *
 * Example use:
 * ${theme.breakpoints.between('lg', 'xl')} {
 *     height: 100px;
 * }
 * @param low
 * @param high
 * @returns {string}
 */
export const breakpointBetween = (low, high) => {
    const min = getBreakpointMin(low);
    const max = getBreakpointMax(high);
    return `@media (min-width: ${min}px)${max ? ` and (max-width: ${max}px)` : ""}`;
};

/**
 * This method generates bootstrap media queries with the transform, and transform-origin property for breakpoints 3xl
 * and above. It will apply a transform based on the breakpoint scale factor.
 *
 * Example use:
 * ${theme.breakpoints.scaleUp()}
 *
 * @returns {string}
 */
export const scaleUp = () => {
    let media = "";

    //Loop through breakpoints we want to scale.
    breakpoints.breakpointsToScale.forEach((breakpoint, index) => {
        //If we aren't on the last breakpoint, load the breakpointOnly function.  Otherwise assume we are the last
        //breakpoint and so use the breakpointUp function.
        const notLastBreakpoint = breakpoints.breakpointsToScale.size > (index + 1);

        media += `
        ${notLastBreakpoint ? breakpointOnly(breakpoint) : breakpointUp(breakpoint)} {
            transform: scale(${breakpoints.scale[breakpoint]});
            transform-origin: center top;
        }
        `;
    });

    return media;
};

/**
 * This function will take a prop and value, and expand it based on the breakpoint given.
 * @param breakpoint
 * @param prop
 * @param value
 * @param prefixes
 * @param postfixes
 * @returns {string}
 */
const scaleValue = (breakpoint, prop, value, prefixes = "", postfixes = "") => {
    //Split out the inherited value by spaces.
    const values = value.split(' ');

    const scaledValues = [];

    for (const val of values) {
        //Match the numbers and measurement e.g. "74px" = ["74","px"].
        const match = val.match(/[a-zA-Z]+|-?[0-9]+/g);

        // If the first match is not a number, just return the value.
        // This is mainly to take into account keywords like 'auto' for a margin.
        if (isNaN(match[0])) {
            scaledValues.push(match[0]);
            continue;
        }

        scaledValues.push(`${match[0] * breakpoints.scale[breakpoint]}${match[1] || ''}`)
    }

    return `${prop}: ${prefixes} ${scaledValues.join(' ')} ${postfixes};`;
};

/**
 * This function accepts a css property like "height" and its value, and creates bootstrap media queries for
 * breakpoints 3xl and above.  It will multiply the value by the breakpoint scale factor.
 *
 * This method accept parameters (property, size to scale, prefix, postfix)
 *
 * Example use:
 * ${scalePropUp('border', '500px', '', 'solid red')};
 * ${scalePropUp('padding', '5px 4px 3px 5px')};
 *
 * @param prop
 * @param value
 * @param prefixes
 * @param postfixes
 * @returns {string}
 */
export const scalePropUp = (prop, value, prefixes = "", postfixes = "") => {
    let media = "";

    //Loop through breakpoints we want to scale.
    breakpoints.breakpointsToScale.forEach((breakpoint, index) => {

        //If we aren't on the last breakpoint, load the breakpointOnly function.  Otherwise assume we are the last
        //breakpoint and so use the breakpointUp function.
        const notLastBreakpoint = breakpoints.breakpointsToScale.length > (index + 1);

        //Join all scaled values back together.
        media += `
        ${notLastBreakpoint ? breakpointOnly(breakpoint) : breakpointUp(breakpoint)} {
            ${scaleValue(breakpoint, prop, value, prefixes, postfixes)}
        }
        `;
    });

    return media;
};

/**
 * This mixin accepts css properties like "height" and its value and creates bootstrap media queries for breakpoints 3xl
 * and above. It will multiply each value by the breakpoint scaling factor.
 *
 * This method accept 2 dimensional list of (property, size to scale, prefix, postfix).
 *
 * Example use:
 * ${scalePropListUp(
 *     [
 *         ['height', '500px'],
 *         ['width', '500px'],
 *         ['padding', '5px 4px 3px 5px'],
 *         ['border', '500px', '', 'solid red']
 *     ]
 * )};
 * @param list
 * @returns {string}
 */
export const scalePropListUp = (list) => {
    let media = "";

    breakpoints.breakpointsToScale.forEach((breakpoint, index) => {
        let props = "";

        //Loop through each array in the list and create a combined list of scaled values for the given breakpoint
        list.forEach(([prop, value, prefixes, postfixes]) => {
            props += scaleValue(breakpoint, prop, value, prefixes, postfixes);
        });

        //If we aren't on the last breakpoint, load the breakpointOnly function.  Otherwise assume we are the last
        //breakpoint and so use the breakpointUp function.
        const notLastBreakpoint = breakpoints.breakpointsToScale.length > (index + 1);

        //Join all scaled values back together.
        media += `
        ${notLastBreakpoint ? breakpointOnly(breakpoint) : breakpointUp(breakpoint)} {
            ${props}
        }
        `;
    });

    return media;
};

export default {
    ...breakpoints,
    getNextBreakpoint,
    getBreakpointMin,
    getBreakpointMax,
    only: breakpointOnly,
    not: breakpointNot,
    between: breakpointBetween,
    up: breakpointUp,
    down: breakpointDown,
    scaleUp,
    scalePropUp,
    scalePropListUp
};
