import React, { useCallback, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import Loading from "../loading/Loading";
import { Button } from "primereact/button";
import { css } from "@emotion/react";

const EnhancedDialog = ({
  title = "Dialog",
  children = "정말 수정하시겠습니까?",
  className = "",
  showFooter = true,
  footer = null, // should be null as default
  onCancel = null,// should be null as default
  onConfirm = null,// should be null as default
  withLoading = true,
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback((e) => {
    setLoading(true);
    if (onConfirm) {
      return onConfirm(e);
    }
  }, [onConfirm]);

  const confirmationDialogFooter = useMemo(() => (
    <>
      {onCancel && (
        <Button
          type="button"
          label="Close"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-text"
        />
      )}
      {onConfirm && (
        <Button
          type="button"
          label="Yes"
          icon="pi pi-check"
          onClick={handleConfirm}
          autoFocus
        />
      )}
    </>
  ), [onCancel, onConfirm, handleConfirm]);

  return (
    <Dialog
      header={title}
      visible
      {...onCancel && { onHide: onCancel }}
      css={dialogStyle}
      className={className}
      modal
      {...showFooter && { footer: footer || confirmationDialogFooter }}
    >
      {withLoading && loading && <Loading overlay />}
      <div className="flex align-items-center justify-content-center enhanced-dialog-content-body">
        {children}
      </div>
    </Dialog>
  )
}

export default EnhancedDialog;

const dialogStyle = css`
    width: 350px;
    position: relative;
`;