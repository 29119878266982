import React, { useCallback, useMemo } from 'react';
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";
import FilterLabel from "./FilterLabel";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

const ALL_ACTIVE_STATUS_ITEM = { Name: '전체', Code: "All", isDefault: true };

const activeStatusDropdownItems = [
    ALL_ACTIVE_STATUS_ITEM,
    { Name: '사용', Code: "Active" },
    { Name: '미사용', Code: "Inactive" },
];

export const activeStatusSearchFilterState = atom({
    key: 'activeStatusSearchFilterState',
    default: {
        activeStatus: ALL_ACTIVE_STATUS_ITEM.Code,
    },
});

const ActiveStatusSearchFilter = () => {
    const setActiveStatusSearchFilterState = useSetRecoilState(activeStatusSearchFilterState);
    const activeStatusSearchFilterValue = useRecoilValue(activeStatusSearchFilterState);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setActiveStatusSearchFilterState({
                activeStatus: searchObject.activeStatus,
            });
        }
    }, [setActiveStatusSearchFilterState]);

    useSetQueryStringToState(setQueryStringToState);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setActiveStatusSearchFilterState(prev => {
            if (prev.activeStatus) return prev;

            return {
                ...prev,
                activeStatus: defaultItem.Code,
            }
        });
    }, [setActiveStatusSearchFilterState]);

    useSetFilterDefaultValue({
        items: activeStatusDropdownItems,
        isAlreadySet: !!activeStatusSearchFilterValue.activeStatus,
        setFilterDefaultValue,
    });

    const setDropdownItem = useCallback(e => {
        setActiveStatusSearchFilterState({
            activeStatus: e.value?.Code
        })
    }, [setActiveStatusSearchFilterState]);

    return (
        <div className="grid flex-grow-1" css={searchGroupStyle}>
            <FilterLabel htmlFor={"activeStatus"}>사용여부</FilterLabel>
            <Dropdown
                name="activeStatus"
                value={activeStatusDropdownItems.find(item => item.Code === activeStatusSearchFilterValue.activeStatus)}
                onChange={setDropdownItem}
                options={activeStatusDropdownItems}
                optionLabel="Name"
                placeholder="전체"
            />
        </div>
    )
}

const searchGroupStyle = theme => css`
    display: flex;
    align-items: center;
    margin: 0;

    ${theme.breakpoints.down("xs")} {

        > * {
            width: 100%;
            margin: 2px 0;
            padding: 0;
        }
    }
`;

export default ActiveStatusSearchFilter;