import { useEffect } from "react";
import { isEmpty } from "lodash";
import { NOOP } from "../../includes/constants";

const useSetFilterDefaultValue = ({
    items = [],
    isAlreadySet = true,
    setFilterDefaultValue = NOOP,
}) => {
    useEffect(() => {
        // 처음 한번만 세팅 해 주세요.
        if (isAlreadySet) return;
        if (isEmpty(items)) return;

        const dropdownItem = items.find(i => i.isDefault);
        if (!dropdownItem) return;

        setFilterDefaultValue(dropdownItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAlreadySet, items, setFilterDefaultValue]);
}

export default useSetFilterDefaultValue;