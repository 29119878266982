import { isKR, isNZ } from "./countries";
import { isEmpty } from "lodash";

export const validateNumber = (num, fractionDigits = 2) => {
  const stringExchangeRate = num.toString();
  const fixedExchangeRateNumber = toNumber(num, fractionDigits);

  const splitNumbers = stringExchangeRate.split(".");
  if (
    !isEmpty(splitNumbers) &&
    splitNumbers[splitNumbers.length - 1].length > fractionDigits
  ) {
    return false;
  }

  if (fixedExchangeRateNumber !== Number(stringExchangeRate)) {
    return false;
  }
  return true;
};

/**
 *
 * @param {number} num
 * @param fractionDigits
 * @returns {number}
 */
export const toNumber = (num, fractionDigits = 2) => {
  const fixedExchangeRate = Number(num).toFixed(fractionDigits);
  return Number(fixedExchangeRate);
};

export const formatNumber = (rowData, field) => {
  return Number(rowData[field] ?? 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatIntNumber = (rowData, field) => {
  return Number(rowData[field] ?? 0).toLocaleString();
};

export const getKoreanCurrencyMark = (rowData, field) => {
  return "₩";
};

export const getNewZealandCurrencyMark = (rowData, field) => {
  return "$";
};

export const getNowSeconds = () => {
  return Date.now() / 1000;
};

export const formatCurrency = (rowData, field) => {
  if (isKR(rowData["DeliveryLocationCode"])) {
    // to KR
    return `${getNewZealandCurrencyMark()}${Number(
      rowData[field]
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }

  return `${getKoreanCurrencyMark()}${Number(rowData[field]).toLocaleString()}`;
};

export const formatItemAmount = (rowData, field) => {
  const totalItemAmount =
    Number(rowData["ItemAmount"]) * Number(rowData["ItemQuantity"]);

  if (isKR(rowData["DeliveryLocationCode"])) {
    // to KR
    return `${getNewZealandCurrencyMark()}
      ${totalItemAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
  }

  return `${getKoreanCurrencyMark()}${totalItemAmount.toLocaleString()}`;
};

export const formatDutyCurrency = (rowData, field) => {
  if (isNZ(rowData["DeliveryLocationCode"])) {
    // to NZ
    return `${getNewZealandCurrencyMark()}${Number(
      rowData[field]
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }

  return `${getKoreanCurrencyMark()}${Number(rowData[field]).toLocaleString()}`;
};
