import { atom } from "recoil";

export const consignmentListFilterState = atom({
    key: 'consignmentListFilterState',
    default: {},
});

export const consignmentPaginationFilterState = atom({
    key: 'consignmentPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy : null,
    },
});

export const selectedConsignmentsState = atom({
    key: 'selectedConsignmentsState',
    default: [],
});
