import React, { useEffect, useState, useCallback } from 'react';
import { css } from "@emotion/react";
import { Button } from "primereact/button";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { orderItemListFilterState, orderItemPaginationFilterState } from "../store";
import DateSearchFilter, { dateSearchFilterState } from "../../../components/filters/DateSearchFilter";
import { NOOP } from "../../../includes/constants";
import AwaitButton from "../../../components/AwaitButton";
import FilterContainer from "../../../components/filters/FilterContainer";
import FilterContainerGroups from "../../../components/filters/FilterContainerGroups";
import FilterActionContainer from "../../../components/filters/FilterActionContainer";
import DeliveryStatusSearchFilter from "../../../components/filters/DeliveryStatusSearchFilter";
import {
  deliveryStatusSearchFilterState,
  flightSelectSearchFilterState,
  itemEnteredStatusSearchFilterState,
  useMileageStatusSearchFilterState,
  combineItemStatusSearchFilterState,
  repackageStatusSearchFilterState
} from "../../../components/filters/store";
import EnhancedCollapse from "../../../components/layouts/EnhancedCollapse";
import PaginationFilter from "../../../components/filters/PaginationFilter";
import TextFieldSearchFilter from "../../../components/filters/TextFieldSearchFilter";
import PageFilterContainer from "../../../components/filters/PageFilterContainer";
import ItemEnteredStatusSearchFilter from "../../../components/filters/ItemEnteredStatusSearchFilter";
import CombineItemStatusSearchFilter from "../../../components/filters/CombineItemStatusSearchFilter";
import RepackageStatusSearchFilter from "../../../components/filters/RepackageStatusSearchFilter";
import UseMileageStatusSearchFilter from "../../../components/filters/UseMileageStatusSearchFilter";
import LocationSearchFilter, { locationSearchFilterState } from "../../../components/filters/LocationSearchFilter";
import FlightSelectSearchFilter from "../../../components/filters/FlightSelectSearchFilter";

const searchFieldItems = [
  { name: '주문일', searchDateField: 'OrderDate', isDefault: true },
];

const textFieldSearchDropdownItems = [
  { name: '주문자명', searchField: 'UserName', isDefault: true },
  { name: '주문번호', searchField: 'OrderNumber' },
  { name: '수령자명', searchField: 'ReceiverName' },
  { name: '수령자 전화번호', searchField: 'ReceiverPhone' },
  { name: '수령자 휴대폰', searchField: 'ReceiverMobile' },
  { name: '배송회사', searchField: 'DeliveryCompany' },
  { name: '송장번호', searchField: 'TrackingNumber' },
  { name: '상품명', searchField: 'ItemName' },
];

export const orderItemSearchFilterState = atom({
  key: 'orderItemSearchFilterState',
  default: {
    searchField: textFieldSearchDropdownItems[0].searchField,
    searchStr: "",
  },
});

const OrderItemPageFilter = ({
  onSearch = NOOP,
  loading = false,
}) => {
  const [isOpenDetailSearch, setIsOpenDetailSearch] = useState(false);
  const [, setFilter] = useRecoilState(orderItemListFilterState);
  const orderItemSearchFilterValue = useRecoilValue(orderItemSearchFilterState);
  const paginationFilterValue = useRecoilValue(orderItemPaginationFilterState);
  const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
  const deliveryStatusSearchFilterValue = useRecoilValue(deliveryStatusSearchFilterState);
  const itemEnteredStatusSearchFilterValue = useRecoilValue(itemEnteredStatusSearchFilterState);
  const locationSearchFilterValue = useRecoilValue(locationSearchFilterState);
  const flightSelectSearchFilterValue = useRecoilValue(flightSelectSearchFilterState);
  const useMileageStatusSearchFilterValue = useRecoilValue(useMileageStatusSearchFilterState);
  const repackageStatusSearchFilterValue = useRecoilValue(repackageStatusSearchFilterState);
  const combineItemStatusSearchFilterValue = useRecoilValue(combineItemStatusSearchFilterState);

  const toggleDetailSearch = useCallback(() => {
    setIsOpenDetailSearch(prev => !prev);
  }, []);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...paginationFilterValue,
      }
    });
  }, [paginationFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...orderItemSearchFilterValue,
      }
    });
  }, [orderItemSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...dateSearchFilterValue,
      }
    })
  }, [dateSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...deliveryStatusSearchFilterValue,
      }
    })
  }, [deliveryStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...itemEnteredStatusSearchFilterValue,
      }
    })
  }, [itemEnteredStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...locationSearchFilterValue,
      }
    })
  }, [locationSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...flightSelectSearchFilterValue,
      }
    })
  }, [flightSelectSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...useMileageStatusSearchFilterValue,
      }
    })
  }, [useMileageStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...repackageStatusSearchFilterValue,
      }
    })
  }, [repackageStatusSearchFilterValue, setFilter]);

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        ...combineItemStatusSearchFilterValue,
      }
    })
  }, [combineItemStatusSearchFilterValue, setFilter]);
  return (
    <PageFilterContainer>

      <PaginationFilter supportQueryString atom={orderItemPaginationFilterState} />

      <FilterContainerGroups>
        <FilterContainer className={"align-items-start"}>
          <TextFieldSearchFilter
            atom={orderItemSearchFilterState}
            label={"상품 검색"}
            dropdownItems={textFieldSearchDropdownItems}
            supportQueryString
            onSearch={onSearch}
          />
        </FilterContainer>
        <FilterContainer>
          <DateSearchFilter searchFieldItems={searchFieldItems} />
        </FilterContainer>
      </FilterContainerGroups>


      <EnhancedCollapse isOpen={isOpenDetailSearch} css={detailSearchCollapseStyle}>
        <FilterContainerGroups>
          <FilterContainer>
            <LocationSearchFilter supportQueryString />
          </FilterContainer>

          <FilterContainer className={"align-items-start"}>
            <DeliveryStatusSearchFilter
              includeAll
              atom={deliveryStatusSearchFilterState}
              supportQueryString
              label={"배송 상태"}
              className={"align-items-center"}
            />
          </FilterContainer>



          <FilterContainer>
            <FlightSelectSearchFilter
              state={flightSelectSearchFilterState}
              supportQueryString
            />
          </FilterContainer>
          <FilterContainer>
            <ItemEnteredStatusSearchFilter
              label={"입고 상태"}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>
          <FilterContainer>
            <UseMileageStatusSearchFilter
              atom={useMileageStatusSearchFilterState}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>
          <FilterContainer>
            <RepackageStatusSearchFilter
              atom={repackageStatusSearchFilterState}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>
          <FilterContainer>
            <CombineItemStatusSearchFilter
              atom={combineItemStatusSearchFilterState}
              supportQueryString
              className={"grid col-12 p-fluid align-items-center"}
            />
          </FilterContainer>


        </FilterContainerGroups>
      </EnhancedCollapse>
      <FilterActionContainer>
        <AwaitButton
          icon={"pi pi-search"}
          className="mr-2"
          css={buttonStyle}
          type="button"
          onClick={onSearch}
          label={"Search"}
          loading={loading}
        />
        <Button
          icon={isOpenDetailSearch ? "pi pi-angle-up" : "pi pi-angle-down"}
          label={isOpenDetailSearch ? "상세검색 닫기" : "상세검색 열기"}
          className="p-button-outlined mr-2"
          onClick={toggleDetailSearch}
        />
      </FilterActionContainer>
    </PageFilterContainer>
  )
}

export default OrderItemPageFilter;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

const detailSearchCollapseStyle = css`
    border: 1px solid var(--surface-border);
    border-radius: 3px;
    margin: 0.5rem -0.5rem 0.5rem;
`;