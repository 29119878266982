import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { css, keyframes } from "@emotion/react";

const Loading = ({
    onTop = true,
    overlay = false,
    fitToScreen = false,
    small = false,
    delay = false,
    overlayColour = "white",
}) => {
    const loadingStyle = useMemo(() => {
        if (onTop) {
            return (theme) => (css`
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                display: flex;
                z-index: ${theme.zIndex.zIndexHigh - 1};
                animation: ${delayKeyframe} ${delay}ms;
            `);
        }
        return (theme) => (css`
            width: 100%;
            height: 50px;
            position: relative;
            z-index: ${theme.zIndex.zIndexHigh - 1};
            animation: ${delayKeyframe} ${delay}ms;
        `);
    }, [onTop, delay]);

    const loadingRingStyle = useMemo(() => {
        return (theme) => css`
            display: inline-block;
            position: relative;
            width: ${small ? 30 : 64}px;
            height: ${small ? 30 : 64}px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        `;
    }, [small]);

    const overlayStyle = useMemo(() => {
        if (fitToScreen) {
            return (theme) => (css`
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.25;
                z-index: ${theme.zIndex.zIndexHigh - 1};
                background-color: ${overlayColour};
            `);
        }

        return (theme) => (css`
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.25;
            z-index: ${theme.zIndex.zIndexHigh - 1};
            background-color: ${overlayColour};
        `);
    }, [fitToScreen, overlayColour]);

    return (
        <div css={loadingStyle}>
            {overlay && (<div css={overlayStyle} />)}
            <div css={loadingRingStyle}>
                <div css={loadingRingSectionStyle(small)} />
                <div css={loadingRingSectionStyle(small)} />
                <div css={loadingRingSectionStyle(small)} />
                <div css={loadingRingSectionStyle(small)} />
            </div>
        </div>
    );
}

Loading.propTypes = {
    overlay: PropTypes.bool,
    ringIndicator: PropTypes.bool,
    small: PropTypes.bool,
    onTop: PropTypes.bool,
    fitToScreen: PropTypes.bool,
    delay: PropTypes.bool,
    overlayColour: PropTypes.string,
};

export default Loading;

const delayKeyframe = keyframes`
    0% {
        visibility: hidden;
        position: fixed;
    }

    99.9% {
        visibility: hidden;
        position: fixed;
    }

    100% {
        visibility: visible;
        position: inherit;
    }
`;

const ringLoadingKeyframe = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const loadingRingSectionStyle = small => theme => css`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 9%;
    border: ${small ? 4 : 6}px solid white;
    border-radius: 50%;
    animation: ${ringLoadingKeyframe} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-color) transparent transparent transparent;
    
    :nth-of-type(N+1) {
        animation-delay: -0.45s;
    }

    :nth-of-type(N+2) {
        animation-delay: -0.3s;
    }

    :nth-of-type(N+3) {
        animation-delay: -0.15s;
    }
`;

