import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { trim } from "lodash";
import moment from "moment";
import { Button } from "primereact/button";

import { NOOP } from "../../../../includes/constants";
import { toBooleanValue } from "../../../../includes/ajax";
import { useAwaitingDialogContext } from "../../../../core/contexts/AwaitingDialogContext";
import { DATE_FORMAT_REACT_PRIME, formatFromDate, stringToDate } from "../../../../includes/dates";

import AwaitButton from "../../../../components/AwaitButton";
import InputTextWithError from "../../../../components/forms/InputTextWithError";
import EnhancedEditor from "../../../../components/editor/EnhancedEditor";
import DataTableActions from "../../../../components/layouts/DataTableActions";
import IsActiveRadioButtons from "../../../../components/forms/IsActiveRadioButtons";
import CalendarWithError from "../../../../components/forms/CalendarWithError";

const today = moment().hours(0).minutes(0).seconds(0).toDate();

const PopupWriter = ({
    onSavePopup = NOOP,
    popupData = null,
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const history = useHistory();
    const editorKey = useRef(Math.random());

    const onSaveClicked = useCallback((values) => {
        return onSavePopup(values);
    }, [onSavePopup]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            const newValues = {
                ...values,
                StartDate: formatFromDate(values.StartDate, "YYYY-MM-DD HH:mm:00"),
                EndDate: formatFromDate(values.EndDate, "YYYY-MM-DD HH:mm:00"),
            }

            onSaveClicked(newValues);
        },
    });

    const resetForm = useCallback(() => {
        formik.setValues({
            Title: popupData?.Title || '',
            Contents: popupData?.Contents || '',
            WriterName: popupData?.WriterName || '관리자',
            IsActive: toBooleanValue(popupData?.IsActive, true),
            StartDate: popupData?.StartDate ? stringToDate(popupData?.StartDate) : today,
            EndDate: popupData?.EndDate ? stringToDate(popupData?.EndDate) : today,
        });
        editorKey.current = Math.random();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popupData]);

    const onContentsChanged = useCallback((contents) => {
        formik.setFieldValue("Contents", contents);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goBackToList = useCallback(() => {
        history.goBack();
        closeConfirmation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    const leftSectionButtons = useMemo(() => {
        return (
            <>
                <AwaitButton
                    buttonClassName={"p-button-secondary"}
                    icon="pi pi-replay"
                    css={buttonStyle}
                    label={"리셋"}
                    type="button"
                    onClick={resetForm}
                />
                <AwaitButton
                    icon="pi pi-check"
                    label={"저장"}
                    type="submit"
                    css={buttonStyle}
                />
            </>
        )
    }, [resetForm]);

    return (
        <div className="col-12">
            <form onSubmit={formik.handleSubmit}>

                <div className="p-fluid formgrid grid" css={maxWidthStyle}>
                    <DataTableActions className={"mb-5"} direction={"right"} titleSection={leftSectionButtons}>
                        <Button
                            className={"p-button-info"}
                            icon="pi pi-list"
                            label={"목록"}
                            type="button"
                            onClick={goBackToList}
                        />
                    </DataTableActions>


                    <div className="field col-12">
                        <label htmlFor="Title">제목</label>
                        <InputTextWithError
                            name="Title"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.Title || ''}
                            error={formik.errors.Title}
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="StartDate">팝업 시작일</label>
                        <CalendarWithError
                            name={"StartDate"}
                            dateFormat={DATE_FORMAT_REACT_PRIME}
                            showIcon
                            showButtonBar
                            onChange={formik.handleChange}
                            value={formik.values.StartDate || ''}
                            error={formik.errors.StartDate}
                            showTime
                            showCloseButton
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="EndDate">팝업 종료일</label>
                        <CalendarWithError
                            name={"EndDate"}
                            dateFormat={DATE_FORMAT_REACT_PRIME}
                            showIcon
                            showButtonBar
                            onChange={formik.handleChange}
                            value={formik.values.EndDate || ''}
                            error={formik.errors.EndDate}
                            showTime
                            showCloseButton
                        />
                    </div>

                    <div className="field grid col-12">
                        <label htmlFor="IsActive" className="col-12">사용여부</label>
                        <IsActiveRadioButtons
                            isActive={toBooleanValue(formik.values.IsActive)}
                            handleChange={formik.handleChange}
                        />
                    </div>

                    <div className="field col-12">
                        <label htmlFor="Contents">내용</label>
                        <EnhancedEditor
                            defaultContents={formik.values.Contents}
                            onChange={onContentsChanged}
                            error={formik.errors.Contents}
                            key={editorKey.current}
                            aria-describedby="Contents-help"
                        />
                        <small id="Contents-help" className="p-d-block">
                            이미지 등록시 400px * 300px 로 등록해주세요.
                        </small>
                    </div>


                </div>
            </form>
        </div>
    )
}

export default PopupWriter;

const validate = (values) => {
    const errors = {};

    if (!trim(values.Title)) {
        errors.Title = "Required";
    }
    if (!trim(values.WriterName)) {
        errors.WriterName = "Required";
    }

    if (!trim(values.Contents)) {
        errors.Contents = "Required";
    } else if (!values.Contents.includes("<img")) {
        const cleanText = values.Contents.replace(/<\/?[^>]+(>|$)/g, "");
        if (!trim(cleanText)) {
            errors.Contents = "Required";
        }
    }

    if (!trim(values.StartDate)) {
        errors.StartDate = "Required";
    }

    if (!trim(values.EndDate)) {
        errors.EndDate = "Required";
    }

    if (!trim(values.IsActive)) {
        errors.IsActive = "Required";
    }
    return errors;
}

const buttonStyle = css`
    margin-right: 10px;
`;

const maxWidthStyle = css`
    max-width: 800px;
`;