import useAxios from "axios-hooks";
import { useCallback, useEffect, useState } from "react";
import { API_INVOICE_URI } from "../../includes/constants";
import useToast from "../../core/hooks/useToast";

const useDownloadInvoice = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { showError } = useToast();

    /***********************************************************
     * 인보이스 PDF 다운로드
     ***********************************************************/
    const [{
        error
    }, download] = useAxios({
            url: `${API_INVOICE_URI}/{invoiceId}/pdf`,
            method: "GET",
        },
        { manual: true },
    );

    const onInvoiceDownloadClicked = useCallback((e, invoiceNumber, invoiceId) => {
        e.preventDefault();
        setIsDownloading(true);

        download({
            url: `${API_INVOICE_URI}/${invoiceNumber.InvoiceId}/pdf`,
            responseType: 'arraybuffer', /** blob 이 가능한데, 에러 메시지를 꺼내야 하는 경우도 있어서, arraybuffer로 한다. **/
        }).then(response => {
            const href = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
            );
            const link = document.createElement('a');
            link.download = `${invoiceId}-${Date.now()}.pdf`;
            link.href = href;
            document.body.appendChild(link);
            link.click();
            link.href = '';
        }).finally(() => {
            setIsDownloading(false);
        });

    }, [download]);

    useEffect(() => {
        if (error) {
            try {
                showError({ message: { data: JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.data))) } });
            } catch (e) {
                showError({ message: e });
            }
        }
    }, [error, showError]);

    return {
        isDownloading,
        onInvoiceDownloadClicked,
    }
}

export default useDownloadInvoice;