import { smoothScroll } from "../../includes/browsers";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

// const wizardItems = [
//     { label: '주문정보', isTop: true, },
//     { label: '상품정보', linkName: "#OrderItemList" },
//     { label: '결제정보', linkName: "#OrderChargeList" },
//     { label: '주문자정보', linkName: "#OrderUserInfo" },
//     { label: '수령자정보', linkName: "#OrderReceiverInfo" },
//     { label: '인보이스', linkName: "#OrderInvoiceList" },
//     { label: '관리메모', linkName: "#OrderNoteList" },
// ];

const useTabMenu = (wizardItems = []) => {
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);

    const scrollAndSetActiveIndex = useCallback(menuItem => {
        const index = menuItem.index;

        if (menuItem.isTop) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else {
            if (menuItem.linkName) {
                if (location.hash !== menuItem.linkName) {
                    window.history.replaceState(null, null, menuItem.linkName);
                }
                smoothScroll(menuItem.linkName);
            }
        }

        if (index) {
            setActiveIndex(index);
        } else {
            const activeIndex = wizardItems.findIndex(i => i.linkName === location.hash);
            if (activeIndex) {
                setActiveIndex(activeIndex);
            }
        }
    }, [location, wizardItems]);

    const onTabChange = useCallback(e => {
        const menu = e.value;

        scrollAndSetActiveIndex({
            ...menu,
            index: e.index,
        });

        return e;
    }, [scrollAndSetActiveIndex]);

    const initTab = useCallback(linkName => {
        scrollAndSetActiveIndex({ linkName });
    }, [scrollAndSetActiveIndex]);

    return {
        onTabChange,
        activeIndex,
        initTab,
    };
}

export default useTabMenu;