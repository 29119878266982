import { useCallback } from "react";
import useAxios from "axios-hooks";
import { API_PURCHASE_ORDER_ITEM_URI } from "../../../includes/constants";

export const TYPE_ORDER_ITEM = "TYPE_ORDER_ITEM";
export const TYPE_TRACKING_NUMBER = "TYPE_TRACKING_NUMBER";

const usePurchaseOrderItemData = (type = TYPE_ORDER_ITEM) => {
    const [{ data, loading, error }, getData] = useAxios({
            method: 'GET',
        },
        { manual: true },
    );

    const getPurchaseOrderItem = useCallback((purchaseOrderItemId) => {
        return getData({
            url: `${API_PURCHASE_ORDER_ITEM_URI}/${purchaseOrderItemId}`
        });
    }, [getData]);

    const getPurchaseOrderItemByTrackingNumber = useCallback((trackingNumber) => {
        return getData({
            url: `${API_PURCHASE_ORDER_ITEM_URI}/tracking?trackingNumber=${trackingNumber}`,
        });
    }, [getData]);

    return [
        {
            data,
            loading,
            error,
        },
        type === TYPE_ORDER_ITEM ? getPurchaseOrderItem : getPurchaseOrderItemByTrackingNumber,
    ];
}

export default usePurchaseOrderItemData;