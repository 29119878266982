import React, { useCallback, useEffect } from "react";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { trim } from "lodash";
import { validate as validateEmail } from "email-validator";
import { Checkbox } from "primereact/checkbox";

import { toBooleanValue } from "../../../includes/ajax";
import { NOOP } from "../../../includes/constants";

import AwaitButton from "../../../components/AwaitButton";
import EnhancedDivider from "../../../components/layouts/EnhancedDivider";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import IsActiveRadioButtons from "../../../components/forms/IsActiveRadioButtons";

const CustomerInfo = ({
    onSaveCustomerInfo = NOOP,
    customerData = null,
}) => {
    const onSaveClicked = useCallback((values) => {
        return onSaveCustomerInfo(values);
    }, [onSaveCustomerInfo]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            const newValues = {
                ...values,
            };

            delete newValues.UserLoginId;

            if (!values.IsChangePassword) {
                delete newValues.UserPassword;
            }

            onSaveClicked(newValues);
        },
    });

    const onSubmitForm = useCallback(() => {
        return formik.submitForm();
    }, [formik]);

    const resetForm = useCallback(() => {
        formik.setValues({
            IsChangePassword: false,
            UserPassword: '',
            UserId: customerData?.UserId ?? 0,
            UserLoginId: customerData?.UserLoginId || '',
            UserName: customerData?.UserName || '',
            EmailAddress: customerData?.EmailAddress || '',
            PhoneNumber: customerData?.PhoneNumber || '',
            MobileNumber: customerData?.MobileNumber || '',
            IsActive: toBooleanValue(customerData?.IsActive, true),
            ZipCodeKR: customerData?.ZipCodeKR || '',
            UserAddressKR1: customerData?.UserAddressKR1 || '',
            UserAddressKR2: customerData?.UserAddressKR2 || '',
            ZipCodeNZ: customerData?.ZipCodeNZ || '',
            UserAddressNZ1: customerData?.UserAddressNZ1 || '',
            UserAddressNZ2: customerData?.UserAddressNZ2 || '',
            Mileage: customerData?.Mileage || '',
            PersonalCustomCode: customerData?.PersonalCustomCode || '',
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
                <AwaitButton
                    buttonClassName={"p-button-secondary"}
                    icon="pi pi-replay"
                    css={buttonStyle}
                    label={"리셋"}
                    type="button"
                    onClick={resetForm}
                />
                <AwaitButton
                    icon="pi pi-check"
                    className={"mb-5"}
                    label={"저장"}
                    type="submit"
                    onClick={onSubmitForm}
                    css={buttonStyle}
                />

                <div className="p-fluid formgrid grid" css={maxWidthStyle}>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="UserName">회원명</label>
                        <InputTextWithError
                            name="UserName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.UserName || ''}
                            error={formik.errors.UserName}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="IsActive">사용여부</label>
                        <IsActiveRadioButtons
                            isActive={toBooleanValue(formik.values.IsActive)}
                            handleChange={formik.handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="EmailAddress">이메일</label>
                        <InputTextWithError
                            name="EmailAddress"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.EmailAddress || ''}
                            error={formik.errors.EmailAddress}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="UserName">로그인 아이디</label>
                        <InputTextWithError
                            name="UserName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.UserLoginId || ''}
                            error={formik.errors.UserLoginId}
                            readOnly
                            noBorder
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="UserPassword">비밀번호</label>
                        <InputTextWithError
                            name="UserPassword"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.UserPassword || ''}
                            error={formik.errors.UserPassword}
                            readOnly={!formik.values.IsChangePassword}
                        />
                    </div>

                    <div className="field col-12 md:col-6 field-checkbox" css={checkboxContainerStyle}>
                        <Checkbox
                            inputId="IsChangePassword"
                            name="IsChangePassword"
                            value={formik.values.IsChangePassword}
                            onChange={formik.handleChange}
                            checked={formik.values.IsChangePassword}
                        />
                        <label
                            htmlFor="IsChangePassword"
                            className="p-checkbox-label"
                            css={checkboxLabelStyle}
                        >
                            비밀번호 수정
                        </label>
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="PhoneNumber">전화번호</label>
                        <InputTextWithError
                            name="PhoneNumber"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.PhoneNumber || ''}
                            error={formik.errors.PhoneNumber}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="MobileNumber">휴대폰</label>
                        <InputTextWithError
                            name="MobileNumber"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.MobileNumber || ''}
                            error={formik.errors.MobileNumber}
                        />
                    </div>

                    <EnhancedDivider align="left" className={"mb-3 mt-3"} css={addressDividerStyle}>
                        <div className="p-d-inline-flex p-ai-center">
                            <i className="pi pi-home mr-2" style={{ 'fontSize': 'inherit' }}></i>
                            <b>대한민국 주소</b>
                        </div>
                    </EnhancedDivider>

                    <div className="field col-12 md:col-12">
                        <label htmlFor="UserAddressKR1">주소</label>
                        <InputTextWithError
                            name="UserAddressKR1"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.UserAddressKR1 || ''}
                            error={formik.errors.UserAddressKR1}
                        />
                    </div>

                    <div className="field col-12 md:col-8">
                        <label htmlFor="UserAddressKR2">상세주소</label>
                        <InputTextWithError
                            name="UserAddressKR2"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.UserAddressKR2 || ''}
                            error={formik.errors.UserAddressKR2}
                        />
                    </div>

                    <div className="field col-12 md:col-4">
                        <label htmlFor="ZipCodeKR">우편번호</label>
                        <InputTextWithError
                            name="ZipCodeKR"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.ZipCodeKR || ''}
                            error={formik.errors.ZipCodeKR}
                        />
                    </div>

                    <EnhancedDivider align="left" className={"mb-3 mt-3"} css={addressDividerStyle}>
                        <div className="p-d-inline-flex p-ai-center">
                            <i className="pi pi-home mr-2" style={{ 'fontSize': 'inherit' }}></i>
                            <b>뉴질랜드 주소</b>
                        </div>
                    </EnhancedDivider>

                    <div className="field col-12 md:col-12">
                        <label htmlFor="UserAddressNZ1">주소</label>
                        <InputTextWithError
                            name="UserAddressNZ1"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.UserAddressNZ1 || ''}
                            error={formik.errors.UserAddressNZ1}
                        />
                    </div>

                    <div className="field col-12 md:col-8">
                        <label htmlFor="UserAddressNZ2">상세주소</label>
                        <InputTextWithError
                            name="UserAddressNZ2"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.UserAddressNZ2 || ''}
                            error={formik.errors.UserAddressNZ2}
                        />
                    </div>

                    <div className="field col-12 md:col-4">
                        <label htmlFor="ZipCodeNZ">우편번호</label>
                        <InputTextWithError
                            name="ZipCodeNZ"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.ZipCodeNZ || ''}
                            error={formik.errors.ZipCodeNZ}
                        />
                    </div>

                    <EnhancedDivider className={"mb-5 mt-3"} />

                    <div className="field col-12 md:col-6">
                        <label htmlFor="PersonalCustomCode">개인통관부호</label>
                        <InputTextWithError
                            name="PersonalCustomCode"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.PersonalCustomCode || ''}
                            error={formik.errors.PersonalCustomCode}
                        />
                    </div>

                </div>
            </form>
        </div>
    )
}

export default CustomerInfo;

const validate = (values) => {
    const errors = {};

    if (!trim(values.UserName)) {
        errors.UserName = "Required";
    }

    if (!trim(values.UserLoginId)) {
        errors.UserLoginId = "Required";
    }

    if (!trim(values.EmailAddress)) {
        errors.EmailAddress = "Required";
    } else if (!validateEmail(values.EmailAddress)) {
        errors.EmailAddress = 'Invalid email';
    }

    if (values.IsChangePassword) {
        if (!trim(values.UserPassword)) {
            errors.UserPassword = "Required";
        } else if (trim(values.UserPassword).length < 8) {
            errors.UserPassword = "Password must be large than 8 characters";
        }
    }

    if (!trim(values.IsActive)) {
        errors.IsActive = "Required";
    }

    if (!trim(values.MobileNumber)) {
        errors.MobileNumber = "Required";
    }

    if (!trim(values.PhoneNumber)) {
        errors.PhoneNumber = "Required";
    }

    // if (!trim(values.UserAddressKR1)) {
    //     errors.UserAddressKR1 = "Required";
    // }
    // if (!trim(values.UserAddressKR2)) {
    //     errors.UserAddressKR2 = "Required";
    // }
    // if (!trim(values.ZipCodeKR)) {
    //     errors.ZipCodeKR = "Required";
    // }

    // if (!trim(values.PersonalCustomCode)) {
    //     errors.PersonalCustomCode = "Required";
    // }

    return errors;
}

const buttonStyle = css`
    margin-right: 10px;
`;

const maxWidthStyle = css`
    max-width: 800px;
`;

const checkboxLabelStyle = css`
    cursor: pointer;
    margin-left: 0.5rem;
    margin-bottom: 0 !important;
    user-select: none;
`;

const checkboxContainerStyle = theme => css`
    &.field.col-12 {
        padding-top: 20px;
        display: flex;
        align-items: center;

        ${theme.breakpoints.up("md")} {
            height: 60px;
        }

        ${theme.breakpoints.down("sm")} {
            padding-top: 0;
            margin-bottom: 2rem;
        }
    }
`;

const addressDividerStyle = css`
    font-size: 1.2rem;
`;