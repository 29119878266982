import React, { memo } from 'react';
import { css } from "@emotion/react";

const FilterLabel = ({ htmlFor, children, className }) => (
    <label htmlFor={htmlFor} css={filterLabelStyle} className={className}>
        {children}
    </label>
);


const filterLabelStyle = css`
    margin-right: 10px;
    min-width: 74px;
`;

export default memo(FilterLabel);