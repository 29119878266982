import React, { useCallback } from "react";
import { useFormik } from "formik";
import { trim } from "lodash";
import { NOOP } from "../../../includes/constants";
import AwaitButton from "../../../components/AwaitButton";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import HiddenFormField from "../../../components/forms/HiddenFormField";
import { validateNumber } from "../../../includes/numbers";
import { css } from "@emotion/react";

const ExchangeRateInfo = ({
    onSaveExchangeRate = NOOP,
    exchangeRate = null,
}) => {
    const onSaveClicked = useCallback((values) => {
        return onSaveExchangeRate({
            ...values,
        });
    }, [onSaveExchangeRate]);

    const formik = useFormik({
        initialValues: {
            ExchangeRate: exchangeRate ? Number(exchangeRate) : exchangeRate,
        },
        validateOnChange: false,
        validate,
        onSubmit: values => {
            onSaveClicked(values);
        },
    });

    return (
        <div className="col-12" css={exchangeRateStyle}>
            <form onSubmit={formik.handleSubmit} noValidate>
                <AwaitButton
                    icon="pi pi-check"
                    className={"mb-5"}
                    label={"저장"}
                    type="submit"
                />
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="ExchangeRate">적용환율</label>
                        <InputTextWithError
                            name="ExchangeRate"
                            type="number"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.ExchangeRate}
                            error={formik.errors.ExchangeRate}
                            step={".01"}
                            min={0.00}
                            max={2000.00}
                        />
                    </div>
                    <HiddenFormField />
                </div>
            </form>
        </div>
    )
}

export default ExchangeRateInfo;

const validate = (values) => {
    const errors = {};

    if (!trim(values.ExchangeRate)) {
        errors.ExchangeRate = "Required";
    } else {

        if (!validateNumber(values.ExchangeRate)) {
            errors.ExchangeRate = "소수점 두자리까지 입력이 가능합니다.";
        }
    }

    return errors;
}

const exchangeRateStyle = css`
    max-width: 800px;
`;
