const getApi = () => {
  switch (window.GlobalConfigs?.serviceUrl) {
    case "STAGING":
      return process.env.REACT_APP_SERVICE_STAGING;
    case "PROD":
      return process.env.REACT_APP_SERVICE_PROD;
    default:
      return process.env.REACT_APP_SERVICE_LOCAL;
  }
};

export const API_BASE = getApi();

export const NOOP = () => {
};

export const getPromise = () => {
  return new Promise(resolve => {
    resolve();
  });
};

export const DEFAULT_PAGE_SIZE = 10;
export const rowsPerPageOptions = [10, 20, 50, 100];

export const API_COMMON_CODE_ADMIN_MENU_ROLE_URI = "/common/code/adminmenu/role";

export const API_ORDER_URI = "/manager/order";
export const API_ORDER_BRIEF_URI = `${API_ORDER_URI}/brief`;
export const API_ORDER_CSV_URI = `${API_ORDER_URI}/csv`;
export const API_ORDER_BRIEF_CSV_URI = `${API_ORDER_BRIEF_URI}/csv`;
export const API_ADD_WEIGHT_CHARGE_URI = `${API_ORDER_URI}/addweightcharge`;
export const API_PURCHASE_ORDER_URI = `${API_ORDER_URI}/purchase`;
export const API_FLIGHT_URI = "/manager/flight";
export const API_INVOICE_URI = "/manager/invoice";
export const API_ORDER_NOTE_URI = "/manager/ordernote";
export const API_ORDER_ITEM_URI = "/manager/orderitem";
export const API_ALL_ORDER_ITEM_URI = "/manager/orderitem/all";
export const API_PURCHASE_ORDER_ITEM_URI = "/manager/purchaseorderitem";
export const API_ORDER_ITEM_BATCH_ENTER_URI = `${API_ORDER_ITEM_URI}/enter`;
export const API_PURCHASE_ORDER_ITEM_BATCH_ENTER_URI = `${API_PURCHASE_ORDER_ITEM_URI}/enter`;
export const API_PURCHASE_ORDER_ITEM_BATCH_ORDER_URI = `${API_PURCHASE_ORDER_ITEM_URI}/ordered`;
export const API_PURCHASE_ORDER_ITEM_BATCH_PAID_URI = `${API_PURCHASE_ORDER_ITEM_URI}/paid`;
export const API_CONSIGNMENT_URI = "/manager/consignment";
export const API_CHARGE_TYPE_URI = "/manager/orderchargetype";
export const API_WEIGHT_AMOUNT_URI = "/manager/weightamount";
export const API_ORDER_CHARGE_URI = "/manager/ordercharge";
export const API_ORDER_CHARGE_TYPE_URI = "/manager/orderchargetype";
export const API_MANAGER_ADMIN_URI = "/manager/admin";
export const API_MANAGER_ACCOUNT_URI = "/manager/customer";

export const API_MANAGER_CUSTOMER_MILEAGE_URI = "/manager/customer/mileage";

export const API_BOARD_NOTICE_URI = "/manager/board/notice";
export const API_BOARD_POPUP_URI = "/manager/board/popup";
export const API_BOARD_QNA_URI = "/manager/board/qna";
export const API_BOARD_QNA_COMMENT_URI = `${API_BOARD_QNA_URI}/comment`;

export const taxRateDropdownItems = [
  { Code: "15", Name: "15%" },
  { Code: "0", Name: "0%" },
]

const deliveryCompanies = [
  "CJ대한통운",
  "우체국택배",
  "한진택배",
  "롯데택배",
  "로젠택배",
  "홈픽택배",
  "CVSnet 편의점택배",
  "CU 편의점택배",
  "경동택배",
  "대신택배",
  "일양로지스",
  "기타",
  "직접접수",
];

export const deliveryCompanyItems = (() => {
  return deliveryCompanies.map(company => ({ Code: company, Name: company }));
})();

export const isCombineItemItems = [
  { Code: true, Name: "Yes" },
  { Code: false, Name: "No" },
]

export const isRepackageItems = [
  { Code: true, Name: "Yes" },
  { Code: false, Name: "No" },
]
export const isUseMileageItems = [
  { Code: true, Name: "Yes" },
  { Code: false, Name: "No" },
]
export const DELIVERY_LOCATION_CODE_TO_NZ = "1";
export const DELIVERY_LOCATION_CODE_TO_KR = "2";
export const DELIVERY_LOCATION_CODE_TO_NZSEA = "3";


export const PERMISSION_ADMIN = "Admin";
export const PERMISSION_WAREHOUSE_ADMIN = "Warehouse Manager";