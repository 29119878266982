import { atom } from "recoil";

export const flightOrdersListState = atom({
    key: 'flightOrdersListState',
    default: [],
});

export const flightOrdersDetailPageDeliveryStatusState = atom({
    key: 'flightOrdersDetailPageDeliveryStatusState',
    default: {
        deliveryStatusCode: null,
        deliveryStatusName: null,
    }
});

export const flightOrdersDetailPageFlightSelectState = atom({
    key: 'flightOrdersDetailPageFlightSelectState',
    default: {
        flightId: null,
        flightName: null,
    }
});

export const flightOrdersDetailState = atom({
    key: 'flightOrdersDetailState',
    default: {
        OrderItemList: [],
        FlightInfo: null,
        OrderChargeList: [],
        OrderInvoiceList: [],
        OrderNoteList: [],
        OrderId: '',
        OrderNumber: '',
        UserId: '',
        UserName: '',
        OrderDate: '',
        DeliveryLocationCode: '',
        DeliveryLocationName: '',
        DeliveryTypeCode: '',
        DeliveryTypeName: '',
        DeliveryStatusCode: '',
        DeliveryStatusName: '',
        IsReceiverOwn: '',
        ReceiverName: '',
        ReceiverEmail: '',
        ReceiverPhone: '',
        ReceiverMobile: '',
        ReceiverAddress1: '',
        ReceiverAddress2: '',
        ReceiverZipcode: '',
        LocalTrackingNumber: '',
        PickupTypeCode: '',
        PersonalCustomCode: '',
        TotalWeight: '',
        TotalVolumeWeight: '',
        TotalItemAmount: '',
        TotalExchangeItemAmount: '',
        FlightId: '',
        FlightName: '',
        TotalChargeAmount: '',
        TotalItemCount: '',
        IsAllItemEntered: '',
        ConsignmentNumber: '',
        TotalPaidAmount: ''
    },
});

export const flightOrdersListFilterState = atom({
    key: 'flightOrdersListFilterState',
    default: {},
});

export const flightOrdersPaginationFilterState = atom({
    key: 'flightOrdersPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy: null,
    },
});

export const selectedFlightOrderItemsState = atom({
    key: 'selectedFlightOrderItemsState',
    default: [],
});


export const flightOrdersDateSearchFilterState = atom({
    key: 'flightOrdersDateSearchFilterState',
    default: {
        searchDateField: null,
        startDate: null,
        endDate: null,
        allDate: false,
    },
});
