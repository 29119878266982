import React, { useEffect } from 'react';
import { css } from "@emotion/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { weightAmountListFilterState, weightAmountPaginationFilterState } from "../store";
import PaginationFilter from "../../../../components/filters/PaginationFilter";
import FilterContainerGroups from "../../../../components/filters/FilterContainerGroups";
import FilterContainer from "../../../../components/filters/FilterContainer";
import { NOOP } from "../../../../includes/constants";
import FilterActionContainer from "../../../../components/filters/FilterActionContainer";
import AwaitButton from "../../../../components/AwaitButton";
import LocationSearchFilter, { locationSearchFilterState } from "../../../../components/filters/LocationSearchFilter";
import PageFilterContainer from "../../../../components/filters/PageFilterContainer";

const WeightAmountPageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(weightAmountListFilterState);
    const paginationFilterValue = useRecoilValue(weightAmountPaginationFilterState);
    const locationSearchFilterValue = useRecoilValue(locationSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...locationSearchFilterValue,
            }
        })
    }, [locationSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString atom={weightAmountPaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer>
                    <LocationSearchFilter />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

export default WeightAmountPageFilter;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

