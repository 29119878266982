import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash";

import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { selectedOrderItemsState } from "../store";
import { API_ORDER_URI, NOOP, PERMISSION_ADMIN } from "../../../includes/constants";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import useCreateConsignment from "../../../service/hooks/useCreateConsignment";
import useAddWeightCharge from "../../../service/hooks/useAddWeightCharge";
import useCreateInvoice from "../../../service/hooks/useCreateInvoice";
import useAPI from "../../../core/hooks/useAPI";

import DataTableActions from "../../../components/layouts/DataTableActions";
import ConfirmActionButton from "../../../components/action-buttons/ConfirmActionButton";
import FlightSelectActionButton from "../../../components/action-buttons/FlightSelectActionButton";
import DeliveryStatusActionButton from "../../../components/action-buttons/DeliveryStatusActionButton";
import CreateConsignmentActionButton from "../../../components/action-buttons/CreateConsignmentActionButton";
import { currentUserState } from "../../../includes/auth";
import AwaitButton from "../../../components/AwaitButton";
import useOrdersCsvData from "../hooks/useOrdersCsvData";


const OrderPageDataTableActions = ({
  className = '',
  refresh = NOOP,
}) => {

  const [{ loading: ordersDataLoading }, getCSV] = useOrdersCsvData();
  const history = useHistory();
  const [selectedOrderItems, setSelectedOrderItems] = useRecoilState(selectedOrderItemsState);
  const { closeConfirmation } = useAwaitingDialogContext();
  const [currentUser,] = useRecoilState(currentUserState);
  const refreshAndInitialize = useCallback(() => {
    setSelectedOrderItems([]);
    return refresh();
  }, [refresh, setSelectedOrderItems]);

  /***********************************************************
   * 운항정보 변경
   ***********************************************************/
  const updateOrderFlight = useAPI({
    url: `${API_ORDER_URI}/orderflight`,
    method: 'PUT',
    successMessage: "선택하신 주문의 운항정보를 성공적으로 변경하였습니다.",
    callbackAfterSuccess: refreshAndInitialize,
  });

  const onSelectFlight = useCallback((selectedFlight) => {
    if (selectedFlight) {
      updateOrderFlight({
        data: {
          FlightId: selectedFlight.FlightId,
          OrderIds: selectedOrderItems.map(order => order.OrderId)
        }
      });
    } else {
      closeConfirmation();
    }
  }, [updateOrderFlight, closeConfirmation, selectedOrderItems]);

  /***********************************************************
   * 배송상태 변경
   ***********************************************************/
  const updateOrderDeliveryStatus = useAPI({
    url: `${API_ORDER_URI}/deliverystatus`,
    method: 'PUT',
    successMessage: "선택하신 주문의 배송상태를 성공적으로 변경하였습니다.",
    callbackAfterSuccess: refreshAndInitialize,
  });

  const onSelectDeliveryStatus = useCallback((deliveryStatusUpdateData) => {
    if (deliveryStatusUpdateData) {
      updateOrderDeliveryStatus({
        data: {
          DeliveryStatusCode: deliveryStatusUpdateData.deliveryStatusCode,
          IsSendEmail: deliveryStatusUpdateData.isSendEmail,
          OrderIds: selectedOrderItems.map(order => order.OrderId),
        }
      });
    } else {
      closeConfirmation();
    }
  }, [updateOrderDeliveryStatus, closeConfirmation, selectedOrderItems]);

  const [onConfirmCreateConsignment] = useCreateConsignment({
    callbackAfterSuccess: refreshAndInitialize,
    orderIds: selectedOrderItems.map(order => Number(order.OrderId)),
    successMessage: "선택하신 주문의 송장을 성공적으로 발행하였습니다.",
  });

  const [onConfirmCreateInvoice] = useCreateInvoice({
    callbackAfterSuccess: refreshAndInitialize,
    orderIds: selectedOrderItems.map(order => Number(order.OrderId)),
    successMessage: "선택하신 주문의 인보이스를 성공적으로 발행하였습니다.",
  });

  const [onConfirmAddWeightCharge] = useAddWeightCharge({
    callbackAfterSuccess: refreshAndInitialize,
    orderIds: selectedOrderItems.map(order => Number(order.OrderId)),
    successMessage: "선택하신 주문의 배송비를 성공적으로 계산하였습니다.",
  });

  return (
    <DataTableActions className={className}>
      <Button
        type="button"
        label="배송대행 주문생성"
        icon={"pi pi-pencil"}
        onClick={() => { history.push('/order/createorder') }}
        className={"mb-2 ml-2"}
      />
       <Button
        type="button"
        label="구매대행 주문생성"
        icon={"pi pi-pencil"}
        onClick={() => { history.push('/order/createpurchaseorder') }}
        className={"mb-2 ml-2"}
      />
      <AwaitButton
        type="button"
        label="CSV 다운로드"
        icon={"pi pi-download"}
        onClick={getCSV}
        className={"mb-2 ml-2 mr-5"}
      />
      <FlightSelectActionButton onSelect={onSelectFlight} disabled={isEmpty(selectedOrderItems)} />
      <DeliveryStatusActionButton onSelect={onSelectDeliveryStatus} disabled={isEmpty(selectedOrderItems)} />
      {currentUser.permission === PERMISSION_ADMIN &&
        <>
          <ConfirmActionButton
            icon={"pi pi-calendar-times"}
            className={"mb-2 ml-2"}
            label={"배송비 계산"}
            onOkayClicked={onConfirmAddWeightCharge}
            disabled={isEmpty(selectedOrderItems)}
          >
            선택하신 주문의 배송비를 계산하시겠습니까?
          </ConfirmActionButton>


          <CreateConsignmentActionButton
            className={"mb-2 ml-2"}
            onOkayClicked={onConfirmCreateConsignment}
            disabled={isEmpty(selectedOrderItems)}
          />
          <ConfirmActionButton
            icon={"pi pi-plus"}
            className={"mb-2 ml-2"}
            label={"인보이스 발행"}
            onOkayClicked={onConfirmCreateInvoice}
            disabled={isEmpty(selectedOrderItems)}
          >
            선택하신 주문의 인보이스를 발행하시겠습니까?
          </ConfirmActionButton>
        </>
      }
    </DataTableActions>
  );
}

OrderPageDataTableActions.propTypes = {
  className: PropTypes.string,
  refresh: PropTypes.func,
}

export default OrderPageDataTableActions;