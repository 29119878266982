import React, { useCallback, useEffect, useMemo } from "react";
import { NOOP } from "../../../includes/constants";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import { css } from "@emotion/react";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import { useFormik } from "formik";
import { trim } from "lodash";
import AwaitButton from "../../../components/AwaitButton";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

const OrderLocalTrackingNumber = ({
    onSaveOrderLocalTrackingNumber = NOOP,
    orderData = null,
    className = "",
}) => {
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const orderItemDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm}>
            송장번호를 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const onSaveClicked = useCallback((values) => {
        return openConfirmation(orderItemDialog)
            .then(() => {
                onSaveOrderLocalTrackingNumber(values);
            }).catch(() => {
                // ignore onCancel
            }).finally(() => {
                /**
                 * 이 페이지는 마지막에 취소를 해도 값을 지우지 말자!!!
                 * formik.setValues({});
                 */
            });
    }, [onSaveOrderLocalTrackingNumber, orderItemDialog, openConfirmation]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            return onSaveClicked(values);
        },
    });

    useEffect(() => {
        if (orderData) {
            formik.setValues({
                LocalTrackingNumber: orderData.LocalTrackingNumber,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderData]);

    const onSaveOrderLocalTrackingNumberClicked = useCallback(() => {
        return formik.submitForm();
    }, [formik]);

    return (
        <div
            className={className}
            css={orderLocalTrackingNumberStyle}
        >
            <label htmlFor="LocalTrackingNumber" className="field col-12 md:col-12">로컬 송장 번호</label>
            <div className="grid field col-12 md:col-12" css={gridGroupNoMarginStyle}>
                <div className="col-12 lg:col-8 md:col-12 sm:col-8">
                    <InputTextWithError
                        name="LocalTrackingNumber"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.LocalTrackingNumber || ''}
                        error={formik.errors.LocalTrackingNumber}
                    />
                </div>
                <AwaitButton
                    css={localTrackingNumberButtonStyle}
                    className="col-12 lg:col-4 md:col-12 sm:col-4"
                    type="button"
                    onClick={onSaveOrderLocalTrackingNumberClicked}
                    label={"송장번호 수정"}
                />
            </div>
        </div>
    )
}

export default OrderLocalTrackingNumber;


const validate = (values) => {
    const errors = {};

    if (!trim(values.LocalTrackingNumber)) {
        errors.LocalTrackingNumber = "Required";
    }

    return errors;
}

const orderLocalTrackingNumberStyle = css`
    position: relative;

    label.field.col-12 {
        font-weight: 600;
        display: inline-block;
        margin-bottom: 0.5rem;
    }
`;

const gridGroupNoMarginStyle = css`
    margin: 0 0 1rem;
    padding: 0;
`;

const localTrackingNumberButtonStyle = css`
    height: 30px;
    margin-top: 3px;
    margin-bottom: 12px;
`;