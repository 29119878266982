import React, { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { isEmpty, throttle } from "lodash";
import { css } from "@emotion/react";

import { DataTable } from "primereact/datatable";

import { selectedInvoicesState } from "./store";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../includes/constants";
import { formatNumber, getNewZealandCurrencyMark } from "../../includes/numbers";
import useInvoicesData from "./hooks/useInvoicesData";
import useDataTableExportCSV from "../../core/hooks/useDataTableExportCSV";
import useDataTableColumns from "../../core/hooks/useDataTableColumns";
import usePaymentInvoice from "../../service/hooks/usePaymentInvoice";
import useDownloadInvoice from "../../service/hooks/useDownloadInvoice";

import Loading from "../../components/loading/Loading";
import InvoicePageFilter from "./components/InvoicePageFilter";
import DataTableActions from "../../components/layouts/DataTableActions";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import ConfirmActionButton from "../../components/action-buttons/ConfirmActionButton";
import ScrollDataTableContainer from "../../components/layouts/ScrollDataTableContainer";

const fieldColumnTitleMap = {
    InvoiceId: "인보이스 ID",
    InvoiceNumber: "인보이스 넘버",
    OrderId: "주문 ID",
    OrderNumber: "주문번호",
    UserId: "주문자 ID",
    UserName: "주문자",
    InvoiceDate: "발행일",
    PaidDate: "임급일",
    InvoiceAmount: "금액",
    TaxAmount: "세금",
    TotalAmount: "총금액",
    IsPaymentCompleted: "입금상태"
}

const sortableFields = [
    "InvoiceId",
    "UserName",
    "OrderNumber",
    "InvoiceNumber",
    "InvoiceDate",
    "OrderDate",
    "InvoiceAmount",
    "TotalAmount",
    "PaymentStatus"
];

const InvoicePage = () => {
    const [selectedItems, setSelectedItems] = useRecoilState(selectedInvoicesState);
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: invoicesData, loading: invoicesDataLoading }, getInvoices] = useInvoicesData();
    const { isDownloading, onInvoiceDownloadClicked } = useDownloadInvoice();

    const onSearch = useMemo(() => {
        return throttle((e) => {
            if (invoicesDataLoading) return getPromise();
            return getInvoices();
        }, 1000);
    }, [getInvoices, invoicesDataLoading]);

    const {
        sortPaginationProps,
        renderLinkColumn,
        renderColumn,
        renderCheckboxColumn
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getInvoices,
        dataSize: invoicesData?.Data?.length ?? 0,
    });

    const callbackAfterSuccess = useCallback(() => {
        setSelectedItems([]);
        return getInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getInvoices]);

    const onConfirmPaymentInvoice = usePaymentInvoice({
        callbackAfterSuccess: callbackAfterSuccess,
        invoiceIds: selectedItems.map(item => Number(item.InvoiceId)),
        successMessage: "선택하신 인보이스를 성공적으로 입금처리 하였습니다.",
    });

    return (
        <div className="grid" css={invoicePageStyle}>
            <div className="col-12">

                {isDownloading && <Loading onTop />}
                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>인보이스 리스트</h5>
                    <InvoicePageFilter onSearch={onSearch} loading={invoicesDataLoading} />
                </div>

                <div className="card">
                    <DataTableActions>
                        <ConfirmActionButton
                            icon={"pi pi-dollar"}
                            className={"mb-2 ml-2"}
                            label={"입금처리"}
                            onOkayClicked={onConfirmPaymentInvoice}
                            disabled={isEmpty(selectedItems)}
                        >
                            선택하신 인보이스를 입금처리하시겠습니까?
                        </ConfirmActionButton>
                    </DataTableActions>

                    <ScrollDataTableContainer loading={invoicesDataLoading} minWidth={1024}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={invoicesDataLoading}
                            ref={dataTableRef}
                            value={invoicesData?.Data || []}
                            selection={selectedItems}
                            onSelectionChange={(e) => setSelectedItems(e.value)}
                            dataKey="InvoiceId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={invoicesData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={invoicesData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                            selectionMode="checkbox"
                        >
                            {renderCheckboxColumn()}
                            {renderLinkColumn("InvoiceNumber", "InvoiceId", {
                                onClick: onInvoiceDownloadClicked
                            })}
                            {renderColumn("UserName")}
                            {renderColumn("OrderNumber")}
                            {renderColumn("InvoiceDate")}
                            {renderColumn("TotalAmount", {
                                formatValue: formatNumber,
                                prefixAppender: getNewZealandCurrencyMark
                            })}
                            {renderColumn("IsPaymentCompleted")}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}

export default InvoicePage;

const invoicePageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalAmount {
            text-align: right;
            width: 160px;
        }
    }

    .column-TotalAmount {
        text-align: right;
        padding-right: 20px;
    }
`;