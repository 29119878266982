import React, { memo } from 'react';
import { css } from "@emotion/react";
import classNames from "classnames";

const HiddenFormField = ({ className }) => (
    <div
        className={classNames("field col-12 md:col-6", className)}
        css={hiddenFormFieldStyle}
    />
);

export default memo(HiddenFormField);

const hiddenFormFieldStyle = css`
    visibility: hidden;
    margin: 0
`;
