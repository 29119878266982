import { atom } from "recoil";

// export const orderListState = atom({
//     key: 'orderListState',
//     default: [],
// });

export const orderDetailPageDeliveryStatusState = atom({
    key: 'orderDetailPageDeliveryStatusState',
    default: {
        deliveryStatusCode: null,
        deliveryStatusName: null,
    }
});


export const orderDetailPageFlightSelectState = atom({
    key: 'orderDetailPageFlightSelectState',
    default: {
        flightId: null,
        flightName: null,
    }
});

export const orderDetailState = atom({
    key: 'orderDetailState',
    default: {
        OrderItemList: [],
        FlightInfo: null,
        OrderChargeList: [],
        OrderInvoiceList: [],
        OrderNoteList: [],
        OrderId: '',
        OrderNumber: '',
        UserId: '',
        UserName: '',
        OrderDate: '',
        DeliveryLocationCode: '',
        DeliveryLocationName: '',
        DeliveryTypeCode: '',
        DeliveryTypeName: '',
        DeliveryStatusCode: '',
        DeliveryStatusName: '',
        IsReceiverOwn: '',
        ReceiverName: '',
        ReceiverEmail: '',
        ReceiverPhone: '',
        ReceiverMobile: '',
        ReceiverAddress1: '',
        ReceiverAddress2: '',
        ReceiverZipcode: '',
        LocalTrackingNumber: '',
        PickupTypeCode: '',
        PersonalCustomCode: '',
        TotalWeight: '',
        TotalVolumeWeight: '',
        TotalItemAmount: '',
        TotalExchangeItemAmount: '',
        FlightId: '',
        FlightName: '',
        TotalChargeAmount: '',
        TotalItemCount: '',
        IsAllItemEntered: '',
        ConsignmentNumber: '',
        TotalPaidAmount: '',
        PurchaseOrderItemList: [],
        IsPurchaseOrder: '',
        
    },
});

export const orderListFilterState = atom({
    key: 'orderListFilterState',
    default: {},
});

export const orderPaginationFilterState = atom({
    key: 'orderPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy: null,
    },
});

export const selectedOrderItemsState = atom({
    key: 'selectedOrderItemsState',
    default: [],
});

export const createOrderChargeDialogDeliveryLocationCodeState = atom({
    key: 'createOrderChargeDialogDeliveryLocationCodeState',
    default: {
        DeliveryLocationCode: "1", /** 1 NZ, 2 KR **/
    },
});
