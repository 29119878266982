import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { css } from "@emotion/react";
import classNames from "classnames";

import { logout } from "../../includes/auth";
import { useReactPrimeThemeContext } from "../../core/contexts/ReactPrimeThemeContext";
import LoginUserProfile from "./LoginUserProfile";

export const AppTopbar = (props) => {

    const { layoutColorMode } = useReactPrimeThemeContext();
    const history = useHistory();

    const onLogout = useCallback(e => {
        logout();
        history.replace("/login");
    }, [history]);

    return (
        <div className="layout-topbar">
            <button
                type="button"
                className="p-link  layout-menu-button layout-topbar-button"
                onClick={props.onToggleMenuClick}
            >
                <i className="pi pi-bars" />
            </button>

            <Link to="/" className="layout-topbar-logo">
                <img
                    src={layoutColorMode === 'light' ? '/assets/layout/images/kaircargo-logo-dark.svg' : '/assets/layout/images/kaircargo-logo-white.svg'}
                    alt="logo"
                />
            </Link>

            <button
                type="button"
                className="p-link layout-topbar-menu-button layout-topbar-button"
                onClick={props.onMobileTopbarMenuClick}
            >
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li css={bigBreakpointStyle}>
                    <LoginUserProfile />
                    <button className="p-link layout-topbar-button" onClick={onLogout}>
                        <i className="pi pi-sign-out" />
                        <span>로그아웃</span>
                    </button>
                </li>
                {/*<li>*/}
                {/*    <Button icon={"pi pi-sign-out"} label={"로그아웃"} />*/}
                {/*</li>*/}
            </ul>
        </div>
    );
}

const bigBreakpointStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 991px) {
        flex-direction: column;

        .layout-topbar-button {
            justify-content: center !important;
        }
    }
`;