import React, { useMemo } from "react";
import { DataTable } from "primereact/datatable";

import { NOOP } from "../../../includes/constants";
import Loading from "../../../components/loading/Loading";
import DataTableActions from "../../../components/layouts/DataTableActions";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import TableTitle from "../../../components/layouts/TableTitle";
import useCreateInvoice from "../../../service/hooks/useCreateInvoice";
import CardContainer from "../../../components/layouts/CardContainer";
import ConfirmActionButton from "../../../components/action-buttons/ConfirmActionButton";
import { isEmpty } from "lodash";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import useDownloadInvoice from "../../../service/hooks/useDownloadInvoice";
import { formatNumber, getNewZealandCurrencyMark } from "../../../includes/numbers";
import { css } from "@emotion/react";

const fieldColumnTitleMap = {
    InvoiceId: "인보이스 ID",
    InvoiceNumber: "인보이스 번호",
    OrderId: "주문 ID",
    UserId: "회원 ID",
    InvoiceDate: "인보이스 발행일",
    PaidDate: "결제일",
    IsPaymentCompleted: "입금여부",
    TotalAmount: "총금액",
    TaxAmount: "GST",
    InvoiceAmount: "발행금액",
}

const OrderInvoiceList = ({
    refresh = NOOP,
    orderData = null,
}) => {
    const { isDownloading, onInvoiceDownloadClicked } = useDownloadInvoice();
    const {
        renderColumn,
        renderLinkColumn,
        rowClassName,
        getSummaryData,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        getData: NOOP,
        dataSize: (orderData?.OrderInvoiceList?.length ?? -1) + 1,
    });

    const titleSection = useMemo(() => (
        <TableTitle linkName={"OrderInvoiceList"}>
            <h5>인보이스</h5>
        </TableTitle>
    ), []);

    /***********************************************************
     * 인보이스 발행
     ***********************************************************/
    const [onConfirmCreateInvoice] = useCreateInvoice({
        callbackAfterSuccess: refresh,
        orderIds: [Number(orderData.OrderId)],
        successMessage: "인보이스를 성공적으로 발행하였습니다.",
    });

    const orderInvoiceList = useMemo(() => {
        const invoiceList = orderData?.OrderInvoiceList;
        if (isEmpty(invoiceList)) {
            return [];
        }

        const summaryFields = [
            "InvoiceAmount",
            "TaxAmount",
            "TotalAmount",
        ];

        return [
            ...invoiceList,
            getSummaryData(invoiceList, summaryFields)
        ]
    }, [orderData, getSummaryData]);

    return (
        <CardContainer css={orderInvoiceListStyle}>
            {(!orderData || isDownloading) && <Loading />}

            <DataTableActions className={"mb-2"} titleSection={titleSection}>
                <ConfirmActionButton
                    icon={"pi pi-plus"}
                    className={"mb-2 ml-2"}
                    label={"인보이스 발행"}
                    onOkayClicked={onConfirmCreateInvoice}
                >
                    인보이스를 발행하시겠습니까?
                </ConfirmActionButton>
            </DataTableActions>

            <ScrollDataTableContainer loading={!orderData} minWidth={1280}>
                <DataTable
                    resizableColumns
                    columnResizeMode="fit"
                    rowClassName={rowClassName}
                    loading={!orderData}
                    value={orderInvoiceList}
                    dataKey="InvoiceId"
                    className="datatable-responsive"
                    emptyMessage="No data found."
                >
                    {renderLinkColumn("InvoiceNumber", "InvoiceId", {
                        onClick: onInvoiceDownloadClicked
                    })}
                    {renderColumn("InvoiceDate")}
                    {renderColumn("IsSummaryData", { headerStyle: { width: '100px' } })}
                    {renderColumn("InvoiceAmount",{
                        formatValue: formatNumber,
                        prefixAppender: getNewZealandCurrencyMark
                    })}
                    {renderColumn("TaxAmount",{
                        formatValue: formatNumber,
                        prefixAppender: getNewZealandCurrencyMark
                    })}
                    {renderColumn("TotalAmount",{
                        formatValue: formatNumber,
                        prefixAppender: getNewZealandCurrencyMark
                    })}
                    {renderColumn("IsPaymentCompleted")}
                </DataTable>
            </ScrollDataTableContainer>
        </CardContainer>
    );
}

export default OrderInvoiceList;


const orderInvoiceListStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-InvoiceAmount,
        &.header-column-TaxAmount,
        &.header-column-TotalAmount {
            text-align: right;
            width: 180px;

            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-InvoiceAmount,
    .column-TaxAmount,
    .column-TotalAmount {
        text-align: right;
        padding-right: 20px;
    }
`;