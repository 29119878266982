import React, { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { throttle } from "lodash";
import { selectedOrderItemsState } from "./store";
import { DataTable } from "primereact/datatable";

import usePurchaseOrdersData from "./hooks/usePurchaseOrdersData";


import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions, PERMISSION_ADMIN } from "../../includes/constants";
import useDataTableExportCSV from "../../core/hooks/useDataTableExportCSV";
import useDataTableColumns from "../../core/hooks/useDataTableColumns";

import OrderPageFilter from "./components/OrderPageFilter";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import PurchaseOrderPageDataTableActions from "./components/PurchaseOrderPageDataTableActions";
import ScrollDataTableContainer from "../../components/layouts/ScrollDataTableContainer";
import { css } from "@emotion/react";
import { formatCurrency, formatDutyCurrency, formatNumber, getNewZealandCurrencyMark } from "../../includes/numbers";
import { currentUserState } from "../../includes/auth";
import { getOrderPath } from "../../core/routes/routes";

const fieldColumnTitleMap = {
    DeliveryLocationCode: "배송국가 코드",
    DeliveryLocationName: "배송국가",
    DeliveryStatusCode: "배송상태 코드",
    DeliveryStatusName: "배송상태",
    DeliveryTypeCode: "배송타입 코드",
    DeliveryTypeName: "배송타입",
    OrderDate: "주문일",
    OrderNumber: "주문번호",
    OrderId: "구매대행 번호",
    UserId: "주문자 ID",
    UserName: "주문자"


}

const sortableFields = [
  "OrderId",
  "OrderNumber",
  "UserName",
  "OrderDate",
];

const PurchaseOrderPage = () => {

  const [selectedOrderItems, setSelectedOrderItems] = useRecoilState(selectedOrderItemsState);
  const { dataTableRef } = useDataTableExportCSV();
  const [{ data: ordersData, loading: ordersDataLoading }, getOrders] = usePurchaseOrdersData();

  const [currentUser,] = useRecoilState(currentUserState);

  useEffect(() => {
    setSelectedOrderItems([]);
  }, [setSelectedOrderItems]);

  const onSearch = useMemo(() => {
    return throttle((e) => {
      if (ordersDataLoading) return getPromise();
      return getOrders();
    }, 1000);
  }, [getOrders, ordersDataLoading]);

  const {
    sortPaginationProps,
    renderColumn,
    renderLinkColumn,
    renderCheckboxColumn
  } = useDataTableColumns({
    fieldColumnTitleMap,
    sortableFields,
    getData: getOrders,
    dataSize: ordersData?.Data?.length ?? 0,
  });

  return (
    <div className="grid" css={orderPageStyle}>
      <div className="col-12">

        <EnhancedBreadCrumb />

        <div className="card">
          <h5>구매 대행 주문 리스트</h5>
          <OrderPageFilter onSearch={onSearch} loading={ordersDataLoading} />
        </div>

        <div className="card">
          <PurchaseOrderPageDataTableActions refresh={getOrders} />

          <ScrollDataTableContainer loading={ordersDataLoading} minWidth={1600}>
            <DataTable
              resizableColumns
              columnResizeMode="fit"
              loading={ordersDataLoading}
              ref={dataTableRef}
              value={ordersData?.Data || []}
              selection={selectedOrderItems}
              onSelectionChange={(e) => setSelectedOrderItems(e.value)}
              dataKey="OrderId"
              paginator
              {...sortPaginationProps}
              lazy
              totalRecords={ordersData?.Paging?.TotalNumberOfRecords}
              rows={ordersData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
              rowsPerPageOptions={rowsPerPageOptions}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              emptyMessage="No data found."
              selectionMode="checkbox"
            >
              {renderCheckboxColumn()}
              {renderLinkColumn("OrderId", "OrderId")}
              {renderColumn("DeliveryStatusName")}
              {renderColumn("DeliveryLocationName")}
              {renderColumn("UserName")}
              {renderColumn("OrderDate")}
              {renderLinkColumn("OrderNumber", "OrderId", {getLinkPath: getOrderPath})}


              
            </DataTable>
          </ScrollDataTableContainer>
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrderPage;

const orderPageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalItemAmount,
        &.header-column-TotalExchangeItemAmount,
        &.header-column-TotalChargeAmount,
        &.header-column-TotalPaidAmount,
        &.header-column-TotalChargeableWeight {
            text-align: right;
        }

        &.header-column-TotalItemAmount {
            width: 160px;
        }

        &.header-column-TotalChargeableWeight {
            width: 190px;
        }

        &.header-column-TotalPaidAmount,
        &.header-column-TotalChargeAmount,
        &.header-column-TotalExchangeItemAmount {
            width: 160px;

            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-TotalPaidAmount,
    .column-TotalItemAmount,
    .column-TotalExchangeItemAmount,
    .column-TotalChargeAmount,
    .column-TotalChargeableWeight {
        text-align: right;
        padding-right: 20px;
    }
`;