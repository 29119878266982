import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { throttle } from "lodash";
import { DataTable } from "primereact/datatable";

import useAPI from "../../../core/hooks/useAPI";
import useChargeTypesData from "./hooks/useChargeTypesData";
import useDataTableExportCSV from "../../../core/hooks/useDataTableExportCSV";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import { formatNumber, getNewZealandCurrencyMark } from "../../../includes/numbers";
import { API_CHARGE_TYPE_URI, DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../../includes/constants";

import ChargeTypePageFilter from "./components/ChargeTypePageFilter";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import EnhancedBreadCrumb from "../../../components/layouts/EnhancedBreadCrumb";
import DataTableActions from "../../../components/layouts/DataTableActions";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import CreateChargeTypeDialog, { createChargeTypeDialogState } from "../../../components/dialogs/CreateChargeTypeDialog";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";

const fieldColumnTitleMap = {
    CreateDate: "등록일",
    ChargeTypeId: "비용타입 ID",
    ChargeTypeName: "비용타입명",
    DeliveryLocationCode: "배송지역 코드 ",
    DeliveryLocationName: "배송국가",
    IsActive: "사용여부",
    ChargeAmount: "비용",
    TaxRate: "GST Rate(%)"
}

const sortableFields = [];

const ChargeTypePage = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const { dataTableRef } = useDataTableExportCSV();
    const [{ data: chargeTypesData, loading: chargeTypesDataLoading }, getChargeTypes] = useChargeTypesData();
    const { closeConfirmation } = useAwaitingDialogContext();
    const setCreateChargeTypeDialogState = useSetRecoilState(createChargeTypeDialogState);

    const onSearch = useMemo(() => {
        return throttle((e) => {
            if (chargeTypesDataLoading) return getPromise();
            return getChargeTypes();
        }, 1000);
    }, [getChargeTypes, chargeTypesDataLoading]);

    const {
        sortPaginationProps,
        renderColumn,
    } = useDataTableColumns({
        fieldColumnTitleMap,
        sortableFields,
        getData: getChargeTypes,
        dataSize: chargeTypesData?.Data?.length ?? 0,
    });

    /***********************************************************
     * 비용타입 생성
     ***********************************************************/
    const createChargeType = useAPI({
        url: API_CHARGE_TYPE_URI,
        method: 'POST',
        successMessage: "비용타입을 성공적으로 저장하였습니다.",
        callbackAfterSuccess: getChargeTypes,
    });

    const onCreateItem = useCallback((newChargeTypeData) => {
        if (newChargeTypeData) {
            createChargeType({
                data: {
                    DeliveryLocationCode: newChargeTypeData.DeliveryLocationCode,
                    ChargeTypeName: newChargeTypeData.ChargeTypeName,
                    IsActive: newChargeTypeData.IsActive,
                    ChargeAmount: newChargeTypeData.ChargeAmount,
                    TaxRate: newChargeTypeData.TaxRate,
                }
            });

            // TODO 200개 테스트 코드
            // const callTest = async () => {
            //     await asyncForEach(times(200), async (index) => {
            //         await createChargeType({
            //             data: {
            //                 DeliveryLocationCode: newChargeTypeData.DeliveryLocationCode,
            //                 ChargeTypeName: newChargeTypeData.ChargeTypeName + " : " + index,
            //                 IsActive: newChargeTypeData.IsActive,
            //                 ChargeAmount: newChargeTypeData.ChargeAmount,
            //                 TaxRate: newChargeTypeData.TaxRate,
            //             }
            //         });
            //     })
            // }
            // callTest();
        } else {
            closeConfirmation();
        }
    }, [createChargeType, closeConfirmation]);

    /***********************************************************
     * 비용타입 수정
     ***********************************************************/
    const updateChargeType = useAPI({
        url: API_CHARGE_TYPE_URI,
        method: 'PUT',
        successMessage: "비용타입을 성공적으로 수정하였습니다.",
        callbackAfterSuccess: getChargeTypes,
    });

    const onUpdateItem = useCallback((newChargeTypeData) => {
        if (newChargeTypeData) {
            updateChargeType({
                url: `${API_CHARGE_TYPE_URI}/${newChargeTypeData.ChargeTypeId}`,
                data: {
                    DeliveryLocationCode: newChargeTypeData.DeliveryLocationCode,
                    ChargeTypeName: newChargeTypeData.ChargeTypeName,
                    IsActive: newChargeTypeData.IsActive,
                    ChargeAmount: newChargeTypeData.ChargeAmount,
                    TaxRate: newChargeTypeData.TaxRate,
                }
            });
        } else {
            closeConfirmation();
        }
    }, [updateChargeType, closeConfirmation]);

    const onUpdateClicked = useCreateActionButton({
        onSelect: onUpdateItem,
        confirmMessage: "배송비용을 수정하시겠습니까?",
        atom: createChargeTypeDialogState,
        dialogComponent: (props) => {
            return (
                <CreateChargeTypeDialog title={"배송비용 수정"} {...props} />
            );
        },
    });

    useEffect(() => {
        if (selectedItem && !selectedItem.IsSummaryData) {
            setCreateChargeTypeDialogState(selectedItem);
            onUpdateClicked().finally(() => setSelectedItem(null));
        }
        // onUpdateClicked 를 디펜던시에 넣으면 안되요!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, setCreateChargeTypeDialogState]);

    return (
        <div className="grid" css={chargeTypePageStyle}>
            <div className="col-12">

                <EnhancedBreadCrumb />

                <div className="card">
                    <h5>비용타입 리스트</h5>
                    <ChargeTypePageFilter onSearch={onSearch} loading={chargeTypesDataLoading} />
                </div>

                <div className="card">

                    <DataTableActions className={"mb-2"}>
                        <CreateActionButton
                            label="비용타입 생성"
                            onSelect={onCreateItem}
                            confirmMessage={"비용타입을 추가하시겠습니까?"}
                            atom={createChargeTypeDialogState}
                            dialogComponent={CreateChargeTypeDialog}
                            shouldShowConfirm={false}
                        />
                    </DataTableActions>

                    <ScrollDataTableContainer loading={chargeTypesDataLoading} minWidth={800}>
                        <DataTable
                            resizableColumns
                            columnResizeMode="fit"
                            loading={chargeTypesDataLoading}
                            ref={dataTableRef}
                            value={chargeTypesData?.Data || []}
                            selectionMode={"single"}
                            selection={selectedItem}
                            onSelectionChange={(e) => setSelectedItem(e.value)}
                            dataKey="ChargeTypeId"
                            paginator
                            {...sortPaginationProps}
                            lazy
                            totalRecords={chargeTypesData?.Paging?.TotalNumberOfRecords ?? 0}
                            rows={chargeTypesData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
                            rowsPerPageOptions={rowsPerPageOptions}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No data found."
                        >
                            {renderColumn("DeliveryLocationName")}
                            {renderColumn("ChargeTypeName")}
                            {renderColumn("ChargeAmount", {
                                formatValue: formatNumber,
                                prefixAppender: getNewZealandCurrencyMark
                            })}
                            {renderColumn("TaxRate")}
                            {renderColumn("IsActive")}
                        </DataTable>
                    </ScrollDataTableContainer>
                </div>
            </div>
        </div>
    );
}

export default ChargeTypePage;

const chargeTypePageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-ChargeAmount {
            text-align: right;
            width: 180px;

            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-ChargeAmount {
        text-align: right;
        padding-right: 20px;
    }
`;