import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { css } from "@emotion/react";
import { trim } from "lodash";
import { NOOP } from "../../../includes/constants";
import { DATE_FORMAT_REACT_PRIME } from "../../../includes/dates";
import useCommonCode, { COMMON_CODE_DELIVERY_LOCATION } from "../../../service/hooks/useCommonCode";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import DropdownWithError from "../../../components/forms/DropdownWithError";
import CalendarWithError from "../../../components/forms/CalendarWithError";
import HiddenFormField from "../../../components/forms/HiddenFormField";
import AwaitButton from "../../../components/AwaitButton";
import Loading from "../../../components/loading/Loading";

const FlightInfo = ({
    onSaveFlightInfo = NOOP,
    flightData = null,
}) => {
    const {
        data: deliveryLocationData,
        loading: deliveryLocationLoading
    } = useCommonCode(COMMON_CODE_DELIVERY_LOCATION);

    const onSaveClicked = useCallback((values) => {
        return onSaveFlightInfo({
            ...values,
            DeliveryLocationCode: values.DeliveryLocationCode.Code,
            DeliveryLocationName: values.DeliveryLocationCode.Name,
        });
    }, [onSaveFlightInfo]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            onSaveClicked({
                ...values,
                DepartDate: values?.DepartDate || '',
                ArrivalDate: values?.ArrivalDate?.toString() === "Invalid Date" ? '' : values?.ArrivalDate,
                EstimatedArrivalDate: values?.EstimatedArrivalDate || '',
            });
        },
    });

    useEffect(() => {
        // 수정할때 적용되는 값들
        if (flightData?.FlightId) {
            formik.setValues({
                ...formik.values,
                ...flightData,
                DeliveryLocationCode: deliveryLocationData.find(item => item.Code === flightData?.DeliveryLocationCode),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightData]);

    useEffect(() => {
        // 수정할때 적용되는 값들
        if (flightData?.FlightId) {
            formik.setValues({
                ...formik.values,
                ...flightData,
                DeliveryLocationCode: deliveryLocationData.find(item => item.Code === flightData?.DeliveryLocationCode),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightData]);

    return (
        <div className="col-12" css={flightInfoStyle}>
            <form onSubmit={formik.handleSubmit}>
                {deliveryLocationLoading && <Loading />}
                <AwaitButton
                    icon="pi pi-check"
                    className={"mb-5"}
                    label={"저장"}
                    type="submit"
                />
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="FlightName">운항명</label>
                        <InputTextWithError
                            name="FlightName"
                            type="text"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.FlightName}
                            error={formik.errors.FlightName}
                            maxLength={100}
                        />
                    </div>
                    <HiddenFormField />
                    <div className="field col-12 md:col-6">
                        <label htmlFor="FlightNumber">편명</label>
                        <InputTextWithError
                            name="FlightNumber"
                            type="text"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.FlightNumber}
                            error={formik.errors.FlightNumber}
                            maxLength={10}
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="DeliveryLocationCode">배송지역</label>
                        <DropdownWithError
                            name="DeliveryLocationCode"
                            options={deliveryLocationData}
                            optionLabel="Name"
                            placeholder="Select One"
                            onChange={formik.handleChange}
                            value={deliveryLocationData.find(item => item.Code === formik.values.DeliveryLocationCode?.Code)}
                            error={formik.errors.DeliveryLocationCode}
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="DepartDate">출발일</label>
                        <CalendarWithError
                            name={"DepartDate"}
                            dateFormat={DATE_FORMAT_REACT_PRIME}
                            showIcon
                            showButtonBar
                            onChange={formik.handleChange}
                            value={formik.values.DepartDate}
                            error={formik.errors.DepartDate}
                        />
                    </div>
                    <HiddenFormField />
                    <div className="field col-12 md:col-6">
                        <label htmlFor="EstimatedArrivalDate">예상도착일</label>
                        <CalendarWithError
                            name={"EstimatedArrivalDate"}
                            dateFormat={DATE_FORMAT_REACT_PRIME}
                            showIcon
                            showButtonBar
                            onChange={formik.handleChange}
                            value={formik.values.EstimatedArrivalDate}
                            error={formik.errors.EstimatedArrivalDate}
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="ArrivalDate">도착일</label>
                        <CalendarWithError
                            name={"ArrivalDate"}
                            dateFormat={DATE_FORMAT_REACT_PRIME}
                            showIcon
                            showButtonBar
                            onChange={formik.handleChange}
                            value={formik.values.ArrivalDate}
                            error={formik.errors.ArrivalDate}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FlightInfo;

const validate = (values) => {
    const errors = {};

    if (!trim(values.FlightName)) {
        errors.FlightName = "Required";
    }

    if (!trim(values.FlightNumber)) {
        errors.FlightNumber = "Required";
    }

    if (!trim(values.DeliveryLocationCode)) {
        errors.DeliveryLocationCode = "Required";
    }

    if (!trim(values.DepartDate)) {
        errors.DepartDate = "Required";
    }

    if (!trim(values.EstimatedArrivalDate)) {
        errors.EstimatedArrivalDate = "Required";
    }

    return errors;
}

const flightInfoStyle = css`
    max-width: 800px;
`;