import React, { useCallback, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import { trim } from "lodash";
import { validate as validateEmail } from "email-validator";

import { NOOP } from "../../../includes/constants";
import { isBooleanString, toBooleanValue } from "../../../includes/ajax";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import Loading from "../../../components/loading/Loading";
import TableTitle from "../../../components/layouts/TableTitle";
import AwaitButton from "../../../components/AwaitButton";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import CardContainer from "../../../components/layouts/CardContainer";
import EnhancedDivider from "../../../components/layouts/EnhancedDivider";
import DataTableActions from "../../../components/layouts/DataTableActions";
import InputTextWithError from "../../../components/forms/InputTextWithError";

const OrderUserInfo = ({
    onSaveOrderUserInfo = NOOP,
    orderUserData = null,
    className = "",
}) => {
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const orderItemDialog = useMemo(() => (
        <ConfirmDialog onCancel={closeConfirmation} onConfirm={confirm}>
            주문자(회원) 정보를 수정하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const onSaveClicked = useCallback((values) => {
        return openConfirmation(orderItemDialog)
            .then(() => {
                onSaveOrderUserInfo(values);
            }).catch(() => {
                // ignore onCancel
            }).finally(() => {
                // 여기서도 구지 지울 필요가 없다.
                // formik.setValues({});
            });
    }, [onSaveOrderUserInfo, orderItemDialog, openConfirmation]);

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validate,
        onSubmit: values => {
            return onSaveClicked(values);
        },
    });

    const onSaveOrderUserInfoClicked = useCallback(() => {
        return formik.submitForm();
    }, [formik]);

    useEffect(() => {
        if (orderUserData) {
            formik.setValues({
                IsActive: isBooleanString(orderUserData.IsActive) ? toBooleanValue(orderUserData.IsActive) : false,
                PersonalCustomCode: orderUserData.PersonalCustomCode,
                UserId: orderUserData.UserId,
                EmailAddress: orderUserData.EmailAddress,
                UserName: orderUserData.UserName,
                MobileNumber: orderUserData.MobileNumber,
                PhoneNumber: orderUserData.PhoneNumber,
                UserAddressKR1: orderUserData.UserAddressKR1,
                UserAddressKR2: orderUserData.UserAddressKR2,
                ZipCodeKR: orderUserData.ZipCodeKR,
                UserAddressNZ1: orderUserData.UserAddressNZ1,
                UserAddressNZ2: orderUserData.UserAddressNZ2,
                ZipCodeNZ: orderUserData.ZipCodeNZ,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderUserData]);

    const titleSection = useMemo(() => (
        <TableTitle noPadding linkName={"OrderUserInfo"}>
            <h5>주문자(회원) 정보</h5>
        </TableTitle>
    ), []);

    return (
        <CardContainer>
            <form onSubmit={formik.handleSubmit} noValidate>
                {!orderUserData && <Loading />}
                <DataTableActions className={"mb-2"} titleSection={titleSection}>
                    <AwaitButton onClick={onSaveOrderUserInfoClicked} label={"주문자 수정"} icon="pi pi-pencil" />
                </DataTableActions>
                <EnhancedDivider />

                <div className="p-fluid formgrid grid">

                    <div className="field col-12 md:col-6">
                        <label htmlFor="UserName">주문자명</label>
                        <InputTextWithError
                            name="UserName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.UserName || ''}
                            error={formik.errors.UserName}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="EmailAddress">이메일</label>
                        <InputTextWithError
                            name="EmailAddress"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.EmailAddress || ''}
                            error={formik.errors.EmailAddress}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="PhoneNumber">전화번호</label>
                        <InputTextWithError
                            name="PhoneNumber"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.PhoneNumber || ''}
                            error={formik.errors.PhoneNumber}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="MobileNumber">휴대폰</label>
                        <InputTextWithError
                            name="MobileNumber"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.MobileNumber || ''}
                            error={formik.errors.MobileNumber}
                        />
                    </div>

                    <EnhancedDivider align="left" className={"mb-3"} css={addressDividerStyle}>
                        <div className="p-d-inline-flex p-ai-center">
                            <i className="pi pi-home mr-2" style={{ 'fontSize': 'inherit'}}></i>
                            <b>대한민국 주소</b>
                        </div>
                    </EnhancedDivider>


                    {/*****************************************
                     * 주소 덩어리
                     *****************************************/}
                    <div className="field grid col-12" css={gridGroupNoMarginStyle}>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="UserAddressKR1">주소</label>
                            <InputTextWithError
                                name="UserAddressKR1"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.UserAddressKR1 || ''}
                                error={formik.errors.UserAddressKR1}
                            />
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="UserAddressKR2">상세주소</label>
                            <InputTextWithError
                                name="UserAddressKR2"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.UserAddressKR2 || ''}
                                error={formik.errors.UserAddressKR2}
                            />
                        </div>

                        <div className="field col-12 md:col-2">
                            <label htmlFor="ZipCodeKR">우편번호</label>
                            <InputTextWithError
                                name="ZipCodeKR"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.ZipCodeKR || ''}
                                error={formik.errors.ZipCodeKR}
                            />
                        </div>

                    </div>

                    <EnhancedDivider align="left" className={"mb-3"} css={addressDividerStyle}>
                        <div className="p-d-inline-flex p-ai-center">
                            <i className="pi pi-home mr-2" style={{ 'fontSize': 'inherit'}}></i>
                            <b>뉴질랜드 주소</b>
                        </div>
                    </EnhancedDivider>

                    {/*****************************************
                     * 주소 덩어리
                     *****************************************/}
                    <div className="field grid col-12" css={gridGroupNoMarginStyle}>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="UserAddressNZ1">주소</label>
                            <InputTextWithError
                                name="UserAddressNZ1"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.UserAddressNZ1 || ''}
                                error={formik.errors.UserAddressNZ1}
                            />
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="UserAddressNZ2">상세주소</label>
                            <InputTextWithError
                                name="UserAddressNZ2"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.UserAddressNZ2 || ''}
                                error={formik.errors.UserAddressNZ2}
                            />
                        </div>

                        <div className="field col-12 md:col-2">
                            <label htmlFor="ZipCodeNZ">우편번호</label>
                            <InputTextWithError
                                name="ZipCodeNZ"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.ZipCodeNZ || ''}
                                error={formik.errors.ZipCodeNZ}
                            />
                        </div>

                    </div>


                </div>
            </form>
        </CardContainer>
    )
}

export default OrderUserInfo;


const validate = (values) => {
    const errors = {};

    // TODO 당분간은 PersonalCustomCode 이 필드는 체크하지 않는다.
    // if (!trim(values.PersonalCustomCode)) {
    //     errors.PersonalCustomCode = "Required";
    // }

    if (!trim(values.UserName)) {
        errors.UserName = "Required";
    }
    if (!trim(values.EmailAddress)) {
        errors.EmailAddress = "Required";
    } else if (!validateEmail(values.EmailAddress)) {
        errors.EmailAddress = 'Invalid email';
    }
    if (!trim(values.PhoneNumber)) {
        errors.PhoneNumber = "Required";
    }
    if (!trim(values.MobileNumber)) {
        errors.MobileNumber = "Required";
    }
    // if (!trim(values.UserAddressKR1)) {
    //     errors.UserAddressKR1 = "Required";
    // }
    // if (!trim(values.UserAddressKR2)) {
    //     errors.UserAddressKR2 = "Required";
    // }
    // if (!trim(values.ZipCodeKR)) {
    //     errors.ZipCodeKR = "Required";
    // }

    return errors;
}

const gridGroupNoMarginStyle = css`
    margin: 0 0 1rem;
    padding: 0;
`;

const addressDividerStyle = css`
    font-size: 1.2rem;
`;