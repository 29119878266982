import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { trim } from "lodash";
import { css } from "@emotion/react";
import useAxios from "axios-hooks";
import qs from "qs";
import { atom, useRecoilValue } from "recoil";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import FilterLabel from "../../components/filters/FilterLabel";

import TextFieldSearchFilter from "../../components/filters/TextFieldSearchFilter";
import AwaitButton from "../AwaitButton";
import FilterContainer from "../filters/FilterContainer";
import Loading from "../loading/Loading";
import EnhancedDialog from "./EnhancedDialog";
import { stringifyFilter } from "../../includes/ajax";
import useHandleEnterEvent from "../../core/hooks/useHandleEnterEvent";

const customerSearchDropdownItems = [
  { name: '이름', searchField: 'UserName', isDefault: true },
  { name: '회원아이디', searchField: 'UserLoginId' },
  { name: '이메일', searchField: 'EmailAddress' },
  { name: '전화번호', searchField: 'PhoneNumber' },
  { name: '휴대폰', searchField: 'MobileNumber' },
];



const fieldColumnTitleMap = {
  UserLoginId: "아이디",
  UserName: "이름",
  MobileNumber: "휴대폰",

}

const PAGE_SIZE = 10;
const API_URI = '/manager/customer';

const UserSelectSearchDialog = ({
  onCancel,
  onConfirm,
  title = "고객정보",
  shouldShowConfirm = true,
  renderConfirm = null, // should be null as default
}) => {

  const [searchStr, setSearchStr] = useState("");
  const [searchField, setSearchField] = useState(customerSearchDropdownItems[0]);
  const [pageNo, setPageNo] = useState(0);


  const [selectedUser, setSelectedUser] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const searchStrRef = useRef();



  const handleReturnConfirm = useCallback(() => {
    onConfirm(selectedUser)
  }, [onConfirm, selectedUser]);

  const handleConfirm = useCallback(() => {
    if (shouldShowConfirm && renderConfirm && selectedUser) {
      setShowConfirm(true);
    } else {
      return onConfirm(selectedUser);
    }
  }, [shouldShowConfirm, renderConfirm, onConfirm, selectedUser, setShowConfirm]);

  const [{ data: usersData, loading: usersLoading }, getData] = useAxios({
    url: API_URI,
    method: 'GET',
  },
    { manual: true },
  );





  const getColumnTitle = useCallback(field => {
    return fieldColumnTitleMap[field] || field;
  }, []);

  const getFieldTemplate = useCallback((field) => {
    return (rowData) => (rowData[field]);
  }, []);

  const renderSortableColumn = useCallback((field) => (
    <Column
      field={field}
      header={getColumnTitle(field)}
      sortable={false}
      body={getFieldTemplate(field)}
    />
  ), [getColumnTitle, getFieldTemplate]);



  const onSearch = useCallback((e, pageNo = 1, pageSize = PAGE_SIZE) => {
    setSelectedUser(null);

    const queryString = qs.stringify({
      ...searchStr && { searchStr },
      searchField: searchField.searchField,
      pageNo,
      pageSize,
    }, { filter: stringifyFilter });

    return getData({
      url: `${API_URI}${queryString ? "?" + queryString : ""}`
    })
  }, [searchField, getData, searchStr]);


  const handleEnterEvent = useHandleEnterEvent(onSearch);

  const onPage = useCallback(pageObject => {
    setPageNo(pageObject.page);
    onSearch(null, pageObject.page + 1, pageObject.rows);
  }, [onSearch]);

  const onUserSelected = useCallback(e => {
    setSelectedUser(e.value);
  }, []);

  const onConfirmCancel = useCallback(() => {
    setShowConfirm(false)
  }, [setShowConfirm]);


  useEffect(() => {
    if (!usersLoading) {

      searchStrRef?.current?.focus();
    }
  }, [usersLoading]);

  return (
    <EnhancedDialog
      onCancel={onCancel}
      onConfirm={handleConfirm}
      title={title}
      css={flightSelectSearchDialogStyle}
      withLoading={false}
    >
      {showConfirm && renderConfirm && (
        renderConfirm({
          onConfirm: handleReturnConfirm,
          onCancel: onConfirmCancel,
          data: selectedUser,
        })
      )}
      <div>
        {usersLoading && <Loading />}
        <div className="col-12" css={filtersStyle}>
          <div css={searchGroupStyle}>
            <FilterLabel htmlFor={"searchField"}>회원검색</FilterLabel>
            <Dropdown
              name="searchField"
              value={customerSearchDropdownItems.find(item => item.searchField === searchField.searchField)}
              onChange={e => setSearchField(e.value)}
              options={customerSearchDropdownItems}
              optionLabel="name"
              placeholder="Select One"
            />
          </div>
          <FilterContainer css={filterContainerStyle}>

            <InputText
              name="searchStr"
              type="text"
              defaultValue={searchStr}
              onChange={e => setSearchStr(trim(e.target.value))}
              onKeyDown={handleEnterEvent}
              css={inputStyle}
              forwardRef={searchStrRef}
            />



            <AwaitButton
              className=""
              css={buttonStyle}
              type="submit"
              onClick={onSearch}
              label={"Search"}
            />

          </FilterContainer>
        </div>
        <div className="col-12" css={filtersStyle}>
          <DataTable
            resizableColumns
            columnResizeMode="fit"
            value={usersData?.Data || []}
            selection={selectedUser}
            onSelectionChange={onUserSelected}
            dataKey="UserId"
            paginator
            onPage={onPage}
            lazy
            totalRecords={usersData?.Paging?.TotalNumberOfRecords ?? 0}
            rows={PAGE_SIZE}
            first={PAGE_SIZE * pageNo}
            className="datatable-responsive"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink  CurrentPageReport "
            //currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            emptyMessage="No data found."
            rowHover
            selectionMode="single"
          >
            {renderSortableColumn("UserName")}
            {renderSortableColumn("UserLoginId")}
            {renderSortableColumn("MobileNumber")}
          </DataTable>
        </div>
      </div>
    </EnhancedDialog>
  )
}

UserSelectSearchDialog.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  shouldShowConfirm: PropTypes.bool,
  renderConfirm: PropTypes.func,
}

export default UserSelectSearchDialog;

const flightSelectSearchDialogStyle = css`
    width: 500px;
`;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

const filtersStyle = css`
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
    display: flex;
    align-items: center;
`;

const inputStyle = css`
    max-width: 140px;
    margin-right: 2px;
`;

const flightStatusSearchFilterStyle = css`
    margin: 0;

    .p-dropdown {
        min-width: 110px;
    }
`;

const filterContainerStyle = css`
    width: max-content;
`;


const searchGroupStyle = theme => css`
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: max-content;
    // display: flex;
    // align-items: center;
    // margin: 0;
    //
        // ${theme.breakpoints.down("xs")} {
    //     > * {
    //         width: 100%;
    //         margin: 2px 0;
    //         padding: 0;
    //     }
    // }
`;
