import { useLocation } from "react-router-dom";
import qs from "qs";
import { useEffect } from "react";

const useSetQueryStringToState = (setState) => {
    const location = useLocation();

    useEffect(() => {
        setState && setState(qs.parse(location.search, { ignoreQueryPrefix: true }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
}

export default useSetQueryStringToState;