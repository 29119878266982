import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { css } from "@emotion/react";

const InputTextWithError = ({ className, inputKey, error, noBorder = false, ...props }) => (
    <span css={withErrorStyle(error, noBorder)}>
        <InputText
            {...props}
            className={classNames(className, { "p-invalid": !!error })}
        />
    </span>
);

export default InputTextWithError;

const withErrorStyle = (error, noBorder) => css`
    display: flex;
    position: relative;
    padding-top: 3px;
    margin-bottom: 12px;

    ${noBorder && css`
        .p-inputtext.p-component {
            border-color: transparent;
            //border-bottom-color: inherit;
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 1rem;
            margin-top: -5px;
            background: var(--surface-a); // TODO 
        }

        .p-inputtext:enabled:focus {
            box-shadow: unset;
        }
    `}

    ${error && css`
        :after {
            content: '${error}';
            position: absolute;
            top: 100%;
            left: 0;
            color: #f44336;
            width: 100%;
            height: 10px;
            font-size: 10px;
            line-height: 10px;
            margin-top: 2px;
        }
    `}
`;
