import { atom } from "recoil";

export const qnaListState = atom({
    key: 'qnaListState',
    default: [],
});

export const qnaListFilterState = atom({
    key: 'qnaListFilterState',
    default: {},
});

export const qnaPaginationFilterState = atom({
    key: 'qnaPaginationFilterState',
    default: {
        pageNo: 0,
        pageSize: 0,
        orderField: null,
        orderBy : null,
    },
});

// export const filteredQnaListState = selector({
//     key: 'filteredQnaListState',
//     get: ({ get }) => {
//         const filter = get(qnaListFilterState);
//         const list = get(qnaListState);
//
//         switch (filter) {
//             case 'Show Completed':
//                 return list.filter((item) => item.isComplete);
//             case 'Show Uncompleted':
//                 return list.filter((item) => !item.isComplete);
//             default:
//                 return list;
//         }
//     },
// });
