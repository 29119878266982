import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import AwaitButton from "../AwaitButton";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import { NOOP } from "../../includes/constants";
import ConfirmDialog from "../dialogs/ConfirmDialog";

const CreateConsignmentActionButton = ({
    className = '',
    disabled = false,
    onOkayClicked = NOOP,
}) => {
    const { openConfirmation, closeConfirmation, confirm } = useAwaitingDialogContext();

    const confirmDialog = useMemo(() => (
        <ConfirmDialog
            onCancel={closeConfirmation}
            onConfirm={confirm}
        >
            선택하신 주문의 송장을 발행하시겠습니까?
        </ConfirmDialog>
    ), [closeConfirmation, confirm]);

    const onButtonClicked = useCallback(() => {
        return openConfirmation(confirmDialog)
            .then(() => {
                onOkayClicked(true);
            }).catch(() => {
                // ignore onCancel
            });
    }, [onOkayClicked, confirmDialog, openConfirmation]);

    return (
        <AwaitButton
            type="button"
            className={className}
            onClick={onButtonClicked}
            label={"송장발행"}
            icon="pi pi-plus"
            disabled={disabled}
        />
    )
}

CreateConsignmentActionButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onOkayClicked: PropTypes.func,
}

export default CreateConsignmentActionButton;