import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { throttle } from "lodash";

import { DataTable } from "primereact/datatable";

import { mainPathNameState } from "../../store";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions } from "../../includes/constants";
import useFlightsData from "./hooks/useFlightsData";
import useDataTableExportCSV from "../../core/hooks/useDataTableExportCSV";
import useDataTableColumns from "../../core/hooks/useDataTableColumns";

import FlightPageFilter from "./components/FlightPageFilter";
import AwaitButton from "../../components/AwaitButton";
import ScrollDataTableContainer from "../../components/layouts/ScrollDataTableContainer";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import DataTableActions from "../../components/layouts/DataTableActions";
import { onlyDateFormatter } from "../../includes/dates";
import { formatNumber, getKoreanCurrencyMark } from "../../includes/numbers";

const fieldColumnTitleMap = {
  ArrivalDate: "도착일",
  CreateDate: "등록일",
  DepartDate: "출항일(ETD)",
  EstimatedArrivalDate: "도착 예정일(ETA)",
  ExchangeRate: "환율",
  FlightName: "운항명",
  FlightNumber: "편명",
  DeliveryLocationName: "배송지역",
}

const sortableFields = [
  "FlightId",
  "FlightName",
  "FlightNumber",
  "DeliveryStatusCode",
  "DeliveryLocationCode",
  "DepartDate",
  "EstimatedArrivalDate",
  "ArrivalDate",
  "ExchangeRate",
];

const FlightPage = () => {
  const history = useHistory();
  const mainPathNameStateValue = useRecoilValue(mainPathNameState);
  const [selectedItems, setSelectedItems] = useState(null);
  const { dataTableRef } = useDataTableExportCSV();
  const [{ data: flightsData, loading: flightsDataLoading }, getFlights] = useFlightsData();

  const onSearch = useMemo(() => {
    return throttle((e) => {
      if (flightsDataLoading) return getPromise();
      return getFlights();
    }, 1000);
  }, [getFlights, flightsDataLoading]);

  const {
    sortPaginationProps,
    renderColumn,
    renderLinkColumn,
  } = useDataTableColumns({
    fieldColumnTitleMap,
    sortableFields,
    getData: getFlights,
    dataSize: flightsData?.Data?.length ?? 0,
  });

  const onCreateFlightClicked = useCallback(() => {
    history.push(`${mainPathNameStateValue}/create`);
  }, [history, mainPathNameStateValue]);

  return (
    <div className="grid" css={flightPageStyle}>
      <div className="col-12">

        <EnhancedBreadCrumb />

        <div className="card">
          <h5>운항정보 리스트</h5>
          <FlightPageFilter onSearch={onSearch} loading={flightsDataLoading} />
        </div>

        <div className="card">
          <DataTableActions>
            <AwaitButton
              icon="pi pi-plus"
              type="button"
              className={"mb-2"}
              onClick={onCreateFlightClicked}
              label={"운항정보 생성"}
            />
          </DataTableActions>
          <ScrollDataTableContainer loading={flightsDataLoading} minWidth={1400}>
            <DataTable
              resizableColumns
              columnResizeMode="fit"
              loading={flightsDataLoading}
              ref={dataTableRef}
              value={flightsData?.Data || []}
              selection={selectedItems}
              onSelectionChange={(e) => setSelectedItems(e.value)}
              dataKey="FlightId"
              paginator
              {...sortPaginationProps}
              lazy
              totalRecords={flightsData?.Paging?.TotalNumberOfRecords ?? 0}
              rows={flightsData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
              rowsPerPageOptions={rowsPerPageOptions}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              emptyMessage="No data found."
            >
              {renderLinkColumn("FlightName", "FlightId")}
              {renderColumn("FlightNumber")}
              {renderColumn("DeliveryLocationName")}
              {renderColumn("DepartDate", { formatValue: onlyDateFormatter })}
              {renderColumn("EstimatedArrivalDate", { formatValue: onlyDateFormatter })}
              {renderColumn("ArrivalDate", { formatValue: onlyDateFormatter })}
              {renderColumn("CreateDate")}
              {renderColumn("ExchangeRate", {
                formatValue: formatNumber,
                prefixAppender: getKoreanCurrencyMark
              })}
            </DataTable>
          </ScrollDataTableContainer>
        </div>
      </div>
    </div>
  );
}

export default FlightPage;

const flightPageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-ExchangeRate {
            text-align: right;
            width: 140px;
        }
    }

    .column-ExchangeRate {
        text-align: right;
        padding-right: 20px;
    }
`;