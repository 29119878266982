import React, { useEffect } from 'react';
import { css } from "@emotion/react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

import { NOOP } from "../../../../includes/constants";
import { managerAdminUserListFilterState, managerAdminUserPaginationFilterState } from "../store";

import AwaitButton from "../../../../components/AwaitButton";
import FilterContainer from "../../../../components/filters/FilterContainer";
import PaginationFilter from "../../../../components/filters/PaginationFilter";
import PageFilterContainer from "../../../../components/filters/PageFilterContainer";
import FilterContainerGroups from "../../../../components/filters/FilterContainerGroups";
import FilterActionContainer from "../../../../components/filters/FilterActionContainer";
import TextFieldSearchFilter from "../../../../components/filters/TextFieldSearchFilter";
import DateSearchFilter, { dateSearchFilterState } from "../../../../components/filters/DateSearchFilter";
import AdminRoleIdSearchFilter, { adminRoleIdSearchFilterState } from "../../../../components/filters/AdminRoleIdSearchFilter";

const searchFieldItems = [
    { name: '등록일', searchDateField: 'CreateDate', isDefault: true },
];

const textFieldSearchDropdownItems = [
    { name: '관리자명', searchField: 'AdminName', isDefault: true },
    { name: '관리자이메일', searchField: 'AdminEmail' },
];

export const managerAdminUserSearchFilterState = atom({
    key: 'managerAdminUserSearchFilterState',
    default: {
        searchField: textFieldSearchDropdownItems[0].searchField,
        searchStr: "",
    },
});

const ManagerAdminUserPageFilter = ({
    onSearch = NOOP,
    loading = false,
}) => {
    const setFilter = useSetRecoilState(managerAdminUserListFilterState);
    const paginationFilterValue = useRecoilValue(managerAdminUserPaginationFilterState);
    const dateSearchFilterValue = useRecoilValue(dateSearchFilterState);
    const adminRoleIdSearchFilterValue = useRecoilValue(adminRoleIdSearchFilterState);
    const managerAdminUserSearchFilterValue = useRecoilValue(managerAdminUserSearchFilterState);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...paginationFilterValue,
            }
        });
    }, [paginationFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...adminRoleIdSearchFilterValue,
            }
        })
    }, [adminRoleIdSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...dateSearchFilterValue,
            }
        })
    }, [dateSearchFilterValue, setFilter]);

    useEffect(() => {
        setFilter(prevFilter => {
            return {
                ...prevFilter,
                ...managerAdminUserSearchFilterValue,
            }
        })
    }, [managerAdminUserSearchFilterValue, setFilter]);

    return (
        <PageFilterContainer>

            <PaginationFilter supportQueryString atom={managerAdminUserPaginationFilterState} />

            <FilterContainerGroups>
                <FilterContainer className={"align-items-start"}>
                    <TextFieldSearchFilter
                        atom={managerAdminUserSearchFilterState}
                        label={"관리자 검색"}
                        dropdownItems={textFieldSearchDropdownItems}
                        supportQueryString
                        onSearch={onSearch}
                    />
                </FilterContainer>
                <FilterContainer>
                    <DateSearchFilter searchFieldItems={searchFieldItems} />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterContainerGroups>
                <FilterContainer>
                    <AdminRoleIdSearchFilter label={"권한 검색"} />
                </FilterContainer>
            </FilterContainerGroups>

            <FilterActionContainer>
                <AwaitButton
                    icon={"pi pi-search"}
                    className="mr-2"
                    css={buttonStyle}
                    type="button"
                    onClick={onSearch}
                    label={"Search"}
                    loading={loading}
                />
            </FilterActionContainer>
        </PageFilterContainer>
    )
}

export default ManagerAdminUserPageFilter;

const buttonStyle = css`
    &.p-button {
        width: unset;
    }
`;

