import React, { useCallback, useMemo } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";

import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";

import FilterLabel from "./FilterLabel";

export const adminRoleIdSearchFilterDropdownItems = [
    { Name: '관리자', Code: 2 },
    { Name: '입고 관리자', Code: 3 },
];

const adminRoleIdSearchFilterDropdownItemsWithAll = [
    { Name: '전체', Code: '', isDefault: true },
    ...adminRoleIdSearchFilterDropdownItems,
];

export const adminRoleIdSearchFilterState = atom({
    key: 'adminRoleIdSearchFilterState',
    default: {
        adminRoleId: '',
    },
});

const AdminRoleIdSearchFilter = ({
    label = "권한 검색",
    className = null,
    dropdownItems = adminRoleIdSearchFilterDropdownItemsWithAll,
    placeholder = "전체",
}) => {
    const setAdminRoleIdSearchFilterState = useSetRecoilState(adminRoleIdSearchFilterState);
    const adminRoleIdSearchFilterValue = useRecoilValue(adminRoleIdSearchFilterState);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setAdminRoleIdSearchFilterState({
                adminRoleId: searchObject.adminRoleId
                    ? Number(searchObject.adminRoleId)
                    : searchObject.adminRoleId,
            });
        }
    }, [setAdminRoleIdSearchFilterState]);

    useSetQueryStringToState(setQueryStringToState);

    const setDropdownItem = useCallback(e => {
        setAdminRoleIdSearchFilterState({
            adminRoleId: e.value?.Code
        })
    }, [setAdminRoleIdSearchFilterState]);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setAdminRoleIdSearchFilterState(prev => {
            if (prev.adminRoleId) return prev;

            return {
                ...prev,
                adminRoleId: defaultItem.Code,
            }
        });
    }, [setAdminRoleIdSearchFilterState]);

    useSetFilterDefaultValue({
        items: dropdownItems,
        isAlreadySet: !!adminRoleIdSearchFilterValue.adminRoleId,
        setFilterDefaultValue,
    });

    return (
        <div className={className} css={searchGroupStyle}>
            <FilterLabel htmlFor={"adminRoleId"}>{label}</FilterLabel>
            <Dropdown
                name="adminRoleId"
                value={dropdownItems.find(item => item.Code === adminRoleIdSearchFilterValue.adminRoleId)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="Name"
                placeholder={placeholder}
            />
        </div>
    )
}

const searchGroupStyle = css`
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: max-content;
`;

export default AdminRoleIdSearchFilter;