import React, { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Dropdown } from "primereact/dropdown";

import FilterLabel from "./FilterLabel";

import useSetQueryStringToState from "../../core/hooks/useSetQueryStringToState";
import useSetFilterDefaultValue from "../../core/hooks/useSetFilterDefaultValue";
import { itemEnteredStatusSearchFilterState } from "./store";

const itemEnteredStatusDropdownItems = [
    { Name: '전체', Code: 'All', isDefault: true },
    { Name: '입고완료', Code: 'Completed' },
    { Name: '입고전', Code: 'Standby' },
];

const ItemEnteredStatusSearchFilter = ({
    label = "입고 상태",
    atom = itemEnteredStatusSearchFilterState,
    supportQueryString = false,
    className = "",
    dropdownItems = itemEnteredStatusDropdownItems,
}) => {
    const setItemEnteredStatusSearchFilterState = useSetRecoilState(atom);
    const itemEnteredStatusSearchFilterValue = useRecoilValue(atom);

    const setQueryStringToState = useMemo(() => {
        return (searchObject) => {
            setItemEnteredStatusSearchFilterState({
                itemEnteredStatus: searchObject.itemEnteredStatus,
            });
        }
    }, [setItemEnteredStatusSearchFilterState]);

    useSetQueryStringToState(supportQueryString && setQueryStringToState);

    const setFilterDefaultValue = useCallback((defaultItem) => {
        setItemEnteredStatusSearchFilterState(prev => {
            if (prev.itemEnteredStatus) return prev;

            return {
                ...prev,
                itemEnteredStatus: defaultItem.Code,
            }
        });
    }, [setItemEnteredStatusSearchFilterState]);

    useSetFilterDefaultValue({
        items: dropdownItems,
        isAlreadySet: !!itemEnteredStatusSearchFilterValue.itemEnteredStatus,
        setFilterDefaultValue,
    });

    // useEffect(() => {
    //     // 처음 한번만 세팅 해 주세요.
    //     if (!itemEnteredStatusSearchFilterValue.itemEnteredStatus) {
    //         if (!isEmpty(dropdownItems)) {
    //             const deliveryStatusItem = dropdownItems.find(i => i.isDefault);
    //             if (deliveryStatusItem) {
    //                 setItemEnteredStatusSearchFilterState(prev => {
    //                     if (prev.itemEnteredStatus) return prev;
    //
    //                     return {
    //                         itemEnteredStatus: deliveryStatusItem.Code,
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dropdownItems, setItemEnteredStatusSearchFilterState]);

    const setDropdownItem = useCallback(e => {
        setItemEnteredStatusSearchFilterState({
            itemEnteredStatus: e.value?.Code
        })
    }, [setItemEnteredStatusSearchFilterState]);

    return (
        <div className={className}>
            <FilterLabel htmlFor={"itemEnteredStatus"}>{label}</FilterLabel>
            <Dropdown
                name="itemEnteredStatus"
                value={dropdownItems.find(item => item.Code === itemEnteredStatusSearchFilterValue.itemEnteredStatus)}
                onChange={setDropdownItem}
                options={dropdownItems}
                optionLabel="Name"
                placeholder="전체"
            />
        </div>
    )
}

export default ItemEnteredStatusSearchFilter;