import React, { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { throttle } from "lodash";
import { selectedOrderItemsState } from "./store";
import { DataTable } from "primereact/datatable";

import useOrdersData from "./hooks/useOrdersData";
import { DEFAULT_PAGE_SIZE, getPromise, rowsPerPageOptions, PERMISSION_ADMIN } from "../../includes/constants";
import useDataTableExportCSV from "../../core/hooks/useDataTableExportCSV";
import useDataTableColumns from "../../core/hooks/useDataTableColumns";

import OrderPageFilter from "./components/OrderPageFilter";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import OrderPageDataTableActions from "./components/OrderPageDataTableActions";
import ScrollDataTableContainer from "../../components/layouts/ScrollDataTableContainer";
import { css } from "@emotion/react";
import { formatCurrency, formatDutyCurrency, formatNumber, getNewZealandCurrencyMark } from "../../includes/numbers";
import { currentUserState } from "../../includes/auth";

const fieldColumnTitleMap = {
  ConsignmentNumber: "송장번호", // K택배 내부 송장번호
  DeliveryLocationCode: "배송국가 코드",
  DeliveryLocationName: "배송국가",
  DeliveryStatusCode: "배송상태 코드",
  DeliveryStatusName: "배송상태",
  DeliveryTypeCode: "배송타입 코드",
  DeliveryTypeName: "배송타입",
  FlightId: "운항 ID",
  FlightName: "운항명",
  IsAllItemEntered: "상품입고",
  LocalTrackingNumber: "배송국가 트래킹번호",
  OrderDate: "주문일",
  OrderNumber: "주문번호",
  PersonalCustomCode: "개인통관부호",
  PickupTypeCode: "수령방법코드",
  ReceiverAddress1: "수령자명 주소1",
  ReceiverAddress2: "수령자명 주소2",
  ReceiverMobile: "수령자명 휴대폰",
  ReceiverName: "수령자명",
  ReceiverPhone: "수령자 전화번호",
  ReceiverZipcode: "수령자명 우편번호",
  TotalChargeAmount: "결제금액", // 고객이 내야할 금액
  TotalExchangeItemPrice: "운송 및 세금????",
  TotalItemCount: "전체 상품수",
  TotalItemAmount: "상품가격",
  TotalExchangeItemAmount: "Duty Amount",
  TotalPaidAmount: "입금액",
  TotalVolumeWeight: "전체 부피무게",
  TotalWeight: "무게(kg)",
  UserId: "주문자 ID",
  UserName: "주문자",
  TotalChargeableWeight: "Chargeable Wt(kg)",
  IsUseMileage: "마일리지사용",
  IsCombineItem: "합배송",
  IsRepackage: "재포장",
  IsPurchaseOrder: "주문타입"
}

const sortableFields = [
  "OrderId",
  "OrderNumber",
  "UserName",
  "OrderDate",
  "TotalItemAmount",
  "TotalWeight",
  "TotalPaymentAmount",
  "IsPurchaseOrder"
];

const OrderPage = () => {

  const [selectedOrderItems, setSelectedOrderItems] = useRecoilState(selectedOrderItemsState);
  const { dataTableRef } = useDataTableExportCSV();
  const [{ data: ordersData, loading: ordersDataLoading }, getOrders] = useOrdersData();
  const [currentUser,] = useRecoilState(currentUserState);

  useEffect(() => {
    setSelectedOrderItems([]);
  }, [setSelectedOrderItems]);

  const onSearch = useMemo(() => {
    return throttle((e) => {
      if (ordersDataLoading) return getPromise();
      return getOrders();
    }, 1000);
  }, [getOrders, ordersDataLoading]);

  const {
    sortPaginationProps,
    renderColumn,
    renderLinkColumn,
    renderCheckboxColumn
  } = useDataTableColumns({
    fieldColumnTitleMap,
    sortableFields,
    getData: getOrders,
    dataSize: ordersData?.Data?.length ?? 0,
  });

  return (
    <div className="grid" css={orderPageStyle}>
      <div className="col-12">

        <EnhancedBreadCrumb />

        <div className="card">
          <h5>전체 주문 리스트</h5>
          <OrderPageFilter onSearch={onSearch} loading={ordersDataLoading} />
        </div>

        <div className="card">
          <OrderPageDataTableActions refresh={getOrders} />

          <ScrollDataTableContainer loading={ordersDataLoading} minWidth={1600}>
            <DataTable
              resizableColumns
              columnResizeMode="fit"
              loading={ordersDataLoading}
              ref={dataTableRef}
              value={ordersData?.Data || []}
              selection={selectedOrderItems}
              onSelectionChange={(e) => setSelectedOrderItems(e.value)}
              dataKey="OrderId"
              paginator
              {...sortPaginationProps}
              lazy
              totalRecords={ordersData?.Paging?.TotalNumberOfRecords}
              rows={ordersData?.Paging?.PageSize ?? DEFAULT_PAGE_SIZE}
              rowsPerPageOptions={rowsPerPageOptions}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              emptyMessage="No data found."
              selectionMode="checkbox"
            >
              {renderCheckboxColumn()}
              {renderLinkColumn("OrderNumber", "OrderId")}
              {renderColumn("IsPurchaseOrder")}
              {renderColumn("UserName")}
              {renderColumn("OrderDate")}
              {renderColumn("IsAllItemEntered")}
              {renderColumn("FlightName")}
              {renderColumn("DeliveryLocationName")}
              {renderColumn("DeliveryStatusName")}
              {renderColumn("TotalItemAmount", { formatValue: formatCurrency })}
              {renderColumn("TotalExchangeItemAmount", { formatValue: formatDutyCurrency })}
              {renderColumn("TotalChargeableWeight", { formatValue: formatNumber })}
              {renderColumn("IsCombineItem")}
              {renderColumn("IsUseMileage")}
              {renderColumn("IsRepackage")}

              {currentUser.permission === PERMISSION_ADMIN &&
                renderColumn("TotalChargeAmount", {
                  formatValue: formatNumber,
                  prefixAppender: getNewZealandCurrencyMark
                })}
              {currentUser.permission === PERMISSION_ADMIN &&
                renderColumn("TotalPaidAmount", {
                  formatValue: formatNumber,
                  prefixAppender: getNewZealandCurrencyMark
                })}
              {renderColumn("ConsignmentNumber")}
            </DataTable>
          </ScrollDataTableContainer>
        </div>
      </div>
    </div>
  );
}

export default OrderPage;

const orderPageStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalItemAmount,
        &.header-column-TotalExchangeItemAmount,
        &.header-column-TotalChargeAmount,
        &.header-column-TotalPaidAmount,
        &.header-column-TotalChargeableWeight {
            text-align: right;
        }

        &.header-column-TotalItemAmount {
            width: 160px;
        }

        &.header-column-TotalChargeableWeight {
            width: 190px;
        }

        &.header-column-TotalPaidAmount,
        &.header-column-TotalChargeAmount,
        &.header-column-TotalExchangeItemAmount {
            width: 160px;

            .p-column-title {
                padding-right: 20px;
            }
        }
    }

    .column-TotalPaidAmount,
    .column-TotalItemAmount,
    .column-TotalExchangeItemAmount,
    .column-TotalChargeAmount,
    .column-TotalChargeableWeight {
        text-align: right;
        padding-right: 20px;
    }
`;