import React, { useCallback, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import Loading from "../loading/Loading";
import { Button } from "primereact/button";

const ConfirmDialog = ({
    title = "Confirmation",
    children = "정말 수정하시겠습니까?",
    onCancel = null,// should be null as default
    onConfirm = null,// should be null as default
}) => {
    const [loading, setLoading] = useState(false);

    const handleConfirm = useCallback((e) => {
        setLoading(true);
        if (onConfirm) {
            return onConfirm(e);
        }
    }, [onConfirm]);

    const confirmationDialogFooter = useMemo(() => (
        <>
            {onCancel && (
                <Button
                    type="button"
                    label="Close"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-text"
                />
            )}
            {onConfirm && (
                <Button
                    type="button"
                    label="Yes"
                    icon="pi pi-check"
                    onClick={handleConfirm}
                    autoFocus
                />
            )}
        </>
    ), [onCancel, onConfirm, handleConfirm]);

    return (
        <Dialog
            header={title}
            visible
            {...onCancel && { onHide: onCancel }}
            style={{ width: '350px' }}
            modal
            footer={confirmationDialogFooter}
            className={"confirm-dialog"}
        >
            {loading && <Loading overlay />}
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>{children}</span>
            </div>
        </Dialog>
    )
}

export default ConfirmDialog;