import { useCallback } from "react";
import useAxios from "axios-hooks";
import { useRecoilValue } from "recoil";

import { API_ORDER_URI, API_ORDER_BRIEF_URI, PERMISSION_ADMIN } from "../../../includes/constants";
import { currentUserState } from "../../../includes/auth";

const usePurchaseOrderData = () => {

  const currentUser = useRecoilValue(currentUserState);

  const [{ data, loading, error }, getData] = useAxios({
    url: currentUser.permission === PERMISSION_ADMIN ? API_ORDER_URI : API_ORDER_BRIEF_URI,
    method: 'GET',
  },
    { manual: true },
  );

  const getOrder = useCallback((purchaseOrderId) => {
    return getData({
      url: `${currentUser.permission === PERMISSION_ADMIN ? API_ORDER_URI : API_ORDER_BRIEF_URI}/${purchaseOrderId}`
    });
  }, [getData, currentUser]);

  return [
    {
      data,
      loading,
      error,
    },
    getOrder,
  ];
}

export default usePurchaseOrderData;