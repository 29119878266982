import React, { useEffect, useRef, useState } from 'react';
import { Button } from "primereact/button";
import { NOOP } from "../includes/constants";
import Loading from "./loading/Loading";
import { css } from "@emotion/react";

const AwaitButton = ({
    className,
    buttonClassName,
    icon = '',
    type = "button",
    onClick = NOOP,
    label,
    loading = false,
    disabled = false,
    tooltip = null,
    children,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        }
    }, []);

    const onClickFunc = e => {
        if (isLoading) return;

        const clickResult = onClick(e);

        if (clickResult instanceof Promise) {
            setIsLoading(true);

            clickResult.finally(() => {
                if (!unmounted.current) setIsLoading(false);
            });
        }
    };

    return (
        <div css={buttonContainerStyle} className={className}>
            {(isLoading || loading) && <Loading css={loadingStyle} small />}
            <Button
                {...icon && { icon }}
                type={type}
                onClick={onClickFunc}
                disabled={(disabled || isLoading || loading)}
                label={label}
                {...tooltip && { tooltip }}
                {...buttonClassName && { className: buttonClassName}}
            >
                {children}
            </Button>
        </div>
    )
}

const buttonContainerStyle = css`
    position: relative;
    display: inline-flex;
`;

const loadingStyle = css`
    width: 25px;
    height: 25px;

    circle {
        stroke-width: 10px;
    }
`;

export default AwaitButton;