import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { TabView } from "primereact/tabview";

const EnhancedTabView = ({ children, className }) => {
    const safeTabViewChildren = useMemo(() => {
        const safeTabViewChildren = [];
        React.Children.forEach(children, child => {
            if (child) {
                safeTabViewChildren.push(child);
            }
        });
        return safeTabViewChildren;
    }, [children]);

    return (
        <TabView className={className}>
            {safeTabViewChildren}
        </TabView>
    )
}

EnhancedTabView.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default EnhancedTabView;