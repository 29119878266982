import React, { useMemo, useState, useRef } from 'react'
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import { useHistory } from "react-router-dom";

import useAPI from "../../core/hooks/useAPI";
import { menuState } from "../../service/hooks/useMenus";
import { API_PURCHASE_ORDER_URI } from "../../includes/constants";
import EnhancedBreadCrumb from "../../components/layouts/EnhancedBreadCrumb";
import CardContainer from "../../components/layouts/CardContainer";
import OrderCreateItemList from "./components/PurchaseOrderCreateItemList";
import OrderCreateUserInfo from "./components/OrderCreateUserInfo";
import { useAwaitingDialogContext } from "../../core/contexts/AwaitingDialogContext";
import AwaitButton from "../../components/AwaitButton";
import DataTableActions from "../../components/layouts/DataTableActions";
import useToast from "../../core/hooks/useToast";
import PurchaseOrderCreateItemList from './components/PurchaseOrderCreateItemList';

const createOrderModel =
{
  UserId: 0,
  DeliveryLocationCode: "",
  IsReceiverOwn: true,
  ReceiverName: "",
  ReceiverEmail: "",
  ReceiverPhone: "",
  ReceiverMobile: "",
  ReceiverAddress1: "",
  ReceiverAddress2: "",
  ReceiverZipcode: "",
  PickupTypeCode: "2",
  PersonalCustomCode: "",
  ReceiverMessage: "",
  IsCombineItem: false,
  IsUseMileage: false,
  IsRepackage: false,
  OrderItems: [],
  UserInfo: [],
  IsPurchaseOrder: true,
  PurchaseOrderItems: []
};


const PurchaseOrderCreatePage = () => {

  const menuStateValue = useRecoilValue(menuState);
  const [createOrderDetail, setCreateOrderDetail] = useState(createOrderModel);
  const { closeConfirmation } = useAwaitingDialogContext();
  const { showError } = useToast();
  const receiverRef = useRef();
  const history = useHistory();


  const breadcrumbItems = useMemo(() => {
    return [
      ...menuStateValue.currentBreadcrumbItems,
      { label: '주문생성' }
    ]
  }, [menuStateValue]);

  const onDeleteItem = orderItemData => {

    const orderItemArrayIndex = createOrderDetail.PurchaseOrderItems.findIndex(i => i.PurchaseOrderItemId === orderItemData.PurchaseOrderItemId);

    const newOrderItems = [...createOrderDetail.PurchaseOrderItems];

    newOrderItems.splice(orderItemArrayIndex, 1);

    setCreateOrderDetail((p) => {
      return { ...p, PurchaseOrderItems: newOrderItems };
    });

    closeConfirmation();
  }

  const onChangeItem = orderItemData => {


    const newPurchaseOrderItemId = orderItemData.PurchaseOrderItemId === 0 ? createOrderDetail.PurchaseOrderItems.length + 1 : orderItemData.PurchaseOrderItemId;
    const orderItemArrayIndex = createOrderDetail.PurchaseOrderItems.findIndex(i => i.PurchaseOrderItemId === newPurchaseOrderItemId);

    let newOrderItems = [];

    const newOrderItem = {
      PurchaseOrderItemId: newPurchaseOrderItemId,
      ItemTypeCode: orderItemData.ItemTypeCode,
      ItemTypeName: orderItemData.ItemTypeName,
      ItemName: orderItemData.ItemName,
      ItemQuantity: orderItemData.ItemQuantity,
      ItemUrl: orderItemData.ItemUrl,
      ItemAmount: orderItemData.ItemAmount,
      DeliveryCompany: orderItemData.DeliveryCompany || '',
      TrackingNumber: orderItemData.TrackingNumber || '',
      OrderNote: orderItemData.OrderNote || ''
    }

    if (orderItemArrayIndex < 0) {
      //create
      newOrderItems = [...createOrderDetail.PurchaseOrderItems, newOrderItem];
    }
    else {
      //update
      newOrderItems = [...createOrderDetail.PurchaseOrderItems];
      //newOrderItems.splice(orderItemArrayIndex, 1);

      newOrderItems[orderItemArrayIndex] = newOrderItem;
    }

    setCreateOrderDetail(p => {
      return {
        ...p,
        PurchaseOrderItems: newOrderItems
      }
    })

    closeConfirmation();
  }

  const onSaveOrderUserInfo = userData => {

    setCreateOrderDetail(p => {
      return { ...p, UserInfo: userData };
    })
  }

  /***********************************************************
   * 주문 생성
   ***********************************************************/
  const createOrder = useAPI({
    url: `${API_PURCHASE_ORDER_URI}`,
    method: 'POST',
    successMessage: "주문을 성공적으로 생성하였습니다.",
  });


  const onSaveOrder = () => {

    if (createOrderDetail.UserInfo.length === 0) {
      showError({ message: "고객정보를 선택해 주세요" })
      return;
    }

    if (createOrderDetail.PurchaseOrderItems.length === 0) {
      showError({ message: "상품정보를 선택해 주세요" })
      return;
    }

    const receiverData = receiverRef.current.handleSubmit();


    if (receiverData) {
      const data = {
        ...createOrderDetail,
        ...receiverData,
        UserId: createOrderDetail.UserInfo.UserId,
      };
      createOrder({
        data: data,
      }).then(() => {
        history.push('/order');
      });
    }

  }

  return (
    <div className="grid" css={orderDetailPageStyle}>


      <div className="col-12">
        <EnhancedBreadCrumb model={breadcrumbItems} showBack />

        <CardContainer>
          <OrderCreateUserInfo
            orderUserData={createOrderDetail.UserInfo}
            onSaveOrderUserInfo={onSaveOrderUserInfo} />

        </CardContainer>
        <PurchaseOrderCreateItemList

          orderData={createOrderDetail}
          onChangeItem={onChangeItem}
          onDeleteItem={onDeleteItem}
        />


        <CardContainer>
          <DataTableActions className={"mb-2"} >
            <AwaitButton onClick={onSaveOrder} label={"주문 저장"} icon="pi pi-pencil" />
          </DataTableActions>
        </CardContainer>

      </div>
      <ScrollUpButton />
    </div>
  );
}


export default PurchaseOrderCreatePage;

const orderDetailPageStyle = css`
      position: relative;
      `;

