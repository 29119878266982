import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { Calendar } from "primereact/calendar";
import { css } from "@emotion/react";
import { yearRange } from "../../includes/dates";
import AwaitButton from "../AwaitButton";

const CalendarWithError = ({ className, error, showCloseButton, ...props }) => {

    // showCloseButton
    const [visibleCalendar, setVisibleCalendar] = useState(false);

    const onVisibleChange = useCallback(e => {
        setVisibleCalendar(true);
    }, [setVisibleCalendar]);

    const toggleVisibleChange = useCallback(e => {
        setVisibleCalendar(prev => !prev);
    }, [setVisibleCalendar]);

    const calendarFooterTemplate = useMemo(() => {
        return (e) => {
            console.log("e", e)
            return (
                <AwaitButton
                    icon="pi pi-check"
                    css={buttonStyle}
                    className={"flex justify-content-end"}
                    label={"확인"}
                    type="button"
                    onClick={toggleVisibleChange}
                />
            );
        }
    }, [toggleVisibleChange]);

    return (
        <span css={withErrorStyle(error)}>
        <Calendar
            {...props}
            monthNavigator
            yearNavigator
            yearRange={yearRange}
            className={classNames(className, { "p-invalid": !!error })}
            {...showCloseButton && {
                footerTemplate: calendarFooterTemplate,
                visible: visibleCalendar,
                onVisibleChange: onVisibleChange,
            }}
        />
    </span>
    );
}

export default CalendarWithError;

const withErrorStyle = error => css`
    display: block;
    position: relative;
    margin-bottom: 12px;

    ${error && css`
        :after {
            content: '${error}';
            position: absolute;
            top: 100%;
            left: 0;
            color: #f44336;
            width: 100%;
            height: 10px;
            font-size: 10px;
            line-height: 10px;
            margin-top: 2px;
        }
    `}
`;

const buttonStyle = css`
    margin-right: 10px;
`;