import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import PrimeReact from "primereact/api";

import { getToken, getUserFromStorage } from "../../includes/auth";
import { useReactPrimeThemeContext } from "../../core/contexts/ReactPrimeThemeContext";
import useMenus from "../../service/hooks/useMenus";
import { AppTopbar } from "../../components/topbar/AppTopbar";
import { AppMenu } from "../../components/menus/AppMenu";
import { AppFooter } from "../../components/footer/AppFooter";
import { AppConfig } from "../../AppConfig";
import Loading from "../../components/loading/Loading";

const Home = () => {
    const { menus, menuRoutes, loading } = useMenus();
    const {
        inputStyle,
        layoutMode,
        layoutColorMode,
    } = useReactPrimeThemeContext();
    const token = getToken();
    const user = getUserFromStorage();
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true); // 초기에 메뉴 닫아 놓기 true
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    PrimeReact.ripple = true;

    const menuClick = useRef(false);
    const mobileTopbarMenuClick = useRef(false);

    const onRipple = useCallback((e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }, []);

    const onWrapperClick = useCallback((event) => {
        if (!menuClick.current) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick.current) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick.current = false;
        menuClick.current = false;
    }, []);

    const isDesktop = useCallback(() => {
        return window.innerWidth >= 992;
    }, []);

    const onToggleMenuClick = useCallback((event) => {
        menuClick.current = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutMode, mobileMenuActive])

    const onSidebarClick = useCallback(() => {
        menuClick.current = true;
    }, []);

    const onMobileTopbarMenuClick = useCallback((event) => {
        mobileTopbarMenuClick.current = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }, [])

    const onMobileSubTopbarMenuClick = useCallback((event) => {
        mobileTopbarMenuClick.current = true;
        event.preventDefault();
    }, []);

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const addClass = useCallback((element, className) => {
        if (element.classList) {
            element.classList.add(className);
        } else {
            element.className += ' ' + className;
        }
    }, [])

    const removeClass = useCallback((element, className) => {
        if (element.classList) {
            element.classList.remove(className);
        } else {
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }, []);

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobileMenuActive]);

    if (!token || !user) {
        return <Redirect to={"/login"} />;
    }

    return (
        <div
            onClick={onWrapperClick}
            className={
                classNames('layout-wrapper', {
                    'layout-overlay': layoutMode === 'overlay',
                    'layout-static': layoutMode === 'static',
                    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
                    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
                    'layout-mobile-sidebar-active': mobileMenuActive,
                    'p-input-filled': inputStyle === 'filled',
                    'p-ripple-disabled': ripple === false,
                    [`layout-theme-${layoutColorMode}`]: true,
                })
            }
        >
            <AppTopbar
                onToggleMenuClick={onToggleMenuClick}
                mobileTopbarMenuActive={mobileTopbarMenuActive}
                onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
            />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menus} onMenuItemClick={onMenuItemClick} />
                {/*<ul className={"layout-menu"}>*/}
                {/*    <li className="layout-menuitem-category">*/}
                {/*        <ul>*/}
                {/*            <li className="">*/}
                {/*                <a className="p-ripple" href="/" onClick={logout}>*/}
                {/*                    <i></i>*/}
                {/*                    <span>로그아웃</span>*/}
                {/*                    <span className="p-ink"></span>*/}
                {/*                </a>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    {loading && <Loading onTop />}
                    <Switch>
                        {menuRoutes}
                        {!isEmpty(menuRoutes) && (
                            <Route path="">
                                <Redirect to={menus?.[0]?.items?.[0]?.to ?? "/"} />
                            </Route>
                        )}
                    </Switch>
                </div>

                <AppFooter />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} />

            <CSSTransition
                classNames="layout-mask"
                timeout={{ enter: 200, exit: 200 }}
                in={mobileMenuActive}
                unmountOnExit
            >
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    )
}

export default Home;