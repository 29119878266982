import React, { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { trim } from "lodash";
import { NOOP } from "../../../includes/constants";
import AwaitButton from "../../../components/AwaitButton";
import InputTextWithError from "../../../components/forms/InputTextWithError";
import FilterLabel from "../../../components/filters/FilterLabel";

const SearchTrackingNumberInput = ({
    onSearch = NOOP,
    inputRef = NOOP,
    inputKey = "",
}) => {
    const [trackingNumber, setTrackingNumber] = useState("");
    const [trackingNumberError, setTrackingNumberError] = useState("");

    const onChangeSearchStr = useCallback((e) => {
        const searchStr = trim(e.target.value);
        if (!searchStr) {
            setTrackingNumberError("Required");
        }

        setTrackingNumber(searchStr);
    }, []);

    const reset = useCallback(e => {
        setTrackingNumber("");
        setTrackingNumberError("");
    }, []);

    useEffect(() => {
        if (inputKey) {
            reset();
        }
    }, [inputKey, reset]);

    const handleSearch = useCallback(trackingNumber => {
        return onSearch(trackingNumber).then(reset);
    }, [onSearch, reset]);

    const onTrackingNumberKeyDown = useCallback(e => {
        if (e.key === "Enter" && trackingNumber) {
            setTrackingNumberError(null);
            return handleSearch(trackingNumber);
        }
        inputRef?.current?.focus?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackingNumber, handleSearch, setTrackingNumberError]);

    const onSearchWithTrackingNumber = useCallback(e => {
        if (trackingNumber) {
            setTrackingNumberError(null);
            return handleSearch(trackingNumber);
        }
        inputRef?.current?.focus?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackingNumber, handleSearch, setTrackingNumberError]);

    return (
        <div className="grid col-12 p-fluid align-items-center">
            <FilterLabel htmlFor={"trackingNumber"} css={trackingNumberLabelStyle}>송장번호 검색</FilterLabel>
            <div className="col-6 lg:col-6" css={trackingNumberInputContainerStyle}>
                <InputTextWithError
                    name="trackingNumber"
                    type="text"
                    onKeyDown={onTrackingNumberKeyDown}
                    onChange={onChangeSearchStr}
                    value={trackingNumber}
                    error={trackingNumberError}
                    css={trackingNumberInputStyle}
                    autoComplete={"off"}
                    autoFocus
                    forwardRef={inputRef}
                />
            </div>
            <AwaitButton
                css={buttonStyle}
                type="submit"
                onClick={onSearchWithTrackingNumber}
                label={"검색"}
            />
        </div>
    )
}

export default SearchTrackingNumberInput;

const trackingNumberLabelStyle = css`
    font-size: 50px;
`;

const trackingNumberInputStyle = css`
    font-size: 50px;
    height: 60px;
`;

const buttonStyle = css`
    height: 60px;

    .p-button-label {
        font-size: 30px;
    }
`;

const trackingNumberInputContainerStyle = css`
    margin-top: 12px;
`;


