import React, { createContext, useCallback, useContext, useMemo } from "react";
import * as PropTypes from "prop-types";
import { toInteger } from "lodash";
import useSetState from "../hooks/useSetState";

const kaircargo_theme_scheme = localStorage.getItem("kaircargo_theme_scheme") || 'saga';
const kaircargo_theme_color = localStorage.getItem("kaircargo_theme_color") || 'blue';
const kaircargo_layout_color_mode = localStorage.getItem("kaircargo_layout_color_mode") || 'light';
const kaircargo_layout_mode = localStorage.getItem("kaircargo_layout_mode") || 'static';
const kaircargo_input_style = localStorage.getItem("kaircargo_input_style") || 'outlined';
const kaircargo_scale = toInteger(localStorage.getItem("kaircargo_scale"));
const DEFAULT_SCALE = 13;

if (!kaircargo_scale) {
    localStorage.setItem("kaircargo_scale", DEFAULT_SCALE.toString());
}

const DEFAULT_STATE = {
    themeScheme: kaircargo_theme_scheme,
    themeColor: kaircargo_theme_color,
    layoutColorMode: kaircargo_layout_color_mode,
    layoutMode: kaircargo_layout_mode,
    inputStyle: kaircargo_input_style,
    scale: kaircargo_scale || DEFAULT_SCALE,
    scales: [12, 13, 14, 15, 16],
}

const ReactPrimeThemeContext = createContext(DEFAULT_STATE);

export const ReactPrimeThemeContextProvider = ({ children }) => {
    const [state, setState] = useSetState(DEFAULT_STATE);

    const setThemeScheme = useCallback(themeScheme => {
        localStorage.setItem("kaircargo_theme_scheme", themeScheme);
        setState({ themeScheme });
    }, [setState]);

    const setThemeColor = useCallback(themeColor => {
        localStorage.setItem("kaircargo_theme_color", themeColor);
        setState({ themeColor });
    }, [setState]);

    const setLayoutColorMode = useCallback(layoutColorMode => {
        localStorage.setItem("kaircargo_layout_color_mode", layoutColorMode);
        setState({ layoutColorMode });
    }, [setState]);

    const setLayoutMode = useCallback(layoutMode => {
        localStorage.setItem("kaircargo_layout_mode", layoutMode);
        setState({ layoutMode });
    }, [setState]);

    const setInputStyle = useCallback(inputStyle => {
        localStorage.setItem("kaircargo_input_style", inputStyle);
        setState({ inputStyle });
    }, [setState]);


    const decrementScale = useCallback(() => {
        setState((prevState) => {
            const scale = --prevState.scale;
            localStorage.setItem("kaircargo_scale", scale.toString());
            return {
                scale
            };
        });
    }, [setState]);

    const incrementScale = useCallback(() => {
        setState((prevState) => {
            const scale = ++prevState.scale;
            localStorage.setItem("kaircargo_scale", scale.toString());
            return {
                scale
            };
        });
    }, [setState])

    const value = useMemo(() => {
        return {
            ...state,
            setThemeScheme,
            setThemeColor,
            setLayoutColorMode,
            setLayoutMode,
            setInputStyle,
            decrementScale,
            incrementScale,
        }
    }, [state, setThemeScheme, setThemeColor, setLayoutColorMode, setLayoutMode, setInputStyle, decrementScale, incrementScale]);

    return (
        <ReactPrimeThemeContext.Provider value={value}>
            {children}
        </ReactPrimeThemeContext.Provider>
    );
}

ReactPrimeThemeContextProvider.propTypes = {
    children: PropTypes.node
};

export const useReactPrimeThemeContext = () => {
    return useContext(ReactPrimeThemeContext);
};