import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { isEmpty } from "lodash";
import { css } from "@emotion/react";
import { useSetRecoilState } from "recoil";

import { NOOP } from "../../../includes/constants";
import { formatCurrency, formatDutyCurrency, formatNumber, formatItemAmount } from "../../../includes/numbers";
import { useAwaitingDialogContext } from "../../../core/contexts/AwaitingDialogContext";
import DataTableActions from "../../../components/layouts/DataTableActions";
import CardContainer from "../../../components/layouts/CardContainer";
import useDataTableColumns from "../../../core/hooks/useDataTableColumns";
import ScrollDataTableContainer from "../../../components/layouts/ScrollDataTableContainer";
import CreateActionButton from "../../../components/action-buttons/CreateActionButton";
import TableTitle from "../../../components/layouts/TableTitle";
import useCreateActionButton from "../../../service/hooks/useCreateActionButton";
import CreatePurchaseOrderItemDialog, { createPurchaseOrderItemDialogState } from "../../../components/dialogs/CreatePurchaseOrderItemDialog";

const fieldColumnTitleMap = {
  PurchaseOrderItemId: "주문상품 ID",
  DeliveryCompany: "배송회사",
  EnteredDate: "입고일",
  IsAddedToInvoice: "인보이스 추가 여부",
  IsEntered: "입고여부",
  ItemAmount: "상품가격",
  ExchangedItemAmount: "Duty Amount",
  ItemDepth: "깊이",
  ItemHeight: "높이",
  ItemQuantity: "상품수량",
  ItemName: "상품명",
  TrackingNumber: "송장번호",
  ItemWeight: "무게(kg)",
  ItemVolumeWeight: "부피무게(kg)",
  ItemTypeName: "아이템 타입",
  DeliveryLocationCode: "배송지역 코드 ",
  DeliveryLocationName: "배송지역",
  TotalChargeableWeight: "Chargeable Weight(kg)",
}

const OrderCreatePurchaseItemList = ({

  orderData = null,
  onChangeItem,
  onDeleteItem,
}) => {

  const {
    renderColumn,
    rowClassName,
    getSummaryData,
  } = useDataTableColumns({
    fieldColumnTitleMap,
    getData: NOOP,
    dataSize: (orderData?.PurchaseOrderItems?.length ?? -1) + 1,
  });

  const [selectedItem, setSelectedItem] = useState(null);
  const setCreateOrderItemDialogState = useSetRecoilState(  createPurchaseOrderItemDialogState);

  /***********************************************************
   * 상품 리스트
   ***********************************************************/
  const orderItemList = useMemo(() => {
    const list = orderData?.PurchaseOrderItems;
    if (isEmpty(list)) {
      return [];
    }

    const summaryFields = [
      "ItemAmount",
      "ItemQuantity",
      "ItemWeight",
      "ItemVolumeWeight",
      "ExchangedItemAmount",
      "TotalChargeableWeight",
    ];

    const summaryData = getSummaryData(list, summaryFields);

    return [
      ...list,
      {
        ...summaryData,
        DeliveryLocationCode: orderData?.DeliveryLocationCode,
        ItemQuantity: Number(summaryData.ItemQuantity).toFixed(0),
      },
    ]
  }, [orderData, getSummaryData]);

  const titleSection = useMemo(() => (
    <TableTitle linkName={"OrderCreateItemList"}>
      <h5>상품정보</h5>
    </TableTitle>
  ), []);


  const onUpdateClicked = useCreateActionButton({
    onSelect: onChangeItem,
    confirmMessage: "상품을 수정하시겠습니까?",
    deleteConfirmMessage: "상품을 삭제하시겠습니까?",
    onDelete: onDeleteItem,
    atom:   createPurchaseOrderItemDialogState,
    readOnly: !!orderData?.ConsignmentNumber,
    shouldShowConfirm: false,
    dialogComponent: (props) => {
      return (
        <CreatePurchaseOrderItemDialog title={"상품 수정"} {...props} />
      );
    },
  });


  useEffect(() => {
    if (selectedItem && !selectedItem.IsSummaryData) {

      setCreateOrderItemDialogState(selectedItem);
      onUpdateClicked().finally(() => setSelectedItem(null));
    }
    // onUpdateClicked 를 디펜던시에 넣으면 안되요!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, setCreateOrderItemDialogState]);

  return (
    <CardContainer css={orderItemListStyle}>
      <DataTableActions className={"mb-2"} titleSection={titleSection}>
        <CreateActionButton
          label="상품추가"
          onSelect={onChangeItem}
          confirmMessage={"상품을 추가하시겠습니까?"}
          atom={  createPurchaseOrderItemDialogState}
          dialogComponent={CreatePurchaseOrderItemDialog}
          shouldShowConfirm={false}
        />
      </DataTableActions>

      <ScrollDataTableContainer loading={!orderData} minWidth={1200}>
        <DataTable
          resizableColumns
          columnResizeMode="fit"
          rowClassName={rowClassName}
          //loading={!orderData}
          value={orderItemList}
          selectionMode={"single"}
          selection={selectedItem}
          onSelectionChange={(e) => setSelectedItem(e.value)}
          dataKey="OrderItemId"
          className="datatable-responsive"
          emptyMessage="No data found."
        >
          {renderColumn("ItemName")}
          {renderColumn("ItemTypeName")}
          {renderColumn("DeliveryCompany")}
          {renderColumn("TrackingNumber")}
          {renderColumn("IsSummaryData", { headerStyle: { width: '80px' } })}
          {renderColumn("ItemQuantity")}
          {renderColumn("ItemAmount", { formatValue: formatNumber })}

        </DataTable>
      </ScrollDataTableContainer>
    </CardContainer>
  )
}

export default OrderCreatePurchaseItemList;

const orderItemListStyle = css`
    .p-datatable .p-datatable-thead > tr > th {
        &.header-column-TotalChargeableWeight,
        &.header-column-ExchangedItemAmount,
        &.header-column-ItemQuantity,
        &.header-column-ItemWeight,
        &.header-column-ItemVolumeWeight,
        &.header-column-ItemAmount {
            text-align: right;

            .p-column-title {
                padding-right: 20px;
            }
        }

        &.header-column-TotalChargeableWeight {
            width: 190px;
        }
        
        &.header-column-ItemWeight {
            width: 160px;
        }

        &.header-column-ItemVolumeWeight {
            width: 140px;
        }

        &.header-column-ExchangedItemAmount,
        &.header-column-ItemAmount {
            width: 140px;
        }
    }

    .column-TotalChargeableWeight,
    .column-IsSummaryData,
    .column-ExchangedItemAmount,
    .column-ItemQuantity,
    .column-ItemWeight,
    .column-ItemVolumeWeight,
    .column-ItemAmount {
        text-align: right;
        padding-right: 20px;
    }
`;