import React from "react";
import Header from "./Header";
import Body from "./Body";
import Weight from "./Weight";
import Footer from "./Footer";

const ConsignmentPrintItem = ({
    consignment,
    className,
}) => {
    return (
        <div className={className}>
            <Header consignment={consignment} />
            <Body consignment={consignment} />
            <Weight consignment={consignment} />
            <Footer consignment={consignment} />
        </div>
    )
}

export default ConsignmentPrintItem;