import React, { useCallback, useMemo } from "react";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";

import { NOOP } from "../../../../includes/constants";
import { useAwaitingDialogContext } from "../../../../core/contexts/AwaitingDialogContext";

import AwaitButton from "../../../../components/AwaitButton";
import DataTableActions from "../../../../components/layouts/DataTableActions";
import { Button } from "primereact/button";

const QnaDetail = ({
    qnaData,
    toggleEditModeClicked = NOOP,
}) => {
    const { closeConfirmation } = useAwaitingDialogContext();
    const history = useHistory();

    const goBackToList = useCallback(() => {
        history.goBack();
        closeConfirmation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    const leftSectionButtons = useMemo(() => {
        return (
            <AwaitButton
                icon="pi pi-check"
                label={"게시글 수정"}
                type="button"
                css={buttonStyle}
                onClick={toggleEditModeClicked}
            />
        )
    }, [toggleEditModeClicked]);

    if (!qnaData) {
        return null;
    }

    return (
        <div className="col-12" css={qnaDetailStyle}>
            <div className="p-fluid formgrid grid" css={maxWidthStyle}>
                <DataTableActions className={"mb-5"} direction={"right"} titleSection={leftSectionButtons}>
                    <Button
                        className={"p-button-info"}
                        icon="pi pi-list"
                        label={"목록"}
                        type="button"
                        onClick={goBackToList}
                    />
                </DataTableActions>


                <div className="field grid col-12">
                    <label htmlFor="Title">제목</label>
                    <div className="mb-1 flex align-items-center">
                        <div className="p-text-left" name={"Title"}>{qnaData.Title}</div>
                    </div>
                </div>

                <div className="field grid col-12 md:col-4">
                    <label htmlFor="WriterName">작성자</label>
                    <div className="mb-1 flex align-items-center">
                        <div className="p-text-left">{qnaData.WriterName}</div>
                    </div>
                </div>

                <div className="field grid col-12  md:col-4">
                    <label htmlFor="IsActive">공개상태</label>
                    <div className="mb-1 flex align-items-center">
                        <div className="p-text-left">{qnaData.IsSecret ? "비밀글" : "공개글"}</div>
                    </div>
                </div>

                <div className="field grid col-12  md:col-4">
                    <label htmlFor="IsActive">작성일</label>
                    <div className="mb-1 flex align-items-center">
                        <div className="p-text-left">{qnaData.CreateDate}</div>
                    </div>
                </div>

                <div className="field grid col-12">
                    <label htmlFor="Contents">내용</label>
                    <div
                        className="p-text-left"
                        dangerouslySetInnerHTML={{ __html: qnaData.Contents }}
                        css={qnaContentStyle}
                    />
                </div>

            </div>
        </div>
    )
}

export default QnaDetail;

const buttonStyle = css`
    margin-right: 10px;
`;

const maxWidthStyle = css`
    max-width: 800px;
`;

const qnaContentStyle = css`
    border: 1px solid grey;
    display: table-cell;
    border-radius: 3px;
    width: 800px;
    padding: 20px;

    img {
        max-width: 100%;
    }
`;

const qnaDetailStyle = css`
    label {
        min-width: 80px;
        max-width: 80px;
        padding-left: 8px;

        :after {
            content: ':';
        }
    }
`;