import React from "react";
import { css } from "@emotion/react";

const AddressBoxContainer = ({
    children,
    className,
}) => (
    <div css={addressBoxContainerStyle} className={className}>
        {children}
    </div>
);

export default AddressBoxContainer;

const addressBoxContainerStyle = css`
    text-align: left;
    min-height: 80px;
`;